import { useParams } from 'react-router-dom'
import Animals from 'pages/panels/animals/Animals.overview'
import Boundaries from 'pages/panels/boundaries/Boundary.overview'
import Herds from 'pages/panels/herds/Herd.overview'

export const Overview: React.FC = () => {
	const params = useParams<{ type: string }>()

	const Page = () => {
		switch (params.type) {
		case 'animal':
		case 'animals': {
			return (
				<>
					<Animals />
				</>
			)
		}
		case 'features':
		case 'salt-and-mineral-deposit':
		case 'water-tank':
		case 'gate':
		case 'pasture':
		case 'exclusion':
		case 'pastures': {
			return <Boundaries />
		}
		case 'herd':
		case 'herds': {
			return <Herds />
		}
		default: {
			return null
		}
		}
	}
	return (
		<>
			<Page />
			{/* <Outlet /> */}
		</>
	)
}
export default Overview
