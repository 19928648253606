import * as uuid from 'uuid'
import Herd from './herd.model'
export class Animal {
	public id: string
	public secondaryId?: string
	public tag: string
	public deviceId: string
	public herd: Partial<Herd>
	public herdId?: string
	public herdName?: string
	public lastEventTimestamp?: Date
	public lastCoordinate?: [number, number]
	public lastMovement?: Date
	public createdDate?: Date
	public dob?: Date
	public sireId?: string
	public damId?: string
	public breed?: string
	public tagColor?: string
	public sex?: string
	public archived: boolean
	public images: AnimalImage[]
	public archiveDate?: Date
	public archiveReason?: string
	public enroute: boolean
	public pastureName?: string
	public deviceVoltage?: number
	public batteryCharge?: number
	public animalEvents: AnimalEvent[]
	public deleted: boolean
	public new?: boolean

	constructor(props?: Partial<Animal>) {
		this.id = props?.id || uuid.v4().toString()
		this.tag = props?.tag || ''
		this.deviceId = props?.deviceId || ''
		this.lastEventTimestamp = props?.lastEventTimestamp
		this.lastCoordinate = props?.lastCoordinate
		this.herd = props?.herd || new Herd({id: props?.herdId, name: props?.herdName}),
		this.herdId = props?.herdId
		this.herdName = props?.herdName
		this.lastMovement = props?.lastMovement
		this.secondaryId = props?.secondaryId
		this.createdDate = props?.createdDate || new Date()
		this.dob = props?.dob || new Date()
		this.sireId = props?.sireId
		this.damId = props?.damId
		this.breed = props?.breed
		this.tagColor = props?.tagColor
		this.sex = props?.sex
		this.images = props?.images || []
		this.archived = props?.archived || false
		this.archiveDate = props?.archiveDate
		this.archiveReason = props?.archiveReason
		this.enroute = props?.enroute || false
		this.pastureName = props?.pastureName
		this.deviceVoltage = props?.deviceVoltage
		this.batteryCharge = props?.batteryCharge
		this.animalEvents = props?.animalEvents || [],
		this.deleted = false,
		this.new = props?.new || false
		return this
	}
}

export class AnimalImage {
	id: string
	animalId: string
	addedById: string
	caption?: string
	addedBy?: User
	primary?: boolean
	seq?: number
	imageFile: ImageFile | null
	constructor(props: AnimalImage) { 
		this.id = props?.id || uuid.v4().toString()
		this.animalId = props.animalId
		this.addedById = props.addedById
		this.caption = props?.caption
		this.addedBy = props?.addedBy
		this.primary = props?.primary || false
		this.imageFile = props?.imageFile || null
		this.seq = props?.seq
	}
}

export class AnimalEvent {
	id: string
	animalId: string
	eventType: AnimalEventType['type'] | null
	subType: AnimalEventType['type'] | null
	weight: number | null
	cost: number | null
	notes: string | null
	addedById: string
	eventDate: Date
	healthWatch?: Date
	userName: string
	images: AnimalEventImage[]
	constructor(props: AnimalEvent) {
		this.id = props?.id || uuid.v4().toString()
		this.animalId = props.animalId
		this.eventType = props.eventType
		this.subType = props.subType
		this.weight = props.weight
		this.cost = props.cost
		this.notes = props.notes
		this.addedById = props.addedById
		this.eventDate = props.eventDate || new Date()
		this.healthWatch = props.healthWatch
		this.userName = props.userName
		this.images = props.images || []
	}
}

export type AnimalEventType = {
	type: 'Acquired' | 'Artificial Insem' | 'Bad' | 'Caesarian' | 'Calving' | 'Deceased' | 'Easy Pull' | 'Good' | 'Hard Pull' | 'Health Concern' | 'Improving' | 'Malpresentation' | 'Not Pregnant' | 'Penicillin' | 'Poor' | 'PregCheck' | 'Pregnant - AI' | 'Pregnant - Bull' | 'Sold' | 'Surgery' | 'Unassisted' | 'Vaccination' | 'Weaning' | 'Yearling'
	description: string | null,
	parentType: AnimalEventType['type'] | null
}

export type AnimalEventImage = {
	id: string
	animalEventId: string
	addedById: string
	caption: string
	addedBy: string
	seq: number
	imageFile: ImageFile | null
}

export default Animal