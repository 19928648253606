import { useGetRanchQuery, useGetRanchesQuery, useUpdateRanchMutation } from 'library/api/ranch'
import { useGetUserQuery } from 'library/api/user'
import { Container } from 'components/base'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Image } from 'components/Image'
import { colors, device } from 'styles'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { Input } from 'components/inputs'
import { Icon } from 'components/icons'
import { formatPhoneNumber } from 'utilities'

export const Ranch = () => {
	const { id } = useParams()
	const { data: currentUser } = useGetUserQuery()
	const { data: ranch } = useGetRanchQuery({ id })
	const { mutate: updateRanch } = useUpdateRanchMutation()

	const [canEdit, setCanEdit] = useState(false)
	const [editRanchDetails, setEditRanchDetails] = useState(false)
	const [ranchDetails, setRanchDetails] = useState<any>({
		name: '',
		address: '',
		phone: '',
		loaded: false
	})

	useEffect(() => {
		setCanEdit(currentUser?.currentRanch?.id === id && (currentUser?.currentRole === 'Manager' || currentUser?.currentRole === 'Owner'))
		setRanchDetails({
			name: ranch?.name,
			address: ranch?.address,
			phone: ranch?.phone,
			loaded: true
		})
	}, [currentUser?.currentRanch?.id, currentUser?.currentRole, id, ranch?.address, ranch?.name, ranch?.phone])

	return (
		<Container style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
			<ProfileCard>
				<ProfileCardImage>
					{ranch?.imageFile ? <Image alt={'profile'} src={`file/${ranch?.imageFile?.id}/0`} /> : null}
				</ProfileCardImage>
				<RanchInfo>
					<UserInfoName>{ranch?.name}</UserInfoName>
					<h2>{ranch?.address.address1}</h2>
				</RanchInfo>
			</ProfileCard>
			<Card>
				<form onSubmit={async (event: SyntheticEvent) => {
					event.preventDefault()
					setEditRanchDetails(false)
					
					const payload: any = {
						...ranch,
						name: ranchDetails.name,
						phone: ranchDetails.phone,
						address: ranchDetails.address
					}

					updateRanch(payload)
					event.preventDefault()
				}}>
					<CardHeader>
						<h3>Ranch Info</h3>
						{canEdit &&
							<ButtonContainer>
								{editRanchDetails &&
									<>
										<EditButton type={'submit'} >
											Done
											<Icon name={'check'} />
										</EditButton>
										<EditButton type={'button'} onClick={e => {
											setEditRanchDetails(false)
											e.preventDefault()
										}}>
											Cancel
											<Icon name={'block'} />
										</EditButton>
									</>
								}
								{!editRanchDetails &&
									<>
										<EditButton type={'button'}
											onClick={e => {
												setEditRanchDetails(true)
												e.preventDefault()
											}}>
											Edit
											<Icon name={'edit-alt'} />
										</EditButton>
									</>
								}
							</ButtonContainer>
						}
					</CardHeader>
					<Grid>
						<Input
							disabled={!editRanchDetails}
							label={'Name'}
							maxLength={50}
							defaultValue={ranch?.name}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setRanchDetails({ ...ranchDetails, name: e.target.value })
								e.preventDefault()
							}}
						/>
						<Input
							disabled={!editRanchDetails}
							label={'Address'}
							maxLength={50}
							defaultValue={ranch?.address.address1}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setRanchDetails({ ...ranchDetails, address: { ...ranchDetails.address, address1: e.target.value } })
								e.preventDefault()
							}}
						/>
						<Input
							disabled={!editRanchDetails}
							label={'Phone'}
							type={'phone'}
							autoComplete={'phone'}
							maxLength={50}
							defaultValue={ranch?.phone || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setRanchDetails({ ...ranchDetails, phone: formatPhoneNumber(e.target.value) })
								e.preventDefault()
							}}
						/>
					</Grid>

				</form>
			</Card>
		</Container>
	)
}

const ProfileCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const Card = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const CardHeader = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    @media (${device.max.laptop}) {
        grid-template-columns: 1fr;
    }
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: flex-end;
    justify-content: right;
    height: fit-content;
    // width: fit-content;
    gap: 0.4rem;
    button {
        margin: 0.4rem 0rem;
        min-width: 96px;
        width: fit-content;
    }
`
const ProfileCardImage = styled.div`
		padding: 0;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		overflow: hidden;
		border: none;
		flex-shrink: 0;
		margin: auto;
		background: ${transparentize(.9, colors.dark)};
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (${device.max.mobile}) {
			width: 75px;
			height: 75px;
		}
`
const RanchInfo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
`
const UserInfoName = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    color: ${colors.dark};
`
const EditButton = styled.button`
    padding: .5em;
    border-radius: 999999px;
    color: red;
    color: ${transparentize(.2, colors.dark)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25em;
    border: 1px solid ${transparentize(.9, colors.dark)};
    background: transparent;
`
