import { useEndpoints } from './endpoints'
import { useQuery,  useMutation, MutationMeta, UseQueryOptions, MutationFunction, UseMutationOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { AxiosError, AxiosResponse } from 'axios'
import * as turf from '@turf/turf'

export const useGetAnimalsQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Animal[], AxiosError>>) => {
	const { animal } = useEndpoints()
	return useQuery<Animal[], AxiosError>({
		queryKey: ['animals'],
		queryFn: () => animal.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		staleTime: 1000 * 60 * 60 * 24,
		refetchInterval: 1000 * 60 * 5,
		refetchOnReconnect: true,
		refetchOnMount: true,
		...options
	})}

export const useGetArchivedAnimalsQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Animal[], AxiosError>>) => {
	const { animal } = useEndpoints()
	return useQuery<Animal[], AxiosError>({
		queryKey: ['animals', 'archived'],
		queryFn: () => animal.query.archived.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useGetAnimalsByHerdIdQuery = (payload: { id: string | undefined, includeArchived?: boolean }, options?: Partial<UseQueryOptions<Animal[], AxiosError>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Animal[], AxiosError>({
		queryKey: ['animals', 'herd', payload.id, payload.includeArchived],
		queryFn: () => animal.query.byHerd(payload),
		initialData: () => {
			return queryClient.getQueryData<Animal[]>(['animals'])?.filter((animal) => animal.herdId === payload.id)
		},
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetArchievedAnimalsByHerdIdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Animal[], AxiosError>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Animal[], AxiosError>({
		queryKey: ['animals', 'archieved', 'herd', payload.id],
		queryFn: () => animal.query.archived.byHerd(payload),
		initialData: () => {
			return queryClient.getQueryData<Animal[]>(['animals', 'archived'])?.filter((animal) => animal.herdId === payload.id)
		},
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetAnimalQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Animal, AxiosError>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Animal, AxiosError>({
		queryKey: ['animals', payload.id],
		queryFn: () => animal.query.byId(payload),
		initialData: () => {
			const placeholder = queryClient.getQueryData<Animal[]>(['animals'])?.find((animal) => animal.id === payload.id)
			return placeholder
		},
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetAnimalByDeviceIdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Animal, AxiosError>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Animal, AxiosError>({
		queryKey: ['animals', 'device', payload.id],
		queryFn: () => animal.query.byDevice(payload),
		initialData: () => {
			return queryClient.getQueryData<Animal[]>(['animals'])?.find((animal) => animal.deviceId === payload.id)
		},
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetAnimalByTagQuery = (payload: { tag: string | undefined }, options?: Partial<UseQueryOptions<Animal, AxiosError>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Animal, AxiosError>({
		queryKey: ['animals', 'tag', payload.tag],
		queryFn: () => animal.query.byTag(payload),
		initialData: () => {
			return queryClient.getQueryData<Animal[]>(['animals'])?.find((animal) => animal.tag === payload.tag)
		},
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetAnimalBreedsQuery = (options?: Partial<UseQueryOptions<Breed[], AxiosError>>) => {
	const { animal } = useEndpoints()
	return useQuery<Breed[], AxiosError>({
		queryKey: ['breeds'],
		queryFn: ()=> animal.query.breeds(),
		networkMode: 'offlineFirst',
		staleTime: Infinity,
    
		...options
	})}

export const useGetAnimalHistoryQuery = (payload: AnimalHistoryParams, options?: Partial<UseQueryOptions<AnimalHistory, AxiosError>>) => {
	const { animal } = useEndpoints()
	return useQuery<AnimalHistory, AxiosError>({
		queryKey: ['animals', 'history', payload.id, moment(payload.startDate).format('YYYY-MM-DDT00:00:00'), moment(payload.endDate).format('YYYY-MM-DDT23:59:59'), payload?.hasCoordinates],
		queryFn: () => animal.query.history(payload),
		networkMode: 'offlineFirst',
		...options
	})
}
export const useLazyGetAnimalHistoryQuery = (options?: Partial<MutationFunction<AnimalHistory, Promise<AnimalHistory>>>) => {
	const { animal } = useEndpoints()
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: (payload: AnimalHistoryParams) => animal.query.history(payload),
		onSuccess: (data, variables, context) => {
			const startDate = moment(variables.startDate).format('YYYY-MM-DDT00:00:00')
			const endDate = moment(variables.endDate).format('YYYY-MM-DDT23:59:59')
			queryClient.setQueryData(['animals', 'history', variables.id, startDate, endDate, variables?.hasCoordinates], data)
		},
		...options
	})}


export const useUpdateAnimalMutation = (options?: Partial<MutationFunction<Animal, AxiosResponse<Animal, AxiosError>>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.update'],
		onMutate: (payload: Animal) => {
			queryClient.cancelQueries({ queryKey: ['animals'] })
			queryClient.cancelQueries({ queryKey: ['animal-features'] })
			queryClient.cancelQueries({ queryKey: ['animals', payload.id] })
			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			const previousAnimal = queryClient.getQueryData<Animal>(['animals', payload.id])
			const previousAnimalFeatures = queryClient.getQueryData<turf.FeatureCollection<turf.Point, Animal>>(['animal-features'])
			queryClient.setQueryData(['animals'], previousAnimals ? previousAnimals.map(animal => animal.id === payload.id ? { ...animal, ...payload } : animal) : [])
			queryClient.setQueryData(['animals', payload.id], previousAnimal ? {...previousAnimal, ...payload} : payload)
			
			// Update the animals on the map with the new tag if that changes
			queryClient.setQueryData(['animal-features'], (old: turf.FeatureCollection<turf.Point, Animal>) => {
				const updatedFeatures = old.features.map((feature: turf.Feature<turf.Point, Animal>) => {
					if (feature.id === payload.id) return {...feature, properties: {...feature.properties, tag: payload.tag}}
					return feature
				})
				return {...old, features: updatedFeatures}
			})
			return { animals: previousAnimals, animal: previousAnimal, features: previousAnimalFeatures }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.id] })
			queryClient.invalidateQueries({ queryKey: ['animal-features'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context?.animals)
			queryClient.setQueryData(['animal-features'], context?.features)
			queryClient.setQueryData(['animals', variables.id], context?.animal)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useCreateAnimalMutation = (options?: Partial<MutationFunction<Animal, Promise<Animal>>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.create'],
		onMutate: (payload: Animal) => {
        
			queryClient.cancelQueries({ queryKey: ['animals'] })
			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			queryClient.setQueryData(['animals'], previousAnimals ? [...previousAnimals, payload] : [payload])
			queryClient.setQueryData(['animals', payload.id], payload)
			queryClient.setQueryData(['animals', 'herd', payload.herd.id, false], (old: Animal[]) => old ? [...old, payload] : [payload])
			queryClient.setQueryData(['herds', payload.herd.id], (old: Herd) => old ? {...old, ...payload.herd} : payload.herd)
			return previousAnimals
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context)
			queryClient.invalidateQueries({ queryKey: ['animals', variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useArchiveAnimalMutation = (options?: Partial<UseMutationOptions<Animal, AxiosError, { id: string, archived: boolean, archiveDate: Date, archiveReason: string }, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.archive'],
		onMutate: (payload: { id: string, archived: boolean, archiveDate: Date, archiveReason: string }) => {
			queryClient.cancelQueries({ queryKey: ['animals'] })
			queryClient.cancelQueries({ queryKey: ['animals', payload.id] })
			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			const previousAnimal = queryClient.getQueryData<Animal>(['animals', payload.id])
			queryClient.setQueryData(['animals'], previousAnimals ? previousAnimals.map(animal => animal.id === payload.id ? {...animal, deleted: true} : animal) : [])
			queryClient.setQueryData(['animals', payload.id], previousAnimal ? {...previousAnimal, deleted: true} : undefined)
			return { animals: previousAnimals, animal: previousAnimal }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context?.animals)
			queryClient.setQueryData(['animals', variables.id], context?.animal)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useAddAnimalImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.image.create'],
		onMutate: (payload: {animalId: string, image: ImageUpload}) => {
			queryClient.cancelQueries({ queryKey: ['animals'] })
			queryClient.cancelQueries({ queryKey: ['animals', payload.animalId] })

			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			queryClient.setQueryData(['animals'], previousAnimals ? previousAnimals.map(animal => animal.id === payload.animalId ? {...animal, image: payload.image} : animal) : [])

			const previousAnimal = queryClient.getQueryData<Animal>(['animals', payload.animalId])
			const currentUser = queryClient.getQueryData<User>(['user', 'current'])
			if(previousAnimal) {
				const image = {
					id: payload.image.id, 
					imageFile: payload.image,
					animalId: payload.animalId,
					caption: '',
					isPrimary: false,
					addedBy: currentUser,
					addedById: currentUser?.id || '',
				} as unknown as AnimalImage
			
				const animal = {...previousAnimal, images: [...previousAnimal.images, image]}
				queryClient.setQueryData<Animal>(['animals', payload.animalId], animal)
			}
			return { animals: previousAnimals, animal: previousAnimal }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.animalId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context?.animals)
			queryClient.setQueryData(['animals', variables.animalId], context?.animal)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useUpdateAnimalImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.image.update'],
		onMutate: (payload: { animalId: string, imageId: string, image: UpdateAnimalImageParams }) => {
			queryClient.cancelQueries({ queryKey: ['animals'] })
			queryClient.cancelQueries({ queryKey: ['animals',  payload.animalId] })
        
			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			const animals = previousAnimals?.map(animal => animal.id === payload.animalId ? {...animal, images: [...animal.images ]} : animal)
			queryClient.setQueryData<Animal[]>(['animals'], animals || [])
        
			const previousAnimal = queryClient.getQueryData<Animal>(['animals',  payload.animalId])
			const animal = previousAnimal ? {...previousAnimal, images: previousAnimal.images.map((img: AnimalImage) => img.id === payload.imageId ? {...img, ...payload.image} : img)} : undefined
			queryClient.setQueryData<Animal>(['animals', payload.animalId], animal)
        
			return { animals: previousAnimals, animal: previousAnimal }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.animalId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context?.animals)
			queryClient.setQueryData(['animals', variables.animalId], context?.animal)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useRemoveAnimalImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['animal.mutation.image.delete'],
		onMutate: (payload: { animalId: string, imageId: string }) => {
			queryClient.cancelQueries({ queryKey: ['animals'] })
			queryClient.cancelQueries({ queryKey: ['animals',  payload.animalId] })
        
			const previousAnimals = queryClient.getQueryData<Animal[]>(['animals'])
			const animals = previousAnimals?.map(animal => animal.id === payload.animalId ? {...animal, images: animal.images.filter((img: AnimalImage) => img.id !== payload.imageId)} : animal)
			queryClient.setQueryData<Animal[]>(['Animals'], animals || [])
        
			const previousAnimal = queryClient.getQueryData<Animal>(['animals', { id: payload.animalId }])
			const animal = previousAnimal ? {...previousAnimal, images: previousAnimal.images.filter((img: AnimalImage) => img.id !== payload.imageId)} : undefined
			queryClient.setQueryData<Animal>(['animals', payload.animalId], animal)

			return { animals: previousAnimals, animal: previousAnimal }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['animals', variables.animalId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['animals'], context?.animals)
			queryClient.setQueryData(['animals', variables.animalId], context?.animal)
		},
		networkMode: 'offlineFirst',
		...options
	})
}