import * as turf from '@turf/turf'
import { useGetHerdQuery } from 'library/api/herd'
import DateRangeCalendar from 'components/DateRangeCalendar'
import { useAnimalsLayer, useGrazingDistributionLayer, useAnimalTrailLayer } from 'components/map/layers'
import { useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useGetAnimalQuery } from 'library/api/animal'
import styled from 'styled-components/macro'
import { routeSlice } from 'store/route.slice'
import { store } from 'store'
import { BottomSheetRef } from 'react-spring-bottom-sheet'
import { colors, device } from 'styles'
import LoadingSpinner from 'components/LoadingSpinner'
import { clamp } from 'utilities'
import { transparentize } from 'polished'

export const LocationHistory = () => {
	const { id } = useParams<{id: string}>()
	const outletContext = useOutletContext<BottomSheetRef>()
	const animals = useAnimalsLayer()
	const trail = useAnimalTrailLayer()
	const { MapView } = useMap()
	const grazingDistribution = useGrazingDistributionLayer()
	const navigate = useNavigate()

	// useGetAnimalQuery

	const { data: animal } = useGetAnimalQuery({ id })
	const { data: herd }: any = useGetHerdQuery({ id: animal?.herdId }, {
		enabled: !!animal?.herdId,
	})

	const [dateRange, setDateRange] = useState<Date[]>([herd?.pastureAssignmentDate || herd?.pastureAssignmentDate ? new Date(herd?.pastureAssignmentDate) : new Date(), new Date()])
	const [animalDataIsEmpty, setAnimalDataIsEmpty] = useState<boolean>(false)
	const [animalDataState, setAnimalDataState] = useState<any>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [foundDataDateRangeStart, setFoundDataDateRangeStart] = useState<Date>()
	const [foundDataDateRangeEnd, setFoundDataDateRangeEnd] = useState<Date>()
	const [error, setError] = useState<string | null>()
	const [helpText, setHelpText] = useState<string | null>()

	const displayDateRange = useMemo(() => {
		if (!dateRange || dateRange.length < 1) return
		if (dateRange[1].getFullYear() - dateRange[0].getFullYear() > 1) {
			const newStartDate = new Date()
			newStartDate.setMonth(1)
			newStartDate.setFullYear(dateRange[1].getFullYear())
			return [newStartDate, dateRange[1]]
		}
		return dateRange
	}, [dateRange])


	async function getLocationHistory([startTime, endTime]: Date[]) {
		trail.reset()
		setError(null)
		setAnimalDataState(null)
		setHelpText(null)
		// TODO: Why does an animal need a pasture assignment date to have a location history?
		if (!herd?.pastureAssignmentDate) {
			setError('This animal isn\'t assigned to a pasture')
			setHelpText('A herd can be assigned to a pasture in the herd details page')
			return
		}
		setIsLoading(true)
		let animalData
		if (animal?.id || id) {
			animalData = await trail.getData({ id: animal?.id || id || '', startTime: startTime, endTime: endTime })
		}
		setIsLoading(false)

		if (!animalData || animalData.waypoints.length === 0) {
			setError('No location events found')
			setHelpText('Please select a different date range')
			return
		}

		setAnimalDataState(animalData)

		setFoundDataDateRangeStart(new Date(animalData.waypoints[0].eventTimestamp))
		setFoundDataDateRangeEnd(new Date(animalData.waypoints[animalData.waypoints.length - 1].eventTimestamp))

		trail.setVisible(true)
		grazingDistribution.setVisible(false)
		animals.setVisible(false)
		if(window.innerWidth < device.size.tablet) {
			setTimeout(() => {
				outletContext?.snapTo(({maxHeight}) => {
					store.dispatch(routeSlice.actions.setSnapToIndex(0))
					return 0;
				})
			}, 100)
		}
		trail.setDateRange([startTime, endTime])
		if (animalData?.waypoints?.length) {
			animals.setVisible(false)
			const coords = animalData?.waypoints?.map((waypoint: any, index: number) => {
				return turf.point(waypoint.coordinate)
			})
			let [minLng, minLat, maxLng, maxLat] = turf.bbox(turf.featureCollection(coords))
			minLng = clamp(minLng, -180, 180)
			maxLng = clamp(maxLng, -180, 180)
			minLat = clamp(minLat, -90, 90)
			maxLat = clamp(maxLat, -90, 90)
			MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: 100 })
		}
		trail.setPlaying(true)

	}
	
	return (
		<Container>
			<DateRangeCalendar
				standAlone={true}
				id={'location-history-date-range'}
				dateRange={displayDateRange || dateRange}
				onCancel={() => navigate(-1)}
				onChange={async (e: Date[]) => {
					setError(null)
					setAnimalDataState(null)
					setHelpText(null)
					setDateRange(e)
					getLocationHistory(e)
				}}
			/>
			{isLoading && <LoadingOverlay><LoadingSpinner/></LoadingOverlay>}
			<EventsFoundContainer>
				{animalDataState && animalDataState.waypoints.length ? <span>
					{animalDataState.waypoints.length} location event{animalDataState.waypoints.length === 1 ? '' : 's'} found
				</span> : null}
				{error || helpText ? <ErrorContainer>
					{error ? <Error>{error}</Error> : null}
					{helpText ? <Help>{helpText}</Help> : null}
				</ErrorContainer> 
					: null}
				
			</EventsFoundContainer>
		</Container>
	)
}

const Container = styled.div`
	padding: 0.5em;
	position: relative;
`
const LoadingOverlay = styled.div`
	position: absolute;
	inset: 0;
	background: ${transparentize(0.2, '#ffffff')};
	display: flex;
	justify-content: center;
	align-items: center;
`
const EventsFoundContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: .5em;
	width: 100%;
	justify-content: center;
	gap: .5em;
	align-items: center;
	text-align: center;
	`
const ErrorContainer = styled(EventsFoundContainer)`
	background: ${colors.error};
	border-radius: var(--border-radius);
	padding: .5em;
	text-align: left;

`
const Error = styled.div`
	color: #ffffff;
	width: 100%;
	font-size: .9em;
	font-weight: 700;
`
const Help = styled.div`
	font-size: 0.8em;
	color: #ffffff;
	width: 100%;
`