import styled from 'styled-components/macro'
import { ProfileImage } from 'components/ProfileImage'
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRemoveUserFromRanchMutation, useGetUserQuery, useGetUserByIdQuery, useAddUserToRanchMutation, useUpdateUserMutation, useAddUserImageMutation } from 'library/api/user'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import LoadingSpinner from 'components/LoadingSpinner'
import { ChipContainer, Chip } from 'components/buttons'
import { Input } from 'components/inputs'
import { Container } from 'components/base'
import { Icon } from 'components/icons'
import { formatPhoneNumber } from 'utilities'
import { AvatarInput } from 'components/inputs/AvatarInput'
import * as uuid from 'uuid'
import { useAddAccountImageMutation, useGetAccountQuery, useUpdateAccountMutation } from 'library/api/account'
import { useColors } from 'hooks'

export const AccountProfile = () => {
	const { data: user } = useGetUserQuery()
	const { mutate: updateAccount } = useUpdateAccountMutation()
	const { data: account }: { data: any } = useGetAccountQuery()
	const { mutate: addAccountImage } = useAddAccountImageMutation()
	const colors = useColors()


	const [editAccountDetails, setEditAccountDetails] = useState(false)

	const [accountDetails, setAccountDetails] = useState<any>({
		name: '',
		phone: '',
		address: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			postalCode: '',
		},
		loaded: false
	})

	useEffect(() => {	
		setAccountDetails({
			name: account?.name,
			phone: account?.phone,
			address: account?.address,
			loaded: true
		})
	}, [account, account?.address, account?.name, account?.phone])

	const initials = useMemo(() => {
		if (account?.name) {
			return account.name.split(' ').map((word: string) => word.charAt(0)).join('')
		}
		return ''
	}, [account.name])

	if (!account || !user) return <LoadingSpinner />

	return (
		<Container style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
			<ProfileCard>
				<AvatarInput
					placeholder={initials}
					primaryColor={colors.getColorForObject(account).primary}
					backgroundColor={colors.getColorForObject(account).background}
					image={account?.imageFile ? account.imageFile : null}
					onChange={async ({ fileName, contentType, data}) => {
						if (account?.id) {
							addAccountImage({
								id: account.id,
								image: {
									id: uuid.v4(),
									fileName,
									contentType,
									data
								}
							})
						}
					}}
				/>
				<AccountInfo>
					<AccountInfoName>{account?.name}</AccountInfoName>
				</AccountInfo>
			</ProfileCard>

			<Card>
				<form onSubmit={async (event: SyntheticEvent) => {
					event.preventDefault()
					setEditAccountDetails(false)
					updateAccount({
						...account,
						name: accountDetails?.name,
						phone: accountDetails?.phone,
						address: accountDetails?.address,
					})
					event.preventDefault()
				}}>
					<CardHeader>
						<h3>Account Info</h3>
						{user?.currentRole === 'Owner' || user?.currentRole === 'Manager' && <ButtonContainer>
							{editAccountDetails ?
								<>
									<EditButton type={'submit'} >
										Done
										<Icon name={'check'} />
									</EditButton>
									<EditButton type={'button'} onClick={e => {
										e.preventDefault()
										setEditAccountDetails(false)
									}}>
										Cancel
										<Icon name={'block'} />
									</EditButton>
								</>
								: <EditButton type={'button'}
									onClick={e => {
										e.preventDefault()
										setEditAccountDetails(true)
									}}>
									Edit
									<Icon name={'edit-alt'} />
								</EditButton>
							}
						</ButtonContainer>}
					</CardHeader>
					<Grid>
						<Input
							disabled={!editAccountDetails}
							label={'Name'}
							maxLength={50}
							value={accountDetails?.name || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, name: e.target.value })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'Phone'}
							type={'phone'}
							autoComplete={'phone'}
							maxLength={50}
							value={accountDetails?.phone || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, phone: formatPhoneNumber(e.target.value) })
							}}
						/>
					</Grid>
					<h3>Address</h3>
					<Grid>
						<Input
							disabled={!editAccountDetails}
							label={'Address1'}
							maxLength={50}
							value={accountDetails?.address?.address1 || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, address1: e.target.value } })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'Address2'}
							maxLength={50}
							value={accountDetails?.address?.address2 || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, address2: e.target.value } })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'City'}
							maxLength={50}
							value={accountDetails?.address?.city || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, city: e.target.value } })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'State'}
							maxLength={50}
							value={accountDetails?.address?.state || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, state: e.target.value } })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'Country'}
							maxLength={50}
							value={accountDetails?.address?.country || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, country: e.target.value } })
							}} />
						<Input
							disabled={!editAccountDetails}
							label={'Postal Code'}
							maxLength={50}
							value={accountDetails?.address?.postalCode || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								e.preventDefault()
								setAccountDetails({ ...accountDetails, address: { ...accountDetails.address, postalCode: e.target.value } })
							}} />
					</Grid>

				</form>
			</Card>
		</Container>
	)
}

const ModalHeader = styled.div``
const ModalTitle = styled.h3`
    margin: 0;
`
const ModalBody = styled.div`
    padding-bottom: 1em;
    padding-top: 1em;
`
const ProfileCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    @media (${device.max.laptop}) {
        grid-template-columns: 1fr;
    }
`
const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    @media (${device.max.mobileM}) {
        flex-direction: column;
    }
`
const Card = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const CardHeader = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const AccountInfo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
`
const AccountInfoName = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    color: ${colors.dark};
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: flex-end;
    justify-content: right;
    height: fit-content;
    // width: fit-content;
    gap: 0.4rem;
    button {
        margin: 0.4rem 0rem;
        min-width: 96px;
        width: fit-content;
    }
`
const EditButton = styled.button`
    padding: .5em;
    border-radius: 999999px;
    color: red;
    color: ${transparentize(.2, colors.dark)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25em;
    border: 1px solid ${transparentize(.9, colors.dark)};
    background: transparent;
`