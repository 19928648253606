import styled from 'styled-components/macro'
import { colors, alpha } from 'styles'
import { darken, lighten } from 'polished'
const cardActions = ({ className, children }: any) => <div className={className}>{children}</div>

export const CardActions = styled(cardActions)`
	padding-top: 0.5em;
	display: flex;
	gap: 0.5em;
	justify-content: center;
`

export const CardAction = styled.button<{ variant?: string; disabled?: boolean }>`
	all: unset;
	cursor: ${props => (props.disabled ? 'no-drop ' : 'pointer')};;
	color: ${props => (props.disabled ? alpha(colors.dark, 0.4) : colors.dark)};
	font-weight: bold;
	display: flex;
	width: 100%;
	text-align: center;
	flex-direction: column;
	gap: 0;
	font-size: 0.7em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${alpha(colors.dark, 0.95)};
	align-items: center;
	justify-content: center;
	transition: 0.3s ease all;
	&:focus {
		background: ${alpha(colors.dark, 0.85)};
	}
	&:hover {
		background: ${props => (!props.disabled && alpha(colors.dark, 0.9))};
	}
	& > a {
		transition: 0.3s ease all;
		text-decoration: none;
	}
	${props => {
		switch (props.variant) {
		case 'danger': {
			return `
					color: ${colors.error};
					background: ${lighten(0.43, colors.error)};
					&:focus {
						background: ${lighten(0.35, colors.error)};
					}
					&:hover {
						background: ${lighten(0.4, colors.error)};
					}
				`
		}
		}
	}}
`
export const CardDeleteAction = styled(CardAction)`
	color: ${colors.error};
	font-weight: bold;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	text-align: center;
	gap: 0.25em;
	font-size: 0.7em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${alpha(colors.error, 0.9)};
	align-items: center;
	transition: 0.3s ease all;
	&:focus {
		background: ${alpha(colors.error, 0.75)};
	}
	&:hover {
		background: ${alpha(colors.error, 0.85)};
	}
`
