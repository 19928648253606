import * as turf from '@turf/turf'
import * as uuid from 'uuid'

export class PastureStyle {
	backgroundColor: string
	opacity: number
	outlineColor: string
	outlineWidth: number
	constructor(props?: Partial<PastureStyle>) {
		this.backgroundColor = props?.backgroundColor || '#ffffff'
		this.opacity = props?.opacity || .25
		this.outlineColor = props?.outlineColor || '#ffffff'
		this.outlineWidth = props?.outlineWidth || 1
	}

}
export class Pasture {
	id: string
	name: string
	ranchId?: string
	isDeleted: boolean
	style: PastureStyle
	geometry: turf.Geometry
	new?: boolean
	constructor(props?: Partial<Pasture>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.isDeleted = props?.isDeleted || false
		this.ranchId = props?.ranchId || undefined
		this.style = props?.style || new PastureStyle()
		this.geometry = props?.geometry || turf.geometry('Polygon', [])
		this.new = props?.new || false
	}
}

export default Pasture
