import React, { ChangeEvent, useState } from 'react'
import { highlight } from 'utilities'
import { Card, CardItem } from 'components/cards'
import { Label } from 'components/cards/CardItem'
import DateTime from 'components/DateTime'
import { Icon, CowTrailsIcon } from 'components/icons'
import { colors } from 'styles'
import styled from 'styled-components/macro'
import DateRangeCalendar, { DateRange } from 'components/DateRangeCalendar'
import RangeSlider, { RangeSliderProps } from 'components/inputs/RangeSlider'
// import AnimalModal from 'pages/animals/AsnimalModal'
interface CowCardProps {
	cow: Animal
	before?: any
	after?: any
	searchQuery?: string | null | undefined
	onClick?: () => void
	showTrails?: boolean
	onControlChange?: (data: any) => void
	onControlRangeChange?: (data: ChangeEvent) => void
	onEdit?: (event: React.MouseEvent<HTMLButtonElement>) => void
	range?: RangeSliderProps
	togglePlayState?: (data: boolean) => void
	playState?: boolean
}

export const CowCard = ({ cow, searchQuery, before, after, onEdit, showTrails, onControlChange, range, togglePlayState, playState }: CowCardProps) => {
	const [, setShowModal] = useState<boolean>(false)
	const [showControl, setShowControl] = useState<string | null>(null)
	const [dateRange, setDateRange] = useState<DateRange>([new Date(), new Date()])

	return (
		<Card>
			<>
				{before}
				{!showControl ? (
					<CowCardWrapper>
						<Tag>
							<CardItem label={'Tag'} value={highlight(cow.tag, searchQuery)} />
						</Tag>
						<Herd>
							<CardItem label={'Herd'} value={cow?.herdName ? highlight(cow?.herdName, searchQuery) : 'Not Available'} />
						</Herd>
						<CowDateTime date={cow.lastEventTimestamp} />
						<DeviceId>{cow?.deviceId ? highlight(String(cow?.deviceId), searchQuery) : 'Not Available'}</DeviceId>
						<Tools>
							{showTrails && onControlChange ? (
								<Tool
									onClick={_ => {
										if (togglePlayState) togglePlayState(true)
										setShowControl('date')
										onControlChange(dateRange)
									}}>
									<CowTrailsIcon fill={colors.primary} />
								</Tool>
							) : null}
							{onEdit ? (
								<Tool onClick={e => setShowModal(true)}>
									<Icon color={colors.primary} type={'solid'} name={'pencil'} />
								</Tool>
							) : null}
						</Tools>
					</CowCardWrapper>
				) : showControl === 'date' ? (
					<>
						<ButtonWrapper>
							<Button onClick={() => setShowControl(null)}>
								<Icon color={colors.primary} name='left-arrow-alt' />
							</Button>
							<b>Details</b>
						</ButtonWrapper>
						<Controls
							as={DateControl}
							dateRange={dateRange}
							onClose={(control: string | null) => setShowControl(control)}
							onChange={(e: any) => {
								setDateRange(e)
								if (onControlChange) onControlChange(e)
							}}
						/>

						<TimelineControl
							onClose={(control: string | null) => setShowControl(control)}
							onRangeChange={(e: ChangeEvent) => {
								if (range) range.onChange(e)
							}}
							togglePlayState={(e: boolean) => {
								if (togglePlayState) togglePlayState(e)
							}}
							range={range}
							playState={playState ? playState : false}
							onChange={(e: any) => {
								if (onControlChange) onControlChange(e)
							}}
						/>
					</>
				) : null}
			</>
			{after}
			{/* <AnimalModal animal={cow} show={showModal} setShow={(state: boolean) => setShowModal(state)} /> */}
		</Card>
	)
}

const DateControl = ({ className, dateRange, onChange }: { className?: any; dateRange: DateRange; onChange: (e: DateRange) => void; onClose: (control: string | null) => void }) => {
	return (
		<div className={className}>
			<Label>Date Range</Label>
			{/* <DateRangeCalendar
				dateRange={dateRange}
				onChange={(e: Date[]) => {
					onChange(e)
				}}
			/> */}
		</div>
	)
}
const TimelineControl = ({
	className,
	range,
	playState,
	togglePlayState,
}: {
	className?: any
	onChange: (e: DateRange) => void
	onRangeChange: (e: ChangeEvent) => void
	range?: RangeSliderProps
	playState: boolean
	togglePlayState: (value: boolean) => void
	onClose: (control: string | null) => void
}) => {
	return (
		<div className={className}>
			{range ? (
				<>
					<LabelWrapper>
						<Label>Time Line</Label>
						<Output htmlFor={range.name}>{range.displayValue}</Output>
					</LabelWrapper>
					<TimeLineWrapper>
						<Button onClick={() => togglePlayState(!playState)}>
							<Icon color={colors.primary} name={playState ? 'pause' : 'play'} />
						</Button>
						<RangeSlider {...range} label={null}></RangeSlider>
					</TimeLineWrapper>
				</>
			) : null}
		</div>
	)
}

const CowCardWrapper = styled.div`
	display: grid;
	gap: 0.5em 3rem;
	position: relative;
	grid-template-columns: 1fr 1fr auto min-content;
	grid-template-rows: auto;
	grid-template-areas:
		'tag tag lastEvent lastEvent'
		'herd herd herd herd'
		'deviceId deviceId tools tools';
`
const Controls = styled.div`
	display: flex;
	flex-direction: column;
	${DateRangeCalendar} {
		z-index: 1;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		button {
			width: 100%;
		}
	}
`
const Tools = styled.div`
	grid-area: tools;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`
const Tool = styled.button`
	all: unset;
	display: flex;
	align-items: flex-end;
`
const Tag = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25em;
	grid-area: tag;
`

const Herd = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25em;
	grid-area: herd;
`

const CowDateTime = styled(DateTime)`
	grid-area: lastEvent;
`

const DeviceId = styled.div`
	display: flex;
	align-items: flex-end;
	font-size: 0.6em;
	color: #999;
	grid-area: deviceId;
`

const ButtonWrapper = styled.div`
	display: flex;
	align-items: flex-start;
`
const Button = styled.button`
	all: unset;
	cursor: pointer;
	color: ${colors.primary};
`
const LabelWrapper = styled.div`
	display: flex;
	gap: 0.5em;
	width: 100%;
	justify-content: space-between;
	margin-top: 0.5em;
`
const TimeLineWrapper = styled.div`
	display: flex;
	gap: 0.5em;
	width: 100%;
`
const Output = styled.output`
	font-size: 0.8em;
	font-weight: 600;
	color: ${colors.dark};
`
