import { useClient } from './client'
import { AxiosError, AxiosResponse } from 'axios'
import * as turf from '@turf/turf'
import moment from 'moment'
import { AnimalEvent } from '../models/animal.model'
import { CreateAccount } from '../models/account.model'
import { CreateInviteUser } from '../models/user.model'
import { Herd } from 'library/models'


export const useEndpoints = () => {
	const client = useClient()
	return {
		account: {
			query: {
				baseKey: 'account',
				current: () => client.get<Account>('/account').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Account>(`/account/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: CreateAccount) => client.post<Account>('/account', payload).then(res => res.data),
				update: (payload: Account) => client.put<Account>('/account', payload).then(res => res.data),
				delete: () => client.delete<Boolean>('/account').then(res => res.data),
				image: {
					create: (payload: { id: string; image: ImageUpload }) => client.post('/account/image', payload.image).then(res => res.data),
					update: (payload: { id: string }) => client.put(`/account/image/${payload.id}`).then(res => res.data),
					delete: (payload: { id: string }) => client.delete(`/account/image/${payload.id}`).then(res => res.data),
				},
			},
		},
		address: {
			query: {
				baseKey: 'address',
				byId: (payload: { id: string | undefined }) => client.get(`/address/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: Address) => client.post<Address>('/address', payload).then(res => res.data),
				update: (payload: Address) => client.put<Address>(`/address/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/address/${payload.id}`).then(res => res.data),
			}
		},
		ranch: {
			query: {
				baseKey: 'ranch',
				all: () => client.get<Ranch[]>('/ranches').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Ranch>(`/ranch/${payload.id}`).then((res) => res.data),
				features: {
					map: () => client.get<turf.FeatureCollection, AxiosResponse<turf.FeatureCollection, AxiosError>>('/ranch/mapFeatures').then((res) => res.data),
					animals: () => client.get('/ranch/animalFeatures').then(res => res.data),
				}
			},
			mutation: {
				create: (payload: CreateRanchParams) => client.post<Ranch>('/ranch', payload).then(res => res.data),
				update: (payload: UpdateRanchParams) => client.put<Ranch>(`/ranch/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/ranch/${payload.id}`).then(res => res.data),
				image: {
					create: (payload: { id: string; image: ImageUpload }) => client.post(`/ranch/${payload.id}/image`, payload.image).then(res => res.data),
					update: (payload: { id: string; image: ImageUpload }) => client.put(`/ranch/${payload.id}/image`, payload.image).then(res => res.data),
					delete: (payload: { id: string }) => client.delete(`/ranch/${payload.id}/image`).then(res => res.data),
				}
			}
		},
		invitation: {
			query: {
				key: 'invitation',
				all: () => client.get<UserInvite[]>('/user/invitations').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<UserInvite>(`/user/invitation/${payload.id}`).then(res => res.data),
			},
			mutation: {
				resend: (payload: { id: string }) => client.put<UserInvite>(`/user/invitation/${payload.id}/resend`).then(res => res.data),
				create: (payload: { email: string, role: RanchRoleType, hostName: string }) => client.post('/user/invitation/', payload).then(res => res.data),
				accept: (payload: CreateInviteUser) => client.post<User>(`/user/invitation/${payload.token}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/user/invitation/${payload.id}`).then(res => res.data),
			}
		},
		user: {
			query: {
				baseKey: 'user',
				all: () => client.get('/user').then(res => res.data),
				current: () => client.get('/user').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get(`/user/${payload.id}`).then(res => res.data),
				ranch: {
					byId: (payload: { id: string | undefined }) => client.get(`/ranch/${payload.id}/users`).then(res => res.data),
				}
			},
			mutation: {
				update: (payload: UserParams) => client.put(`/user/${payload.id}`, payload).then(res => res.data),
				create: (payload: UserParams) => client.post('/user', payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete(`/user/${payload.id}`).then(res => res.data),
				ranch: {
					add: (payload: { userId: string; ranchId: string, role: RanchRoleType }) => client.post(`/user/${payload.userId}/ranch/${payload.ranchId}/${payload.role}`).then(res => res.data),
					update: (payload: { id: string }) => client.put(`/user/activeRanch/${payload.id}`).then(res => res.data),
					remove: (payload: { userId: string, ranchId: string }) => client.delete(`/user/${payload.userId}/ranch/${payload.ranchId}`).then(res => res.data),
				},
				image: {
					create: (payload: { id: string; image: ImageUpload }) => client.post(`/user/${payload.id}/image`, payload.image).then(res => res.data),
					update: (payload: { id: string; image: ImageUpload }) => client.put(`/user/${payload.id}/image`, payload.image).then(res => res.data),
					delete: (payload: { id: string }) => client.delete(`/user/${payload.id}/image`).then(res => res.data),
				}
			},
		},
		device: {
			query: {
				baseKey: 'device',
				all: () => client.get<Device[]>('/devices').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Device>(`/device/${payload.id}`).then(res => res.data),
				byLocalId: (payload: { id: string | undefined }) => client.get<Device>(`/device/idstr/${payload.id}`).then(res => res.data),
				locate: (payload: { id: string, enabled: boolean }) => client.put(`/device/${payload.id}/locate/${payload.enabled}`).then(res => res.data),
			},
			mutation: {
				state: (payload: { id: string; state: boolean }) => client.put(`/device/${payload.id}/enabled/${payload.state}`).then(res => res.data),
				assign: (payload: AssignDeviceToAnimalPayload) => client.put(`/device/deviceIdStr/${payload.deviceId}/animal/${payload.animalId}/${payload.reassign}`).then(res => res.data),
				remove: (payload: AssignDeviceToAnimalPayload) => client.put(`/device/deviceIdStr/${payload.deviceId}/animal/00000000-0000-0000-0000-0000000000/false`).then(res => res.data),
			},
		},
		animal: {
			query: {
				baseKey: 'animal',
				all: () => client.get<Animal[]>('/animals').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Animal>(`/animal/${payload.id}`).then(res => res.data),
				byHerd: (payload: { id: string | undefined, includeArchieved?: boolean }) => client.get<Animal[]>(`/herd/${payload.id}/animals/${payload.includeArchieved ? payload.includeArchieved : false}`).then(res => res.data),
				byTag: (payload: { tag: string | undefined }) => client.get<Animal>(`/animal/tag/${payload.tag}`).then(res => res.data),
				byDevice: (payload: { id: string | undefined }) => client.get<Animal>(`/animal/deviceidstr/${payload.id}`).then(res => res.data),
				archived: {
					all: () => client.get<Animal[]>('/animal/archived').then(res => res.data),
					byHerd: (payload: { id: string | undefined }) => client.get<Animal[]>(`/herd/${payload.id}/animals/archived`).then(res => res.data),
				},
				breeds: () => client.get<Breed[]>('/AnimalBreeds').then(res => res.data),
				history: (payload: AnimalHistoryParams) =>
					client
						.get<AnimalHistory>(
							`/animal/${payload.id}/deviceEvents/${moment(payload.startDate).format('YYYY-MM-DDT00:00:00')}/${moment(payload.endDate).format('YYYY-MM-DDT23:59:59')}/${payload?.hasCoordinates}`
						)
						.then(res => res.data)
						.then(res => ({
							...res,
							events: res.events
								.sort((a: any, b: any) => new Date(a.eventTimestamp).getTime() - new Date(b.eventTimestamp).getTime())
								.map((event: any) => ({
									...res.animal,
									...event,
								})),
						})),
			},
			mutation: {
				create: (payload: Animal) => client.post<Animal>('/animal', payload).then(res => res.data),
				update: (payload: Animal): Promise<Animal> => client.put<Animal, AxiosResponse<Animal, AxiosError>>(`/animal/${payload.id}`, payload).then(res => res.data),
				archive: (payload: { id: string, archived: boolean, archiveDate: Date, archiveReason: string }): Promise<Animal> => client.delete(`/animal/${payload.id}`, { data: payload }).then(res => res.data),
				image: {
					create: (payload: { animalId: string; image: ImageUpload }) => client.post(`/animal/${payload.animalId}/image`, payload.image).then(res => res.data),
					update: (payload: { animalId: string; imageId: string; image: UpdateAnimalImageParams }) => client.put(`/animal/image/${payload.imageId}`, payload.image).then(res => res.data),
					delete: (payload: { animalId: string; imageId: string }) => client.delete(`/animal/image/${payload.imageId}`).then(res => res.data),
				},
			},
		},
		animalEvent: {
			query: {
				baseKey: 'animalEvent',
				byId: (payload: { animalId: string | undefined; eventId: string | undefined }) => client.get<AnimalEvent>(`/animals/animalEvent/${payload.eventId}`).then(res => res.data),
				types: () => client.get('/animalEventTypes').then(res => res.data),
			},
			mutation: {
				create: (payload: { id: string; event: any }) => client.post<AnimalEvent>(`/animal/${payload.id}/animalEvent`, payload.event).then(res => res.data),
				update: (payload: { id: string; event: any }) => client.put<AnimalEvent>(`/animal/${payload.id}/animalEvent/${payload.event.id}`, payload.event).then(res => res.data),
				delete: (payload: { animalId: string; eventId: string }) => client.delete<Boolean>(`/animal/${payload.animalId}/animalEvent/${payload.eventId}`).then(res => res.data),
				image: {
					create: (payload: { animalId: string; eventId: string; image: ImageFile }) =>
						client.post(`/animal/${payload.animalId}/animalEvent/${payload.eventId}/image`, payload.image).then(res => res.data),
					update: (payload: { animalId: string; eventId: string; imageId: string; image: ImageFile }) =>
						client.put(`/animal/${payload.animalId}/animalEvent/${payload.eventId}/image/${payload.imageId}`, payload.image).then(res => res.data),
					delete: (payload: { animalId: string; eventId: string; imageId: string }) => client.delete(`/animal/${payload.animalId}/animalEvent/image/${payload.imageId}`).then(res => res.data),
				},
			},
		},
		favorite: {
			query: {
				baseKey: 'favorite',
				all: () => client.get<Favorite[]>('/userRecent/0').then(res => res.data),
				byEntity: (payload: { entityId: string | undefined }) => client.get<Favorite[]>('/userRecent/0').then(res => {
					const favorite = res.data?.find((recent: Favorite) => recent.entityId === payload.entityId)
					if (favorite) return favorite
					return null
				}),
			},
			mutation: {
				create: (payload: Favorite) => client.post('/userRecent', payload).then<Favorite>(res => res.data),
				update: (payload: Omit<Favorite, 'added' | 'entityType' | 'entityId'>) => client.put<Favorite>(`/userRecent${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/userRecent/${payload.id}`).then(res => res.data),
			},
		},
		recent: {
			query: {
				baseKey: 'recent',
				all: (payload: {count: number}) => client.get<Recent[]>(`/UserRecent/${payload.count}`).then((res) => res.data.filter((recent: Recent) => !recent.fav)),
			},
			mutation: {
				create: (payload: Recent) => client.post<Recent>('/UserRecent', payload).then((res) => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/userRecent/${payload.id}`).then(res => res.data),
			},
		},
		grazingPlan: {
			query: {
				baseKey: 'grazingPlan',
				all: (payload: { startDate: Date | null; endDate: Date | null }) => client.get(`/grazingPlans/${payload.startDate}/${payload.endDate}`).then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get(`/grazingPlan/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: GrazingPlanParams) => client.post('/grazingPlans', payload).then(res => res.data),
				update: (payload: GrazingPlanParams) => client.put(`/grazingPlans/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string | undefined }) => client.delete(`/grazingPlans/${payload.id}`).then(res => res.data),
			},
		},
		herd: {
			query: {
				baseKey: 'herd',
				all: () => client.get<Herd[]>('/herds').then(res => res.data),
				byId: (payload: {id: string | undefined}) => client.get<Herd>(`/herd/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: Herd) => client.post<Herd>('/herd', payload).then(res => res.data),
				update: (payload: Herd) => client.put<Herd>(`/herd/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { herdId: string, deleted: boolean }) => client.delete<Boolean>(`/herd/${payload.herdId}`).then(res => res.data),
				pasture: {
					assign: (payload: { herdId: string; pastureId: string }) => client.put(`/herd/${payload.herdId}/pasture/${payload.pastureId}`).then(res => res.data),
					move: (payload: { herdId: string; pastureId: string; routeId: string }) => client.put(`/herd/${payload.herdId}/pasture/${payload.pastureId}/Route/${payload.routeId}`).then(res => res.data),
				},
				device: {
					toggle: (payload: ToggleHerdParams) => client.put(`/herd/${payload.id}/Enabled/${Boolean(payload.enabled)}`, payload).then(res => res.data),}
			},
		},
		notification: {
			query: {
				baseKey: 'notification',
				all: () => client.get<Notification[]>('/notifications').then(res => res.data),
				byId: (payload: {id: string | undefined}) => client.get<Notification>(`/notification/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: Notification) => client.post<Notification>('/notification', payload).then(res => res.data),
				update: (payload: Notification) => client.put<Notification>(`/notification/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/notification/${payload.id}`).then(res => res.data),
			},
		},
		pasture: {
			query: {
				baseKey: 'pasture',
				all: () => client.get<Pasture[]>('/pastures').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Pasture>(`/pasture/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: Pasture) => client.post<Pasture>('/pasture', payload).then(res => res.data),
				update: (payload: Pasture) => client.put<Pasture>(`/pasture/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/pasture/${payload.id}`).then(res => res.data),
			},
		},
		structure: {
			query: {
				baseKey: 'structure',
				all: () => client.get<Structure[]>('/pastureFeatures').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<Structure>(`/pastureFeature/${payload.id}`).then(res => res.data),
			},
			mutation: {
				create: (payload: Structure) => client.post<Structure>('/pastureFeature', payload).then(res => res.data),
				update: (payload: Structure) => client.put<Structure>(`/pastureFeature/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/pastureFeature/${payload.id}`, { data: payload }).then(res => res.data),
			},
		},
		pastureRoute: {
			query: {
				baseKey: 'pastureRoute',
				all: () => client.get<PastureRoute[]>('/pastureRoutes').then(res => res.data),
				byId: (payload: { id: string | undefined }) => client.get<PastureRoute>(`/pastureRoute/${payload.id}`).then(res => res.data),
				pasture: {
					byId: (payload: { id: string | undefined }) => client.get<PastureRoute[]>(`/pastureRoute/from/${payload.id}`).then(res => res.data),
					byIds: (payload: { fromId: string | undefined, toId: string | undefined }) => client.get<PastureRoute[]>(`/pastureRoute/from/${payload.fromId}/to/${payload.toId}`).then(res => res.data),
				}
			},
			mutation: {
				create: (payload: PastureRoute) => client.post<PastureRoute>('/pastureRoute', payload).then(res => res.data),
				update: (payload: PastureRoute) => client.put<PastureRoute>(`/pastureRoute/${payload.id}`, payload).then(res => res.data),
				delete: (payload: { id: string }) => client.delete<Boolean>(`/pastureRoute/${payload.id}`).then(res => res.data),
			},
		},
		analytics: {
			query: {
				baseKey: 'analytics',
				animal: {
					stimulusCounts: (payload: { startDate: Date | null, endDate: Date | null }) => client.get<Animal[]>(`/animals/stimulusCounts/${payload.startDate}/${payload.endDate}`).then(res => res.data),
					activityReports: (payload: { type: string, id: string, startDate: Date, endDate: Date }) => client.get<any>(`/animals/activityReports/${payload.type}/${payload.id}/${payload.startDate}/${payload.endDate}`).then(res => res.data),
				},
				herd: {
					distancesTraveled: (payload: { herdId: string, startDate: Date | null; endDate: Date | null, featureType: string }) => client.get<Animal[]>(`/herd/${payload.herdId}/distancesTraveled/${payload.startDate}/${payload.endDate}/${payload.featureType}`).then(res => res.data),
				},
				device: {
					locations: (payload: { id: string, startDate: Date, endDate: Date, type: 'animal' | 'herd' | 'pasture' }) => client.get<GeoJSON.FeatureCollection>(`/devices/${payload.type}/${payload.id}/locations/${payload.startDate}/${payload.endDate}`).then(res => res.data),
				},
				pasture: {
					production: (payload: { pastureId: string, stockingHead: number }) => client.get<any>(`/pasture/${payload.pastureId}/production/${payload.stockingHead}`).then(res => res.data),
				},
			},
			mutation: {}
		}
	}
}
