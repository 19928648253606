import { useGesture } from '@use-gesture/react';
import { useSpring, animated, config } from '@react-spring/web';
import { darken, lighten } from 'polished';
import React from 'react'
import styled from 'styled-components/macro'
import { colors } from 'styles';
import Icon from './Icon';

export const Component = ({ className, children, variant, icon, ...props }: { className?: any; variant?: 'dark' | 'warning' | 'error' | 'success', children: React.ReactNode; icon?: React.ReactNode }) => {
	const [{ x }, api] = useSpring(() => ({ x: 0 }))
	const bind = useGesture(
		{
			onScroll: () => {
				// api.start({y: 10, immediate: true})
			},
			onDrag: ({down, tap, velocity: [vx, vy], direction: [dx, dy], movement: [mx, my], offset: [ox, oy], canceled, scrolling, event, target, currentTarget}) => {
				// if (scrolling) return
				// if (!down && tap) return
				if (down) {
					api.start({x: ox, immediate: true})
				} else {
					if (mx > 100 || (vx > 0.5 && dx > 0)) {
						api.start({x: window.innerWidth, immediate: false, config: {velocity: vx}})
					} else if (mx < -100 || (vx < -0.5 && dx < 0)) {
						api.start({x: -window.innerWidth, immediate: false, config: canceled ? config.wobbly : config.stiff})
					} else {
						api.start({x: 0, immediate: false, config: canceled ? config.wobbly : config.stiff})
					}
				}
			},
		},
		{
			// enabled: windowWidth < device.size.mobile,
			enabled: true,
			drag: {
				axis: 'x',
				from: () => [x.get(), 0],
				filterTaps: true,
				rubberband: true,
			},
		}
	)
	return (
		<animated.div className={className} {...bind()} style={{x, touchAction: 'none'}}>
			<NotificationWrapper>
				<Badge>{icon}</Badge>
				<Content>{children}</Content>
			</NotificationWrapper>
			<CloseButton>
				<Icon color={'currentColor'} name={'x'} />
			</CloseButton>
		</animated.div>
	)
}
const NotificationContainer = ({className, children}: any) => {
	return (<div className={className}>
		{children}
	</div>)
}

export const Notifications = styled(NotificationContainer)`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 55px;
	margin: 1em;
	right: 0;
	z-index: 100;
	gap: .5em;
`

export const Notification = styled(Component) <{ variant?: 'dark' |'warning' | 'error' | 'success'}>`
	display: flex;
	flex-direction: row;
	padding: 1em;
	align-items: center;
	justify-content: space-between;
	border-radius: var(--border-radius);
	gap: 0.5em;
	width: 100%;
	box-shadow: var(--box-shadow);
	position: relative;
	${({ variant }) => { 
		switch (variant) {
		case 'warning': {
			return `
				background: ${lighten(0.3, colors.warning)};
				color: ${darken(.35, colors.warning)};
			`
		}
		case 'error': {
			return `
				background: ${lighten(0.4, colors.primary)};
				color: ${darken(0.1, colors.primary)};
			`
		}
		case 'success': {
			return `
				background: ${lighten(0.5, colors.success)};
				color: ${darken(0.2, colors.success)};
			`
		}
		case 'dark': {
			return `
				background: ${lighten(0, colors.dark)};
				color: ${colors.light};
			`
		}
		default: {
			return `
				background: #ffffff;
				color: ${colors.dark};
			`
		}
		}
	}}
`
const NotificationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5em;
 `
const Badge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	/* background: ${lighten(0.2, colors.primary)}; */
	border-radius: 50%;
	padding: 0.5em;
`
const CloseButton = styled.button`
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	border-radius: 50%;
`
const Content = styled.div``
