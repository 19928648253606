import { AxiosError } from 'axios'
import { useEndpoints } from './endpoints'
import { useQuery, QueryMeta, useQueryClient, useMutation, MutationFunction, UseQueryOptions } from '@tanstack/react-query'

export const useGetAnimalStimulusCountsQuery = (payload: { startDate: Date | null, endDate: Date | null }, options?: QueryMeta) => {
	const { analytics } = useEndpoints()
	return useQuery<Animal[], Error>({
		queryKey: ['analytics', 'stimulus-counts', payload.startDate, payload.endDate],
		queryFn: () => analytics.query.animal.stimulusCounts(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}
export const useGetDistancesTraveledByHerdQuery = (payload: { herdId: string, startDate: Date | null; endDate: Date | null, featureType: string }, options?: QueryMeta) => {
	const { analytics } = useEndpoints()
	return useQuery<Animal[], Error>({
		queryKey: ['analytics', 'distance-traveled-by-herd', payload.herdId, payload.startDate, payload.endDate, payload.featureType],
		queryFn: () => analytics.query.herd.distancesTraveled(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}
export const useLazyGetDistancesTraveledByHerdQuery = (options?: Partial<MutationFunction<any, AxiosError>>) => {
	const { analytics } = useEndpoints()
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: (payload: { herdId: string, startDate: Date | null; endDate: Date | null, featureType: string }) => analytics.query.herd.distancesTraveled(payload),
		onSuccess: (data, variables, context) => {
			queryClient.setQueryData(['analytics', 'distance-traveled-by-herd', variables.herdId, variables.startDate, variables.endDate, variables.featureType], data)
		},
		...options
	})}
export const useGetDeviceLocationsQuery = (payload: { id: string, startDate: Date, endDate: Date, type: 'animal' | 'herd' | 'pasture' }, options?: Partial<UseQueryOptions<GeoJSON.FeatureCollection, Error>>) => {
	const { analytics } = useEndpoints()
	return useQuery<GeoJSON.FeatureCollection, Error>({
		queryKey: ['analytics', 'device-locations', payload.type, payload.id, payload.startDate, payload.endDate],
		queryFn: () => analytics.query.device.locations(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}
export const useLazyGetDeviceLocationsQuery = (options?: Partial<MutationFunction<GeoJSON.FeatureCollection, Promise<GeoJSON.FeatureCollection>>>) => {
	const queryClient = useQueryClient()
	const { analytics } = useEndpoints()
	return useMutation({
		mutationFn: (payload: {type: 'animal' | 'herd' | 'pasture', startDate: Date, endDate: Date, id: string}) => analytics.query.device.locations(payload),
		onSuccess: (data, variables, context) => { 
			queryClient.setQueryData(['analytics', 'device-locations', variables.type, variables.id, variables.startDate, variables.endDate], data)
		},
		...options
	})}
export const useGetPastureProductionQuery = (payload: { pastureId: string, stockingHead: number }, options: QueryMeta) => {
	const { analytics } = useEndpoints()
	return useQuery<any, Error>({
		queryKey: ['analytics', 'pasture-production', payload.pastureId, payload.stockingHead],
		queryFn: () => analytics.query.pasture.production(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useGetAnimalActivityReportsQuery = (payload: { type: string, id: string, startDate: Date, endDate: Date }, options: QueryMeta) => {
	const { analytics } = useEndpoints()
	return useQuery<any, Error>({
		queryKey: ['animal-activity-reports', payload.type, payload.id, payload.startDate, payload.endDate],
		queryFn: () => analytics.query.animal.activityReports(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}