import { MutationFunction, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEndpoints } from './endpoints'
import { AxiosError } from 'axios';


export const useGetDevicesQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Device[], AxiosError>>) => {
	const { device } = useEndpoints()
	return useQuery<Device[], AxiosError>({
		queryKey: ['devices'],
		queryFn: () => device.query.all(),
		networkMode: 'offlineFirst',
		...options
	})}
export const useGetDeviceQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Device, AxiosError, Device | null>>) => {
	const { device } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Device, AxiosError, Device | null>({
		queryKey: ['devices', payload.id],
		queryFn: () => device.query.byId(payload),
		networkMode: 'offlineFirst',
		initialData: () => {
			return queryClient.getQueryData<Device[]>(['devices'])?.find((device) => device.id === payload.id)
		},
		...options
	})}
export const useGetDeviceByLocalIdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Device, AxiosError, Device | null>>) => {
	const { device } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<Device, AxiosError, Device | null>({
		queryKey: ['devices', 'idstr', payload.id],
		queryFn: () => device.query.byLocalId(payload),
		networkMode: 'offlineFirst',
		initialData: () => {
			return queryClient.getQueryData<Device[]>(['devices'])?.find((device) => device.deviceIdStr === payload.id)
		},
		...options
	})}


export const useAssignDeviceToAnimalMutation = (options?: Partial<MutationFunction<Device, Error>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['device.mutation.assign'],	
		onMutate: (variables: AssignDeviceToAnimalPayload) => {
			// Halt any ongoing queries
			queryClient.cancelQueries({queryKey: ['animals']})
			queryClient.cancelQueries({queryKey: ['animals', variables.animalId]})
			queryClient.cancelQueries({ queryKey: ['devices'] })
			queryClient.cancelQueries({ queryKey: ['devices', variables.deviceId] })

			// Update Animals
			const animals = queryClient.getQueryData<Animal[]>(['animals'])
			queryClient.setQueryData(['animals'], (old: Animal[]) => {
				const item = old.find((item: Animal) => item.id === variables.animalId)
				const items = old.filter((item: Animal) => item.id !== variables.animalId)
				if (items && item) return [...items, { ...item, deviceId: variables.deviceId }]
				if (items) return [...items, { deviceId: variables.deviceId, animalId: variables.animalId }]
				return [{ deviceId: variables.deviceId, id: variables.animalId }]
			})
			// Update Animal
			const animal = queryClient.getQueryData<Animal[]>(['animal', variables.animalId])
			queryClient.setQueryData(['animals', variables.animalId], (old: Animal) => (old ? { ...old, deviceId: variables.deviceId }: {id: variables.animalId, deviceId: variables.deviceId}))
			queryClient.setQueryData(['animals', 'device', variables.deviceId], (old: Animal) => (old ? { ...old, deviceId: variables.deviceId } : {id: variables.animalId, deviceId: variables.deviceId}))
			
			// Update Devices
			const devices = queryClient.getQueryData<Device[]>(['devices'])
			queryClient.setQueryData(['devices'], (old: Device[]) => {
				const item = old.find((item: Device) => item.deviceIdStr === variables.deviceId)
				const items = old.filter((item: Device) => item.deviceIdStr !== variables.deviceId)
				if (items && item) return [...items, { ...item, animalId: variables.animalId }]
				if (items) return [...items, { deviceIdStr: variables.deviceId, animalId: variables.animalId }]
				return [{ deviceIdStr: variables.deviceId, animalId: variables.animalId }]
			})

			// Update Device
			const device = queryClient.getQueryData<Device>(['devices', variables.deviceId])
			queryClient.setQueryData(['devices', variables.deviceId], (old: Device) => (old? { ...old, animalId: variables.animalId } : { deviceIdStr: variables.deviceId, animalId: variables.animalId }))
			return {animals, animal, devices, device}
		},
		onError: (error, variables, context) => { 
			queryClient.setQueryData(['animal'], context?.animals)
			queryClient.setQueryData(['animals', 'device', variables.deviceId], context?.animal)
			queryClient.setQueryData(['device', variables.deviceId], context?.device)
			queryClient.setQueryData(['devices'], context?.devices)	
		},
		onSettled: (data, error, variables, context) => { 
			queryClient.invalidateQueries({queryKey: ['animals']})
			queryClient.invalidateQueries({queryKey: ['animals', variables.animalId]})
			queryClient.invalidateQueries({ queryKey: ['devices'] })
			queryClient.invalidateQueries({ queryKey: ['devices', variables.deviceId] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useRemoveDeviceFromAnimalMutation = (options?: Partial<MutationFunction<Device, Error>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['device.mutation.remove'],
		onMutate: (variables: AssignDeviceToAnimalPayload) => {
		// Halt any ongoing queries
			queryClient.cancelQueries({queryKey: ['animals']})
			queryClient.cancelQueries({queryKey: ['animals', variables.animalId]})
			queryClient.cancelQueries({ queryKey: ['devices'] })
			queryClient.cancelQueries({ queryKey: ['devices', variables.deviceId] })

			// Update Animals
			const animals = queryClient.getQueryData<Animal[]>(['animals'])
			queryClient.setQueryData(['animals'], (old: Animal[]) => {
				const item = old.find((item: Animal) => item.id === variables.animalId)
				const items = old.filter((item: Animal) => item.id !== variables.animalId)
				if (items && item) return [...items, { ...item, deviceId: null }]
				if (items) return [...items, { deviceId: null, id: variables.animalId }]
				return [{ deviceId: null, id: variables.animalId }]
			
			})
			// Update Animal
			const animal = queryClient.getQueryData<Animal[]>(['animal', variables.animalId])
			queryClient.setQueryData(['animals', variables.animalId], (old: Animal) => (old ? { ...old, deviceId: null } : {id: variables.animalId, deviceId: null }))
			queryClient.setQueryData(['animals', 'device', variables.deviceId], (old: Animal) => (old ? { ...old, deviceId: null } : {id: variables.animalId, deviceId: null }))
		
			// Update Devices
			const devices = queryClient.getQueryData<Device[]>(['devices'])
			queryClient.setQueryData(['devices'], (old: Device[]) => {
				const item = old.find((item: Device) => item.deviceIdStr === variables.deviceId)
				const items = old.filter((item: Device) => item.deviceIdStr !== variables.deviceId)
				if (items && item) return [...items, { ...item, animalId: null }]
				if (items) return [items, { deviceIdStr: variables.deviceId, animalId: null }]
				return [{ deviceIdStr: variables.deviceId, animalId: null }]
			})

			// Update Device
			const device = queryClient.getQueryData<Device>(['devices', variables.deviceId])
			queryClient.setQueryData(['devices', variables.deviceId], (old: Device) => (old ? { ...old, animalId: null } : { deviceIdStr: variables.deviceId, animalId: null }))
			return {animals, animal, devices, device}
		},
		onError: (error, variables, context) => { 
			queryClient.setQueryData(['animal'], context?.animals)
			queryClient.setQueryData(['animals', 'device', variables.deviceId], context?.animal)
			queryClient.setQueryData(['device', variables.deviceId], context?.device)
			queryClient.setQueryData(['devices'], context?.devices)	
		},
		onSettled: (data, error, variables, context) => { 
			queryClient.invalidateQueries({queryKey: ['animals']})
			queryClient.invalidateQueries({queryKey: ['animals', variables.animalId]})
			queryClient.invalidateQueries({ queryKey: ['devices'] })
			queryClient.invalidateQueries({ queryKey: ['devices', variables.deviceId] })
		},
		...options
	})
}
