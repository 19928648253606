import { useQuery,  QueryMeta, useMutation, MutationMeta, MutateFunction, MutationFunction } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { useEndpoints } from './endpoints'
import { AxiosError } from 'axios'
import { FeatureCollection } from 'geojson'
const BASE_QUERY_KEY = 'pasture-routes'

export const useGetPastureRoutesQuery = (payload?: undefined, options?: QueryMeta) => {
	const { pastureRoute } = useEndpoints()
	return useQuery<PastureRoute[], Error>({
		queryKey: [BASE_QUERY_KEY],
		queryFn: () => pastureRoute.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useGetPastureRouteQuery = (payload: { id: string | undefined }, options?: QueryMeta) => {
	const { pastureRoute } = useEndpoints()
	return useQuery<PastureRoute, Error>({
		queryKey: [BASE_QUERY_KEY, payload.id],
		queryFn: () => pastureRoute.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})
}
export const useGetPastureRoutesByPastureIdQuery = (payload: { id: string | undefined }, options?: QueryMeta) => {
	const { pastureRoute } = useEndpoints()
	return useQuery<PastureRoute, Error>({
		queryKey: [BASE_QUERY_KEY, 'pasture', payload.id],
		queryFn: () => pastureRoute.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})
}
export const useGetPastureRoutesByPastureIdsQuery = (payload: { fromId: string | undefined, toId: string | undefined }, options?: Partial<MutateFunction<PastureRoute[], AxiosError>>) => {
	const { pastureRoute } = useEndpoints()
	return useQuery<PastureRoute[], Error>({
		queryKey: [BASE_QUERY_KEY, 'pasture', payload.fromId, payload.toId],
		queryFn: () => pastureRoute.query.pasture.byIds(payload),
		networkMode: 'offlineFirst',
		...options
	})
}

export const useLazyGetPastureRoutesByPastureIdsQuery = (options?: Partial<MutationFunction<PastureRoute[], AxiosError>>) => {
	const { pastureRoute } = useEndpoints()
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: (payload: { fromId: string, toId: string }) => pastureRoute.query.pasture.byIds(payload),
		onSuccess: (data, variables, context) => {
			queryClient.setQueryData([BASE_QUERY_KEY, variables.fromId, variables?.toId], data)
		},
		...options
	})}

// export const createPastureRoute = (payload: PastureRoute) => client.post(`/PastureRoute`, payload).then((res) => res.data)
export const useCreatePastureRouteMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pastureRoute.mutation.create'],
		// mutationFn: createPastureRoute,
		onMutate: (payload: PastureRoute) => {
        
			queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY] })
			const previousPastureRoutes = queryClient.getQueryData<PastureRoute[]>([BASE_QUERY_KEY])
			queryClient.setQueryData([BASE_QUERY_KEY], previousPastureRoutes ? [...previousPastureRoutes, payload] : [payload])
			queryClient.setQueryData([BASE_QUERY_KEY, payload.id], payload)
			return previousPastureRoutes
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY] })
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY, variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData([BASE_QUERY_KEY], context)
			queryClient.removeQueries({ queryKey: [BASE_QUERY_KEY, variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useUpdatePastureRouteMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pastureRoute.mutation.update'],
		onMutate: (payload: PastureRoute) => {
			queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY] })
			queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY, payload.id] })
			const previousPastureRoutes = queryClient.getQueryData<PastureRoute[]>([BASE_QUERY_KEY])
			const previousPastureRoute = queryClient.getQueryData<PastureRoute[]>([BASE_QUERY_KEY, payload.id])
			queryClient.setQueryData([BASE_QUERY_KEY], previousPastureRoutes ? previousPastureRoutes.map(pastureRoute => pastureRoute.id === payload.id ? {...pastureRoute, ...payload} : pastureRoute) : [])
			queryClient.setQueryData([BASE_QUERY_KEY, payload.id], payload)
			return previousPastureRoutes
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY] })
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY, variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData([BASE_QUERY_KEY], context)
			queryClient.setQueryData([BASE_QUERY_KEY, variables.id], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useDeletePastureRouteMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pastureRoute.mutation.delete'],
		onMutate: (payload: { id: string }) => {
			queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY] })
			queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY, payload.id] })
			queryClient.cancelQueries({ queryKey: ['map-features'] })
			const previousPastureRoutes = queryClient.getQueryData<PastureRoute[]>([BASE_QUERY_KEY])
			const previousPastureRoute = queryClient.getQueryData<PastureRoute[]>([BASE_QUERY_KEY, payload.id])
			const previousMapFeatures = queryClient.getQueryData<FeatureCollection>(['map-features'])
			queryClient.setQueryData([BASE_QUERY_KEY], previousPastureRoutes ? previousPastureRoutes.filter(pastureRoute => pastureRoute.id !== payload.id) : [])
			queryClient.setQueryData(['map-features'], (old: FeatureCollection) => {
				if (!old || !old?.features) return
				return { ...old, features: old.features.filter(feature => feature.id !== payload.id)}
			})
			queryClient.removeQueries({ queryKey: [BASE_QUERY_KEY, payload.id] })
			return { pastureRoutes: previousPastureRoutes, pastureRoute: previousPastureRoute, mapFeatures: previousMapFeatures }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY] })
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY, variables.id] })
			queryClient.invalidateQueries({ queryKey: ['map-features'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData([BASE_QUERY_KEY], context?.pastureRoutes)
			queryClient.setQueryData([BASE_QUERY_KEY, variables.id], context?.pastureRoute)
			queryClient.setQueryData(['map-features'], context?.mapFeatures)
		},
		networkMode: 'offlineFirst',
		...options
	})
}