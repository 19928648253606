import { ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { darken, lighten } from 'polished'
import { colors } from 'styles'
import LoadingSpinner from 'components/LoadingSpinner'
const Toggle = ({ label, checked, onChange, id, loading = false }: { label?: string; id?: string, checked: boolean; onChange: (event: ChangeEvent) => void; loading?: boolean }) => {
	return (
		<Switch>
			{label ? label : null}
			<input id={id} type='checkbox' checked={checked} onChange={(e: ChangeEvent) => onChange(e)} />
			{loading ? (
				<LoadingContainer>
					<LoadingWrapper>
						<LoadingSpinner color={!checked ? lighten(0.0, colors.light) : lighten(0.2, colors.error)} size={20} />
					</LoadingWrapper>
				</LoadingContainer>
			) : (
				<Slider />
			)}
		</Switch>
	)
}
const Slider = styled.div`
	height: 24px;
	width: 42px;
	display: flex;
	position: relative;
	border-radius: 99999px;
	border: 1px solid ${lighten(0.075, colors.light)};
	background: ${darken(0.1, colors.background)};
	&::after {
		transition: 0.3s ease all;
		transform: translateX(100%);
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		width: 18px;
		height: 18px;
		border-radius: 99999px;
		background: var(--dark);
	}
`

const LoadingWrapper = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	width: 18px;
	height: 18px;
	transform: translateX(100%);
`

const LoadingContainer = styled.div`
	height: 24px;
	width: 42px;
	display: flex;
	position: relative;
	border-radius: 99999px;
	border: 1px solid ${lighten(0.075, colors.light)};
	background: ${darken(0, colors.background)};
`

const Switch = styled.label`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 0.6em;
	cursor: pointer;

	& > input {
		appearance: none;
		width: 0;
		position: absolute;
		height: 0;
		opacity: 0;
	}
	& > input:checked ~ ${LoadingContainer} {
		border: 1px solid ${lighten(0.3, colors.error)};
		background: ${lighten(0.4, colors.error)};
	}
	& > input:checked ~ ${LoadingContainer} > ${LoadingWrapper} {
		transform: translateX(0%);
	}
	& > input:checked ~ ${Slider} {
		border: 1px solid ${lighten(0.3, colors.error)};
		background: ${lighten(0.4, colors.error)};

		&::after {
			transform: translateX(0%);
			background-color: ${colors.error};
		}
	}
`

export default Toggle
