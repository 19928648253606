import * as uuid from 'uuid'
export class Herd {
	id: string
	name: string
	ranchId?: string
	createdDate: Date
	enabled: boolean
	isArchived: boolean
	animalCount: number
	pastureAssignmentDate?: Date
	pastureId?: string
	pastureRouteId?: string
	pasture?: Pasture
	new?: boolean
	constructor(props?: Partial<Herd>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.isArchived = props?.isArchived || false
		this.ranchId = props?.ranchId || undefined
		this.createdDate = props?.createdDate || new Date()
		this.enabled = props?.enabled || false
		this.animalCount = props?.animalCount || 0
		this.pastureAssignmentDate = props?.pastureAssignmentDate || undefined
		this.pastureId = props?.pastureId
		this.pastureRouteId = props?.pastureRouteId
		this.new = props?.new || false
	}
}

export default Herd
