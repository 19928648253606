import * as uuid from 'uuid'
import * as turf from '@turf/turf'
import { PastureStyle } from './pasture.model'
export class Structure {
	id: string
	name: string
	type: 'waterTank' | 'gate' | 'saltAndMineralDeposit' | 'exclusion' | 'safeZone' | 'marker'
	safeZone: boolean
	safeZoneDistance: number
	ranchId?: string
	geometry?: turf.Point | turf.Geometry
	pastureIds: string[]
	style?: PastureStyle
	new?: boolean
	constructor(props?: Optional<Structure>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.ranchId = props?.ranchId || undefined
		this.pastureIds = props?.pastureIds || []
		this.new = props?.new || false
		this.type = props?.type || 'waterTank'
		this.safeZone = props?.safeZone || false
		this.safeZoneDistance = props?.safeZoneDistance || 0
		this.style = props?.style || undefined
		this.geometry = props?.geometry
	}
}

export default Structure
