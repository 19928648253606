import styled from 'styled-components/macro'

const StyledLabel = (props: React.LabelHTMLAttributes<HTMLLabelElement>) => {
	return <label {...props}>{props.children}</label>
}

const Label = styled(StyledLabel)`
	font-size: 0.6em;
	color: #666;
`

export default Label
