import { useState, useCallback, useMemo } from 'react'
import { FeatureCollection, Feature, Coord } from '@turf/turf'
import * as turf from '@turf/turf'
import { hexToRGB, kebabize, range } from 'utilities'
import { useCreatePastureFeatureMutation, useDeletePastureFeatureMutation, useUpdatePastureFeatureMutation, useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import { GeoJsonLayer, IconLayer, LineLayer, PolygonLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers/typed'
import { CompositeLayer, PickingInfo } from '@deck.gl/core/typed'
import { RootState, store } from 'store'
import { mapSlice } from 'store/map.slice'
import { useMap } from 'react-map-gl'
import { useNavigate, useParams } from 'react-router-dom'
import { useCardBuffer } from 'hooks'
import { structureSlice } from './structure.slice'
import { useSelector } from 'react-redux'
import { useGetPasturesQuery } from 'library/api/pasture'
import { Structure } from 'library/models/structure.model'
import { Pasture } from 'library/models/pasture.model'
const DEFAULT_SAFE_ZONE_DISTANCE = 10
export const useStructureLayer = () => {
	const { MapView } = useMap()
	const params = useParams()

	const navigate = useNavigate()
	const [dragPanEnabled, setDragPanEnabled] = useState(true)
	const [safezoneVisible, setSafezoneVisible] = useState(true)
	// const [bounds, setBounds] = useState<any>(null)
	const [resizeHandleStartPosition, setResizeHandleStartPosition] = useState<PickingInfo | null>(null)
	// const [modeConfig, setModeConfig] = useState<any>({})
	const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<number[]>([])
	const { bufferWidth } = useCardBuffer()

	const { data, refetch } = useGetPastureFeaturesQuery(undefined, {
		select: (features: Structure[]) => {
			return features.filter((f) => f.type !== 'exclusion')
		}
	})

	const { data: pastures } = useGetPasturesQuery()
	const zoom = MapView?.getZoom()

	// Redux State
	const edits = useSelector((state: RootState) => state.structureSlice.feature)
	const mode = useSelector((state: RootState) => state.mapSlice.mode)
	const boundarySliceMode = useSelector((state: RootState) => state.boundarySlice.mode)


	// Lifecycle Hooks
	const features: turf.FeatureCollection<any> = useMemo(() => {
		if (edits?.geometry && data) {
			return turf.featureCollection([...data.filter((f: Structure) => f.id !== edits.id), edits].map(feature => turf.feature(feature.geometry, feature, {id: feature.id})))
		} else if (data) {
			return turf.featureCollection([...data.map(feature => turf.feature(feature.geometry, feature, {id: feature.id}))])
		} else {
			return turf.featureCollection([])
		}
	}, [data, edits])


	// Functions
	function getParentPastureIds(coordinates: number[]) {
		const pastureIds = pastures?.filter((pasture: any) => turf.booleanPointInPolygon(turf.point(coordinates), pasture.geometry)).map((pasture: Pasture) => pasture.id)
		return pastureIds
	}

	const handleMapHover = (info: PickingInfo) => {
		if (boundarySliceMode !== 'ViewMode') return // TODO: Unify modes between slices
		if (mode === 'DrawPointMode') {
			if (info.coordinate) {
				store.dispatch(structureSlice.actions.setGeometry({ type: 'Point', coordinates: info.coordinate as turf.Position }))
			}
		}
		if(info.object?.properties?.type === 'waterTank' || info.object?.properties?.type === 'saltAndMineralDeposit' || info.object?.properties?.type === 'gate'){
			store.dispatch(mapSlice.actions.setCursor('pointer'))
		}
	}

	const handleMapClick = async (info: PickingInfo) => {
		if (boundarySliceMode !== 'ViewMode') return // TODO: Unify modes between slices
		if (mode === 'DrawPointMode') {
			store.dispatch(mapSlice.actions.setMode('ViewMode'))
			store.dispatch(structureSlice.actions.setGeometry({ type: 'Point', coordinates: info.coordinate as turf.Position }))
			const pastureIds = getParentPastureIds(info.coordinate as number[])
			if (pastureIds) store.dispatch(structureSlice.actions.setPastureIds(pastureIds))
			store.dispatch(mapSlice.actions.setCursor('default'))
		} else if(info?.object?.properties?.type && info.object.properties.type !== 'Feature' && info?.object?.properties?.id) {
			navigate(`/${kebabize(info.object.properties.type)}/${info.object.properties.id}`)
		}
	}

	const getBounds = useCallback(
		(data: FeatureCollection) => {
			if (data) {
				const geometries = data.features.map((feature: any) => turf.geometry(feature?.geometry?.type, feature.geometry.coordinates))
				const geometryCollection = turf.geometryCollection(geometries)
				const bboxPolygon = turf.bbox(geometryCollection)

				MapView?.fitBounds(
					[
						[bboxPolygon[0], bboxPolygon[1]],
						[bboxPolygon[2], bboxPolygon[3]],
					],
					{ padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } }
				)
			}
		},
		[MapView, bufferWidth]
	)

	const geolayer = new GeoJsonLayer({
		id: 'stucture-geojson-layer',
		data: features,
		pickable: true,
		pointType: 'circle+icon+text',
		getFillColor: (d: any) => {
			const color = d.properties.type.toUpperCase() === 'WATERTANK' ? '#60a5fa' 
				: d.properties.type.toUpperCase() === 'SALTANDMINERALDEPOSIT' ? '#c084fc' 
					: d.properties.type.toUpperCase() === 'GATE' ? '#fb923c' 
						: d.properties.type.toUpperCase() === 'EXCLUSION' ? '#dc2626' 
							: d.properties.type.toUpperCase() === 'SAFEZONE' ? '#34d399' :  '#ffffff'
			const opacity = 255 * (d.geometry.type === 'Point' ?  1 : .25)
			return hexToRGB(color, opacity)
			
		},
		getLineWidth: (d: any) => {
			if(d.properties.id === params?.id) return 3
			return 1
		},
		stroked: true,
		lineWidthUnits: 'pixels',
		getLineColor: (d: any) => {
			const color = d.properties.type.toUpperCase() === 'WATERTANK' ? '#60a5fa' 
				: d.properties.type.toUpperCase() === 'SALTANDMINERALDEPOSIT' ? '#c084fc' 
					: d.properties.type.toUpperCase() === 'GATE' ? '#fb923c' 
						: d.properties.type.toUpperCase() === 'EXCLUSION' ? '#dc2626' 
							: d.properties.type.toUpperCase() === 'SAFEZONE' ? '#34d399' :  '#ffffff'
			return hexToRGB(color)
		},
		getIcon: (d: any) => {
			const iconUrl =
				d.properties.type.toUpperCase() === 'WATERTANK' ? 'water.png' 
					: d.properties.type.toUpperCase() === 'SALTANDMINERALDEPOSIT' ? 'mineral.png' 
						: d.properties.type.toUpperCase() === 'GATE' ? 'gate.png' 
							: d.properties.type.toUpperCase() === 'EXCLUSION' ? 'cancel.png' 
								: d.properties.type.toUpperCase() === 'SAFEZONE' ? 'pin-marker.png'
									: 'pin-marker.png'
			return {
				url: `${process.env.REACT_APP_CORRAL_APPURL}/map-icons/${iconUrl}`,
				width: 48,
				height: 48,
				anchorY: 24,
				mask: true
			}
		},
		getIconColor: [0, 0, 0, 255],
		getIconSize: (d: any) => {
			if(d.properties.id === params?.id) return 36
			if(!zoom) return 24
			const size = range(14, 10, 16, 0, zoom || 14)
			return size
		},
		getPointRadius: (d: any) => {
			if(d.properties.id === params?.id) return 24
			if(!zoom) return 24
			const size = range(14, 10, 10, 0, zoom || 14)
			return size
		},
		pointRadiusUnits: 'pixels',
		getText: (d: any) => {
			return d.properties.name
		},
		getTextColor: (d: any) => {
			if(d.properties.id === params?.id) return [255, 255, 255, 255]
			const color = d.properties.type.toUpperCase() === 'WATERTANK' ? '#60a5fa' 
				: d.properties.type.toUpperCase() === 'SALTANDMINERALDEPOSIT' ? '#c084fc' 
					: d.properties.type.toUpperCase() === 'GATE' ? '#fb923c' 
						: d.properties.type.toUpperCase() === 'EXCLUSION' ? '#dc2626' 
							: d.properties.type.toUpperCase() === 'SAFEZONE' ? '#34d399' :  '#ffffff'
			return hexToRGB(color)
		},
		textFontWeight: 500,
		textOutlineColor: [0, 0, 0, 255],
		textOutlineWidth: 5,
		textFontSettings: {
			sdf: true,
			fontSize: 48,
			buffer: 8,
			cutoff: .2
		},
		textFontFamily: 'Helvetica, Arial, sans-serif',
		getTextAnchor: (d: any) => {
			if(d.properties.id === params?.id) return 'middle'
			return 'start'
		},
		getTextAlignmentBaseline: 'center',
		getTextPixelOffset: (d: any) => {
			if(d.properties.id === params?.id) return [0, 35]
			return [15, 0]
			if(!zoom) return [0, 16]
			const size = range(14, 10, 20, 0, zoom || 14)
			return [0, size]
		},
		textSizeUnits: 'pixels',
		getTextSize: (d: any) => {
			if(d.properties.id === params?.id) return 16
			if(!zoom) return 16
			const size = range(14, 10, 16, 0, zoom || 14)
			return size

		},
		transitions: {
			getIconSize: { duration: 300, easing: 'ease' },
			getPointRadius: { duration: 300, easing: 'ease' },
			getTextSize: { duration: 300, easing: 'ease' },
		},
		updateTriggers: {
			data: [features],
			getText: [params?.id, features, edits, selectedFeatureIndexes, data],
			getIconSize: [params?.id, edits, features, zoom],
			getPointRadius: [params?.id, edits, features, zoom],
			getTextSize: [params?.id, edits, features, zoom],
			getPixelOffset: [params?.id],
		},
	});

	const safeZoneLayer = new PolygonLayer({
		id: 'structure-safe-zone-radius-polygon',
		data: features.features
			.filter((feature: Feature) => {
				return feature?.geometry?.type === 'Point' && feature?.properties?.safeZone === true
			})
			.map((feature: Feature) => {
				let safeZoneDistance = feature?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE
				if (edits && edits.id === feature.id) safeZoneDistance = edits?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE
				const buffer = turf.buffer(feature, safeZoneDistance, { units: 'meters' })
				buffer.id = feature.id
				buffer.properties = feature.properties
				return buffer
			}),
		visible: safezoneVisible,
		pickable: true,
		polygonOpacity: 0.5,
		polygonOpacityUnits: 'meters',
		getPolygon: (d: any) => d.geometry.coordinates,
		getFillColor: d => [0, 60, 140, 155],
		getLineColor: [0, 60, 140, 255],
		getLineWidth: 0.5,
		onClick(pickingInfo, event) {
			if (pickingInfo.object) {
				// setSelectedFeatureIndexes([pickingInfo.index])
			}
		},
	})

	const resizeIconLayer = new IconLayer({
		id: 'structure-safe-zone-radius-resize-icon',
		data: features.features
			.filter((feature: Feature) => feature?.geometry?.type === 'Point' && feature?.properties?.safeZone === true)
			.map((feature: Feature) => {
				return { ...feature, id: feature.id, properties: { ...feature.properties, center: feature } }
			}),
		pointType: 'icon',
		visible: resizeHandleStartPosition !== null && params?.mode === 'edit',
		// visible: mode.id === 'ModifyMode',
		pickable: false,
		stroked: false,
		sizeScale: 1,
		opacity: 1,
		sizeUnits: 'meters',
		// sizeMaxPixels: 48,
		getSize: (d: any) => {
			return d?.properties?.center?.id === params?.id ? 5 : 0
		},
		getAngle: (d: any) => {
			return -resizeHandleStartPosition?.object?.properties?.bearing + 45 || -175
		},
		background: false,
		getPosition: (d: any) => {
			let safeZoneDistance = (d?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE) + 5
			if (edits && edits.id === d.id) safeZoneDistance = (edits?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE) + 5
			// const distance = (d?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE) + 5
			const position = turf.destination(d, safeZoneDistance, resizeHandleStartPosition?.object?.properties?.bearing || 220, { units: 'meters' })
			return position.geometry.coordinates as any
		},
		getIcon: (d: any) => {
			return {
				url: `${process.env.REACT_APP_CORRAL_APPURL}/open-in-full.png`,
				width: 64,
				height: 64,
			}
		},
		updateTriggers: {
			getSize: [params?.id, params?.mode],
		},
	})

	// const safeZoneResizeHandleLayer = new PolygonLayer({
	// 	id: 'structure-safe-zone-resize-ring',
	// 	data: features.features
	// 		.filter((feature: Feature) => feature?.geometry?.type === 'Point' && feature?.properties?.safeZone === true)
	// 		.map((feature: Feature) => {
	// 			let safeZoneDistance = (feature?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE) + 5
	// 			if (edits && edits.id === feature.id) safeZoneDistance = (edits?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE) + 5
	// 			const buffer = turf.buffer(feature, safeZoneDistance, { units: 'meters' })
	// 			buffer.properties = { ...buffer.properties, center: feature }
	// 			return buffer
	// 		}),
	// 	visible: true,
	// 	// visible: mode.id === 'ModifyMode',
	// 	lineWidthUnits: 'meters',
	// 	getPolygon: (d: any) => d.geometry.coordinates,
	// 	getFillColor: d => [0, 60, 140, 0],
	// 	getLineColor: d => {
	// 		if (params.mode === 'edit' && d?.properties?.center?.id === params?.id) return [255, 255, 255, 255]
	// 		return [255, 255, 255, 0]
	// 	},
	// 	getCursor: ({ isHovering }: { isDragging: boolean; isHovering: boolean }) => {
	// 		return isHovering ? 'nesw-resize	' : 'crosshair'
	// 	},
	// 	getLineWidth: 0.5,
	// 	pickable: true,
	// 	getDashArray: [2, 4],
	// 	dashJustified: true,
	// 	dashGapPickable: true,
	// 	// extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
	// 	updateTriggers: {
	// 		getLineColor: [params?.id, edits],
	// 	},
	// 	onHover: pickingInfo => {
	// 		if (pickingInfo.object) {
	// 			const bearing = turf.bearing(pickingInfo.object.properties.center, pickingInfo.coordinate as turf.Coord)
	// 			setResizeHandleStartPosition({
	// 				...pickingInfo,
	// 				object: {
	// 					...pickingInfo.object,
	// 					properties: {
	// 						...pickingInfo.object.properties,
	// 						bearing: bearing,
	// 						startingEdge: turf.destination(pickingInfo.object.properties.center as Feature<GeoJSON.Point>, pickingInfo?.object?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE, bearing, {
	// 							units: 'meters',
	// 						}),
	// 					},
	// 				},
	// 			})
	// 		} else {
	// 			setResizeHandleStartPosition(null)
	// 		}
	// 	},
	// 	onDragStart: pickingInfo => {
	// 		if (params?.mode !== 'edit') return
	// 		if (pickingInfo?.object?.properties?.center?.id !== params?.id) return
	// 		setDragPanEnabled(false)
	// 		MapView?.getMap().dragPan.disable()
	// 	},
	// 	onDrag: (pickingInfo: any) => {
	// 		if (params?.mode !== 'edit') return
	// 		if (pickingInfo?.object?.properties?.center?.id !== params?.id) return
	// 		// TODO: temp disable drag to resize
	// 		// setTimeout(() => {
	// 		// 	if (pickingInfo.coordinate) {
	// 		// 		const distanceFromCenter = turf.distance(pickingInfo.object.properties.center.geometry.coordinates as GeoJSON.Position, pickingInfo.coordinate, { units: 'meters' })
	// 		// 		const initialDistanceFromPointToEdge = turf.distance(resizeHandleStartPosition?.object.properties.startingEdge, resizeHandleStartPosition?.coordinate as Coord, { units: 'meters' })
	// 		// 		let safeZoneDistance = distanceFromCenter - initialDistanceFromPointToEdge >= DEFAULT_SAFE_ZONE_DISTANCE ? distanceFromCenter - initialDistanceFromPointToEdge : DEFAULT_SAFE_ZONE_DISTANCE
	// 		// 		safeZoneDistance = Number(safeZoneDistance.toFixed(2))
	// 		// 		const updatedFeature = JSON.parse(JSON.stringify(features.features.find((feature: Feature) => feature.id === pickingInfo.object.properties.center.id)))
	// 		// 		if (updatedFeature) {
	// 		// 			Object.assign(updatedFeature, {
	// 		// 				properties: {
	// 		// 					...updatedFeature.properties,
	// 		// 					safeZoneDistance,
	// 		// 				},
	// 		// 			})
	// 		// 			// setFeatures({
	// 		// 			// 	...features,
	// 		// 			// 	features: features.features.map((feature: Feature) => (feature.id === updatedFeature.id ? updatedFeature : feature)),
	// 		// 			// })
	// 		// 			store.dispatch(structureSlice.actions.setSafeZoneDistance(safeZoneDistance))
	// 		// 			// store.dispatch(mapToolSlice.actions.setFeature(updatedFeature))
	// 		// 			// if (mapTools?.feature) {
	// 		// 			// } else {
	// 		// 			// 	store.dispatch(mapToolSlice.actions.setFeature({ ...pickingInfo.object, properties: { ...pickingInfo.object.properties, safeZoneDistance } }))
	// 		// 			// }
	// 		// 		}
	// 		// 	}
	// 		// }, 100)
	// 	},
	// 	onDragEnd: pickingInfo => {
	// 		MapView?.getMap().dragPan.enable()
	// 		if (params?.mode !== 'edit') return
	// 		if (pickingInfo?.object?.properties?.center?.id !== params?.id) return
	// 		setDragPanEnabled(true)
	// 		setTimeout(() => {
	// 			if (pickingInfo.coordinate) {
	// 				const distanceFromCenter = turf.distance(pickingInfo.object.properties.center.geometry.coordinates as GeoJSON.Position, pickingInfo.coordinate, { units: 'meters' })
	// 				const initialDistanceFromPointToEdge = turf.distance(resizeHandleStartPosition?.object.properties.startingEdge, resizeHandleStartPosition?.coordinate as Coord, { units: 'meters' })
	// 				let safeZoneDistance = distanceFromCenter - initialDistanceFromPointToEdge >= DEFAULT_SAFE_ZONE_DISTANCE ? distanceFromCenter - initialDistanceFromPointToEdge : DEFAULT_SAFE_ZONE_DISTANCE
	// 				safeZoneDistance = Number(safeZoneDistance.toFixed(2))
	// 				const updatedFeature = JSON.parse(JSON.stringify(features.features.find((feature: Feature) => feature.id === pickingInfo.object.properties.center.id)))
	// 				if (updatedFeature) {
	// 					Object.assign(updatedFeature, {
	// 						properties: {
	// 							...updatedFeature.properties,
	// 							safeZoneDistance,
	// 						},
	// 					})
	// 					// setFeatures({
	// 					// 	...features,
	// 					// 	features: features.features.map((feature: Feature) => (feature.id === updatedFeature.id ? updatedFeature : feature)),
	// 					// })
	// 					store.dispatch(structureSlice.actions.set(updatedFeature))

	// 					// if (mapTools?.feature) {
	// 					// 	store.dispatch(mapToolSlice.actions.setFeature({ ...mapTools?.feature, properties: { ...mapTools.feature?.properties, safeZoneDistance } }))
	// 					// } else {
	// 					// 	store.dispatch(mapToolSlice.actions.setFeature({ ...pickingInfo.object, properties: { ...pickingInfo.object.properties, safeZoneDistance } }))
	// 					// }
	// 				}
	// 			}
	// 		}, 100)
	// 	},
	// })

	const safeZoneRadiusLineLayer = new LineLayer({
		id: 'structure-safe-zone-radius-distance-line',
		data: features.features
			.filter((feature: Feature<any>) => feature?.geometry?.type === 'Point' && feature?.properties?.safeZone === true)
			.map((feature: Feature<any>) => {
				const edge = turf.destination(feature as Feature<GeoJSON.Point>, feature?.properties?.safeZoneDistance || DEFAULT_SAFE_ZONE_DISTANCE, 270, { units: 'meters' })
				return turf.lineString([edge.geometry.coordinates, feature.geometry.coordinates], { center: feature })
			}),
		visible: !dragPanEnabled,
		widthUnits: 'pixels',
		pickable: false,
		getSourcePosition: d => d.geometry.coordinates[0],
		getTargetPosition: d => d.geometry.coordinates[1],
		getColor: [255, 255, 255, 255],
		getWidth: 2,
	})

	class StructureLayer extends CompositeLayer {
		id: string = 'StructureLayer';
		renderLayers() {
			return [
				safeZoneLayer, 
				safeZoneRadiusLineLayer,
				// safeZoneRadiusTextLayer, // TODO: temp disabled
				// safeZoneResizeHandleLayer, // TODO: temp disabled
				resizeIconLayer, 
				geolayer
				// iconLayer, 
				// textLayer
			]
		}
	}

	const layer = new StructureLayer({
		pickable: true,
		id: 'structure-layer',
		_subLayerProps: {
			points: {
				pickable: true,
			},
		},
		onHover: (pickingInfo, event) => {
			if (pickingInfo?.sourceLayer?.props?.onHover) {
				pickingInfo?.sourceLayer?.props?.onHover(pickingInfo, event)
			}
		},
		onDragStart: (pickingInfo, event) => {
			if (pickingInfo?.sourceLayer?.props?.onDragStart) {
				pickingInfo?.sourceLayer?.props?.onDragStart(pickingInfo, event)
			}
		},
		onDrag: (pickingInfo, event) => {
			if (pickingInfo?.sourceLayer?.props?.onDrag) {
				pickingInfo?.sourceLayer?.props?.onDrag(pickingInfo, event)
			}
		},
		onDragEnd: (pickingInfo, event) => {
			if (pickingInfo?.sourceLayer?.props?.onDragEnd) {
				pickingInfo?.sourceLayer?.props?.onDragEnd(pickingInfo, event)
			}
		},
		onClick: (pickingInfo, event) => {
			if (pickingInfo?.sourceLayer?.props?.onClick) {
				pickingInfo?.sourceLayer?.props?.onClick(pickingInfo, event)
			}
			if (params?.mode === 'edit') return
			if (pickingInfo.object) {
				if (pickingInfo?.object?.geometry?.type === 'Point') MapView?.panTo(pickingInfo.object.properties.center?.geometry?.coordinates, { offset: [bufferWidth / 2, 0] })
			}
		},
	})

	return {
		handleMapHover,
		layer,
		handleMapClick,
	}
}
