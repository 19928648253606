import { useEffect, useRef } from 'react'
// http://javascriptkit.com/javatutors/requestanimationframe.shtml
/**
 * If we have mutable values that we want to remember
 * at the next or later renders and we don’t want them
 * to trigger a re-render when they change, then we should use useRef. I
 */

// function Component() {
//   const variable = React.useRef(5);

//   setTimeout(() => {
//     variable.current = variable.current + 3;
//   }, 100);

//   return <div>{variable.current}</div>;
// }

const useAnimationFrame = (callback: any) => {
	// Use useRef for mutable variables that we want to persist
	// without triggering a re-render on their change
	const requestRef = useRef(0)
	const previousTimeRef = useRef(0)
	/**
	 * The callback function is automatically passed a timestamp indicating
	 * the precise time requestAnimationFrame() was called.
	 */

	useEffect(() => {
		const animate = (time: number) => {
			if (previousTimeRef.current !== undefined) {
				const deltaTime = time - previousTimeRef.current
				callback(deltaTime)
			}
			previousTimeRef.current = time
			requestRef.current = requestAnimationFrame(animate)
		}
		requestRef.current = requestAnimationFrame(animate)
		return () => cancelAnimationFrame(requestRef.current)
	}, [callback]) // Make sure the effect runs only once
}

export default useAnimationFrame
// const Counter = () => {
// 	const [count, setCount] = React.useState(0)

// 	useAnimationFrame(deltaTime => {
// 		// Pass on a function to the setter of the state
// 		// to make sure we always have the latest state
// 		setCount(prevCount => (prevCount + deltaTime * 0.01) % 100)
// 	})

// 	return <div>{Math.round(count)}</div>
// }

// const rootElement = document.getElementById('root')
// ReactDOM.render(<Counter />, rootElement)
