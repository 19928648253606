import { useState, useEffect, useCallback } from 'react'
import { Input, Select } from 'components/inputs'
import { CardAction, CardActions } from 'components/cards'
import ColorPicker from 'components/inputs/ColorPicker'
import * as turf from '@turf/turf'
import styled from 'styled-components/macro'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState, store } from 'store'
import { mapSlice } from 'store/map.slice'
import { useCreatePastureFeatureMutation, useGetPastureFeatureQuery, useUpdatePastureFeatureMutation } from 'library/api/pastureFeature'
import { useCreatePastureMutation, useGetPastureQuery, useGetPasturesQuery, useUpdatePastureMutation } from 'library/api/pasture'
import { BoundaryType, boundarySlice } from './boundary.slice'
import { useMap } from 'react-map-gl'
import { useCardBuffer } from 'hooks'
import { camelize, kebabize } from 'utilities'
import LoadingSpinner from 'components/LoadingSpinner'
import Loading from 'components/Loading'
import { colors } from 'styles'
import { useSelector } from 'react-redux'
import * as uuid from 'uuid'
import { useGetUserQuery } from 'library/api/user'
import { Structure } from 'library/models/structure.model'
import { Pasture } from 'library/models'
import { structureSlice } from '../structures/structure.slice'
import { PastureStyle } from 'library/models/pasture.model'

export const BoundaryForm = () => {
	const { id, type, mode } = useParams()
	const navigate = useNavigate()
	const { MapView } = useMap()
	const card = useCardBuffer()
	
	// Redux State
	const edits = useSelector((state: RootState) => state.boundarySlice.feature)
	
	// API Calls
	const { data: currentUser } = useGetUserQuery()
	
	const { mutate: createFeature, isPending: createPastureFeatureIsLoading } = useCreatePastureFeatureMutation()
	const { mutate: updateFeature, isPending: updatePastureFeatureIsLoading } = useUpdatePastureFeatureMutation()
	const { mutate: createPasture, isPending: createPastureIsLoading } = useCreatePastureMutation()
	const { mutate: updatePasture, isPending: updatePastureIsLoading } = useUpdatePastureMutation()
	const { data: pastures, isLoading: getPasturesIsLoading } = useGetPasturesQuery()
	const { data: pasture, isSuccess, isLoading: getPastureIsLoading } = useGetPastureQuery({ id }, { enabled: !!id && type === 'pasture' })
	const { data: exclusionZone } = useGetPastureFeatureQuery({ id }, { enabled: !!id && type === 'exclusion'})
	
	type FormData = {
		id?: string
		new?: boolean
		type: string
		name: string
		style?: Partial<PastureStyle>
	}

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isValid, setIsValid] = useState(false)
	const [showError, setShowError] = useState(false)
	const [formData, setFormData] = useState<FormData>({
		type: 'Feature',
		name: '',
		style: {
			backgroundColor: type === 'exclusion' ? '#ef4444' : '#ffffff',
			outlineColor: type === 'exclusion' ? '#ef4444' : '#ffffff',
			outlineWidth: 4,
			opacity: 0.25
		}
	})

	function getParentPastures(pastureFeature: turf.Feature<GeoJSON.Geometry>) {
		return pastures?.filter((feature: Pasture) => {
			const coords: any = feature.geometry.coordinates
			const pasture = turf.polygon(coords)
			if (pastureFeature.geometry.type === 'Point') {
				return turf.booleanPointInPolygon(pastureFeature as turf.Feature<GeoJSON.Point>, pasture)
			}
			const points = turf.explode(pastureFeature as turf.Feature<GeoJSON.LineString>)
			return points.features.some(point => turf.booleanPointInPolygon(point, pasture))
		})
			.map((feature: Pasture) => feature.id) || []
	}

	const geoLayerIndexes = useCallback(() => {
		//@ts-ignore
		return MapView?.getLayer('geojson-ranch-map-layer')?.implementation?.map?.__deck?.props?.layers?.find(l => l.id === 'geojson-ranch-map-layer')?.props?.data?.features.map(l => `${l.properties.type}_${l.id}`).indexOf(`pasture_${pasture.id}`)
	}, [MapView, pasture])

	useEffect(() => {
		setIsValid(!isSubmitted && formData.name.length > 0)
	}, [formData.name.length, isSubmitted])

	// Zoom into target pasture
	useEffect(() => {
		if (!pasture) {
			return
		}

		const handleCenterAction = async () => {
			const [minLng, minLat, maxLng, maxLat] = turf.bbox(pasture.geometry)
			MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		}
		if (pasture?.style) {
			store.dispatch(boundarySlice.actions.setStyles(pasture.style))
			handleCenterAction()
		}
	}, [pasture, MapView, card.bufferWidth])

	// Initial load
	useEffect(() => {
		if (getPastureIsLoading) return
		if (formData?.id) return
		if (formData?.new) return

		let boundary;

		if (pasture) boundary = pasture
		if (exclusionZone) boundary = exclusionZone
		
		// creating a new boundary
		if (!boundary && !id) {
			if (!formData?.new) {
				setFormData({
					new: true,
					type: 'Feature',
					name: '',
					style: {
						backgroundColor: type === 'exclusion' ? '#ef4444' : '#ffffff',
						outlineColor: type === 'exclusion' ? '#ef4444' : '#ffffff',
						outlineWidth: 4,
						opacity: 0.25
					}
				})
			}
			return
		}
		// editing an existing boundary
		if (boundary && id) {
			// populate store for map
			store.dispatch(boundarySlice.actions.set({
				id: id,
				type: 'Feature',
				geometry: boundary.geometry,
				properties: {
					name: boundary.name,
					type: type as BoundaryType || 'pasture' as BoundaryType,
					style: boundary.style
				}
			}))
			// populate form data
			if (!formData?.id) setFormData({
				id: id,
				type: 'Feature',
				name: boundary.name,
				style: boundary.style
			})
		}

		if (mode === 'edit') {
			store.dispatch(mapSlice.actions.setMode('ModifyMode'))
			store.dispatch(boundarySlice.actions.setMode('ModifyMode'))
			store.dispatch(mapSlice.actions.setTutorial('MODIFY_BOUNDARY'))
		} else {
			store.dispatch(boundarySlice.actions.setMode('DrawPolygonMode'))
			store.dispatch(mapSlice.actions.setMode('DrawPolygonMode'))
			store.dispatch(boundarySlice.actions.setDefault(camelize(type) as BoundaryType))
		}
		
		const index = geoLayerIndexes()
		if(index && index > -1) store.dispatch(boundarySlice.actions.setIndexes([index]))
	}, [exclusionZone, formData?.id, formData?.new, geoLayerIndexes, getPastureIsLoading, id, mode, pasture, type])

	useEffect(() => {
		setIsLoading(getPasturesIsLoading || getPastureIsLoading || !!(id && !formData.id))
	}, [formData.id, getPastureIsLoading, getPasturesIsLoading, id])

	if (!edits) return null

	if (isLoading || !formData) return <LoadingWrapper><Loading color={colors.dark} /></LoadingWrapper>

	return (
		<Form
			onSubmit={async e => {
				e.preventDefault()
				if(!formData.name || !edits.geometry){
					setShowError(true)
					return
				}
				setIsSubmitted(true)
				
				let feature;
				let toNavId = id
				
				if (type === 'pasture') {
					if (!formData.id) {
						const generatedId = uuid.v4().toString()
						toNavId = generatedId
						const toCreate: Pasture = new Pasture({
							id: uuid.v4().toString(),
							name: formData.name,
							isDeleted: false,
							style: formData.style as any,
							geometry: edits.geometry as turf.Geometry,
						})
						createPasture(toCreate)
					} else {
						const toUpdate: Pasture = new Pasture({
							id: formData.id,
							name: formData.name,
							isDeleted: false,
							style: formData.style as any,
							geometry: edits.geometry as turf.Geometry,
						})
						feature = updatePasture(toUpdate)
					}
				}
				if (type === 'exclusion') {
					
					const pastureIds = getParentPastures(edits as turf.Feature<GeoJSON.Geometry>)
					store.dispatch(boundarySlice.actions.setPastureIds(pastureIds))
					const id = edits.id || uuid.v4().toString()
					
					if (edits.id === null) {
						feature = createFeature(new Structure({
							id,
							name: formData.name,
							type: 'exclusion',
							geometry: edits?.geometry as turf.Geometry,
							pastureIds: pastureIds,
							style: formData.style as PastureStyle
						}))
					} else {
						feature = updateFeature(new Structure({
							id,
							name: formData.name,
							type: 'exclusion',
							geometry: edits?.geometry as turf.Geometry,
							pastureIds: pastureIds,
							style: formData.style as PastureStyle
						}))
					}
					
				}
				
				store.dispatch(mapSlice.actions.clearTutorial())
				// TODO: Navigation triggers rerender before component unmounts, and before next page loads
				// this pushes the page navigation till next tick so the other processes can finish first
				setTimeout(() => navigate(`/${kebabize(type)}`), 0)
			}}>


			<Input
				label={'Name'}
				required
				maxLength={50}
				error={showError && !formData?.name ? 'Name is required' : undefined}
				value={formData?.name || ''}
				onChange={(e: any) => {
					setShowError(false)
					// setFormData({ ...formData, name: e.target.value })
					setFormData({ ...formData, name: e.target.value })
				}}
			/>
			<ColorPicker
				label={'Boundary Opacity and Color'}
				color={formData?.style?.outlineColor}
				onChange={color => {
					store.dispatch(boundarySlice.actions.setStyles({ ...formData.style, outlineColor: color}))
					setFormData({...formData, style: { ...formData.style, outlineColor: color } })
				}}>
				<Select
					value={formData?.style?.outlineWidth}
					label='Width'
					onChange={e => {
						store.dispatch(boundarySlice.actions.setStyles({ ...formData.style, outlineWidth: Number(e.target.value) }))
						setFormData({...formData, style: { ...formData.style, outlineWidth: Number(e.target.value) } })
					}}>
					{[1, 2, 3, 4, 8, 12, 16].map(width => (
						<option key={width} value={width}>
							{width} px
						</option>
					))}
				</Select>
			</ColorPicker>
			<ColorPicker
				label={'Fill Opacity and Color'}
				color={formData?.style?.backgroundColor}
				onChange={color => {
					store.dispatch(boundarySlice.actions.setStyles({ ...formData.style, backgroundColor: color }))
					setFormData({...formData, style: { ...formData.style, backgroundColor: color }})
				}}>
				<Select
					value={formData?.style?.opacity}
					label='Opacity'
					onChange={e => {
						store.dispatch(boundarySlice.actions.setStyles({ ...formData.style, opacity: Number(e.target.value) }))
						setFormData({...formData, style: { ...formData.style, opacity: Number(e.target.value) }})
					}}>
					{[0, 0.25, 0.5, 0.75, 1].map(opacity => (
						<option key={opacity} value={opacity}>{`${opacity * 100}%`}</option>
					))}
				</Select>
			</ColorPicker>
			
			<CardActions>
				<CardAction
					type={edits.geometry ? 'submit': 'button'}
					onClick={() => {
						store.dispatch(boundarySlice.actions.setMode('DrawPolygonMode'))
						store.dispatch(mapSlice.actions.setCursor('crosshair'))
						if (edits.geometry) {
							store.dispatch(mapSlice.actions.clearTutorial())
						} else {
							store.dispatch(mapSlice.actions.setTutorial('START_DRAWING_BOUNDARY'))
						}
					}}
					disabled={edits.geometry ? !isValid : false}
				>
					{<>{edits.geometry ? 'Save': 'Draw Boundary'}</>}
				</CardAction>
				<CardAction
					type={'button'}
					variant={isSubmitted ? 'dark' : 'danger'}
					onClick={() => {
						store.dispatch(boundarySlice.actions.setMode('ViewMode'))
						store.dispatch(mapSlice.actions.clearTutorial())
						navigate(-1)
					}}
					disabled={isSubmitted}
				>
						Cancel
				</CardAction>
			</CardActions>
			
			
		</Form>
	)
}

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Form = styled.form`
	padding: 1em;
`