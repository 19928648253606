import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon, MDIcon } from 'components/icons'
import { useGrazingDistributionLayer, useAnimalTrailLayer, useAnimalsLayer } from 'components/map/layers'
import { useGetHerdsQuery } from 'library/api/herd'
import { RootState, store } from 'store'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import { useCardBuffer } from 'hooks'
import styled from 'styled-components/macro'
import { CardAction, CardActions } from 'components/cards'
import { colors } from 'styles'
import { DetailsBody, DetailsHeader, DetailsItem } from 'components/details'
import { CardDeleteAction } from 'components/cards/CardActions'
import { boundarySlice } from './boundary.slice'
import { useDeletePastureMutation, useGetPastureQuery } from 'library/api/pasture'
import { useDeletePastureFeatureMutation, useGetPastureFeatureQuery } from 'library/api/pastureFeature'
import { useGetMapFeaturesQuery } from 'library/api/ranch'
import { kebabize } from 'utilities'
import { routeSlice } from 'store/route.slice'
import { useSelector } from 'react-redux'
export default function BoundaryDetails() {
	const navigate = useNavigate()
	const { id, type } = useParams<{id: string, type: string}>()
	const grazingDistribution = useGrazingDistributionLayer()
	const animalTrail = useAnimalTrailLayer()
	const { data: herds } = useGetHerdsQuery(undefined, {
		enabled: !!id
	})
	const herd = useMemo(() => {
		return herds?.find((herd: Herd) => herd?.pastureId === id)
	}, [herds, id])
	const { MapView } = useMap()
	const { mutate: deletePasture } = useDeletePastureMutation()
	const { mutate: deletePastureFeature } = useDeletePastureFeatureMutation()
	const animalsMap = useAnimalsLayer()
	const card = useCardBuffer()
	const { data: boundaries } = useGetMapFeaturesQuery(undefined, {
		enabled: !!id
	})

	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)

	
	// const { data: boundary, isPending } = useGetPastureQuery({ id }, { enabled: !!id })
	// const { data: structure, isPending: structureQueryIsPending } = useGetPastureFeatureQuery({id: id})
	// const { data: pasture, isPending:  pastureQueryIsPending } = useGetPastureQuery({id: id})
	const boundary = useMemo(() => {
		const target = boundaries?.features?.find((feature: turf.Feature) => feature.id === id)
		// return boundaries?.features?.find((feature: turf.Feature) => feature.id === id)
		return target
	},[boundaries, id])
	
	const grazingDays = useMemo(
		() => (herd?.pastureAssignmentDate ? `${Math.floor((new Date().getTime() - new Date(herd?.pastureAssignmentDate).getTime()) / (1000 * 3600 * 24))} Days` : 'Not Available'),
		[herd?.pastureAssignmentDate]
	)

	const handleDelete = async () => {
		store.dispatch(boundarySlice.actions.clear())
		store.dispatch(boundarySlice.actions.setIndexes([]))
		if(id) {
			if (boundary?.properties?.type === 'pasture') deletePasture({id: id})
			else deletePastureFeature({id: id})
			navigate('/features')
		}
	}

	const handleEdit = () => {
		animalsMap.setVisible(true)
		grazingDistribution.setVisible(false)
		animalTrail.reset()
		navigate(`/${type}/${id}/edit`)
	}

	const handleCenterAction = async () => {
		const [minLng, minLat, maxLng, maxLat] = turf.bbox(boundary)
		MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		if (isMobile) store.dispatch(routeSlice.actions.setSnapToIndex(0))
	}

	const handleGrazingPatternAction = async () => {
		setTimeout(() => navigate(`/${type}/${id}/grazing-map`), 300)
	}

	useEffect(() => {
		store.dispatch(boundarySlice.actions.setMode('ViewMode'))
	}, [])

	if (!boundary) return null

	return (
		<>
			<Container>
				<CardActions>
					<CardAction onClick={async () => handleCenterAction()}>
						<MDIcon size={'sm'} style={{ transform: 'scale(.75)' }} name={'my_location'} />
						Locate Position
					</CardAction>
					{type !== 'exclusion' && <CardAction onClick={async () => handleGrazingPatternAction()}>
						<Icon size={'sm'} style={{ transform: 'scale(.75)' }} name={'scatter-chart'} />
						Grazing Pattern
					</CardAction>}
				</CardActions>

				<DetailsHeader>Details</DetailsHeader>
				<DetailsBody>
					<DetailsItem label={'Name'}>{boundary?.properties?.name}</DetailsItem>
					<DetailsItem label={'Area'}>{turf.convertArea(turf.area(boundary), 'meters', 'acres').toFixed(1)} Acres</DetailsItem>
					<DetailsItem label={'Boundary Type'} tooltip={'The type of boundary this feature represents'}>{boundary?.properties?.type === 'pasture' ? 'Pasture' : 'Exclusion Zone'}</DetailsItem>
					{type !== 'exclusion' && <DetailsItem label={'Grazing Period'} tooltip={'Number of days animals have been in the pasture'}>{grazingDays}</DetailsItem>}
					{type !== 'exclusion' && <DetailsItem label={'Herd Assignment'}>{herd?.name || 'None'}</DetailsItem>}
				</DetailsBody>
				<CardActions>
					<CardAction style={{ flexDirection: 'row', justifyContent: 'center' }} onClick={e => handleEdit()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.dark} name={'pencil'} />
						Edit
					</CardAction>
					<CardDeleteAction onClick={e => handleDelete()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.error} name={'trash'} />
						Delete
					</CardDeleteAction>
				</CardActions>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
`
