import { useState, useMemo } from 'react'
// import { IconLayer, ScatterplotLayer } from '@deck.gl/layers/typed'
import { useGetAnimalFeaturesQuery } from 'library/api/ranch'
// import Supercluster from 'supercluster'
import * as turf from '@turf/turf'
///
import { CompositeLayer } from '@deck.gl/core/typed'
import { IconLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers/typed'
import Supercluster from 'supercluster'
import { useMap } from 'react-map-gl'
import { useNavigate, useParams } from 'react-router-dom'
import { selectedFeature } from 'store/selectedFeature'
import { RootState, store } from 'store'
import { useCardBuffer } from 'hooks'
import { animalSlice } from './animal.slice'
import { useSelector } from 'react-redux'
import { hexToRGB, range } from 'utilities'


export const useAnimalsLayer = () => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { MapView } = useMap()
	const selected = store.getState().selectedFeature
	const [clusterDensity, setClusterDensity] = useState(40)

	const visible = useSelector((state: RootState) => state.animalSlice.layer.visible)
	const boundaryMode = useSelector((state: RootState) => state.boundarySlice.mode)

	// const { data, isSuccess, isUninitialized, isLoading, isFetching, isError, error, currentData, status, refetch } = useGetAnimalFeaturesQuery()
	const { data, isSuccess, isLoading, isFetching, isError, error, status, refetch } = useGetAnimalFeaturesQuery()
	const { bufferWidth } = useCardBuffer()
	const animals = useMemo(() => {
		if (data?.features) {
			return data?.features?.filter((f: any) => f) || []
		}
		return []
	}, [data])

	// useEffect(() => {
	// 	if (data) {
	// 		if (id) {
	// 			const feature = data.features.find((feature: turf.Feature) => feature.id === id)
	// 			// if (feature) MapView?.panTo(feature?.geometry?.coordinates, { offset: [bufferWidth / 2, 0] })
	// 		}
	// 	}
	// }, [data, isUninitialized, bufferWidth, id, MapView])
	
	const layerProps = {
		context: MapView,
		data: animals ? animals : [],
		pickable: true,
		visible: visible,
		sizeScale: clusterDensity,
		getPosition: (d: any) => d.geometry.coordinates,
		onDoubleClick: (info: any, event: any) => {
			if (info.object.cluster) {
				const [minLng, minLat, maxLng, maxLat] = turf.bbox(turf.featureCollection(info.objects))
				// For some reason, the map doesn't zoom to the correct bounds on touch devices without a timeout
				setTimeout(() => {
					MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
				}, 100)
			} else {
				zoomIntoView(info.object.geometry.coordinates)
				navigate(`/animals/${info.object.id}`, { replace: id ? true : false })
				setTimeout(() => {
					store.dispatch(selectedFeature.actions.set(info))
				}, 500)
				// MapView?.panTo(info.object.geometry.coordinates)

				// MapView?.zoomTo(info.object.properties)
			}
		},
		selected: selected,
		onClick: (info: any, event: any) => {
			if (boundaryMode !== 'ViewMode') return
			if (info.object.cluster) {
				const [minLng, minLat, maxLng, maxLat] = turf.bbox(turf.featureCollection(info.objects))
				// For some reason, the map doesn't zoom to the correct bounds on touch devices without a timeout
				setTimeout(() => {
					MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
				}, 100)
			} else {
				// zoomIntoView(info.object.geometry.coordinates)
				navigate(`/animals/${info.object.id}`, { replace: id ? true : false })
				setTimeout(() => {
					store.dispatch(selectedFeature.actions.set(info))
				}, 500)
				// MapView?.panTo(info.object.geometry.coordinates)

				// MapView?.zoomTo(info.object.properties)
			}
		},
		// onHover: !hoverInfo.objects && setHoverInfo,
	}

	

	const layer = new IconClusterLayer({
		id: 'animals-cluster-layer',
		...layerProps,
	})
	function zoomIntoView(coords: turf.helpers.Position) {
		const zoom = layer.getPointUnclusterZoom(coords)
		MapView?.flyTo({ animate: true, center: coords as [number, number], zoom })
		return zoom
	}
	return {
		layer,
		visible,
		setVisible: (visible: boolean) => store.dispatch(animalSlice.actions.setVisible(visible)),
		setClusterDensity,
		zoomIntoView,
		data: data ? data : { type: 'FeatureCollection', features: [] },
		isSuccess,
		// isUninitialized,
		isLoading,
		isFetching,
		isError,
		error,
		// currentData,
		status,
		refetch,
	}
}

const fround =
	Math.fround ||
	(tmp => x => {
		tmp[0] = +x
		return tmp[0]
	})(new Float32Array(1))
function lngX(lng: number) {
	return lng / 360 + 0.5
}
function latY(lat: number) {
	const sin = Math.sin((lat * Math.PI) / 180)
	const y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI
	return y < 0 ? 0 : y > 1 ? 1 : y
}

export default class IconClusterLayer extends CompositeLayer {
	shouldUpdateState({ changeFlags }: { changeFlags: { somethingChanged: boolean } }) {
		return changeFlags.somethingChanged
	}

	updateState({ props, oldProps, changeFlags }: any) {
		const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale
		if (rebuildIndex) {
			const index = new Supercluster({ maxZoom: 18, radius: props.sizeScale * Math.sqrt(2) })
			index.load(
				props.data.map((d: any) => ({
					geometry: { coordinates: props.getPosition(d) },
					properties: d,
				}))
			)
			this.setState({ index })
		}
		const z = Math.floor(props?.context?.getZoom())
		if (rebuildIndex || z !== this.state.z) {
			this.setState({
				data: this.state.index.getClusters([-180, -90, 180, 90], z),
				z,
			})
		}
	}
	getClusterIndex() {
		return this.state.index
	}
	getPointUnclusterZoom(point: turf.helpers.Position) {
		const [lng, lat] = point
		const pointX = fround(lngX(lng))
		const pointY = fround(latY(lat))

		let expansionZoom = 20
		while (expansionZoom < 0) {
			const tree = this.state.index.trees[expansionZoom]

			const pointIdxs = tree.within(pointX, pointY, 0)

			const unclustered = pointIdxs.some((idx: any) => tree.points[idx].parentId !== -1)
			if (unclustered) return expansionZoom

			expansionZoom++
		}
		return expansionZoom
	}

	getPickingInfo({ info, mode }: any) {
		const pickedObject = info.object && info.object.properties
		if (pickedObject) {
			if (pickedObject.cluster && mode === 'query') {
				info.objects = this.state.index.getLeaves(pickedObject.cluster_id, Infinity).map((f: any) => f.properties)
			}
			info.object = pickedObject
		}
		return info
	}

	renderLayers() {
		const { data } = this.state
		if (!data) return null
		const { sizeScale, selected, visible, context }: any = this.props
		const zoom = context.getZoom()
		
		return [
			new ScatterplotLayer({
				data,
				getRadius: (d: any) => {
					
					if(d.properties.id === selected?.object?.id && !d.properties.cluster) return 24
					if(!zoom) return 24
					const size = range(14, 10, 10, 0, zoom || 14)
					return size
				},
				getPosition: (d: any) => d.geometry.coordinates,
				radiusUnits: 'pixels',
				getFillColor: (d: any) => {
					const color = d.properties.cluster ? hexToRGB('#fca5a5') : hexToRGB('#f87171')	
					return color
				},
				updateTriggers: {
					getRadius: [zoom, selected],
				}
			}),
			new IconLayer(
				this.getSubLayerProps({
					id: 'animals-cluster-layer-icon',
					data,
					// iconAtlas,

					getIcon: (d: any) => {
						const icon = d.properties.cluster ? 'cow-group.png' : 'cow.png'
						return {
							url: `${process.env.REACT_APP_CORRAL_APPURL}/map-icons/${icon}`,
							width: 48,
							height: 48,
							anchorY: 24,
							mask: true,
						}
					},
					// getSize: (d: any) => (!d.properties.cluster && d?.properties?.id === selected?.object?.id ? 8 : 5),
					getSize: (d: any) => {
						if(d.properties.id === selected?.object?.id && !d.properties.cluster) return 36
						if(!zoom) return 24
						const size = range(14, 10, 16, 0, zoom || 14)
						return size
					},
					getPosition: (d: any) => d.geometry.coordinates,
					updateTriggers: {
						getSize: [zoom, selected],
					},
				})
			),
			new TextLayer(
				this.getSubLayerProps({
					data,
					id: 'animals-cluster-layer-text',
					pointType: 'icon+text',
					visible: visible,
					background: false,
					fontWeight: 500,
					outlineColor: [0, 0, 0, 255],
					outlineWidth: 5,
					textFontFamily: 'Helvetica, Arial, sans-serif',
					fontSettings: {
						sdf: true,
						fontSize: 48,
						buffer: 8,
						cutoff: .2
					},
					
					
					getPosition: (d: any) => d.geometry.coordinates,
					getText: (d: any) => {
						return d.properties.cluster ? `x${d?.properties?.point_count_abbreviated}` : `${d.properties.properties.tag}`
					},
					getColor: (d: any) => {
						if(d.properties.id === selected?.object?.id && !d.properties.cluster) return [255,255,255,255]
						const color = d.properties.cluster ? hexToRGB('#fca5a5') : hexToRGB('#f87171')	
						return color
					},
					// getSize: (d: any) => (!d.properties.cluster && d?.properties?.id === selected?.object?.id ? 16 : 12),
					getTextAnchor: (d: any) => {
						if(d.properties.id === selected?.object?.id && !d.properties.cluster) return 'middle'
						return 'start'
					},
					getAlignmentBaseline: () => 'center',
					getPixelOffset: (d: any) => {
						
						if(d.properties.id === selected?.object?.id && !d.properties.cluster) return [0, 35]
						return [15, 0]
						if(!zoom) return [0, 16]
						const size = range(14, 10, 20, 0, zoom || 14)
						return [0, size]
					},
					sizeUnits: 'pixels',
					getSize: (d: any) => {
						
						if(d.properties.id === selected?.object?.id) return 16
						if(!zoom) return 16
						const size = range(14, 10, 16, 0, zoom || 14)
						return size

					},
					updateTriggers: {
						getSize: [zoom, selected],
						getText: [sizeScale, selected],
					}
				})
			),
		]
	}
}
