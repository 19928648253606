import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Position } from '@turf/turf'

interface GrazingDistibutionState {
	id?: number | null
	type?: 'animal' | 'pasture' | 'herd' | null
	visible: boolean
	startDate?: Date | null
	endDate?: Date | null
	coordinates: {COORDINATES: Position}[],
}

const initialState: GrazingDistibutionState = {
	type: null,
	id: null,
	visible: false,
	startDate: null,
	endDate: null,
	coordinates: [],
}

export const grazingDistributionSlice = createSlice({
	name: 'grazingDistribution',
	initialState,
	reducers: {
		clear: state => {
			state.id = null
			state.type = null
			state.visible = false
			state.startDate = null
			state.endDate = null
		},
		reset: () => initialState,
		setVisible: (state, action: PayloadAction<boolean>) => {
			state.visible = action.payload
		},
		setDateRange: (state, action: PayloadAction<{ startDate: Date; endDate: Date }>) => {
			state.startDate = action.payload.startDate
			state.endDate = action.payload.endDate
		},
		setType: (state, action: PayloadAction<'animal' | 'pasture' | 'herd' | null>) => {
			state.type = action.payload
		},
		setId: (state, action: PayloadAction<number | null>) => {
			state.id = action.payload
		},
		setCoordinates: (state, action: PayloadAction<{COORDINATES: Position}[]>) => {
			state.coordinates = action.payload
		},
		set: (state, action: PayloadAction<GrazingDistibutionState>) => {
			state.id = action?.payload?.id || state.id
			state.type = action?.payload?.type || state.type
			state.visible = action?.payload?.visible || state.visible
			state.startDate = action?.payload?.startDate || state.startDate
			state.endDate = action?.payload?.endDate || state.endDate
		},
	},
})

export const { actions, reducer } = grazingDistributionSlice
export const { set, clear, setCoordinates, setId, setType, setDateRange, setVisible, reset } = actions
export default reducer
