import styled from 'styled-components/macro'
import { Icon } from 'components/icons'
import { colors } from 'styles'

const addCard = ({text, className, onClick}: {text: string, className?: any, onClick: (event: React.MouseEvent<HTMLButtonElement>) => void}) => {
	return (
		<button className={className} onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClick(event)}>
			<Icon color={'var(--textColor)'} name='plus'></Icon>
			<Text>{text}</Text>
		</button>
	)
}

export const AddCard = styled(addCard)`
	--textColor: black;
	all: unset;
	border: 2px dotted ${colors.light};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5em;
	border-radius: var(--border-radius);
	color: var(--textColor);
	border-color: var(--textColor);
	&:hover {
		cursor: pointer;
		border-color: ${colors.primary};
		color: ${colors.primary};
		--textColor: ${colors.primary};
	}
`
const Text = styled.div`
	font-size: 1em;
	font-weight: 600;
`
