import { useState } from 'react'
// import { useGetUserQuery, useGetUsersByRanchIdQuery, useAddUserImageMutation } from 'api/user'
import { useGetAccountQuery } from 'library/api/account'
import { Input,  } from 'components/inputs'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { transparentize } from 'polished'
// import { Route, Routes } from 'react-router-dom'
// import AccountUsersPage from './Users'
import { Icon } from 'components/icons'
import { Container } from 'components/base'
export const Billing = () => {
	// const { data: currentUser } = useGetUserQuery()
	const { data: account } = useGetAccountQuery()
	const [editMode, setEditMode] = useState(false)

	return (
		<Container>
			<h2>Billing</h2>
			<CardWrapper>
				<Card>
					<CardHeader>
						<h3>Billing Address</h3>
						<EditButton type={'button'} onClick={_ => setEditMode(!editMode)}>{editMode ? 'Done' : 'Edit'}
							<Icon name={editMode ? 'check' : 'edit-alt'} />
						</EditButton>
					</CardHeader>
					<Grid>
						<Input disabled={!editMode} label={'Address 1'} defaultValue={account?.billingAddress?.address1} />
						<Input disabled={!editMode} label={'Address 2'} defaultValue={account?.billingAddress?.address2} />
						<Input disabled={!editMode} label={'City'} defaultValue={account?.billingAddress?.city} />
						<Input disabled={!editMode} label={'State'} defaultValue={account?.billingAddress?.state} />
						<Input disabled={!editMode} label={'Zip Code'} defaultValue={account?.billingAddress?.postalCode} />
						{/* <Input disabled={!editMode} label={'Country'} defaultValue={account?.country} /> */}
					</Grid>
				</Card>
			</CardWrapper>
		</Container>
	)
}

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`

const Card = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const CardHeader = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    & > :nth-child(1) {
        grid-column: span 3;
    }
    & > :nth-child(2) {
        grid-column: span 3;
    }
    // & > :last-child {
    //     grid-column: 3
    // }
`


const EditButton = styled.button`
    padding: .5em 1em;
    border-radius: 999999px;
    color: red;
    color: ${transparentize(.2, colors.dark)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25em;
    border: 1px solid ${transparentize(.9, colors.dark)};
    background: transparent;
`