import { MDIcon } from 'components/icons'
import { transparentize } from 'polished'
import { useEffect, useRef } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { useAddAccountImageMutation, useCreateAccountMutation, useGetAccountQuery } from 'library/api/account'
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useAccount } from '@azure/msal-react';
import { CreateUser } from 'library/models/user.model'
import { CreateRanch } from 'library/models/ranch.model'
import { stateAbbreviations, countries } from 'library/models/address.model'
import { AvatarInput } from 'components/inputs/AvatarInput'
import { useSelector } from 'react-redux'
import { RootState, store } from 'store'
import { v4 as uuidv4 } from 'uuid'
import CorralLogoLight from 'images/Mark_C/Light/Corral_Mark_C_light.svg'
import { CreateAccount, Account } from 'library/models/account.model'
import Loading from 'components/Loading'
import { signUpRequest } from 'auth/msal'
import { InteractionType } from '@azure/msal-browser'
import Welcome from './Welcome'
import LoadingSpinner from 'components/LoadingSpinner'


export const SignUp = () => {
	const params = useParams()
	const navigate = useNavigate()
	const isAuthenticated = useIsAuthenticated()
	const { data: userAccount, error: accountError, isError, isFetchedAfterMount } = useGetAccountQuery(undefined, { enabled: isAuthenticated, retry: false })
	const { mutateAsync: createAccount } = useCreateAccountMutation()
	const { mutate: addAccountImage } = useAddAccountImageMutation()
	const paths = ['account', 'profile', 'ranch']

	const image = useSelector((state: RootState) => state.createAccountSlice.image)
	const setImage = (payload: ImageFile) => store.dispatch({ type: 'createAccountSlice/setImage', payload: payload })

	const account = useSelector((state: RootState) => state.createAccountSlice)
	const setAccount = (payload: CreateAccount) => store.dispatch({ type: 'createAccountSlice/setAccount', payload: payload })

	const name = useSelector((state: RootState) => state.createAccountSlice.name)
	const setName = (payload: string) => store.dispatch({ type: 'createAccountSlice/setName', payload: payload })

	const user = useSelector((state: RootState) => state.createAccountSlice.user)
	const setUser = (payload: CreateUser) => store.dispatch({ type: 'createAccountSlice/setUser', payload: payload })

	const phone = useSelector((state: RootState) => state.createAccountSlice.phone)
	const setPhone = (payload: string) => store.dispatch({ type: 'createAccountSlice/setPhone', payload: payload })

	const ranch = useSelector((state: RootState) => state.createAccountSlice.ranch)
	const setRanch = (payload: CreateRanch) => store.dispatch({ type: 'createAccountSlice/setRanch', payload: payload })
	const { instance } = useMsal()
	const MsalAccount = useAccount()

	const firstNameRef = useRef<HTMLInputElement>(null)
	const ranchNameRef = useRef<HTMLInputElement>(null)
	
	const Loader = () => <LoadingOverlay><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>

	useEffect(() => {
		if (params?.section === 'profile') firstNameRef.current?.focus()
		if (params?.section === 'ranch') ranchNameRef.current?.focus()
	}, [params?.section])
	return (
		<MsalAuthenticationTemplate 
			interactionType={InteractionType.Redirect} 
			authenticationRequest={{
				...signUpRequest,
				redirectUri: '/sign-up/account',
				redirectStartPage: '/sign-up/account',
			}} 
			// for some reason, if the user goes directly to /sign-up/account, an error is thrown, so when that happens, we just show the welcome page
			errorComponent={Welcome}
			loadingComponent={Loader}>
			<Container>
				{!isFetchedAfterMount ? <Loader /> : <>
					{!!userAccount && isFetchedAfterMount && !isError ? <Navigate to={'/'} /> : null}
				
					<Header>
						{params?.section === 'account' ? <div></div> : 
							<BackButton onClick={() => navigate(-1)}>
								<MDIcon name={'arrow_back'} weight={400} />
						Back
							</BackButton>
						}
						<Logo src={CorralLogoLight} alt={'Corral Technologies Logo'} />
						<LogoutButton onClick={() => instance.logout()}>
						Logout
						</LogoutButton>
					</Header>
					<Form onSubmit={async (e) => {
						e.preventDefault();
						const path = paths.indexOf(params?.section || '')

						if (path < paths.length - 1) {
							if (params?.section === 'profile' && !ranch.name) {
								setRanch({ ...ranch, name: `${user.firstName}'s Ranch` })
							}
							if (params?.section === 'ranch' && !ranch.addressSameAsAccount) {
								setRanch({ ...ranch, address: account.ranch.address })
							}
							navigate(`/sign-up/${paths[path + 1]}`)
						} else {
							const ranchData = ranch?.addressSameAsAccount ? { ...ranch, address: account?.address } : ranch
							const userData = MsalAccount?.username ? { ...user, email: MsalAccount?.username, name: `${user.firstName} ${user.lastName}` } : user
							const createAccountObj = new CreateAccount({ ...account, name: account.name ? account.name : `${user.firstName} ${user.lastName}`, user: userData, ranch: ranchData })
						
							// TODO: this is a TEMP fix. Address is not generating id correctly.
							const newAddressId = uuidv4().toString()
							const toSubmit = {
								...createAccountObj,
								address: {
									...createAccountObj.address,
									id: newAddressId
								},
								ranch: {
									...createAccountObj.ranch,
									address: {
										...createAccountObj.ranch.address,
										id: newAddressId
									}
								}
							}

							setAccount(createAccountObj)
							const accountResp: any = await createAccount(toSubmit)

							if (accountResp?.id && image.data) {
								await addAccountImage({ id: accountResp?.id, image: image })
							}
							navigate('/')
						}
					}}>
						{params?.section === 'account' ?
							<>
								<HeaderText>Account</HeaderText>
								<HeaderSubText>Choose a name for your account</HeaderSubText>
								<p>(If left blank, your first and last name with be used)</p>
								<InputWrapper>
									<Input value={name} placeholder={'Company Name'} id={'companyName'} onChange={(event) => {
										setName(event.target.value)
										event.preventDefault()
									}} />
									<Label htmlFor={'companyName'}>Company Name</Label>
								</InputWrapper>
								<InputGroupWrapper>
									<Title>Address</Title>
									<InputWrapper>
										<Input
											value={account.address.address1}
											placeholder={'Line 1'}
											id={'account_address1'}
											maxLength={50}
											onChange={(event) => {
												setAccount({ ...account, address: { ...account.address, address1: event.target.value } })
												event.preventDefault()

											}} />
										<Label htmlFor={'account_address1'}>Line 1</Label>
									</InputWrapper>
									<InputWrapper>
										<Input
											value={account.address.address2}
											placeholder={'Line 2'}
											maxLength={50}
											id={'account_address2'}
											onChange={(event) => {
												setAccount({ ...account, address: { ...account.address, address2: event.target.value } })
												event.preventDefault()
											}} />
										<Label htmlFor={'account_address2'}>Line 2</Label>
									</InputWrapper>
									<InputGroup>
										<InputWrapper>
											<Input
												value={account.address.city}
												placeholder={'City'}
												maxLength={50}
												id={'account_city'}
												onChange={(event) => {
													setAccount({ ...account, address: { ...account.address, city: event.target.value } })
													event.preventDefault()
												}} />
											<Label htmlFor={'account_city'}>City</Label>
										</InputWrapper>
										<InputWrapper>
											<Select
												required
												value={account.address.state}
												// placeholder={'State'}
												id={'account_state'}
												onChange={(event) => {
													setAccount({ ...account, address: { ...account.address, state: event.target.value } })
													event.preventDefault()
												}}>
												<option value={''} style={{ display: 'none', color: 'black' }}></option>
												{stateAbbreviations.map((state) => <option style={{ color: 'black' }} key={state} value={state}>{state}</option>)}
											</Select>
											<Label htmlFor={'account_state'}>State</Label>
										</InputWrapper>
										<InputWrapper>
											<Input
												type={'text'}
												maxLength={5}
												minLength={5}
												pattern={'[0-9]{5}'}
												value={account.address.postalCode}
												placeholder={'Zip Code'}
												id={'account_postalCode'}
												onChange={(event) => {
													setAccount({ ...account, address: { ...account.address, postalCode: event.target.value.replace(/[^0-9]/g, '').slice(0, 5) } })
													event.preventDefault()
												}} />
											<Label htmlFor={'account_postalCode'}>Zipcode</Label>
										</InputWrapper>
									</InputGroup>
									<InputWrapper>
										<Select
											required
											value={account.address.country}
											// placeholder={'Country'}
											id={'account_country'}
											onChange={(event) => {
												setAccount({ ...account, address: { ...account.address, country: event.target.value } })
											}}>
											<option value={''} style={{ display: 'none', color: 'black' }}></option>
											{countries.map((country) => <option style={{ color: 'black' }} key={country.code} value={country.code}>{country.name}</option>)}
										</Select>
										<Label htmlFor={'account_country'}>Country</Label>
									</InputWrapper>
								</InputGroupWrapper>
								<ContinueButton>Continue</ContinueButton>
							</>
							: params?.section === 'profile' ?
								<>
									<HeaderText>Profile</HeaderText>
									<HeaderSubText>Let's get your profile set up</HeaderSubText>
									<InputWrapper>
										<AvatarInput
											editLabelAlwaysVisible={true}
											imageSize={120}
											image={null}
											onChange={(image) => setImage(image)}
										/>
									</InputWrapper>
									<InputWrapper>
										<Input
											ref={firstNameRef}
											autoComplete={'given-name'}
											value={user.firstName}
											placeholder={'First Name'}
											maxLength={100}
											id={'firstName'}
											onChange={(event) => {
												setUser({ ...user, firstName: event.target.value })
												event.preventDefault()
											}}
										/>
										<Label htmlFor={'firstName'}>First Name</Label>
									</InputWrapper>
									<InputWrapper>
										<Input
											autoComplete={'family-name'}
											value={user.lastName}
											placeholder={'Last Name'}
											maxLength={50}
											id={'lastName'}
											onChange={(event) => {
												setUser({ ...user, lastName: event.target.value })
												event.preventDefault()
											}} />
										<Label htmlFor={'lastName'}>Last Name</Label>
									</InputWrapper>
									<InputWrapper>
										<Input
											type={'phone'}
											autoComplete={'phone'}
											maxLength={50}
											value={phone}
											placeholder={'Phone'}
											id={'phone'}
											onChange={(event) => {
												setPhone(event.target.value)
												event.preventDefault()
											}} />
										<Label htmlFor={'phone'}>Phone</Label>
									</InputWrapper>

									<RadioGroup>
										<RadioGroupTitle>System of Measurement</RadioGroupTitle>
										<RadioWrapper>
											<Radio>
												<RadioInput
													onChange={e => setUser({ ...user, systemOfMeasure: 'imperial' })}
													checked={user.systemOfMeasure === 'imperial'}
													name={'systemOfMeasure'}
													id={'systemOfMeasureMetricImperial'}
													type={'radio'}
												/>
												<RadioLabel htmlFor={'systemOfMeasureMetricImperial'}>Imperial</RadioLabel>
											</Radio>
											<Radio>
												<RadioInput
													onChange={e => setUser({ ...user, systemOfMeasure: 'metric' })}
													checked={user.systemOfMeasure === 'metric'}
													name={'systemOfMeasure'}
													id={'systemOfMeasureMetric'}
													type={'radio'}
												/>
												<RadioLabel htmlFor={'systemOfMeasureMetric'}>Metric</RadioLabel>
											</Radio>
										</RadioWrapper>
									</RadioGroup>
									<ContinueButton>Continue</ContinueButton>
								</>
								: params?.section === 'ranch' ?
									<>
										<HeaderText>Ranch Name</HeaderText>
										<HeaderSubText>Choose a name for your ranch</HeaderSubText>
										<p>(If you have more than one, you can add additional ranches later)</p>
										<InputWrapper>
											<Input
												ref={ranchNameRef}
												value={ranch.name}
												placeholder={'Ranch Name'}
												id={'ranchName'}
												maxLength={100}
												onChange={(event) => {
													setRanch({ ...ranch, name: event.target.value })
													event.preventDefault()
												}} />
											<Label htmlFor={'ranchName'}>Ranch Name</Label>
										</InputWrapper>
										<InputGroupWrapper>
											<Title>Address</Title>
											<RadioGroup>
												<RadioGroupTitle>Same as Account</RadioGroupTitle>
												<RadioWrapper>
													<Radio>
														<RadioInput
															onChange={event => {
																setRanch({ ...ranch, addressSameAsAccount: true })
															}}
															checked={ranch.addressSameAsAccount}
															name={'addressSameAsAccount'}
															id={'addressSameAsAccountTrue'}
															type={'radio'}
														/>
														<RadioLabel htmlFor={'addressSameAsAccountTrue'}>Yes</RadioLabel>
													</Radio>
													<Radio>
														<RadioInput
															onChange={event => {
																setRanch({ ...ranch, addressSameAsAccount: false })
															}}
															checked={!ranch.addressSameAsAccount}
															name={'addressSameAsAccount'}
															id={'addressSameAsAccountFalse'}
															type={'radio'}
														/>
														<RadioLabel htmlFor={'addressSameAsAccountFalse'}>No</RadioLabel>
													</Radio>
												</RadioWrapper>
											</RadioGroup>
											{!ranch.addressSameAsAccount ?
												<>
													<InputWrapper>
														<Input
															value={ranch.address.address1}
															maxLength={50}
															placeholder={'Line 1'}
															id={'ranch_address1'}
															onChange={(event) => {
																setRanch({ ...ranch, address: { ...ranch.address, address1: event.target.value } })
																event.preventDefault()

															}} />
														<Label htmlFor={'ranch_address1'}>Line 1</Label>
													</InputWrapper>
													<InputWrapper>
														<Input
															value={ranch.address.address2}
															maxLength={50}
															placeholder={'Line 2'}
															id={'ranch_address2'}
															onChange={(event) => {
																setRanch({ ...ranch, address: { ...ranch.address, address2: event.target.value } })
																event.preventDefault()
															}} />
														<Label htmlFor={'ranch_address2'}>Line 2</Label>
													</InputWrapper>
													<InputGroup>
														<InputWrapper>
															<Input
																value={ranch.address.city}
																maxLength={50}
																placeholder={'City'}
																id={'ranch_city'}
																onChange={(event) => {
																	setRanch({ ...ranch, address: { ...ranch.address, city: event.target.value } })
																	event.preventDefault()
																}} />
															<Label htmlFor={'ranch_city'}>City</Label>
														</InputWrapper>
														<InputWrapper>
															<Select
																required
																value={ranch.address.state}
																// placeholder={'State'}
																id={'ranch_state'}
																onChange={(event) => {
																	setRanch({ ...ranch, address: { ...ranch.address, state: event.target.value } })
																	event.preventDefault()
																}}>
																<option value={''} style={{ display: 'none' }}></option>
																{stateAbbreviations.map((state) => <option style={{ color: 'black' }} key={state} value={state}>{state}</option>)}
															</Select>
															<Label htmlFor={'ranch_state'}>State</Label>
														</InputWrapper>
														<InputWrapper>
															<Input
																type={'text'}
																maxLength={5}
																minLength={5}
																pattern={'[0-9]{5}'}
																value={ranch.address.postalCode}
																placeholder={'Zip Code'}
																id={'ranch_postalCode'}
																onChange={(event) => {
																	setRanch({ ...ranch, address: { ...ranch.address, postalCode: event.target.value.replace(/[^0-9]/g, '').slice(0, 5) } })
																	event.preventDefault()
																}} />
															<Label htmlFor={'ranch_postalCode'}>Zip Code</Label>
														</InputWrapper>
													</InputGroup>
													<InputWrapper>
														<Select
															required
															value={ranch.address.country}
															// placeholder={'Country'}
															id={'ranch_country'}
															onChange={(event) => {
																setRanch({ ...ranch, address: { ...ranch.address, country: event.target.value } })
															}}>
															<option value={''} style={{ display: 'none', color: 'black' }}></option>
															{countries.map((country) => <option style={{ color: 'black' }} key={country.code} value={country.code}>{country.name}</option>)}
														</Select>
														<Label htmlFor={'ranch_country'}>Country</Label>
													</InputWrapper>
												</>
												: null}
										</InputGroupWrapper>
										<ContinueButton>Continue</ContinueButton>
									</>

									: null
						}
					</Form>
				</>}
			</Container>
		</MsalAuthenticationTemplate>
	)
}

export default SignUp;

const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.primary};
`

const Title = styled.h3`
    font-size: .75em;
    color: rgba(255,255,255,.75);
    // font-weight: 300;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(calc(-100% - .5em));
`
const InputGroupWrapper = styled.div`
    border: 1px solid rgba(255,255,255,.15);
    border-radius: 5px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: relative;
    margin-top: 1.75em;
`
const Header = styled.div`
    width: 100%;
    padding: 1em;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: ${transparentize(.5, colors.primary)};
	backdrop-filter: blur(10px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
`

const Container = styled.div`
    width: 100%;
    min-height: 100svh;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
	background: ${colors.primary};	
    h2, p {
        color: white;
        text-align: center;
    }

`
const Logo = styled.img`
    height: 55px;
    margin: auto;
	justify-self: center;
    @media ${device.max.mobile} {
        height: 55px;
    }
`
const BackButton = styled.button`
    background: none;
    border: none;
    color: white;
    text-decoration: none;
    font-size: 1.25em;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: .25em;
	justify-self: start;
    &:hover {
        opacity: .75;
        color: white;
    }
`

const LogoutButton = styled(BackButton)`
	justify-self: end;

`

const Form = styled.form`
    padding: 1em;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1em;
`

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Label = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1em;
    color: rgba(255,255,255,.75);
    padding: .75em 1em;
    pointer-events: none;
    transition: all .25s ease-in-out;
    ${InputWrapper}:focus-within & {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }

`

const Input = styled.input`
    border: none;
    width: 100%;
    border-radius: 5px;
    background: rgba(255,255,255,.2);
    color: #ffffff;
    padding: .75em 1em;
    &::placeholder {
        color: transparent;
    }
    &:not(:placeholder-shown) ~ ${Label} {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }
`
const Select = styled.select`
    border: none;
    width: 100%;
    border-radius: 5px;
    background: rgba(255,255,255,.2);
    color: #ffffff;
    padding: .75em 1em;
    &::placeholder {
        color: transparent;
    }
    &:has(option[value=""]:not(:checked)) ~ ${Label} {
        font-size: .75em;
        top: -2em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }
`
const InputGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    & > ${InputWrapper} {
        flex: 1;
    }
`
const RadioLabel = styled.label`
    border-radius: 5px;
    padding: .25em 1em;
    color: white;
    border: 1px solid rgba(255,255,255,.85);
    cursor: pointer;
    position: relative;   
`

const Radio = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: .5em;
    position: relative;
    &:focus-within::before {
        content: '';
        position: absolute;
        inset: 5px;
        border-radius: 8px;
        border: 2px solid blue;
    }
`


const RadioInput = styled.input`
    width: 0;
    height: 0;
    appearance: none;
    position: absolute;
    &:checked + ${RadioLabel} {
        background: rgba(255,255,255,.85);
        color: black;
        mix-blend-mode: screen;
        
    }
`
const RadioGroup = styled.fieldset`
display: flex;
flex-direction: row;
background: rgba(255,255,255,.2);
border-radius: 5px;
align-items: center;
justify-content: space-between;
margin-top: .5em;
margin-bottom: .5em;
`
const RadioGroupTitle = styled.div`
    padding-left: 1em;
    color: rgba(255,255,255,.85);
    font-size: 1em;
    margin-bottom: 0;
    line-height: 1.2;
`

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
// background: rgba(255,255,255,.2);
// border-radius: 5px;
`


const ContinueButton = styled.button`
    border: none;
    text-decoration: none;
    background: white;
    opacity: .85;
    text-transform: uppercase;
    color: black;
    mix-blend-mode: screen;
    font-size: 1em;
    font-weight: 400;
    padding: 1em 2em;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: .75;
    }
`
const HeaderText = styled.h2`
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    margin-bottom: 0;
    @media ${device.max.mobile} {
        font-size: 2em;
    }
`
const HeaderSubText = styled.p`
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1.25em;

`