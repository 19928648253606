import { useEffect, useState } from 'react'
import { MDIcon } from 'components/icons'
import { lighten, transparentize } from 'polished'
import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { RoundCloseButton } from 'components/buttons'

type InputValue = string
type TItem = { title: string; value: InputValue }
type Props = {
	header: string
	items?: TItem[] | undefined | null
	defaultValue?: TItem
	onChange: (item: TItem) => void
	onClick?: () => void
	disabled?: boolean
	required?: boolean
}
export const RadioList = ({ header, items, defaultValue, onChange, disabled, required, onClick }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [selected, setSelected] = useState<TItem | undefined>(defaultValue)
	useEffect(() => {
		setSelected(defaultValue)
	}, [defaultValue])
	return (
		<>
			<ButtonWrapper
				onClick={e => {
					onClick ? onClick() : setIsOpen(true)
				}}
			>
				<ButtonHeader>{header}{required && <RequiredStar> * </RequiredStar>}</ButtonHeader>
				<Button disabled={disabled} type={'button'}>
					<div>{selected?.title}</div> <MDIcon name={'chevron_right'} />
				</Button>
			</ButtonWrapper>
			<BottomSheet
				blocking={false}
				header={
					<SheetHeader>
						{header}
						<RoundCloseButton
							type={'button'}
							onClick={e => {
								setIsOpen(false)
							}}
						/>
					</SheetHeader>
				}
				open={isOpen}
				onDismiss={() => setIsOpen(false)}
				snapPoints={({ maxHeight }) => [maxHeight - 65]}>
				<Container>
					<List>
						{items?.map(item => (
							<ListItem key={`${item.title}_${item.value}`}>
								<Item htmlFor={item.title}>
									{item.title}
									<input
										id={item.title}
										type={'radio'}
										value={item.value}
										checked={item.value === selected?.value}
										onChange={e => {
											setSelected(item)
											onChange(item)
											setIsOpen(false)
										}}
									/>
								</Item>
							</ListItem>
						))}
					</List>
				</Container>
			</BottomSheet>
		</>
	)
}

const Container = styled.div`
	padding: 0.5em;
`
const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	background: transparent;
	margin-bottom: 0.5em;
`

const ButtonHeader = styled.div`
	font-size .7em;
	font-weight: 600;
	color: ${colors.dark};
`
const Button = styled.button`
	display: flex;
	background: transparent;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${colors.light};
	border-radius: var(--border-radius);
	padding: 0.5em;
`
const SheetHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const List = styled.ul`
	padding: 0;
`
const ListItem = styled.li`
	&:not(:last-child) {
		border-bottom: 1px solid ${lighten(0.15, colors.light)};
	}
`

const Item = styled.label`
	display: flex;
	padding: 0.5em;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		background: ${transparentize(0.9, colors.light)};
	}
`
const RequiredStar = styled.span`
	color: red
`

export default RadioList
