import { useMemo, useState } from 'react'
import { IconLayer, TextLayer } from '@deck.gl/layers/typed'
import { useGetHerdsQuery } from 'library/api/herd'
import { useNavigate } from 'react-router-dom'
import * as turf from '@turf/turf'
import { store } from 'store'
import { mapSlice } from 'store/map.slice'
export const useHerds = (props?: any) => {
	const { data: herds } = useGetHerdsQuery()
	const navigate = useNavigate()
	const [visible, setVisible] = useState<boolean>(false)	

	const features = useMemo(() => {
		if(!herds) return []
		return herds.map((herd: Herd) => (
			herd?.pasture?.geometry ? turf.centerOfMass(herd?.pasture?.geometry, {
				properties: {
					...herd
				},
			}) : null
		)).filter((herd: turf.Feature | null) => herd)
	}, [herds])

	const textLayer = new TextLayer({
		id: 'herd-text-layer',
		data: features,
		pointType: 'icon+text',
		visible: visible,
		autoHighlight: true,
		sizeScale: 1,
		opacity: 1,
		getSize: (d: any) => {
			// if (!highlightedHerd?.id === d.id) return 10
			return 7
			// return d.id === this.props.selected?.object?.id ? 10 : 6
		},
		getPixelOffset: (d: any) => {
			// return d.id === highlightedHerd?.id ? [0, -25] : [0, -15]
			return [0, -15]
			// if (!this.props.selected?.object) return [0, -15]
		},
		// wordBreak: 'break-word',
		getText: (d: any) => d.properties.name,
		maxWidth: 200,
		textAnchor: 'start',
		highlightColor: [255, 255, 255, 100],
		getLineColor: [255, 255, 255, 255],
		// getColor: [255, 255, 255, 255],
		background: false,
		getPosition: (d: any) => d.geometry.coordinates,
		getTooltip: (d: any) => d.properties.name,
		updateTriggers: {
			data: [features],
			// getText: [this.props.selected?.object, this.props.data, this.props.selectedFeatureIndexes, this.props.mode],
			// getSize: [this.props.selected?.object],
			// getPixelOffset: [this.props.selected?.object],
		},
	})
	const layer = new IconLayer({
		id: 'herd-icon-layer',
		data: features,
		visible: visible,
		pickable: true,
		pickingRadius: 25,
		autoHighlight: true,
		stroked: true,
		onHover(pickingInfo, event) {
			store.dispatch(mapSlice.actions.setCursor('pointer'))
			// setHighlighted(pickingInfo.object)
		},
		pointType: 'icon+text',
		onClick: (pickingInfo, event) => {
			navigate(`/herd/${pickingInfo.object.properties.id}`)
		},
		getIcon: (d: any) => {
			return {
				url: '/herd-bubble.png',
				width: 128,
				height: 128,
				anchorY: 128,
			}
		},
		sizeScale: 10,
		opacity: 1,
		highlightColor: [255, 255, 255, 0],
		getLineColor: [255, 255, 255, 0],
		getPosition: (d: any) => d.geometry.coordinates,
		getSize: (d: any) => 5,
		getColor: (d: any) => [57, 68, 188, 255],
		updateTriggers: {
			getPosition: [herds],
			getIcon: [herds],
		},
	})

	return { 
		layer: [layer, textLayer],
		visible,
		setVisible,
	}
} 
