import styled from 'styled-components/macro'
import { device } from 'styles'
import { transparentize } from 'polished'
import { Image } from 'components/Image'
import { useState, useEffect, useMemo } from 'react'
import { useColors } from 'hooks'

export const ProfileImageComponent = (props: { user: User, imageSize?: number, placeHolder?: string, backgroundColor?: string, primaryColor?: string }) => {
	const { user, imageSize = 0, placeHolder, backgroundColor, primaryColor, ...rest } = props
	const colors = useColors()

	return (
		<div {...rest}>
			<ProfileCardImage $backgroundColor={backgroundColor}>
				{user?.imageFile ?
					<Image alt={'profile'} src={`file/${user?.imageFile?.id}/${imageSize}`} />
					:
					<>
						<ProfileInitialContainer>
							<ProfileInitials $color={primaryColor} viewBox="0 0 32 18">
								<text x={'50%'} y={15}>{placeHolder}</text>
							</ProfileInitials>
						</ProfileInitialContainer>
					</>
				}
			</ProfileCardImage>
		</div>
	)
}

export const ProfileImage = styled(ProfileImageComponent) <{ user: User, imageSize?: number }>`
	position: relative;
	width: 100px;
	height: 100px;
`

const ProfileCardImage = styled.div<{ $backgroundColor?: string; }>`
    padding: 0;
		aspect-ratio: 1 / 1;
		height: 100%;
		width: 100%;
		height: 100%;
    border-radius: 100%;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    margin: auto;
    text-align: center;
		
    background-color: ${props => props.$backgroundColor ? transparentize(.9, props.$backgroundColor) : '#333333'};
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`

const ProfileInitials = styled.svg<{ $color?: string; }>`
	fill: ${props => props.$color};
	margin: auto;
	position: absolute;
	inset: 0;
	text {
		text-align: center;
		text-anchor: middle;
		font-weight: 600;
	}

`
const ProfileInitialContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	align-items: center;
	psoition: relative;
	aspect-ratio: 1 / 1;
`
