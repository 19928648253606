import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import Slider from 'react-slick'
import { UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest, signUpRequest } from 'auth/msal'
import { useAccount } from '@azure/msal-react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import CorralLogoLight from 'images/Primary Logo/Light/Corral_Primary Logo_light.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'

export const Welcome = () => {
	const { instance, inProgress } = useMsal()
	const isAuthenticated = useIsAuthenticated()
	const MsalAccount = useAccount()
	const location = useLocation()
	if(inProgress !== InteractionStatus.None ) return <LoadingOverlay><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>
	return (
		<Container>
			<BackgroundBanner>
				<BackgroundImage src={'/welcome-banner.svg'} alt={''} />
				<ImageGround />
			</BackgroundBanner>
			<LogoContainer>
				<Logo src={CorralLogoLight} alt={'Corral Technologies Logo'} />
				{/* <Name><span>Corral</span> <span>Technologies</span></Name> */}
			</LogoContainer>
			<Carousel>
				<Slider
					dots={true}
					infinite={true}
					speed={500}
					slidesToShow={1}
					slidesToScroll={1}
				>
					<CarouselItem>
						<h3>Track your cows</h3>
						<p>Be confident that your cows are where they are supposed to be at any time</p>
					</CarouselItem>
					<CarouselItem>
						<h3>Cross fence anywhere</h3>
						<p>Create new cross fences anywhere to optimize pasture utilization for any operation</p>
					</CarouselItem>
					<CarouselItem>
						<h3>Move remotely</h3>
						<p>Move herds with just a few button presses from anywhere in the world</p>
					</CarouselItem>
					<CarouselItem>
						<h3>Create Healthier pastures</h3>
						<p>Create grazing plans, track carrying capacities, and see pasture utilization in real-time</p>
					</CarouselItem>
				</Slider>
			</Carousel>
			<WelcomeSection>
				<CTA>
					{!MsalAccount && <SignUpButton onClick={(_) => {
						return instance.loginRedirect({
							...signUpRequest,
							redirectUri: '/sign-up/account',
							redirectStartPage: '/sign-up/account'
						})
					}}>
						Create Account
					</SignUpButton>}
					<LoginButton 
						onClick={_ => instance.loginRedirect({
							...loginRequest,
							redirectStartPage: location.pathname
						})}>
							Log In
					</LoginButton>
				</CTA>
			</WelcomeSection>
			<BottomLinks>
				<LinkButton to={'/privacy-policy'}>Privacy Policy</LinkButton> | <LinkButton to={'/terms-and-conditions'}>Terms and Conditions</LinkButton>
			</BottomLinks>
		</Container>
	)
}

export default Welcome;


const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.primary};
`

const LoginButton = styled.button`
	color: white;
	text-decoration: none;
	font-size: 1em;
	font-weight: 400;
	background: transparent;
	border: none;
	&:hover {
		opacity: .75;
		color: white;
	}
`

const Container = styled.div`
	width: 100vw;
	height: 100%;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr min-content;

`
const Carousel = styled.div`
	max-width: 400px;
	color: #ffffff;
	margin: auto;
	padding: 2em;
	.slick-dots li.slick-active button:before {
			color: #ffffff;
	}
	.slick-dots button:before {
			color: #ffffff;
	}
`

const CarouselItem = styled.div`
	text-align: center;
	p {
		font-size: 1.25em;
		margin-bottom: 0;
	}
`

const LogoContainer = styled.div`
    display: flex;
    // margin-top: 100px;
    // margin-bottom: 200px;
    justify-content: center;
    align-items: center;
    @media ${device.max.mobile} {
    }
`
const Logo = styled.img`
    margin: 2em;
    max-width: 400px;
    wdith: 100%;
`
const Name = styled.div`
    font-size: clamp(.5em, 10vw, 1.5em);
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
`

const ImageGround = styled.div`
	width: 100%;
	background: linear-gradient(180deg, rgba(255,255,255,.15) 0%, transparent 100%);
	mix-blend-mode: overlay;
	height: 100px;
`
const BackgroundBanner = styled.div`
    position: fixed;
    left: 0; 
    right: 0;
    inset: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
	background: ${colors.primary};
`
const BackgroundImage = styled.img`
	max-width: 400px;
	width: 30%;
	margin-right: 10%;
	opacity: .25;
	mix-blend-mode: overlay;
	@media ${device.max.mobile} {
		max-width: 40%;
		margin-right: 1em;
	}
`
const WelcomeSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1em;
	@media ${device.max.mobile} {
		margin-top: 0;
		margin-bottom: 0;
	}
`

const SignUpButton = styled.button`
	text-align: center;
	text-decoration: none;
	background: white;
	opacity: .85;
	text-transform: uppercase;
	color: black;
	mix-blend-mode: screen;
	font-size: 1em;
	font-weight: 400;
	padding: 1em 2em;
	border-radius: 5px;
`

const CTA = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
	justify-content: center;
	align-items: center;
`

const BottomLinks = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em;
	color: #ffffff;
	gap: .5em;
`

const LinkButton = styled(Link)`
	
	color: #ffffff;
	text-decoration: none;
	font-size: .8em;
`