import { useState, useCallback, useMemo } from 'react'
import { FeatureCollection } from '@turf/turf'
import * as turf from '@turf/turf'
import { ScatterplotLayer } from '@deck.gl/layers/typed'
import { PickingInfo } from '@deck.gl/core/typed'
import { RootState, store } from 'store'
import { useMap } from 'react-map-gl'
import { useCardBuffer } from 'hooks'
import { pastureRouteSlice } from './pastureRoute.slice'
import { useGetPastureRoutesQuery } from 'library/api/pastureRoute'
import {GeoJsonLayer} from '@deck.gl/layers/typed';
import { useParams } from 'react-router-dom'
import { TripsLayer } from '@deck.gl/geo-layers/typed'
import useAnimationFrame from 'hooks/useAnimationFrame'
import { useSelector } from 'react-redux'
import { hexToRGB } from 'utilities'
import { useGetPasturesQuery } from 'library/api/pasture'
import { lighten } from 'polished'
import { useGetAnimalsByHerdIdQuery } from 'library/api/animal'
import { Animal } from 'library/models/animal.model'
import { Pasture } from 'library/models/pasture.model'

export const usePastureRoute = () => {
	const { MapView } = useMap()
	const { bufferWidth } = useCardBuffer()
	const params = useParams()

	const [dragPanEnabled, setDragPanEnabled] = useState(true)
	const [visible, setVisible] = useState(true)
	const [bounds, setBounds] = useState<any>(null)
	const [guide, setGuide] = useState<any>(null)
	const [hoveredNode, setHoveredNode] = useState<number | null>(null)
	const [selectedNode, selectSelectedNode] = useState<number | null>(null)

	const { data: pastureRoutes } = useGetPastureRoutesQuery()
	const { data: pastures } = useGetPasturesQuery()

	const currentRoute: PastureRoute = useSelector((state: RootState) => state.pastureRouteSlice.route)
	const displayRoutes: PastureRoute[] = useSelector((state: RootState) => state.pastureRouteSlice.displayRoutes)
	const highlightedRoute: string | null = useSelector((state: RootState) => state.pastureRouteSlice.highlightedRoute)
	const selectedFromPasture: Pasture | null = useSelector((state: RootState) => state.pastureRouteSlice.selectedFromPasture)
	const herd = useSelector((state: RootState) => state.herdSlice.herd)
	const mode = useSelector((state: RootState) => state.pastureRouteSlice.mode)

	const { data: animals } = useGetAnimalsByHerdIdQuery({id: herd?.id , includeArchived: false }, { enabled: !herd?.new })

	const handleMapHover = (info: PickingInfo) => {
		if (mode !== 'EditRouteMode' && mode !== 'CreateRouteMode') {
			if (info.coordinate) {
				store.dispatch(pastureRouteSlice.actions.setGuide(info.coordinate))
			}
		}
	}

	const handleMapClick = async (info: PickingInfo) => {
		// For picking a destination pasture for a route
		if (mode === 'SelectPastureMode' && info?.object) {
			const { id, properties } = info?.object
			
			if (properties.type === 'pasture' && selectedFromPasture?.id !== id) {
				const val = pastures?.find((p) => p.id === id)
				if (val) store.dispatch(pastureRouteSlice.actions.setSelectedDestinationPasture(val))
				
			}
		}
	}

	const getBounds = useCallback(
		(data: FeatureCollection) => {
			if (data) {
				const geometries = data.features.map((feature: any) => turf.geometry(feature.geometry.type, feature.geometry.coordinates))
				const geometryCollection = turf.geometryCollection(geometries)
				const bboxPolygon = turf.bbox(geometryCollection)

				MapView?.fitBounds(
					[
						[bboxPolygon[0], bboxPolygon[1]],
						[bboxPolygon[2], bboxPolygon[3]],
					],
					{ padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } }
				)
			}
		},
		[MapView, bufferWidth]
	)

	const layerOnHover = useCallback((info: PickingInfo) => {
		if (mode !== 'EditRouteMode' && mode !== 'CreateRouteMode') return

		if (info?.object?.properties?.index !== undefined) setHoveredNode(info.object.properties.index)
		else setHoveredNode(null)
		
		if (!currentRoute) return
		
		store.dispatch(pastureRouteSlice.actions.setHighlightedRoute(info?.object?.id))

		const line = currentRoute?.geometry?.coordinates.length ? turf.lineString(currentRoute.geometry.coordinates) : null
		if(!info.coordinate || !line || !info?.object || info?.object?.geometry?.type === 'Point') {
			setGuide(null)
			return
		}
		const point = turf.point(info.coordinate)
		const pointInLine = turf.booleanPointOnLine(turf.point(info.coordinate), line, { epsilon: 0.0001})
		
		// If the cursor is not on the line, stop
		if(!pointInLine) return

		// If the cursor is on the line, find the nearest point of the line to the cursor
		const nearestPoint = turf.nearestPointOnLine(line, point)	
		
		// Should never happen, but if it does, stop
		if(!nearestPoint?.properties?.location) return

		const pointAlong = turf.along(line, nearestPoint?.properties?.location)
		setGuide(pointAlong)
	}, [currentRoute, mode])
	
	const data = useMemo(() => {		
		let animalLines: any = []

		if(herd && herd?.pastureRouteId === currentRoute.id && animals && currentRoute?.geometry?.coordinates && currentRoute?.geometry?.coordinates.length) {
			// draw paths from animals to the nearest point on the route
			animalLines = animals?.filter((animal: Animal) => animal.lastCoordinate && animal.lastCoordinate.length)
				.map((animal: any, index: number) => { 
					if(currentRoute?.geometry?.coordinates) {
						const point = turf.point(animal.lastCoordinate)
						const line = turf.lineString(currentRoute.geometry.coordinates)
						const nearestPoint = turf.nearestPointOnLine(line, point)	
						if(!nearestPoint?.properties?.location) return turf.lineString([animal.lastCoordinate, currentRoute?.geometry?.coordinates[0]])
						const pointAlong = turf.along(line, nearestPoint?.properties?.location)
						return turf.lineString([point.geometry.coordinates, pointAlong?.geometry.coordinates])
					}
				})
		}

		// add a 10 meter buffer around the route to show where the animals are allowed to be along the path
		// const buffer = turf.buffer(turf.lineString(currentRoute.geometry.coordinates), 10, {units: 'meters'})

		// Break up the route into segments so we can double them up and offset them to create a dashed path effect
		// const segments = turf.lineSegment(turf.lineString(currentRoute.geometry.coordinates)).features

		const currentRoutePoints = currentRoute?.geometry?.coordinates ? currentRoute?.geometry.coordinates.map((point: any, index: number) => turf.point(point, { index })) : []
			
		const displayRoutesPoints = displayRoutes.reduce((prev: any[], route) => {
			const points = route?.geometry?.coordinates ? route?.geometry.coordinates.map((point: any, index: number) => turf.point(point, { index, color: route?.style?.color })) : []
			return [
				...prev,
				...points
			]
		}, [])
		
		const features = [
			currentRoute,
			...currentRoutePoints,
			...displayRoutes,
			...displayRoutesPoints,

			// ...segments,
			// ...segments.flatMap((segment: any) => ([segment, {...segment, properties: { offset: 1 }}])),
			
			...animalLines.flatMap((line: any) => ([line, {...line, properties: { offset: 1 }}])),
			// buffer,

		]
		return features.filter(f => f)
	}, [herd, currentRoute, animals, displayRoutes])
	
	const guidePoint = useMemo(() => {
		if(!guide) return []
		return [guide]
	}, [guide])

	const guideLayer = new ScatterplotLayer({
		id: 'pasture-route-guide-layer',
		data: guidePoint,
		pickable: false,
		getFillColor: (d: any) => [255, 255, 255, 255],
		getLineColor: (d: any) => [128, 128, 128, 128],
		getLineWidth: (d: any) => 1,
		getPosition: (d: any) => d.geometry.coordinates,
		radiusUnits: 'pixels',
		radiusScale: 1,
		stroked: true,
		lineWidthUnits: 'pixels',
		// radiusUnits: 'meters',
		// radiusMaxPixels: 7,
		radiusMinPixels: 8,
		getRadius: (d: any) => 8,
		visible: guide !== null,
		onHover: (info: PickingInfo, event: any) => {
		}
	})
		
	const layer = useMemo(() => new GeoJsonLayer({
		id: 'pasture-route-layer',
		data: data,
		pointType: 'circle',
		// visible: edits?.geometry?.coordinates?.length,
		pickable: true,
		autoHighlight: false,
		stroked: false,
		filled: true,
		extruded: false,

		getFillColor: (d: any) => [255, 0, 0, 255],
		onClick: (info: PickingInfo, event: any) => {},

		_subLayerProps: {
			'points-circle': {
				getFillColor: (d: any) => {
					return hexToRGB(d?.properties?.color || '#ffffff', 255)
				},
				getLineColor: (d: any) => {
					return [128, 128, 128, 128]
				},
				getLineWidth: (d: any) => 1,
				stroked: true,
				lineWidthUnits: 'pixels',
				// lineWidthScale: 2,
				// lineWidthMaxPixels: 5,
				// lineWidthMinPixels: 10,
				radiusUnits: 'pixels',
				// radiusScale: 1,
				// radiusMaxPixels: 20,
				radiusMinPixels: 8,
				getRadius: (d: any) => {
					return hoveredNode === d?.__source?.object?.properties?.index ? 10 : 8
				},
				updateTriggers: {
					getFillColor: [hoveredNode, currentRoute],
					getRadius: [hoveredNode],
				},

			},
			'polygons-fill': {
				getFillColor: (d: any) => {
					// return [255, 255, 255, 128]
					return hexToRGB(currentRoute?.style?.color || '#ffffff', 128)
				},
				updateTriggers: {
					getFillColor: [currentRoute.style?.color, displayRoutes],
				}
					
			},
			'linestrings': {
				type: TripsLayer,
				// getDashArray: () => [3, 3],
				dashJustified: true,
				dashGapPickable: true,
				jointRounded: true,
				capRounded: true,
				rounded: true,
				widthUnits: 'meters',
				widthMinPixels: 10,
				// fadeTrail: true,
				// trailLength: 1,
				// currentTime: 1,

				// getPath: (d: any) => d.geometry.coordinates,
				// // getTimestamps: (d: any) => {
				// // 	const timestamp = d.geometry.coordinates.map((c: any, i: number) => i + (d?.__source?.object?.properties?.offset || 0))
				// // 	return timestamp
				// // },

				getColor: (d: any) => {
					let color = d.__source.object?.style?.color || '#ffffff'
					if (d.__source.object?.id !== highlightedRoute) {
						color = lighten(0.1, color)
						if (color === '#fff') color = '#dddddd'
					}
					return hexToRGB(color || '#ffffff', 180)
				},
				// getFillColor: (d: any) => {
				// 	return hexToRGB(d?.properties?.color || '#ffffff', 255)
				// },
				getWidth: (d: any) => 10,
				updateTriggers: {
					// getColor: [edits.style?.color],
					getColor: [currentRoute, highlightedRoute, displayRoutes],
				},
			}
		},

		onDragStart: (info: PickingInfo, event: any) => {
			if (mode !== 'EditRouteMode' && mode !== 'CreateRouteMode') return
			MapView?.getMap().dragPan.disable()
			setGuide(null)
			const line = currentRoute?.geometry?.coordinates.length ? turf.lineString(currentRoute.geometry.coordinates) : null
				
			if(info.coordinate && line) {
				const pointInLine = turf.booleanPointOnLine(turf.point(info.coordinate), line, {
					epsilon: 0.0001,
				})

				if(info.object.geometry.type === 'Point' && info.object.properties.index !== undefined) {
					if(info?.object?.properties?.index !== undefined) selectSelectedNode(info.object.properties.index)
					const point = turf.point(info.coordinate)
					const nearestPoint = turf.nearestPointOnLine(line, point)	
					// if(nearestPoint.properties.index !== undefined) store.dispatch(pastureRouteSlice.actions.setSelectedIndex(nearestPoint.properties.index))
				}

				if(pointInLine && info.object.geometry.type !== 'Point') {
					const point = turf.point(info.coordinate)
					const nearestPoint = turf.nearestPointOnLine(line, point)	
					if(nearestPoint.properties.location === undefined || nearestPoint.properties.index === undefined) return
					// store.dispatch(pastureRouteSlice.actions.setSelectedIndex(nearestPoint.properties.index + 1))
					selectSelectedNode(nearestPoint.properties.index + 1)
					const pointAlong = turf.along(line, nearestPoint.properties.location)
					const geoLine = currentRoute?.geometry?.coordinates ? JSON.parse(JSON.stringify(currentRoute.geometry.coordinates)) : []
					geoLine.splice(nearestPoint.properties.index + 1, 0, pointAlong.geometry.coordinates)
					store.dispatch(pastureRouteSlice.actions.setGeometry({type: 'LineString', coordinates: geoLine}))
				}
			}
		},

		onDrag: (info: PickingInfo, event: any) => {
			if (mode !== 'EditRouteMode' && mode !== 'CreateRouteMode') return
			if(selectedNode === null || !info.coordinate) return
			const geoLine =  currentRoute?.geometry?.coordinates ? JSON.parse(JSON.stringify(currentRoute.geometry.coordinates)) : []
			geoLine.splice(selectedNode, 1, info.coordinate)

			if(geoLine.length === currentRoute?.geometry?.coordinates.length)
				store.dispatch(pastureRouteSlice.actions.setGeometry({type: 'LineString', coordinates: geoLine}))
		},

		onDragEnd: (info: PickingInfo, event: any) => {
			if (mode !== 'EditRouteMode' && mode !== 'CreateRouteMode') return
			// store.dispatch(pastureRouteSlice.actions.setSelectedIndex(nearestPoint.properties.index))
			selectSelectedNode(null)
			MapView?.getMap().dragPan.enable()
		},

		onHover: (info: PickingInfo, event: any) => layerOnHover(info),
		// updateTriggers: {
		// 	getFillColor: [edits, hoveredNode],
		// 	getLineColor: [edits],
		// 	getLineWidth: [edits],
		// 	getOpacity: [edits],
		// 	highlightColor: [edits],
		// },
		updateTriggers: {
			getFillColor: [currentRoute, displayRoutes, hoveredNode],
			getLineColor: [currentRoute, displayRoutes],
			getLineWidth: [currentRoute, displayRoutes],
			getOpacity: [currentRoute, displayRoutes],
			highlightColor: [currentRoute, displayRoutes],
		},
	}), [data, hoveredNode, currentRoute, displayRoutes, highlightedRoute, mode, MapView, selectedNode, layerOnHover])
	
	return {
		visible,
		setVisible,
		bounds,
		setBounds,
		getBounds,
		dragPanEnabled,
		setDragPanEnabled,
		layer: [layer, guideLayer],
		handleMapHover,
		handleMapClick,
		data: pastureRoutes
	}
}