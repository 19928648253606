// import Spinner from 'react-bootstrap/Spinner';
import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import { colors } from 'styles';

// const Loading: React.FC = () => {
//   return (
//     <div className="h-100 d-flex align-items-center justify-content-center">
//       <Spinner animation="border" role="status">
//       </Spinner>
//     </div>
//   );
// };

interface Props {
  color?: string;
}
const Loading = ({color}: Props) => (
	<Container>
		<Wrapper $color={color}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</Wrapper>
	</Container>
)
// export default Loading;

const ellipsis1 = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`
const ellipsis2 = keyframes`    
  0% { transform: translate(0, 0); }
  100% { transform: translate(24px, 0); }
`
const ellipsis3 = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(0); }
`
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const Wrapper = styled.div<{ $color?: string }>`
	display: inline-block;
	position: relative;
	width: 80px;
  transform: scale(.75);
  height: 13px;
	& div {
		position: absolute;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: ${props => props?.$color || lighten(0.2, colors.dark)};
    	// background: #123456;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	& div:nth-child(1) {
		left: 8px;
		animation: ${ellipsis1} 0.6s infinite;
	}
	& div:nth-child(2) {
		left: 8px;
		animation: ${ellipsis2} 0.6s infinite;
	}
	& div:nth-child(3) {
		left: 32px;
		animation: ${ellipsis2} 0.6s infinite;
	}
	& div:nth-child(4) {
		left: 56px;
		animation: ${ellipsis3} 0.6s infinite;
    animation-delay: -0.1s;
	}
`



export default Loading;
