import styled from 'styled-components/macro'
import { LabelWithTooltip } from 'components/cards'
import { Link } from 'react-router-dom'

interface CardItemProps {
	label?: string
	tooltip?: string
	value?: any
	icon?: any
	link?: string
	className?: any
	children?: any
}

const cardContents = ({ className, label, tooltip, value, icon, link, children }: CardItemProps) => (
	<>
		{label ? <LabelWithTooltip text={label} tooltipText={tooltip} />
			: null}
		<ItemWrapper>
			{icon}
			{value !== '' && value !== null && value !== undefined ? <Value>{value}</Value> : null}
			{children}
		</ItemWrapper>
	</>
)

const cardItem = ({ className, label, tooltip, value, icon, link, children }: CardItemProps) => (
	<>
		{link ?
			<CardLink to={link}>
				<div className={className}>
					<CardItemWrapper>
						{cardContents({ className, label, tooltip, value, icon, link, children })}
					</CardItemWrapper>
				</div>
			</CardLink>
			:
			<>
				<div className={className}>
					{cardContents({ className, label, tooltip, value, icon, link, children })}
				</div>
			</>
		}
	</>
)

export const Label = styled.span`
	font-size: 0.6em;
	color: #666;
`
const Value = styled.div`
	position: relative;
	font-weight: 500;
	color: var(--dark);
	line-height: 0.9;
`
const CardItemWrapper = styled.div`
	padding-bottom: 0.5em;
	border-bottom: 1px solid #e6e8eb;
`
const CardLink = styled(Link)`
	text-decoration: none;
	color: inherit;
`
const ItemWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.25em;
`
export const CardItem = styled(cardItem)`
	flex-direction: column;
	width: 100%;
	display: flex;
	margin-bottom: 0.5em;
	/* flex-direction: column; */
`
