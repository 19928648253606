import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InvitationState {
	token: string
}

const initialState: InvitationState = {
	token: ''
}

export const invitationSlice = createSlice({
	name: 'invitationSlice',
	initialState,
	reducers: {
		reset: (state) => {
			state.token = ''
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload
		},
	},
})

export const { reset, setToken } = invitationSlice.actions

export default invitationSlice.reducer
