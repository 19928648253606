import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AnimalTrailState {
	id?: number | null
	visible: boolean
	startTime: string | null
	endTime: string | null
    waypoints: {waypoints: Waypoint[]},
	highlights: DeviceEventHighlight[],
    currentWaypoint: any,
    currentTime: number,
    totalDistance: number,
    playing: boolean
	timeStamp: Date | null
	trailSpeed: number
}

const initialState: AnimalTrailState = {
	id: null,
	visible: false,
	startTime: null,
	endTime: null,
	waypoints: {waypoints: []},
	highlights: [],
	currentWaypoint: null,
	currentTime: 0,
	playing: false,
	totalDistance: 0,
	timeStamp: null,
	trailSpeed: 1000
}

export const animalTrailSlice = createSlice({
	name: 'animalTrail',
	initialState,
	reducers: {
		reset: () => initialState,
		setVisible: (state, action: PayloadAction<boolean>) => {
			state.visible = action.payload
		},
		setTimeStamp: (state, action: PayloadAction<Date | null>) => {
			state.timeStamp = action.payload
		},
		setDateRange: (state, action: PayloadAction<{ startTime: string; endTime: string }>) => {
			state.startTime = action.payload.startTime
			state.endTime = action.payload.endTime
		},
		setWaypoints: (state, action: PayloadAction<{waypoints: Waypoint[]}>) => {
			const getTrailHighlights = (events: Waypoint[]) => {
				if (!events || !events.length) return []
				return events.reduce((acc: DeviceEventHighlight[], curr: Waypoint, index: number): DeviceEventHighlight[] => {
					const last = acc[acc.length - 1]
					// if the last event is the same type as the current event, we want to combine them into one segment
					if (last && last.type === curr.type) {
						return [...acc.slice(0, -1), { ...curr, type: curr.type, id: curr.id, segmentPercent: last.segmentPercent + curr.segmentPercentage }]
					}
					// if the last event is not the same type as the current event, we want to create a new segment
					return [...acc, { ...curr, type: curr.type, segmentPercent: curr.segmentPercentage, id: curr.id }]
				}, [])
			}
			state.highlights = getTrailHighlights(action.payload.waypoints)
			state.waypoints = action.payload
		},
		setCurrentWaypoint: (state, action: PayloadAction<any>) => {
			state.currentWaypoint = action.payload
		},
		setCurrentTime: (state, action: PayloadAction<number>) => {
			state.currentTime = action.payload
		},
		setId: (state, action: PayloadAction<number | null>) => {
			state.id = action.payload
		},
		setPlaying: (state, action: PayloadAction<boolean>) => {
			state.playing = action.payload
		},
		setTotalDistance: (state, action: PayloadAction<number>) => {
			state.totalDistance = action.payload
		},
		set: (state, action: PayloadAction<AnimalTrailState>) => {
			state.id = action?.payload?.id || state.id
			state.visible = action?.payload?.visible || state.visible
			state.startTime = action?.payload?.startTime || state.startTime
			state.endTime = action?.payload?.endTime || state.endTime
		},
	},
})

export const { actions, reducer } = animalTrailSlice
export const { set, reset, setVisible, setWaypoints, setCurrentWaypoint, setCurrentTime, setPlaying, setDateRange, setTotalDistance, setTimeStamp } = actions
export default reducer
