import { useState, useEffect, useMemo } from 'react'
import { device } from 'styles'
// used to offset the bounding box of the map and the mapbox attribution when the bottom sheet is open
export default function useCardBuffer() {
	const [bufferWidth, setBufferWidth] = useState(0)
	const buffer = useMemo(() => {
		return bufferWidth === 0 ? '0px': 'calc(var(--rsbs-max-w) + 10px)'
	}, [bufferWidth])
	const [isEnlarged, setIsEnlarged] = useState(false)

	// Sets the buffer width to the width of the bottom sheet if it is displaying
	useEffect(() => {
		// This observer is watching for changes to the node list on the main body where the bottom sheet is rendered
		const portalObserver = new MutationObserver(() => {
			const portal = document.querySelector('reach-portal')
			if(portal && window.innerWidth >= device.size.mobile) {
				const width = document.querySelector('[data-rsbs-overlay]')?.getBoundingClientRect().width
				setBufferWidth(width ? width + 10 : 0)
			} else{
				setBufferWidth(0)
			}
		});
		portalObserver.observe(document.body, {
			childList: true,
			subtree: false,
			attributes: false,
			characterData: false,
		});
		const handleResize = () => {
			if(window.innerWidth >= device.size.mobile) {
				const width = document.querySelector('[data-rsbs-overlay]')?.getBoundingClientRect().width
				setBufferWidth(width ? width + 10 : 0)
			} else {
				setBufferWidth(0)
			}
		}
		window.addEventListener('resize', handleResize)
		return () => {
			portalObserver.disconnect();
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	
	const enlarge = () => {
		setIsEnlarged(true)
		const rootStyle = document.querySelector(':root') as HTMLElement
		setTimeout(() => {
			if (rootStyle) rootStyle?.style?.setProperty('--rsbs-max-w', '100%')
		}, 0)
	}
	const shrink = () => {
		setIsEnlarged(false)
		const rootStyle = document.querySelector(':root') as HTMLElement
		setTimeout(() => {
			if (rootStyle) rootStyle?.style?.setProperty('--rsbs-max-w', '316px')
		}, 0)
	}
	return {
		buffer,
		// setBuffer,
		bufferWidth,
		setBufferWidth,
		enlarge,
		shrink,
		isEnlarged,
		setIsEnlarged,
	}
}
