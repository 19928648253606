import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TutorialKey = 'START_DRAWING_BOUNDARY' |
'FINISH_DRAWING_BOUNDARY' |
'ADD_STRUCTURE' |
'EDIT_STRUCTURE' |
'RESIZE_STRUCTURE' |
'MODIFY_BOUNDARY'

type Tutorials = {
	[key in TutorialKey]: string
}

const tutorials: Tutorials = {
	START_DRAWING_BOUNDARY: 'Add a starting point for your boundary on the map',
	FINISH_DRAWING_BOUNDARY: 'Select starting point to complete a boundary',
	ADD_STRUCTURE: 'Select a position on the map to place the structure',
	EDIT_STRUCTURE: 'Drag structure to reposition',
	RESIZE_STRUCTURE: 'Drag structure to reposition and resize safe zone by dragging the modify handle',
	MODIFY_BOUNDARY: 'Modify boundary by dragging existing points or add new points by dragging edges',
}


interface MapState {
	type: string | null
	mode: 'DrawPolygonMode' | 'ViewMode' | 'DrawLineStringMode' | 'DrawPointMode' | 'ModifyMode' | 'SplitPolygonMode'
	cursor:
		| 'auto'
		| 'default'
		| 'none'
		| 'context-menu'
		| 'help'
		| 'pointer'
		| 'progress'
		| 'wait'
		| 'cell'
		| 'crosshair'
		| 'text'
		| 'vertical-text'
		| 'alias'
		| 'copy'
		| 'move'
		| 'no-drop'
		| 'not-allowed'
		| 'grab'
		| 'grabbing'
		| 'all-scroll'
		| 'col-resize'
		| 'row-resize'
		| 'n-resize'
		| 'e-resize'
		| 's-resize'
		| 'w-resize'
		| 'ne-resize'
		| 'nw-resize'
		| 'se-resize'
		| 'sw-resize'
	tutorial: string,
}

const initialState: MapState = {
	type: null,
	mode: 'ViewMode',
	cursor: 'default',
	tutorial: '',
}

export const mapSlice = createSlice({
	name: 'mapSlice',
	initialState,
	reducers: {
		// reset: () => initialState,
		reset: (state) => {
			return initialState
		},
		setType: (state, action: PayloadAction<string | null>) => {
			state.type = action.payload
		},
		setMode: (state, action: PayloadAction<'DrawPolygonMode' | 'ViewMode' | 'DrawLineStringMode' | 'DrawPointMode' | 'ModifyMode' | 'SplitPolygonMode'>) => {
			state.mode = action.payload
		},
		setCursor: (state, action: PayloadAction<MapState['cursor']>) => {
			state.cursor = action.payload
		},
		setTutorial: (state, action: PayloadAction<TutorialKey>) => {
			state.tutorial = tutorials[action.payload]
		},
		clearTutorial: (state) => {
			state.tutorial = ''
		},
	},
})

export const { setType, setMode, reset, setTutorial, clearTutorial, setCursor } = mapSlice.actions

export default mapSlice.reducer
