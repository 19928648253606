import { capitalize } from 'lodash'
import { Animal } from 'library/models/animal.model'
import { lighten, transparentize } from 'polished'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetAnimalsQuery } from 'library/api/animal'
import styled from 'styled-components/macro'
import { colors } from 'styles'

export const ParentList = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { data: animals } = useGetAnimalsQuery(undefined, {
		select: (data) => data?.filter((animal: Animal) => !animal.sex || location?.state?.gender === animal?.sex?.toLowerCase())
	})
	const [selected, setSelected] = useState<Animal>(new Animal())

	return (
		<Container>
            Select {capitalize(location.state.gender) || 'Animal'}:
			<List>
				{animals?.map((animal: Animal) => (
					<ListItem key={animal.id}>
						<Item htmlFor={`animal_${animal.id}`}>
							{animal.tag}
							<input
								id={`animal_${animal.id}`}
								type={'radio'}
								value={animal.id}
								checked={animal.id === selected?.id}
								onChange={e => {
									setSelected(animal)
									navigate(location.state.from.pathname, {
										state: {
											replace: true,
											parent: {
												animal: animal,
												gender: location.state.gender,
											},
										},
									})
								}}
							/>
						</Item>
					</ListItem>
				))}
			</List>
		</Container>
	)
}

const Container = styled.div`
    padding: 0.5em;
`
const List = styled.ul`
    padding: 0;
`
const ListItem = styled.li`
    &:not(:last-child) {
        border-bottom: 1px solid ${lighten(0.15, colors.light)};
    }
`

const Item = styled.label`
    display: flex;
    padding: 0.5em;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
        background: ${transparentize(0.9, colors.light)};
    }
`
