import { useNavigate, useParams } from 'react-router-dom'
import { Icon, MDIcon } from 'components/icons'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import { useCardBuffer } from 'hooks'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { DetailsBody, DetailsHeader, DetailsItem } from 'components/details'
import { CardAction, CardActions } from 'components/cards'
import { CardDeleteAction } from 'components/cards/CardActions'
import moment from 'moment'
import { RadioList, Toggle } from 'components/inputs'
import { useEffect, useMemo, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Chip, ChipContainer, RoundCloseButton } from 'components/buttons'
import { useGetPasturesQuery } from 'library/api/pasture'
import { pastureRouteSlice } from '../pastureRoutes/pastureRoute.slice'
import ColorPicker from 'components/inputs/ColorPicker'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import SvgFromGeometry from 'components/SvgFromGeometry'
import { herdSlice } from './herd.slice'
import { useGetHerdQuery } from 'library/api/herd'
import { RootState, store } from 'store'
import { useMoveHerdToPastureMutation } from 'library/api/herd'
import { useDeleteHerdMutation, useToggleHerdMutation } from 'library/api/herd'
import { useGetAnimalsByHerdIdQuery } from 'library/api/animal'
import { useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import { useLazyGetPastureRoutesByPastureIdsQuery } from 'library/api/pastureRoute'

export default function HerdDetails() {
	const navigate = useNavigate()
	const { id } = useParams<QueryParams<Herd>>()
	const { MapView } = useMap()
	const [moveSectionOpen, setMoveSectionOpen] = useState(false)
	

	const card = useCardBuffer()
	const { mutate: toggleHerd, isPending: toggleIsLoading, isSuccess: toggleIsSuccess } = useToggleHerdMutation()
	const [toggledHerd, setToggledHerd] = useState<string | null>(null)

	const { data: herd, isLoading: getHerdIsLoading, refetch: refetchHerd, isRefetching: herdIsRefetching } = useGetHerdQuery({ id }, { enabled: !!id })
	const { data: herdAnimals, isLoading: getAnimalByHerdsLoading } = useGetAnimalsByHerdIdQuery({ id, includeArchived: false }, { enabled: !!id })

	const { data: exclusionZones } = useGetPastureFeaturesQuery(undefined, {
		select: res => ({...res, exclusionZones: res.filter((feature: Structure) => feature?.type === 'exclusion')})
	})

	const { mutate: getPastureRoute, data: pastureRoute } = useLazyGetPastureRoutesByPastureIdsQuery()

	const pastureRouteEdits = useSelector((state: RootState) => state.pastureRouteSlice.route)

	const { mutate: moveHerdToPasture } = useMoveHerdToPastureMutation()
	const { mutate: deleteHerd } = useDeleteHerdMutation()
	const { data: pastures } = useGetPasturesQuery()

	const [herdToggleState, setHerdToggleState] = useState(herd?.enabled || false)

	const handleCenterAction = async () => {
		if (herd?.pasture) {
			const [minLng, minLat, maxLng, maxLat] = turf.bbox(herd?.pasture?.geometry)
			MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		} else {
			if (herdAnimals) {
				//@ts-ignore
				const combinedAnimalFeatures = turf.featureCollection(herdAnimals.map((animal: Animal) => animal?.lastCoordinate.length ? turf.point(animal?.lastCoordinate) : null).filter((feature: any) => feature))
				if (combinedAnimalFeatures.features.length) {
					const currentHerdPoint = turf.centerOfMass(combinedAnimalFeatures)
					const [minLng, minLat, maxLng, maxLat] = turf.bbox(currentHerdPoint.geometry)
					MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
				}
			}
		}
	}
	const [selectedPasture, setSelectedPasture] = useState<{title: string, value: string} | null>(herd?.pasture ? {title: herd?.pasture?.name, value: herd?.pasture?.id} : null)

	const handleEdit = async () => {
		// await handleCenterAction()
		
		navigate(`/herd/${id}/edit`)
	}

	const handleMove = () => {
		if (!herd?.pastureId) {
			navigate(`/herd/${id}/assign`)	
			return
		}

		if (herd) {
			store.dispatch(herdSlice.actions.setHerd(herd))
		}
		navigate(`/herd/${id}/move`)
	}

	const handleDelete = async () => {
		if (id) deleteHerd({ herdId: id, deleted: true })
		navigate('/herds')
	}

	useEffect(() => { //todo: remove this once switched to react query
		setHerdToggleState(herd?.enabled || false)
	}, [herd?.enabled])

	useEffect(() => {
		if (herd && toggleIsSuccess) {
			refetchHerd()
		}
	}, [herd, refetchHerd, toggleIsSuccess])

	const assignmentDateJsx = useMemo(() => {
		const parsedDate = moment(herd?.pastureAssignmentDate)
		if (parsedDate.year() > 1) return parsedDate.format('M/D/YY')
		return 'Not Available'
	}, [herd?.pastureAssignmentDate])

	if (getHerdIsLoading) {
		return <Loading color={colors.dark} />
	}

	return (
		<>
			<Container>
				<CardActions>
					<CardAction onClick={async () => handleCenterAction()}>
						<MDIcon size={'sm'} style={{ transform: 'scale(.75)' }} name={'my_location'} />
						Locate
					</CardAction>
					<CardAction
						onClick={async () => handleMove()}
					>
						<MDIcon size={'sm'} style={{ transform: 'scale(.75)' }} name={'edit_location_alt'} />
						{herd?.pastureId ? 'Move' : 'Assign'}
					</CardAction>
				</CardActions>

				<DetailsHeader>Animals</DetailsHeader>
				{getAnimalByHerdsLoading ?
					<Loading color={colors.dark} />
					: 
					<>
						<ChipContainer>
							{herdAnimals?.map((animal) => {
								return (<Chip key={animal.id} to={`/animal/${animal.id}`}>{animal.tag}</Chip>)
							})}
						</ChipContainer>
					</>
				}


				<DetailsHeader>Details</DetailsHeader>
				<DetailsBody>
					<DetailsItem label={'Name'}>{herd?.name || ''}</DetailsItem>
					<DetailsItem label={'Animal Count'} tooltip={'The number of animals in this herd'}>{herd?.animalCount || 0}</DetailsItem>
					<DetailsItem label={'Pasture'} tooltip={'The herd\'s currently assigned pasture'}>{herd?.pasture?.name || 'Unassigned'}</DetailsItem>
					{herd?.pasture?.name && 
					<DetailsItem label={'Assignment Date'} tooltip={'Date the herd was assigned to it\'s current pasture'}>
						{assignmentDateJsx}
					</DetailsItem>}
					<DetailsItem label={'Collars'} tooltip={'Toggle the collars on or off for this herd'}>
						<JustifyBetween>
							<span>{herdToggleState ? 'Enabled' : 'Disabled'}</span>
							<Toggle
								loading={toggleIsLoading || herdIsRefetching}
								checked={herdToggleState}
								onChange={(e: any) => {
									if (herd && herd?.id) {
										setToggledHerd(herd.id)
										toggleHerd({ id: herd.id, enabled: !herd?.enabled })
									}
									refetchHerd()
								}}
							/>
								
						</JustifyBetween>
					</DetailsItem>
				</DetailsBody>
				<CardActions>
					<CardAction style={{ flexDirection: 'row', justifyContent: 'center' }} onClick={e => handleEdit()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.dark} name={'pencil'} />
						Edit
					</CardAction>
					<CardDeleteAction onClick={e => handleDelete()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.error} name={'trash'} />
						Delete
					</CardDeleteAction>
				</CardActions>
			</Container>
			<BottomSheet
				blocking={false}
				open={moveSectionOpen}
				defaultSnap={({maxHeight}) => maxHeight - 65}
				snapPoints={({maxHeight}) => [0, maxHeight - 65]}
			>
				<MoveSectionHeader>
					<span>Assign to Pasture</span>
					<RoundCloseButton onClick={() => setMoveSectionOpen(false)} />
				</MoveSectionHeader>
				<MoveSection>
					<SvgFromGeometry
						id={'PastureRoutePath'}
						width={'100%'}
						strokeLinecap='round'
						strokeLinejoin='round'
						height={100}
						geometry={pastureRouteEdits?.geometry}
						strokeWidth={'3%'}
						stroke={pastureRouteEdits?.style?.color || '#000000'}
					/>
					
					<RadioList 
						header={'Pasture'} 
						onChange={async (e: {title: string, value: string}) => {
							store.dispatch(pastureRouteSlice.actions.reset())
							const pasture = pastures?.find((pasture: Pasture) => pasture.id === e.value)
							setSelectedPasture(e)
							// setNewPasture(pasture || null)
							if (herd?.pasture?.id && pasture?.id) {
								const pastureRoute: any = await getPastureRoute({ fromId: herd?.pasture?.id, toId: e?.value })
								
								// No existing pasture route
								if (!pastureRoute || !pastureRoute?.length) {

									// Initially set start point to the herd's current pasture center
									let fromPasturePoint = turf.centerOfMass(herd?.pasture?.geometry)

									// Check for the herd animals' current center point, use that instead
									if (herdAnimals) {
										//@ts-ignore
										const combinedAnimalFeatures = turf.featureCollection(animals.map((animal: Animal) => animal?.lastCoordinate.length ? turf.point([animal?.lastCoordinate]) : null).filter((feature: any) => feature))
										if (combinedAnimalFeatures.features.length > 0) {
											fromPasturePoint = turf.centerOfMass(combinedAnimalFeatures)
										}
									}

									// Get destination pasture center point 
									const toPasturePoint = turf.centerOfMass(pasture?.geometry)

									// Build turf shortestPath options
									let options = {}
									if(exclusionZones?.length) {
										options = { 
											resolution: 500,
											units: 'meters',
											obstacles: turf.featureCollection(exclusionZones.filter((zone: any) => zone.geometry.type === 'Polygon').map(zone => ({ geometry: zone.geometry, type: 'Feature', properties: zone, id: zone.id}))) 
										}
									}

									// Get pasture route geometry, and save it to the store
									const line = turf.shortestPath(fromPasturePoint.geometry.coordinates, toPasturePoint.geometry.coordinates, options)
									store.dispatch(pastureRouteSlice.actions.setGeometry(line.geometry))

									// Move map to line
									const [n,e,s,w] = turf.bbox(line)
									MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth + 50, top: 50, bottom: 50, right: 50 } })
								} else {
									// View first pasture route saved for these two pastures
									store.dispatch(pastureRouteSlice.actions.set(pastureRoute[0]))
									const [n,e,s,w] = turf.bbox(pastureRoute[0].geometry)

									MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth + 50, top: 50, bottom: 50, right: 50 } })
								}
							}
						}} 
						defaultValue={selectedPasture ? selectedPasture : herd?.pasture ? {title: herd?.pasture?.name, value: herd?.pasture?.id} : undefined}
						items={pastures?.map((pasture) => ({ title: pasture.name, value: pasture.id }))} />
					<ColorPicker color={pastureRouteEdits?.style?.color} pickRandom={true} label='Route Color' onChange={(color) => store.dispatch(pastureRouteSlice.actions.setColor(color))} />
					<CardActions>
						<CardAction onClick={async () => {
							if (!herd?.pasture?.id) return
							navigate(`/routes/${herd.pasture.id}`)
						}}>
							Move
						</CardAction>
						<CardAction variant='danger' onClick={async () => {}}>Cancel</CardAction>
					</CardActions>
				</MoveSection>
			</BottomSheet>
			
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
`

const JustifyBetween = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const MoveSectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5em;
`
const MoveSection = styled.div`
	padding: 0.5em;
	display: flex;
	flex-direction: column;

`