import { useEffect, useMemo, useRef, useState } from 'react'
import { AddCard, Card, CardItem } from 'components/cards'
import styled from 'styled-components/macro'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPastureRoutesQuery, useDeletePastureRouteMutation } from 'library/api/pastureRoute'
import { useGetPastureRoutesByPastureIdsQuery } from 'library/api/pastureRoute'
import { colors, device } from 'styles'
import { darken, lighten, transparentize } from 'polished'
import { pastureRouteSlice } from './pastureRoute.slice'
import * as turf from '@turf/turf'
import { useSelector } from 'react-redux'
import LoadingSpinner from 'components/LoadingSpinner'
import { animated } from '@react-spring/web'
import Modal from 'react-bootstrap/Modal'
import { DeleteButton } from 'components/buttons'
import Icon from 'components/Icon'
import { herdSlice } from '../herds/herd.slice'
import { RootState, store } from 'store'
import { useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import { useGetPastureQuery, useGetPasturesQuery } from 'library/api/pasture'
import { useGetHerdsQuery, useMoveHerdToPastureMutation } from 'library/api/herd'

export const PastureRouteOverview = () => {
	const { id1: fromPastureId, id2: toPastureId } = useParams()
	const navigate = useNavigate()
	
	const panelRef = useRef<HTMLDivElement>(null)
	const dropdownRef = useRef<HTMLDivElement>(null)

	const { data: routes, isSuccess: getRoutesIsSuccess, isFetching } = useGetPastureRoutesByPastureIdsQuery({ fromId: fromPastureId, toId: toPastureId }, { enabled: !!(fromPastureId && toPastureId) })
	const { data: allRoutes } = useGetPastureRoutesQuery(undefined, { enabled: !(fromPastureId && toPastureId) })
	const { data: pastures } = useGetPasturesQuery()
	const { data: pastureFeatures} = useGetPastureFeaturesQuery()
	const { data: fromPasture} = useGetPastureQuery({ id: fromPastureId || '' }, { enabled: !fromPastureId })
	const { data: toPasture } = useGetPastureQuery({ id: toPastureId || '' }, { enabled: !toPastureId })
	const { data: herds } = useGetHerdsQuery()

	const { mutate: moveHerdToPasture, isPending: moveHerdLoading } = useMoveHerdToPastureMutation()
	const { mutate: deletePastureRoute, isPending: deleteLoading } = useDeletePastureRouteMutation()

	const herd = useSelector((state: RootState) => state.herdSlice.herd)

	const [routeData, setRouteData] = useState<PastureRoute[]>()
	const [waitForWorker, setWaitForWorker] = useState<boolean>(false)
	const [isMoving, setIsMoving] = useState<string>() // Tracking if our current herd is using any of our routes.
	const [showDropdown, setShowDropdown] = useState<string>()
	const [hoverMenu, setHoverMenu] = useState<boolean>(false)
	const [toDelete, setToDelete] = useState<string>('')

	// fetch our worker
	const worker = useMemo(
		() => new Worker(new URL('../../../webWorkers/routeGeneratorWorker.ts', import.meta.url)),
		[]
	)

	// Set up our routeLine worker
	useEffect(() => {
		if (routes?.length) return
		// dont generate if we have a route
		if (!fromPasture && !toPasture) return // dont generate route if we are viewing all routes
		store.dispatch(pastureRouteSlice.actions.setMode('CreateRouteMode'))
		if (window.Worker && worker) {
			worker.onmessage = ({ data: routeLine }) => {
				if (fromPastureId && toPastureId) {
					// set temporary route
					store.dispatch(pastureRouteSlice.actions.setRoute({
						name: '',
						style: {
							color: '#ffffff'
						},
						pastureIds: [fromPastureId, toPastureId],
						id: null,
						geometry: routeLine,
					}))
				}
			}
		}
		return () => {
			worker.terminate()
		}
	}, [worker, routes, getRoutesIsSuccess, fromPasture, toPasture, fromPastureId, toPastureId])

	// Generate a default route between our two pastures
	useEffect(() => {
		if (routes?.length) return // dont generate if we have a route
		const drawRoute = async () => {
			const fromPasturePoint = turf.centerOfMass(herd?.pasture?.geometry)
			const toPasturePoint = turf.centerOfMass(toPasture?.geometry)
			const exclusionZones = pastureFeatures?.filter((pf) => pf.type === 'exclusion')

			if (exclusionZones?.length && !waitForWorker) {
				setWaitForWorker(true)
				worker.postMessage({ exclusionZones, fromPasturePoint, toPasturePoint })
			}
		}
		if (toPasture && fromPasture) {
			drawRoute()
		}
	}, [pastureFeatures, toPasture, fromPasture, getRoutesIsSuccess, routes?.length, herd?.pasture?.geometry, waitForWorker, worker])

	// Put all routes between these pastures into the store for drawing
	useEffect(() => {
		store.dispatch(pastureRouteSlice.actions.setDisplayRoutes(routes?.length ? routes : []))
		setRouteData(routes?.length ? routes : []) // If we are viewing routes for two pastures

		if (!(fromPastureId && toPastureId) && allRoutes?.length) { // If we are viewing ALL routes
			setRouteData(allRoutes)
			store.dispatch(herdSlice.actions.clear())
		}
	}, [routes, allRoutes, fromPastureId, toPastureId])

	useEffect(() => {
		const currentHerd = herds?.find((h) => h.id === herd?.id)
		if (currentHerd?.pastureRouteId) setIsMoving(currentHerd?.pastureRouteId)

	}, [herd?.id, herd.pastureRouteId, herds])

	return (<>
		<Container>
			<h3>Select a route</h3>
			{!herd.new && <OverviewHeader>Current herd: {herd.name}</OverviewHeader>}

			<CardGrid>
				{!getRoutesIsSuccess && <LoadingSpinner/>}
				{routeData && routeData?.length ? <>{routeData ? routeData.map((item: PastureRoute, index: number) => {
					let link;

					if (item?.geometry?.coordinates.length !== 0) {
						const centerPoint = turf.center(item?.geometry as turf.LineString);

						const geojsonData = {
							type: 'Feature',
							geometry: item?.geometry,
							properties: {
								stroke: item?.style?.color,
								'stroke-width': '16',
							}
						}
						
						link = `https://api.mapbox.com/styles/v1/jackkeating/ckyklu3l405r214o8duoe7fe8/static/geojson(${encodeURIComponent(JSON.stringify(geojsonData))})/${centerPoint.geometry.coordinates[0]},${centerPoint.geometry.coordinates[1]},15/500x500?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
					}
					return (
						<Card
							key={item?.id}
							onMouseEnter={() => {if (item.id) store.dispatch(pastureRouteSlice.actions.setHighlightedRoute(item.id))}}
							onMouseLeave={() => {store.dispatch(pastureRouteSlice.actions.clearHighlightedRoute())}}
						>
							<CardWrapper>
								<CardItemWrapper>
									<CardItemTextWrapper>
										<CardItem
											label={'From'}
											value={pastures?.find((p) => p.id === item.pastureIds[0])?.name}
										/>
										<CardItem
											label={'To'}
											value={pastures?.find((p) => p.id === item.pastureIds[1])?.name}
										/>
										<CardItem
											label={'Name'}
											value={item.name}
										/>
									</CardItemTextWrapper>
									<div style={{ backgroundColor: '#eeeeee', borderRadius: '4px', padding: '8px', maxWidth: '50%' }}>
										<img
											src={link}
											style={{
												width: '100%',
											}}
										/>
									</div>
								</CardItemWrapper>
								<ButtonWrapper>
									
									{/* Move Button */}
									<ActionButton
										$disabled={(isMoving === item.id) || herd.new}
										disabled={(isMoving === item.id) || herd.new}
										onClick={async () => {
											if (toPastureId && item.id) {
												setIsMoving(item.id)
												moveHerdToPasture({herdId: herd?.id, pastureId: toPastureId, routeId: item.id})
											}
										}}
										style={{ flexGrow: 2 }}
									>
										{moveHerdLoading ? <LoadingSpinner/> : <>{item.id === isMoving ? 'Enroute' :  'Move'}</>}
									</ActionButton>
									
									{/* Hamburger Menu */}
									{!(isMoving === item.id || (item.id === herd.pastureRouteId)) && <ActionButton
										onClick={() => {
											if (item.id) setShowDropdown(showDropdown === item?.id ? undefined : item?.id)
											setHoverMenu(true)
										}}
										onMouseLeave={() => setHoverMenu(false)}
										style={{
											flexShrink: 2,
											backgroundColor: 'white',
											width: '8px',
											paddingRight: '16px',
										}}
									>
										<Icon color={'currentColor'} name={'dots-vertical-rounded'} />
									</ActionButton>}

									{/* Dropdown Menu */}
									{showDropdown === item?.id && hoverMenu && <>
										<Dropdown
											ref={dropdownRef}
											as={animated.div}
											onMouseEnter={() => setHoverMenu(true)}
											onMouseLeave={() => {setHoverMenu(false); setShowDropdown('')}}
											style={{right: '12px'}}
										>
											<Panel ref={panelRef}>
												<Item
													onClick={() => {navigate(`/routes/${item.id}`)}}
												>Edit</Item>
												
												{
													<Item
														$disabled={!!herds?.find((h) => h.pastureRouteId === item.id)}
														onClick={() => {
															if (herds?.find((h) => h.pastureRouteId === item.id)) return
															if (item.id) setToDelete(item.id)
														}}
													>
														{herds?.find((h) => h.pastureRouteId === item.id) ? 'In Use' : 'Delete'}
													</Item>
												}

											</Panel>
										</Dropdown>
									</>}

								</ButtonWrapper>
							</CardWrapper>
						</Card>
					)
				}) : <></>}</> : <></>}
				{fromPastureId && toPastureId && <AddCard text={'Add Route'} onClick={() => navigate(`/routes/${fromPastureId}/${toPastureId}/add`)} />}
			</CardGrid>
		</Container>
		<Modal
			show={toDelete !== ''}
			onHide={() => setToDelete('')}
			backdrop={'static'}
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>Delete Pasture Route?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ModalBodyWrapper>
					<span>Are you sure you would like to delete route "{routes?.find((r) => r.id === toDelete)?.name}"</span>
				</ModalBodyWrapper>
			</Modal.Body>
			<Modal.Footer style={{justifyContent: 'center'}}>
				<DeleteButton
					type={'button'}
					onClick={async () => {
						if (toDelete) {
							deletePastureRoute({ id: toDelete })
							setToDelete('') // clear target to delete
							setRouteData([]) // clear route data for new fetch
							store.dispatch(pastureRouteSlice.actions.reset())
						}
					}}
				>
					{deleteLoading ? <LoadingSpinner/> : <>Delete</>}
				</DeleteButton>
			</Modal.Footer>
		</Modal>
	</>)
}

const OverviewHeader = styled.span`
	font-weight: bold;
`

const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    @media (${device.max.tablet}) {
        flex-direction: column;
    }
`
const Item = styled.button<{ $disabled?: boolean }>`
	all: unset;
	white-space: nowrap;
	cursor: pointer;
	width: 100%;
	padding: 0.5em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5em;
	box-sizing: border-box;
	color: ${props => props.$disabled ? lighten(0.9, 'black') : 'black'};
	cursor: ${props => props.$disabled ? 'unset' : 'pointer'};
	
	&:hover {
		background: ${props => props.$disabled ? 'initial' : colors.background};
		// background: ${colors.background};
		border-radius: var(--border-radius);
	}
`
const Panel = styled.div`
	padding: 0.5em;
	background: #ffffff;
	border-radius: var(--border-radius);
	box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	// border: 1px solid ${darken(0.1, colors.background)};
	// /* width: 100%; */
	// background-color: ${colors.background}; 
`
const Dropdown = styled.div`
	position: absolute;
	right: 0;
	// top: calc(100% + 1em);
	z-index: 1;
	@media ${device.min.mobile} {
		// width: 100%;
		box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
	@media ${device.max.mobile} {
		// left: 0.5em;
		// right: 0.5em;
		// top: calc(env(safe-area-inset-top, 0) + 0.5em);
		// padding-top: 55px;
	}
`
const Container = styled.div`
	padding-right: 10px;
	height: 100%;
	min-width: 300px;
	color: var(--dark);
	overflow: visible;
	position: relative;
	margin: 0.5em;
	@media ${device.max.mobile} {
		padding-bottom: calc(55px + var(--sab));
	}
	/* min-width: 300px; */
`
const CardGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	@media ${device.max.mobile} {
		grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
	}
	grid-template-rows: auto;
	gap: 0.5em;
	margin-top: 20px;
	margin-bottom: 60px;
`
const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	// padding-right: 12px;
`
const CardItemWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	
`
const CardItemTextWrapper = styled.div`
	// font-size: 0.8em;
	padding-right: 8px;
`
const ButtonWrapper = styled.div`
	border-top: 1px solid ${lighten(0.175, colors.light)};
	padding-top: 0.5em;
	display: flex;
	gap: 0.5em;
	justify-content: center;
`
const ActionButton = styled.button<{ $disabled?: boolean }>`
	all: unset;
	cursor: ${props => props.$disabled ? 'unset' : 'pointer'}
	color: var(--dark);
	font-weight: bold;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 0;
	font-size: 0.8em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background-color: ${props => props.$disabled ? transparentize(.9, colors.background) : darken(0, colors.background)};
    color: ${props => props.$disabled ? transparentize(.4, colors.dark) : colors.dark};

	align-items: center;
	justify-self: end;
`

export default PastureRouteOverview
