import { useEffect, useState } from 'react'
import { Input, Select } from 'components/inputs'
import { CardAction, CardActions } from 'components/cards'
import { Feature } from '@turf/turf'
import Loading from 'components/Loading'
import { useGetPastureFeatureQuery, useUpdatePastureFeatureMutation, useCreatePastureFeatureMutation, useDeletePastureFeatureMutation } from 'library/api/pastureFeature'
import styled from 'styled-components/macro'
// import * as turf from '@turf/turf'
import { colors } from 'styles'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState, store } from 'store'
import { mapSlice } from 'store/map.slice'
import { structureSlice, StructureType } from './structure.slice'
import LoadingSpinner from 'components/LoadingSpinner'
import { useSelector } from 'react-redux'
import { Structure } from 'library/models'
import * as uuid from 'uuid'

export const StructureForm = () => {
	const navigate = useNavigate()
	const { id, mode } = useParams<QueryParams<{ id: string, mode: 'edit' | null }>>()
	const { data: structure, isPending, isSuccess, isPaused } = useGetPastureFeatureQuery({ id }, { enabled: !!id })
	const { mutate: updatePastureFeature } = useUpdatePastureFeatureMutation()
	const { mutate: createPastureFeature } = useCreatePastureFeatureMutation()
	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const edits = useSelector((state: RootState) => state.structureSlice.feature)

	useEffect(() => {
		if (!isSuccess) return

		store.dispatch(mapSlice.actions.setMode('ModifyMode'))
		// TODO: resize and edit are temp disabled
		// if (structure?.safeZone) {
		// 	store.dispatch(mapSlice.actions.setTutorial('RESIZE_STRUCTURE'))
		// } else {
		// 	store.dispatch(mapSlice.actions.setTutorial('EDIT_STRUCTURE'))
		// }
		store.dispatch(structureSlice.actions.set(structure))
	}, [structure, isSuccess])

	if (!edits) return null
	return (
		<Form
			onSubmit={e => {
				e.preventDefault()
				if (mode === 'edit') {
					updatePastureFeature(edits)
				} else {
					const toCreate: Structure = new Structure({
						...edits,
						id: uuid.v4().toString()
					})
					createPastureFeature(toCreate)
				}
				store.dispatch(mapSlice.actions.setMode('ViewMode'))
				setTimeout(() => navigate('/features'), 300)
			}}>
			{isLoading ? (
				<LoadingWrapper>
					<Loading color={colors.dark} />
				</LoadingWrapper>
			) : (
				<>
					<Input
						label={'Name'}
						required
						maxLength={50}
						value={edits?.name || ''}
						onChange={(e: any) => {
							store.dispatch(structureSlice.actions.setName(e.target.value))
						}}
					/>
					<Select
						label={'Type'}
						value={edits?.type || 'waterTank'}
						onChange={e => {
							const value = e.target.value as StructureType
							store.dispatch(structureSlice.actions.setType(value))
						}}>
						<option value={'waterTank'}>Water Tank</option>
						<option value={'gate'}>Gate</option>
						<option value={'saltAndMineralDeposit'}>Salt and Mineral Deposit</option>
					</Select>
					<Select
						label={'Safe Zone'}
						value={String(edits?.safeZone)}
						onChange={e => {
							store.dispatch(structureSlice.actions.setSafeZone(e.target.value === 'true'))
							store.dispatch(structureSlice.actions.setSafeZoneDistance(10))
						}}>
						<option value={'true'}>Enabled</option>
						<option value={'false'}>Disabled</option>
					</Select>
					{edits?.safeZone ? (
						<Input
							label={'Safe Zone Distance'}
							defaultValue={edits?.new ? 20 : (Math.round(edits?.safeZoneDistance * 100) / 100)}
							// min={10}
							required
							type={'number'}
							step={'0.1'}
							onChange={(e: any) => {
								store.dispatch(structureSlice.actions.setSafeZoneDistance(Number(e.target.value)))
							}}
						/>
					) : null}

					{!edits.geometry ? (
						<CardActions>
							<CardAction
								type={'button'}
								onClick={() => {
									store.dispatch(mapSlice.actions.setMode('DrawPointMode'))
									store.dispatch(mapSlice.actions.setCursor('crosshair'))
									store.dispatch(mapSlice.actions.setTutorial('ADD_STRUCTURE'))
								}}>
								Add Structure To Map
							</CardAction>
						</CardActions>
					) : (
						<CardActions>
							<CardAction type={'submit'} disabled={isSubmitting}>
								{isSubmitting ? <LoadingSpinner/> : 'Save'}
							</CardAction>
							<CardAction
								type={'button'}
								variant={isSubmitting ? 'dark' : 'danger'}
								onClick={() => {
									store.dispatch(structureSlice.actions.clear())
									store.dispatch(mapSlice.actions.setMode('ViewMode'))
									navigate(-1)
								}}
								disabled={isSubmitting}
							>
								Cancel
							</CardAction>
						</CardActions>
					)}
				</>
			)}
		</Form>
	)
}

const Form = styled.form`
	padding: 1em;
`
const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
