import { useEffect } from 'react'
import MapPage from './Map'
import styled from 'styled-components/macro'
import { colors, device } from 'styles'
// import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BottomSheetOutlet } from 'components/BottomSheetOutlet'
import { routeSlice } from 'store/route.slice'
import { store } from 'store'
import { useGetAnimalByDeviceIdQuery } from 'library/api/animal'
import LoadingSpinner from 'components/LoadingSpinner'
import { useGetDeviceByLocalIdQuery } from 'library/api/device'
const HomePage = () => {
	const location = useLocation()
	document.title = 'Corral - Home'
	const { type, id } = useParams<QueryParams<{ type?: string, id?: string }>>()
	const navigate = useNavigate()
	// todo: need to update the query to be Partial
	const { data: device, isLoading, isFetching } = useGetDeviceByLocalIdQuery({ id: id }, { 
		enabled: !!id && type === 'devices' 
	})
	useEffect(() => {
		if(isLoading || isFetching || type !== 'devices' || !id) return
		if(device && device.animalId) {
			navigate(`/animal/${device.animalId}`, {
				replace: true
			})
		} else {
			navigate(`/animal/add?deviceId=${id}`, {
				replace: true
			})
		}
	}, [type, isLoading, id, device, navigate, isFetching])

	if(type === 'devices' && id && (isLoading || isFetching) ) return <LoadingOverlay><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>
	return (
		<Container>
			<BottomSheetOutlet />
			<MapPanelWrapper>
				<MapPage />
			</MapPanelWrapper>
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 1fr;
	height: calc(100vh - 55px);
	grid-template-areas:
		'panels map'
		'panels map';
	overflow: hidden;
	@media ${device.max.mobile} {
		height: 100%;
		grid-template-rows: 0 auto 0;
		grid-template-areas:
			'. .'
			'map map'
			'panels panels';
		margin: auto;
	}
`

const MapPanelWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	background: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	border-radius: 0;
	grid-area: map;
`

const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.primary};
`
export default HomePage
