import styled from 'styled-components/macro'
import { ProfileImage } from 'components/ProfileImage'
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRemoveUserFromRanchMutation, useGetUserQuery, useGetUserByIdQuery, useAddUserToRanchMutation, useUpdateUserMutation, useAddUserImageMutation, useGetUsersByRanchIdQuery } from 'library/api/user'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import LoadingSpinner from 'components/LoadingSpinner'
import { SubmitButton, DeleteButton, ChipContainer, Chip, CancelButton } from 'components/buttons'
import Modal from 'react-bootstrap/Modal'
import { Input, Select } from 'components/inputs'
import { Container } from 'components/base'
import { Icon } from 'components/icons'
import { formatPhoneNumber } from 'utilities'
import { AvatarInput } from 'components/inputs/AvatarInput'
import { Any } from '@react-spring/web'
import * as uuid from 'uuid'
import { User } from 'library/models/user.model'

export const TeamUser = () => {
	const { id } = useParams<QueryParams<User>>()
	const navigate = useNavigate()
	const { data: currentUser } = useGetUserQuery()
	// we need to use this endpoint to get full ranchrole data for the user
	const { data: userData, isLoading: userIsLoading } = useGetUsersByRanchIdQuery({ id: currentUser?.currentRanch?.id }, {
		enabled: !!currentUser?.currentRanch?.id,
		select: (data) => {
			return data.filter((u) => u.id === id)
		}
	})
	const { mutate: removeUserFromRanch, isPending: deleteUserLoading } = useRemoveUserFromRanchMutation()
	const { mutate: addUserToRanch } = useAddUserToRanchMutation()
	const { mutate: updateUser } = useUpdateUserMutation()
	const { mutate: addUserImage } = useAddUserImageMutation()
	
	const [updatedRoles, setUpdatedRoles] = useState<RanchRole[]>([])
	const [editRoleMode, setEditRoleMode] = useState(false)
	const [editUserDetails, setEditUserDetails] = useState(false)
	const [toDelete, setToDelete] = useState<string>('')
	const [user, setUser] = useState<User>()

	useEffect(() => {
		if (userData && userData.length > 0) {
			setUser(userData[0])
		}
	}, [userData])

	const updateUserRoles = () => {
		for (const role of updatedRoles) {
			if (!user?.id) break;
			addUserToRanch({
				userId: user?.id,
				ranchId: role.ranchId,
				role: role.role
			})
		}
	}

	const [userDetails, setUserDetails] = useState<any>({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		ranchRoles: [],
		loaded: false
	})

	useEffect(() => {
		setUserDetails({
			firstName: user?.firstName,
			lastName: user?.lastName,
			phone: user?.phone,
			email: user?.email,
			ranchRoles: user?.ranchRoles,
			loaded: true
		})
	}, [user?.email, user?.firstName, user?.lastName, user?.phone, user?.ranchRoles])

	const canEditUser = () => currentUser?.currentRole === 'SysAdmin' || currentUser?.id === user?.id
	const canEditRole = () => user?.id !== currentUser?.id && currentUser?.currentRole === 'SysAdmin' || (currentUser?.currentRole === 'Owner' || currentUser?.accountOwner)

	if (!user || userIsLoading) return <LoadingSpinner />
	
	return (<>
		<Container style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
			<ProfileCard>
				<AvatarInput
					user={user}
					imageSize={120}
					image={user.imageFile ? user.imageFile : null}
					onChange={async ({ fileName, contentType, data}) => {
						if (user?.id) {
							addUserImage({
								id: user?.id,
								image: {
									id: uuid.v4(),
									fileName,
									contentType,
									data
								}
							})
						}
					}}
				/>
				<UserInfo>
					<UserInfoName>{user?.name}</UserInfoName>

					{user?.ranchRoles.filter((role: any) => role.role === 'Manager').length ?
						<UserInfoRole>
							<ChipContainer>Manager:
								{user.ranchRoles.filter((role: any) => role.role === 'Manager').map((role: any) => {
									return <Chip key={role.ranchId} to={`/account/ranch/${role.ranchId}`}>{role.ranchName}</Chip>
								})}
							</ChipContainer>
						</UserInfoRole>
						:
						null
					}
					{user?.ranchRoles.filter((role: any) => role.role === 'Hand').length ?
						<UserInfoRole>
							<ChipContainer>Hand:
								{user.ranchRoles.filter((role: any) => role.role === 'Hand').map((role: any) => {
									return <Chip key={role.ranchId} to={`/account/ranch/${role.ranchId}`}>{role.ranchName}</Chip>
								})}
							</ChipContainer>
						</UserInfoRole>
						:
						null
					}
				</UserInfo>
			</ProfileCard>
			<Card>
				<form onSubmit={async (event: SyntheticEvent) => {
					event.preventDefault()
					setEditUserDetails(false)
					updateUser({
						...user,
						name: `${userDetails?.firstName || user?.firstName} ${userDetails?.lastName || user?.lastName}`,
						phone: userDetails?.phone ? userDetails?.phone : (user?.phone ? user?.phone : ''),
						firstName: userDetails?.firstName ? userDetails?.firstName : user?.firstName,
						lastName: userDetails?.lastName ? userDetails?.lastName : user?.lastName,
						email: userDetails?.email ? userDetails?.email : user?.email,
					})
					event.preventDefault()

				}}>
					<CardHeader>
						<h3>Personal Info</h3>
						{canEditUser() ?
							<ButtonContainer>

								{editUserDetails ?
									<>
										<EditButton type={'submit'} >
											Done
											<Icon name={'check'} />
										</EditButton>
										<EditButton type={'button'} onClick={e => {
											setEditUserDetails(false)
											e.preventDefault()
										}}>
											Cancel
											<Icon name={'block'} />
										</EditButton>
									</>
									: <EditButton type={'button'}
										onClick={e => {
											setEditUserDetails(true)
											e.preventDefault()
										}}>
										Edit
										<Icon name={'edit-alt'} />
									</EditButton>
								}

							</ButtonContainer>
							: null}
					</CardHeader>
					<Grid>
						<Input
							disabled={!editUserDetails}
							label={'First Name'}
							maxLength={50}
							value={userDetails?.firstName || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setUserDetails({ ...userDetails, firstName: e.target.value })
								e.preventDefault()
							}} />
						<Input
							disabled={!editUserDetails}
							label={'Last Name'}
							maxLength={50}
							value={userDetails?.lastName || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setUserDetails({ ...userDetails, lastName: e.target.value })
								e.preventDefault()
							}} />
						<Input
							disabled={!editUserDetails}
							label={'Email'}
							maxLength={128}
							value={userDetails?.email || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setUserDetails({ ...userDetails, email: e.target.value })
								e.preventDefault()
							}}
						/>
						<Input
							disabled={!editUserDetails}
							label={'Phone'}
							type={'phone'}
							autoComplete={'phone'}
							maxLength={50}
							value={userDetails?.phone || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setUserDetails({ ...userDetails, phone: formatPhoneNumber(e.target.value) })
								e.preventDefault()
							}}
						/>
					</Grid>

				</form>
			</Card>


			{!(currentUser?.accountOwner && currentUser?.id === user.id) &&
				<Card>
					<form onSubmit={async (event: SyntheticEvent) => {
						event.preventDefault()
						setEditRoleMode(false)
					}}>
						<CardHeader>
							<h3>Roles</h3>
							<ButtonContainer>
								{editRoleMode ?
									<>
										<EditButton
											type={'submit'}
											onClick={e => {
												setEditRoleMode(false)
												updateUserRoles()
											}}
										>
											Done
											<Icon name={'check'} />
										</EditButton>
										<EditButton
											type={'button'}
											onClick={e => {
												setEditRoleMode(false)
												setUpdatedRoles([])
												e.preventDefault()
											}}
										>
											Cancel
											<Icon name={'block'} />
										</EditButton>
									</>
									: <EditButton type={'button'}
										onClick={e => {
											setEditRoleMode(true)
											e.preventDefault()
										}}>
										Edit
										<Icon name={'edit-alt'} />
									</EditButton>
								}

							</ButtonContainer>
						</CardHeader>

						{user?.ranchRoles && <>{user.ranchRoles.filter((role: any) => role.role !== 'Owner').length ?
							<>
								{user.ranchRoles.filter((role: any) => role.role !== 'Owner').map((role: any) =>
								{
									return <Select
										disabled={!editRoleMode}
										key={role.ranchId}
										name={role.ranchName}
										label={role.ranchName}
										value={
											updatedRoles.length ?
												updatedRoles.find(r => r.userId === role.userId)?.role
												:
												role.role
										}
										onChange={e => {
											e.preventDefault()
											if (role.role === e.target.value) {
												setUpdatedRoles(updatedRoles.filter(r => r.userId !== role.userId))
											} else {
												setUpdatedRoles([...updatedRoles, { ...role, role: e.target.value as RanchRoleType }])
											}
										}}>
										<option key={'Hand'} value='Hand'>
											Hand
										</option>
										<option key={'Manager'} value='Manager'>
											Manager
										</option>
									</Select>
								}
								)}
							</>
							:
							null
						}</>}
					</form>
				</Card>
			}
			{canEditRole() && <ButtonContainer>
				<DeleteButton
					type={'button'}
					disabled={toDelete !== ''}
					onClick={async () => {
						setToDelete(currentUser?.id || '')
					}}
				>
					Remove
				</DeleteButton>
			</ButtonContainer>
			}
		</Container>
		<Modal
			show={toDelete !== ''}
			onHide={() => setToDelete('')}
			backdrop={'static'}
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Confirm</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span>Remove <span style={{ fontWeight: 'bold' }}>{user?.name}</span> from ranch <span style={{ fontWeight: 'bold' }}>{currentUser?.currentRanch?.name}</span>?</span>
			</Modal.Body>
			<Modal.Footer>
				<ButtonContainer style={{ flexDirection: 'row', justifyContent: 'right' }}>
					<DeleteButton
						type={'button'}
						onClick={async () => {
							if (user && user.id && currentUser?.currentRanch?.id) {
								removeUserFromRanch({ userId: user.id, ranchId: currentUser?.currentRanch?.id })
								navigate('/account/team')
							}
						}}
					>
						{deleteUserLoading ? <LoadingSpinner /> : 'Yes'}
					</DeleteButton>
					<CancelButton
						type={'button'}
						onClick={() => {
							setToDelete('')
						}}
					>
						Cancel
					</CancelButton>
				</ButtonContainer>
			</Modal.Footer>
		</Modal>
	</>)
}

// const ModalHeader = styled.div``
const ModalTitle = styled.h3`
    margin: 0;
`
const ModalBody = styled.div`
    padding-bottom: 1em;
    padding-top: 1em;
`
const ModalFooter = styled.div``

const ProfileCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const RoleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #bbbbbb;
    flex-grow: 1;
    
`
const RoleRowRanch = styled.span`
    margin-top: auto;
    margin-bottom: auto;
    padding-top: .25em;
`
const Grid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    @media (${device.max.laptop}) {
        grid-template-columns: 1fr;
    }
`
const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    @media (${device.max.mobileM}) {
        flex-direction: column;
    }
`
const Card = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1.5em;
`
const CardHeader = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const UserInfo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
`
const UserInfoName = styled.div`
    font-weight: 600;
    font-size: 1.15em;
    color: ${colors.dark};
`
const UserInfoRole = styled.div`
    color: ${transparentize(.3, colors.dark)};
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: flex-end;
    justify-content: right;
    height: fit-content;
    // width: fit-content;
    gap: 0.4rem;
    button {
        margin: 0.4rem 0rem;
        min-width: 96px;
        width: fit-content;
    }
`
const EditButton = styled.button`
    padding: .5em;
    border-radius: 999999px;
    color: red;
    color: ${transparentize(.2, colors.dark)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25em;
    border: 1px solid ${transparentize(.9, colors.dark)};
    background: transparent;
`