import styled from 'styled-components'
import { useGetPasturesQuery } from 'library/api/pasture'
import { RadioList } from 'components/inputs'
import { useAssignHerdToPastureMutation, useGetHerdQuery } from 'library/api/herd'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { CardAction, CardActions } from 'components/cards'
import { routeSlice } from 'store/route.slice'
import { RootState, store } from 'store'
import { useSelector } from 'react-redux'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import LoadingSpinner from 'components/LoadingSpinner'
import { boundarySlice } from '../boundaries/boundary.slice'
import { useCardBuffer } from 'hooks'

export const AssignHerdForm = () => {
	const { id } = useParams<QueryParams<Herd>>()
	const navigate = useNavigate()
	const { MapView } = useMap()
	const card = useCardBuffer()


	const { mutate: assignHerdToPasture, isPending: assignIsLoading, isSuccess: assignIsSuccess } = useAssignHerdToPastureMutation()

	const { data: herd } = useGetHerdQuery({ id }, { enabled: !!id })

	const { data: pastures } = useGetPasturesQuery()
	
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)
	const selectedPastureFeature = useSelector((state: RootState) => state.boundarySlice.feature)
	
	const [selectedPasture, setSelectedPasture] = useState<{title: string, value: string}>()
	
	useEffect(() => {
		if (!selectedPasture || selectedPasture?.value === '0') return
		const target = pastures?.find((p) => p.id === selectedPasture.value)
		if (!target) return
		const [n,e,s,w] = turf.bbox(target?.geometry)
		MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
	}, [MapView, card.bufferWidth, pastures, selectedPasture])

	useEffect(() => {
		store.dispatch(boundarySlice.actions.setMode('SelectFeatureMode'))
	}, [])

	useEffect(() => {
		if (!selectedPastureFeature) return
		setSelectedPasture({ title: selectedPastureFeature.properties.name, value: String(selectedPastureFeature.id) })
		isMobile && store.dispatch(routeSlice.actions.setSnapToIndex(1))
	}, [isMobile, selectedPastureFeature])

	useEffect(() => {
		if (assignIsSuccess) navigate(`/herd/${id}`)
	}, [assignIsSuccess, id, navigate])

	return (
		<>
			{herd && !herd.pastureId ? <Container>
				<h3>Assign {herd?.name}</h3> 
				<RadioList
					header={'Pasture'}
					items={
						pastures?.map((pasture) => ({ title: pasture.name, value: pasture.id }))
					}
					onChange={async (e: {title: string, value: string}) => {
						setSelectedPasture({title: e.title, value: e.value});
					}}
					defaultValue={selectedPasture}
					onClick={isMobile ? () => {
						store.dispatch(routeSlice.actions.setSnapToIndex(0))
					} : undefined}
				/>
				<CardActions>
					<CardAction
						type={'submit'}
						disabled={ !selectedPasture }
						onClick={async () => {
							if (id && selectedPasture) {
								assignHerdToPasture({ herdId: id, pastureId: selectedPasture.value })
								navigate(`/herd/${id}`)
							}
						}}
					>
						{assignIsLoading ? <LoadingSpinner /> : 'Assign'}
					</CardAction>
					<CardAction
						type={'button'}
						variant={'dark'}
						disabled={assignIsLoading}
						onClick={() => navigate(-1)}
					>
						Cancel
					</CardAction>
				</CardActions>
			</Container> : <span style={{ color: 'red', padding: '10px 0px' }}>This herd is already assigned to a pasture</span>}
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
`
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Label = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1em;
    color: rgba(255,255,255,.75);
    padding: .75em 1em;
    pointer-events: none;
    transition: all .25s ease-in-out;
    ${InputWrapper}:focus-within & {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }

`
