import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from 'react-router-dom'
import { size } from 'styles'

// const defaultSnapPoints: any = {
// 	'desktop': [24, window.innerHeight - 65],
// 	'mobile': [96, window.innerHeight - 80]
// 	// 'mobile': ['50vh', window.innerHeight - 80]
// }

interface RouteState {
	location: Location
	displayLocation: Location
	snapToIndex: number
	blocking: boolean
	scrollLocking: boolean
	header: any
	footer: any
	compactNavigation: boolean
	isMobile: boolean
	sidebarHeight: number
}

const initialState: RouteState = {
	blocking: false,
	location: { pathname: '/', search: '', hash: '', state: null, key: '5nvxpbdafa' },
	displayLocation: { pathname: '/', search: '', hash: '', state: null, key: '5nvxpbdafa' },
	snapToIndex: 1,
	header: null,
	footer: null,
	scrollLocking: false,
	compactNavigation: window.innerWidth < size.tablet && window.innerWidth > size.mobile,
	isMobile: false,
	sidebarHeight: 0,
}
// TODO: Add ability to set snap points per page
export const routeSlice = createSlice({
	name: 'routeSlice',
	initialState,
	reducers: {
		resetBottomSheet: (state) => {
			// state.snapPoints = state.isMobile ? defaultSnapPoints.mobile : defaultSnapPoints.desktop
			state.snapToIndex = 0
			state.header = null
			state.footer = null
			state.scrollLocking = false
		},
		setDisplayLocation: (state, action: PayloadAction<Location>) => {
			state.displayLocation = action.payload
		},
		setLocation: (state, action: PayloadAction<Location>) => {
			state.location = action.payload
		},
		setSnapToIndex: (state, action: PayloadAction<number>) => {
			state.snapToIndex = action.payload
		},
		setBlocking: (state, action: PayloadAction<boolean>) => {
			state.blocking = action.payload
		},
		setScrollLocking: (state, action: PayloadAction<boolean>) => {
			state.scrollLocking = action.payload
		},
		setHeader: (state, action: PayloadAction<any>) => {
			state.header = action.payload
		},
		setFooter: (state, action: PayloadAction<any>) => {
			state.footer = action.payload
		},
		setCompactNavigation: (state, action: PayloadAction<boolean>) => {
			state.compactNavigation = action.payload
		},
		setIsMobile: (state, action: PayloadAction<boolean>) => {
			state.isMobile = action.payload
		},
		setSidebarHeight: (state, action: PayloadAction<number>) => {
			state.sidebarHeight = action.payload
		}
	},
})

export const { setLocation, setDisplayLocation, setSnapToIndex, setHeader, setFooter, resetBottomSheet, setCompactNavigation } = routeSlice.actions

export default routeSlice.reducer
