import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as turf from '@turf/turf'
import { stateAbbreviations } from 'library/models/address.model'
import { Pasture } from 'library/models/pasture.model'

export type PastureRouteProperties = {
	name: string
	type: 'route'
	style: { 
		color: string | null
	}
	pastureIds: string[]
}

type Mode = 'ViewMode' | 'SelectPastureMode' | 'CreateRouteMode' | 'EditRouteMode'

interface PastureRouteState {
	mode: Mode
	indexes: number[]
	visible: string[]
	selectedIndex: number
	features: { type: 'FeatureCollection'; features: turf.Feature[] }
	route: PastureRoute,
	displayRoutes: PastureRoute[],
	guide: {
		style: {
			color: '#ffffff'
		},
		geometry: turf.Geometry
	},
	selectedFromPasture: Pasture | null,
	selectedDestinationPasture: Pasture | null,
	highlightedRoute: string | null,
}

const initialState: PastureRouteState = {
	mode: 'ViewMode',
	features: { type: 'FeatureCollection', features: [] },
	indexes: [],
	visible: [],
	selectedIndex: 0,
	route: {
		id: null,
		name: '',
		style: {
			color: '#ffffff'
		},
		pastureIds: [],
		geometry: {type: 'LineString', coordinates: []},
	},
	displayRoutes: [],
	guide: {
		style: { 
			color: '#ffffff'
		},
		geometry: {type: 'LineString', coordinates: []},
	},
	selectedFromPasture: null,
	selectedDestinationPasture: null,
	highlightedRoute: null,
}

export const pastureRouteSlice = createSlice({
	name: 'pastureRouteSlice',
	initialState,
	reducers: {
		set: (state, action: PayloadAction<PastureRouteState['route']>) => {
			// const {style, ...rest} = action.payload		
			if(action.payload?.style && typeof action.payload?.style === 'string') {
				state.route = {...action.payload, style: JSON.parse(action.payload.style)}
			} else {
				state.route = action.payload
			}
		},
		// reset: () => initialState,
		reset: (state) => {
			return {...initialState}
		},
		resetMaintainRoute: (state) => {
			return { ...initialState, route: state.route }
		},
		setVisible(state, action: PayloadAction<string[]>) {
			// list of route ids that are visible
			state.visible = action.payload
		},
		setMode: (state, action: PayloadAction<Mode>) => {
			state.mode = action.payload
		},
		setGeometry: (state, action: PayloadAction<turf.LineString>) => {
			state.route.geometry = action.payload
		},
		setGuide: (state, action: PayloadAction<number[]>) => {
			if(state?.route?.geometry && state?.route?.geometry?.coordinates.length < 1) {
				state.guide.geometry = {
					type: 'LineString',
					coordinates: [action.payload, action.payload],
				}
			} else {
				state.guide.geometry.coordinates = state?.route?.geometry ? [state?.route?.geometry?.coordinates[state.route.geometry.coordinates.length -1], action.payload] : [action.payload, action.payload]
			}
		},
		addCoordinate: (state, action: PayloadAction<number[]>) => {
			if(state?.route?.geometry && state?.route?.geometry.coordinates.length < 2) {
				state.route.geometry = {
					type: 'LineString',
					coordinates: [action.payload, action.payload],
				}
			} else if(state?.route?.geometry?.coordinates.length === 2) {
				state?.route?.geometry?.coordinates.splice(state.route.geometry.coordinates.length - 1, 1, action.payload)
				state?.route?.geometry?.coordinates.push(action.payload)
			} else {
				state?.route?.geometry?.coordinates.push(action.payload)
			}
		},
		removeCoordinate: (state, action: PayloadAction<number[]>) => {
			if(!state.route.geometry) {
				state.route.geometry = {
					type: 'LineString',
					coordinates: [action.payload],
				}
			} else {
				state.route.geometry.coordinates.pop()
			}
		},
		setName: (state, action: PayloadAction<string>) => {
			state.route.name = action.payload
		},
		setColor: (state, action: PayloadAction<string>) => {
			state.route = {
				...state.route,
				style: {
					color: action.payload
				}
			}
		},
		setRoute: (state, action: PayloadAction<PastureRoute>) => {
			state.route = action.payload
		},
		setSelectedFromPasture: (state, action: PayloadAction<Pasture>) => {
			state.selectedFromPasture = action.payload
		},
		setSelectedDestinationPasture: (state, action: PayloadAction<Pasture>) => {
			state.selectedDestinationPasture = action.payload
		},
		clearSelectedDestinationPasture: (state) => {
			state.selectedDestinationPasture = null
		},
		setDisplayRoutes: (state, action: PayloadAction<PastureRoute[]>) => {
			state.displayRoutes = action.payload
		},
		setHighlightedRoute: (state, action: PayloadAction<string>) => {
			state.highlightedRoute = action.payload
		},
		clearHighlightedRoute: (state) => {
			state.highlightedRoute = null
		}
	},
})

export const { set, reset, setMode, setGeometry, addCoordinate, removeCoordinate, setColor, setSelectedFromPasture, setSelectedDestinationPasture, clearSelectedDestinationPasture, setDisplayRoutes } = pastureRouteSlice.actions

export default pastureRouteSlice.reducer
