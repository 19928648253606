import { useEndpoints } from './endpoints'
import { useQuery, QueryMeta, useMutation, MutationMeta, UseQueryOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import * as turf from '@turf/turf'
import { AxiosError, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'


export const useGetRanchesQuery = (payload?: undefined, options?: QueryMeta) => {
	const { ranch } = useEndpoints()
	return useQuery<Ranch[], Error>({
		queryKey: ['ranches'],
		queryFn: () => ranch.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})
}

export const useGetRanchQuery = (payload: { id: string | undefined }, options?: QueryMeta) => {
	const { ranch } = useEndpoints()
	return useQuery<Ranch, Error>({
		queryKey: ['ranches', payload.id],
		queryFn: () => ranch.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

// export const createRanch = (payload: CreateRanchParams) => client.post(`/Ranch`, payload).then((res) => res.data)
export const useCreateRanchMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.create'],
		onMutate: (payload: CreateRanchParams) => {
        
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches'], previousRanches ? [...previousRanches, payload] : [payload])
			queryClient.setQueryData(['ranches', payload.id], payload)
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const updateRanch = (payload: UpdateRanchParams) => client.put(`/Ranch/${payload.id}`, payload).then((res) => res.data)
export const useUpdateRanchMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.update'],
		// mutationFn: updateRanch,
		onMutate: (payload: UpdateRanchParams) => {
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			queryClient.cancelQueries({ queryKey: ['ranches', payload.id] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches'], previousRanches ? previousRanches.map(ranch => ranch.id === payload.id ? {...ranch, ...payload} : ranch) : [])
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.setQueryData(['ranches', variables.id], context?.find(ranch => ranch.id === variables.id))
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const deleteRanch = (payload: {id: string}) => client.delete(`/Ranch/${payload.id}`).then((res) => res.data)
export const useDeleteRanchMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.delete'],
		// mutationFn: deleteRanch,
		onMutate: (payload: {id: string}) => {
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', payload.id] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches'], previousRanches ? previousRanches.filter(ranch => ranch.id !== payload.id) : [])
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.setQueryData(['ranches', variables.id], context?.find(ranch => ranch.id === variables.id))
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useGetMapFeaturesQuery = (payload?: undefined, options?: Partial<UseQueryOptions<turf.FeatureCollection, AxiosError>>) => {
	const { ranch } = useEndpoints()
	return useQuery<turf.FeatureCollection, AxiosError>({
		queryKey: ['map-features'],
		queryFn: () => ranch.query.features.map(),
		networkMode: 'offlineFirst',
		// refetchInterval: 1000 * 60 * 3, // 3 minutes
		// refetchIntervalInBackground: true,
		// refetchOnMount: true,
		// refetchOnWindowFocus: true,
		...options
	})
}
export const useGetAnimalFeaturesQuery = (payload?: undefined, options?: Partial<UseQueryOptions<GeoJSON.FeatureCollection, AxiosError>>) => {
	const { ranch } = useEndpoints()
	const queryClient = useQueryClient()
	return useQuery<GeoJSON.FeatureCollection, AxiosError>({
		queryKey: ['animal-features'],
		queryFn: () => ranch.query.features.animals(),
		networkMode: 'online',
		select: (data) => {
			data.features.forEach((feature: any) => {
				const check: any = queryClient.getQueryData(['animals', feature.id])
				if (!check?.id) return;

				queryClient.setQueryData(['animals', feature.id], (old: Animal) => (old ? {
					...old,
					...feature.properties,
					lastCoordinate: feature.geometry.coordinates,
					prevCoordinate: old.lastCoordinate,
				} : {
					...feature.properties
				}))
			})
			return data
		},
		refetchInterval: 1000 * 60 * 3, // 3 minutes
		refetchIntervalInBackground: true,
		refetchOnReconnect: true,
		// refetchOnMount: true,
		// refetchOnWindowFocus: true,
		...options
	})
}

export const useAddRanchImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.image.create'],
		onMutate: (payload: {id: string, image: ImageUpload}) => {
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			queryClient.cancelQueries({ queryKey: ['ranches', payload.id] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches', payload.id], (old: Ranch) => ({...old, imageFile: {
				id: payload.image.id || uuidv4().toString(),
				fileName: payload.image.fileName,
				contentType: payload.image.contentType,
				data: payload.image.data,
				added: new Date().toISOString(),
				isImage: true,
            
			}}))
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.setQueryData(['ranches', variables.id], context?.find(ranch => ranch.id === variables.id))
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useUpdateRanchImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.image.update'],
		onMutate: (payload: {id: string, image: { caption?: string, primary?: boolean }}) => {
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			queryClient.cancelQueries({ queryKey: ['ranches', payload.id] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches', payload.id], (old: Ranch) => ({...old, imageFile: {
				...old.imageFile,
				caption: payload.image.caption,
				primary: payload.image.primary,
            
			}}))
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.setQueryData(['ranches', variables.id], context?.find(ranch => ranch.id === variables.id))
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useRemoveUserImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['ranch.mutation.image.remove'],
		onMutate: (payload: {id: string, image: { caption?: string, primary?: boolean }}) => {
			queryClient.cancelQueries({ queryKey: ['ranches'] })
			queryClient.cancelQueries({ queryKey: ['ranches', payload.id] })
			const previousRanches = queryClient.getQueryData<Ranch[]>(['ranches'])
			queryClient.setQueryData(['ranches', payload.id], (old: Ranch) => ({...old, imageFile: null }))
			return previousRanches
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['ranches', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['ranches'], context)
			queryClient.setQueryData(['ranches', variables.id], context?.find(ranch => ranch.id === variables.id))
		},
		networkMode: 'offlineFirst',
		...options
	})
}