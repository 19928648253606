import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
	size?: number
	color?: string
}

export const LoadingSpinner: React.FC<Props> = ({ size = 24, color = '#000000' }: Props) => (
	<Wrapper size={size}>
		{[...Array(5)].map((_, index) => (
			<Dot key={index} size={size} color={color} delay={index * 0.1} angle={(index + 1) * 18} />
		))}
	</Wrapper>
)

interface DotProps {
	size: number
	color: string
	delay: number
	angle: number
}

const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`

const orbit = ({ size, angle }: { size: number; angle: number }) => keyframes`
	from {
		transform: translate(${size / 2}px)
			rotate(0deg)
			translate(${size / 2 - size / 8}px)
			rotate(0deg)
			translate(${size / 2}px);
	}
	to {
		transform: translate(${size / 2}px)
			rotate(360deg)
			translate(${size / 2 - size / 8}px)
			rotate(-360deg)
			translate(${size / 2}px);
	}
`

const Wrapper = styled.div<{ size: number }>`
	position: relative;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;
	background-color: transparent;
`

const Dot = styled.div<DotProps>`
	position: absolute;
	top: calc(50% - ${({ size }) => size / 6 / 2}px);
	left: calc(-50% - ${({ size }) => size / 6 / 2}px);
	width: ${({ size }) => size / 6}px;
	height: ${({ size }) => size / 6}px;
	background-color: ${({ color }) => color};
	border-radius: 50%;
	transition: 0.3s ease background-color;
	animation: ${rotate} 1.2s linear infinite, ${props => orbit({ size: props.size, angle: props.angle })} 1.2s ease-in-out infinite;
	animation-delay: ${({ delay }) => `-${delay}s`};
`

export default LoadingSpinner
