import { useEffect, useState } from 'react'
import { Input, RadioList } from 'components/inputs'
import { useGetHerdsQuery } from 'library/api/herd'
import { CardAction, CardActions } from 'components/cards/CardActions'
import { useUpdateAnimalMutation, useGetAnimalBreedsQuery, useCreateAnimalMutation, useGetAnimalQuery } from 'library/api/animal'
import styled from 'styled-components/macro'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Animal } from 'library/models/animal.model'
import { useGetUserQuery } from 'library/api/user'

export const AnimalForm = () => {
	const { id } = useParams<{id: string}>()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const { data: herds, isLoading: herdsIsLoading } = useGetHerdsQuery()
	const { data: breeds, isLoading: animalBreedsIsLoading } = useGetAnimalBreedsQuery()
	const { data: animal } = useGetAnimalQuery({ id }, { enabled: !!id })
	const { data: user } = useGetUserQuery()
	
	const [edits, setEdits] = useState<Animal>(new Animal({ new: true }))
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [herdValues, setHerdValues] = useState(herds ? herds?.map(herd => ({ title: herd.name, value: herd.id })) : [])
	const [selectedHerd, setSelectedHerd] = useState({ title: 'Unassigned', value: '' })
	
	const { mutate: updateAnimal, isPending: updateAnimalPending } = useUpdateAnimalMutation()
	const { mutate: createAnimal, isPending: createAnimalIsLoading } = useCreateAnimalMutation()

	useEffect(() => {
		if (herds && herds.length > 0 && herdValues?.length === 0) {
			setHerdValues(herds?.map(herd => ({ title: herd.name, value: herd.id })))
		}
	}, [herdValues, herdValues.length, herds, selectedHerd.value])
	
	useEffect(() => {
		if (animal) {
			// store.dispatch(animalSlice.actions.setAnimal(animal))
			setEdits(animal)
			if (animal.herdName && animal.herdId) {
				setSelectedHerd({ title: animal.herdName, value: animal.herdId })
			}
			
		}
	}, [animal])
	useEffect(() => {
		if (searchParams.has('deviceId')) {
			setEdits((old: Animal) => ({ ...old, deviceId: searchParams.get('deviceId') || '' }))
		}
	}, [searchParams])
	return (
		<Form
			onSubmit={async e => {
				e.preventDefault()
				if (edits && edits?.new) createAnimal(edits)
				else if (edits) updateAnimal(edits)
				
				// navigate(-1)
				navigate(`/animals/${edits.id}`)
			}}>
			
			<>
				<Input
					label={'Tag'}
					value={edits?.tag || ''}
					maxLength={16}
					required
					onChange={(e: any) => {
						setEdits({ ...edits, tag: e.target.value })
					}}
				/>
				<Input
					label={'Collar ID'}
					value={edits?.deviceId || ''}
					disabled={user?.currentRole !== 'SysAdmin' && user?.currentRole !== 'Manager' && user?.currentRole !== 'Owner'}
					onChange={(e: any) => {
						setEdits({ ...edits, deviceId: e.target.value})
					}}
				/>
				<RadioList
					header={'Gender'}
					defaultValue={{ title: edits?.sex || 'Unknown', value: edits?.sex || '' }}
					items={[
						{ title: 'Unknown', value: '' },
						{ title: 'Sire', value: 'Sire' },
						{ title: 'Dam', value: 'Dam' },
					]}
					onChange={item => {
						const value = (item.value ? item.value : null) as 'Sire' | 'Dam'
						setEdits({ ...edits, sex: value })
					}}
				/>
				{selectedHerd && <RadioList
					header={'Breed'}
					defaultValue={{ title: edits?.breed || 'Unknown', value: edits?.breed || '' }}
					items={breeds?.map(item => ({ title: item.breed, value: item.breed }))}
					onChange={item => {
						const value = (item.value ? item.value : null) as Breed['breed']
						setEdits({ ...edits, breed: value })
					}}
				/>}
				<RadioList
					header={'Herd'}
					defaultValue={selectedHerd}
					items={herdValues}
					required
					onChange={item => {
						setEdits({ ...edits, herdName: item.title , herdId: item.value, herd: { id: item.value, name: item.title } })
						setSelectedHerd(item)
					}}
				/>
				<CardActions>
					<CardAction type={'submit'} disabled={isSubmitting || selectedHerd.value === ''}>
						Save
					</CardAction>
					<CardAction type={'button'} variant={isSubmitting ? 'dark' : 'danger'} onClick={() => navigate(-1)} disabled={isSubmitting}>
						Cancel
					</CardAction>
				</CardActions>
			</>
			
		</Form>
	)
}

const Form = styled.form`
	padding: 1em;
	display: flex;
	flex-direction: column;
`
