

export type Palette = {
    primary: string
    background: string
}

export default function useColors() {

	const brandColors = {
		orange: 'rgb(206, 81, 34)',
		blue: 'rgb(45, 81, 106)',
		navy: 'rgb(17, 30, 46)',
		wheat: 'rgb(162, 124, 56)'
	}

	const profileColors = {
		dark_green: '#062726',
		verdigris: '#50C5B7',
		asparagus: '#69995D',
		mountbatten_pink: '#8E7C93',
		tea: '#CADBC0',
		sage: '#B5BD89',
		chocolate_cosmos: '#66101F',
		moss: '#A6A867',
		fern: '#65743A',
		turquoise: '#1DD3B0'
	}

	const getColorForObject = (target: any) => {
		if(!target?.createdDate) return getProfileColors(1)
		const seed = Number(Date.parse(target?.createdDate.toString()))
		return getProfileColors(seed)
	}

	const getProfileColors = (seed: number) => {
		let seedNumber = seed
		const digits = Math.floor(Math.log10(seed) + 1)
		if (digits < 6) {
			seedNumber = 1
		}
		const seed1 = seedNumber % 10
		// const seed2 = Math.floor((seedNumber % 100) / 10)
		const palette: Palette = {
			primary: Object.values(profileColors)[seed1],
			background: Object.values(profileColors)[seed1]
		}
		return palette
	}

	return {
		brandColors,
		profileColors,
		getProfileColors,
		getColorForObject
	}
}
