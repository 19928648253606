import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import useCardBuffer from 'hooks/useCardBuffer'
import { RootState } from 'store'
import styled from 'styled-components/macro'

export const Tutorial = forwardRef((props: any, ref: any) => {
	const {className, children} = props
	const tutorial = useSelector((state: RootState) => state.mapSlice.tutorial)
	const { bufferWidth } = useCardBuffer()
    
	return <Tutorials ref={ref} $inputMargin={bufferWidth}>
		{tutorial &&
		<TutorialWrapper className={className}>
			{children ? children : tutorial}
		</TutorialWrapper>
		}
	</Tutorials>
})

const TutorialContainer = ({className, children}: any) => {
	return (<div className={className}>
		{children}
	</div>)
}
export const Tutorials = styled.div<{ $inputMargin?: number; }>`
	text-align: center;
	display: flex;
	flex-direction: column;
	z-index: 100;
	gap: .5em;
`
const TutorialWrapper = styled(TutorialContainer)`
	display: flex;
	flex-direction: row;
	padding: 1em;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius);
	gap: 0.5em;
	width: 100%;
	box-shadow: var(--box-shadow);
	position: relative;
	background-color: white;
`

export default Tutorial