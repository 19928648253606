import { Account } from './account.model'
import { Ranch } from './ranch.model'
import * as uuid from 'uuid'
export class User {
	id?: string
	accountId: string
	ranchId: string
	name: string
	firstName: string
	lastName: string
	email: string
	phone: string | null
	systemOfMeasure: 'imperial' | 'metric'
	theme: 'system' | 'dark' | 'light'
	createdDate: Date
	accountOwner: boolean
	active: boolean
	currentRole: 'Owner' | 'Manager' | 'Hand' | 'SysAdmin'
	imageFile?: ImageFile | null
	account: Account | null
	ranchRoles: RanchRole[]
	currentRanch: Ranch | null

	constructor(props?: Partial<User>) {
		this.id = props?.id || uuid.v4().toString()
		this.accountId = props?.accountId || '0'
		this.ranchId = props?.ranchId || '0'
		this.name = props?.name || ''
		this.firstName = props?.firstName || ''
		this.lastName = props?.lastName || ''
		this.email = props?.email || ''
		this.phone = props?.phone || null
		this.systemOfMeasure = props?.systemOfMeasure || 'imperial'
		this.theme = props?.theme || 'system'
		this.createdDate = props?.createdDate || new Date()
		this.accountOwner = props?.accountOwner || false
		this.active = props?.active || false
		this.currentRole = props?.currentRole || 'Hand'
		this.imageFile = props?.imageFile || null
		this.account = props?.account || null
		this.ranchRoles = props?.ranchRoles || []
		this.currentRanch = props?.currentRanch || null
	}
}

export class CreateUser {
	id: string
	name: string
	firstName: string
	lastName: string
	phone: string
	email: string
	systemOfMeasure: 'imperial' | 'metric'
	theme: 'system' | 'dark' | 'light'
	constructor(props?: CreateUser) {
		this.id = props?.id || uuid.v4().toString(),
		this.name = props?.name || ''
		this.firstName = props?.firstName || ''
		this.lastName = props?.lastName || ''
		this.phone = props?.phone || ''
		this.email = props?.email || ''
		this.systemOfMeasure = props?.systemOfMeasure || 'imperial'
		this.theme = props?.theme || 'system'
	}
}
export class CreateInviteUser {
	id: string
	token: string
	name: string
	firstName: string
	lastName: string
	phone: string
	email: string
	systemOfMeasure: 'imperial' | 'metric'
	theme: 'system' | 'dark' | 'light'
	constructor(props?: CreateInviteUser) {
		this.id = props?.id || uuid.v4().toString(),
		this.token = props?.token || ''
		this.name = props?.name || ''
		this.firstName = props?.firstName || ''
		this.lastName = props?.lastName || ''
		this.phone = props?.phone || ''
		this.email = props?.email || ''
		this.systemOfMeasure = props?.systemOfMeasure || 'imperial'
		this.theme = props?.theme || 'system'
	}
}