import styled from 'styled-components/macro'
import { colors, alpha } from 'styles'
import { darken, lighten, transparentize } from 'polished'
import { Icon } from 'components/icons'
import { Link } from 'react-router-dom'

const roundCloseButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
	<button {...props}>
		<Icon size={'sm'} style={{ transform: 'scale(.85)' }} color={'currentColor'} name={'x'} />
	</button>
)

export const RoundCloseButton = styled(roundCloseButton)<{ variant?: 'dark' | 'warning' | 'error' | 'success' | 'transparent' }>`
	all: unset;
	cursor: pointer;
	background: ${alpha(colors.primary, 0.85)};
	border-radius: 100%;
	height: 24px;
	width: 24px;
	aspect-ratio: 1/1;
	${({ variant }) => {
		switch (variant) {
		case 'transparent': {
			return `
					background: transparent;
					color: currentColor;
					border: 1px solid currentColor;
				`
		}
		case 'warning': {
			return `
				background: ${lighten(0.3, colors.warning)};
				color: ${darken(0.35, colors.warning)};
			`
		}
		case 'dark': {
			return `
				background: ${darken(0, colors.dark)};
				color: ${colors.light};
			`
		}
		case 'success': {
			return `
				background: ${lighten(0.5, colors.success)};
				color: ${darken(0.2, colors.success)};
			`
		}
		default: {
			return `
				background: ${lighten(0.4, colors.primary)};
				color: ${darken(0.1, colors.primary)};
			`
		}
		}
	}}
`

export const RoundEditButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
	<RoundButton>
		<Icon size={'sm'} style={{ transform: 'scale(.75)' }} color={colors.dark} name={'pencil'} />
	</RoundButton>
)
const roundButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => <button {...props}>{props.children}</button>

export const RoundButton = styled(roundButton)`
	all: unset;
	cursor: pointer;
	margin-bottom: 0.5em;
	background: ${darken(0, colors.background)};
	height: 25px;
	width: 25px;
	border-radius: 100%;
	color: ${colors.dark};
	display: flex;
	align-items: center;
	justify-content: center;
`

export const EditButton = styled.button`
    padding: .5em;
    border-radius: 999999px;
    color: red;
    color: ${transparentize(.2, colors.dark)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .25em;
    border: 1px solid ${transparentize(.9, colors.dark)};
    background: transparent;
`

export const SubmitButton = styled(EditButton)<{ $success?: boolean; $disabled?: boolean }>`
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	font-weight: 500;
	min-width: 96px;
	background-color: ${props => props.$success ? transparentize(.9, colors.light) : '#04A8CC0D'};
    color: ${props => props.$success ? transparentize(.4, colors.dark) : '#04A8CC'};
	border: none;
	&:disabled {
		color: ${transparentize(.4, colors.dark)};
		background-color: ${transparentize(.9, colors.light)}
	}
`
export const DeleteButton = styled(EditButton)`
	padding-left: 1.2rem;
	padding-right: 1.2rem;
	background-color: #FDECEC88;
	font-weight: 500;
	min-width: 96px;
    color: #CC0404;
	border: none;
`
export const CancelButton = styled(EditButton)`
    color: ${transparentize(.2, colors.dark)};
`
export const ChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: .1em;
    flex-wrap: wrap;
`
export const Chip = styled(Link)`
    all: unset;
    cursor: pointer;
    padding: .25em .75em;
    border-radius: 999999px;
    background: ${transparentize(.8, colors.success)};
    color: ${darken(.15, colors.success)};
    font-size: .75em;
    margin-left: .5em;
    margin-top: .25em;
    margin-bottom: .25em;
    transition: .3s ease all;
    &:hover {
        color: inherit;
        background: ${transparentize(.7, colors.success)};
    }
    &:active {
        color: inherit;
    }
`