import { useQuery,  QueryMeta, useMutation, MutationMeta, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import * as uuid from 'uuid'
import { useEndpoints } from './endpoints'
import { AxiosError } from 'axios'
import { User } from 'library/models/user.model'

export const useGetUserQuery = (payload?: undefined, options?: Partial<UseQueryOptions<User, AxiosError>>) => {
	const { user } = useEndpoints()
	return useQuery<User, AxiosError>({
		queryKey: ['user', 'current'],
		queryFn: () => user.query.current(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})
}
export const useGetUserByIdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<User, AxiosError>>) => {
	const { user } = useEndpoints()
	return useQuery<User, AxiosError>({
		queryKey: ['users', payload.id],
		queryFn: () => user.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetUsersByRanchIdQuery = (payload: { id: string | undefined}, options?: Partial<UseQueryOptions<User[], AxiosError>>) => {
	const { user } = useEndpoints()
	return useQuery<User[], AxiosError>({
		queryKey: ['users', 'ranch', payload.id],
		queryFn: () => user.query.ranch.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

export const useUpdateUserMutation = (options?: Partial<UseMutationOptions<User, AxiosError, UserParams>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.update'],
		// mutationFn: (payload: UserParams) => updateUser(payload),
		onMutate: (payload: UserParams) => {
			queryClient.cancelQueries({ queryKey: ['user', 'current'] })
			const previousCurrentUser = queryClient.getQueryData(['user', 'current'])
        
			queryClient.setQueryData(['user', 'current'], (old: User) => old ? {...old, ...payload} : payload)
			return previousCurrentUser
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['user', 'current'] }),
		onError: (data, variables, context) => queryClient.setQueryData(['user', 'current'], context),
		networkMode: 'offlineFirst',
		...options
	})
}

export const useCreateUserMutation = (options?: Partial<UseMutationOptions<User, AxiosError, UserParams>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.create'],
		onMutate: (payload: UserParams) => {
			queryClient.cancelQueries({ queryKey: ['users'] })
			const previousUsers = queryClient.getQueryData<User[]>(['users']) || []
        
			queryClient.setQueryData(['users'], [...previousUsers || [], payload])
			queryClient.setQueryData(['users', payload.id], payload)
			return previousUsers
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['users'] }),
		onError: (data, variables, context) => {
			queryClient.setQueryData(['users'], context)
			queryClient.removeQueries({queryKey: ['users', variables.id]})
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useDeleteUserMutation = (options?: Partial<UseMutationOptions<Boolean, AxiosError, {id: string}, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.delete'],
		onMutate: (payload: { id: string }) => {
			queryClient.cancelQueries({ queryKey: ['users'] })
			const users = queryClient.getQueryData<User[]>(['users']) || []
			const user = queryClient.getQueryData<User>(['users', payload.id])
			queryClient.setQueryData(['users', payload.id], users.filter(user => user.id !== payload.id))
			queryClient.removeQueries({queryKey: ['users', payload.id]})
			return { users, user }
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['users'] }),
		onError: (data, variables, context) => {
			queryClient.setQueryData(['users'], context?.users)
			queryClient.setQueryData(['users', variables.id], context?.user)
        
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useAddUserToRanchMutation = (options?: Partial<UseMutationOptions<User, AxiosError, AddUserToRanchParams>>) => {
	const queryClient = useQueryClient()
	const { ranch: ranchEp } = useEndpoints()
	return useMutation({
		mutationKey: ['user.mutation.ranch.add'],
		onMutate: async (payload: AddUserToRanchParams) => {
			queryClient.cancelQueries({ queryKey: ['users'] })
			const user = queryClient.getQueryData(['users', payload.userId])
			const users = queryClient.getQueryData<User[]>(['users'])
			const ranch = await queryClient.ensureQueryData({
				queryKey: ['ranches', payload.ranchId],
				queryFn: () => ranchEp.query.byId({ id: payload.ranchId })
			})

			queryClient.setQueryData(['users', payload.userId], (user: User) => 
			{
				if (user) {
					return ({
						...user,
						ranchRoles: [
							...user.ranchRoles.filter((ranchRole: RanchRole) => {
								return ranchRole.ranchId !== payload.ranchId
							}),
							{
								id: uuid.v4(),
								role: payload.role,
								userId: payload.userId,
								ranch
							}
						]
					})
				}
			})
			return {user, users}
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['users'] }),
		onError: (data, variables, context) => queryClient.setQueryData(['users'], context),
		networkMode: 'offlineFirst',
		...options
	})

}


// queryClient.setMutationDefaults(['removeUserFromRanch'], { mutationFn: removeUserFromRanch })
export const useRemoveUserFromRanchMutation = (options?: Partial<UseMutationOptions<User, AxiosError, RemoveUserFromRanchParams, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.ranch.remove'],
		onMutate: (payload: RemoveUserFromRanchParams) => {
			queryClient.cancelQueries({ queryKey: ['users'] })
			queryClient.cancelQueries({ queryKey: ['users', payload.userId] })
			const users = queryClient.getQueryData(['users'])
			const user = queryClient.getQueryData(['users', payload.userId])
			queryClient.setQueryData(['users', payload.userId], (user: User) => ({...user, ranchRoles: user.ranchRoles.filter((ranchRole: RanchRole) => ranchRole.ranchId !== payload.ranchId)}))
			return {user, users}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['users'] })
			queryClient.invalidateQueries({ queryKey: ['users', variables.userId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['users', variables.userId], context?.users)
			queryClient.setQueryData(['users', variables.userId], context?.user)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useAddUserImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.image.create'],
		onMutate: (payload: {id: string, image: ImageUpload}) => {
			queryClient.cancelQueries({ queryKey: ['user', 'current'] })
			const previousUserState = queryClient.getQueryData<User>(['user', 'current'])
			queryClient.setQueryData(['user', 'current'], (old: User) => ({...old, imageFile: {
				id: uuid.v4(),
				fileName: payload.image.fileName,
				contentType: payload.image.contentType,
				data: payload.image.data,
				added: new Date().toISOString(),
				isImage: true,
            
			}}))
			return previousUserState
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['user', 'current'] }),
		onError: (data, variables, context) => queryClient.setQueryData(['user', 'current'], context),
		networkMode: 'offlineFirst',
		...options
	})

}

// queryClient.setMutationDefaults(['updateUserImage'], { mutationFn: updateUserImage })
export const useUpdateUserImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.image.update'],
		onMutate: (payload: {id: string, image: { caption?: string, primary?: boolean }}) => {
			queryClient.cancelQueries({ queryKey: ['user', 'current'] })
			const previousUserState = queryClient.getQueryData(['user', 'current'])
			const currentUser = queryClient.getQueryData(['user', 'current'])
			queryClient.setQueryData(['user', 'current'], (old: User) => ({...old, imageFile: {
				...old.imageFile,
				caption: payload.image.caption,
				primary: payload.image.primary,
            
			}}))
			return previousUserState
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['user', 'current'] }),
		onError: (data, variables, context) => queryClient.setQueryData(['user', 'current'], context),
		networkMode: 'offlineFirst',
		...options
	})
}

export const useRemoveUserImageMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.image.remove'],
		onMutate: (payload: {id: string, image: { caption?: string, primary?: boolean }}) => {
			queryClient.cancelQueries({ queryKey: ['user', 'current'] })
			const previousUserState = queryClient.getQueryData(['user', 'current'])
			const currentUser = queryClient.getQueryData(['user', 'current'])
			queryClient.setQueryData(['CurrentUser'], (old: User) => ({...old, imageFile: null }))
			return previousUserState
		},
		onSuccess: (data, variables, context) => queryClient.invalidateQueries({ queryKey: ['user', 'current'] }),
		onError: (data, variables, context) => queryClient.setQueryData(['user', 'current'], context),
		networkMode: 'offlineFirst',
		...options
	})
}

export const useUpdateUserActiveRanchMutation = (options?: MutationMeta) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['user.mutation.ranch.update'],
		onMutate: (payload: {id: string}) => {
			queryClient.cancelQueries({ queryKey: ['user', 'current'] })
			const previousUserState = queryClient.getQueryData(['user', 'current'])
			const currentUser: User | undefined = queryClient.getQueryData(['user', 'current'])
			queryClient.setQueryData(['user', 'current'], (old: User) => {
				const currentRole = currentUser?.ranchRoles.find((ranchRole: RanchRole) => ranchRole.ranchId === payload.id)
				if(!currentRole) return old
				return { 
					...old,
					currentRanch: queryClient.getQueryData(['ranches', currentRole?.ranchId]),
					currentRole: currentRole?.role,
				}
			})
			return previousUserState
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['users'] })
			queryClient.invalidateQueries({ queryKey: ['favorites'] })
			queryClient.invalidateQueries({ queryKey: ['recents'] })
			queryClient.invalidateQueries({ queryKey: ['ranches'] })
			queryClient.invalidateQueries({ queryKey: ['pastures'] })
			queryClient.invalidateQueries({ queryKey: ['animals'] })
			queryClient.invalidateQueries({ queryKey: ['map-features'] })
			queryClient.invalidateQueries({ queryKey: ['animal-features'] })
			queryClient.invalidateQueries({ queryKey: ['pasture-features'] })
			queryClient.invalidateQueries({ queryKey: ['pasture-routes'] })
			queryClient.invalidateQueries({ queryKey: ['stimulus-counts'] })
			queryClient.invalidateQueries({ queryKey: ['distance-traveled-by-herd'] })
			queryClient.invalidateQueries({ queryKey: ['device-locations'] })
			queryClient.invalidateQueries({ queryKey: ['pasture-production'] })
			queryClient.invalidateQueries({ queryKey: ['animal-activity-reports'] })
			queryClient.invalidateQueries({ queryKey: ['animal-events'] })
			queryClient.invalidateQueries({ queryKey: ['grazing-plans'] })
			queryClient.invalidateQueries({ queryKey: ['herds'] })
			queryClient.invalidateQueries({ queryKey: ['notifications'] })
			queryClient.invalidateQueries({ queryKey: ['devices'] })
		},
		onError: (data, variables, context) => queryClient.setQueryData(['user', 'current'], context),
		networkMode: 'offlineFirst',
		...options
	})
}