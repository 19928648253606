import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Feature, FeatureCollection, Geometry, GeometryCollection } from '@turf/turf'
import twcolors from 'tailwindcss/colors'
import * as turf from '@turf/turf'


export type BoundaryType = 'pasture' | 'exclusion' | 'safeZone'

export type BoundaryProperties = {
	name: string
	safeZone?: boolean
	safeZoneDistance?: number
	type: BoundaryType
	style?: any
	ranchId?: string
	pastureIds?: string[]
}

export type BoundaryMode = 'DrawPolygonMode' | 'ViewMode' | 'DrawLineStringMode' | 'DrawPointMode' | 'ModifyMode' | 'SplitPolygonMode' | 'SelectFeatureMode'

interface BoundaryFeature {
	type: 'Feature'
	id: string | null
	geometry?: Geometry | GeometryCollection | turf.Geometry | null
	properties?: BoundaryProperties
	new?: boolean
}
interface BoundaryState {
	mode: BoundaryMode
	indexes: number[]
	features: { type: 'FeatureCollection'; features: Feature[] }
	history: Feature[],
	feature?: BoundaryFeature,
}

// const initialState: BoundaryState = {
const initialState: any = {
	mode: 'ViewMode',
	features: { type: 'FeatureCollection', features: [] },
	indexes: [],
	history: [],
	feature: {
		type: 'Feature',
		id: null,
		geometry: null,
		properties: {
			name: '',
			type: 'pasture',
			style: {
				backgroundColor: '#ffffff',
				outlineColor: '#ffffff',
				outlineWidth: 1,
				opacity: 0.25,
			},
		},
		new: true
	},
}

export const boundarySlice = createSlice({
	name: 'boundarySlice',
	initialState,
	reducers: {
		reset: () => initialState,
		set: (state, action: PayloadAction<BoundaryState['feature']>) => {
			state.feature = action.payload
		},
		clear: state => {
			state.feature = {...initialState.feature}
		},
		setDefault: (state, action: PayloadAction<BoundaryType>) => {
			state.feature.properties.type = action.payload
			state.feature.properties.style.opacity = 0.25
			state.feature.properties.style.outlineWidth = 1
			state.feature.properties.name = ''
			state.feature.id = null
			state.feature.new = true
			state.history = []
			state.feature.geometry = null
			switch (action.payload) {
			case 'pasture': {
				state.feature.properties.style.outlineColor = '#ffffff'
				state.feature.properties.style.backgroundColor = '#ffffff'
				break
			}
			case 'exclusion': {
				state.feature.properties.style.outlineColor = twcolors.red[500]
				state.feature.properties.style.backgroundColor = twcolors.red[500]
				break
			}
			case 'safeZone': {
				state.feature.properties.style.outlineColor = twcolors.green[300]
				state.feature.properties.style.backgroundColor = twcolors.green[300]
				break
			}
			}
		},
		setId: (state, action: PayloadAction<string>) => {
			state.feature.id = action.payload
		},
		setMode: (state, action: PayloadAction<BoundaryMode>) => {
			state.mode = action.payload
		},
		setFeatures: (state, action: PayloadAction<FeatureCollection>) => {
			state.features = action.payload
		},
		setHistory: (state, action: PayloadAction<Feature[]>) => {
			state.history = action.payload
		},
		setProperties: (state, action: PayloadAction<any>) => {	
			state.feature.properties = action.payload
		},
		setGeometry: (state, action: PayloadAction<Geometry>) => {
			state.feature.geometry = action.payload
		},
		setPastureIds: (state, action: PayloadAction<string[]>) => {
			state.feature.properties['pastureIds'] = action.payload
		},
		setType: (state, action: PayloadAction<BoundaryType>) => {
			state.feature.properties.type = action.payload
		},
		setSafeZone: (state, action: PayloadAction<boolean>) => {
			state.feature.properties.safeZone = action.payload
		},
		setSafeZoneDistance: (state, action: PayloadAction<number>) => {
			state.feature.properties.safeZoneDistance = action.payload
		},
		setName: (state, action: PayloadAction<string>) => {
			state.feature.properties.name = action.payload
		},
		setStyles: (state, action: PayloadAction<{ [key: string]: any }>) => {
			state.feature.properties.style = action.payload
		},
		setIndexes: (state, action: PayloadAction<number[]>) => {
			state.indexes = action.payload
		},
	},
})

export const { set, clear, reset, setDefault, setMode, setProperties, setGeometry, setType, setSafeZone, setSafeZoneDistance, setHistory } = boundarySlice.actions

export default boundarySlice.reducer
