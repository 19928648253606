import { useQuery, useMutation, UseQueryOptions, MutationFunction, MutateOptions, MutationOptions, UseMutationOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import * as uuid from 'uuid'
import { useEndpoints } from './endpoints'
import { CreateAccount, Account } from '../models/account.model'
import { AxiosError } from 'axios'

export const useGetAccountQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Account, AxiosError>>) => {
	const { account } = useEndpoints()
	return useQuery<Account, AxiosError>({
		queryKey: ['accounts', 'current'],
		queryFn: account.query.current,
		networkMode: 'offlineFirst',
		...options
	})}

export const useGetAccountByIdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Account, AxiosError>>) => {
	const { account } = useEndpoints()
	return useQuery<Account, AxiosError>({
		queryKey: ['accounts', payload.id],
		queryFn: () => account.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}


export const useCreateAccountMutation = (options?: Partial<UseMutationOptions<Account, AxiosError, CreateAccount, Account>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['account.mutation.create'],
		onMutate: (payload: CreateAccount) => {
			queryClient.cancelQueries({ queryKey: ['accounts', 'current'] })
			const previousAccounts = queryClient.getQueryData<Account>(['accounts'])
			queryClient.setQueryData(['accounts', 'current'], payload)
			queryClient.setQueryData(['accounts', payload.id], payload)
			return previousAccounts
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['accounts'] })
			queryClient.invalidateQueries({ queryKey: ['accounts', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['accounts'], context)
			queryClient.invalidateQueries({ queryKey: ['accounts', variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useUpdateAccountMutation = (options?: Partial<MutateOptions<Account, AxiosError, Account>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['account.mutation.update'],
		onMutate: (payload: Account) => {
			queryClient.cancelQueries({ queryKey: ['accounts', 'current'] })
			queryClient.cancelQueries({ queryKey: ['accounts', payload.id] })
			const previousAccount = queryClient.getQueryData<Account>(['accounts', payload.id])
			const currentAccount = queryClient.getQueryData<Account>(['accounts', 'current'])
			// queryClient.setQueryData(['CurrentAccount'], payload)
			queryClient.setQueryData(['accounts', payload.id], payload)
			if(currentAccount?.id === payload.id) {
				queryClient.setQueryData(['accounts', 'current'], payload)
				return {previousAccount, currentAccount}
			}
			return { previousAccount }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['accounts', 'current'] })
			queryClient.invalidateQueries({ queryKey: ['accounts'] })
			queryClient.invalidateQueries({ queryKey: ['accounts', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['accounts', variables.id], context?.previousAccount)
			if(context?.currentAccount) queryClient.setQueryData(['accounts', 'current'], context?.currentAccount)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useDeleteAccountMutation = (options?: Partial<MutationFunction<{ id: string }, AxiosError>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['account.mutation.delete'],
		onMutate: (payload: { id: string }) => {
        
			queryClient.cancelQueries({ queryKey: ['accounts', payload.id] })
			queryClient.cancelQueries({ queryKey: ['accounts', 'current'] })
			const previousAccount = queryClient.getQueryData<Account>(['accounts', payload.id])
			const currentAccount = queryClient.getQueryData<Account>(['accounts', 'current'])
			queryClient.removeQueries({queryKey: ['accounts', payload.id]})
			return {previousAccount, currentAccount}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['accounts', variables.id] })
			if(context?.currentAccount) queryClient.invalidateQueries({ queryKey: ['accounts', 'current'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['accounts', variables.id], context?.previousAccount)
			if(context?.currentAccount) queryClient.setQueryData(['accounts', 'current'], context?.currentAccount)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


// export const addAccountImage = (payload : { id: string, image: ImageUpload }) => client.post(`/Account/${payload.id}/image`, payload.image).then((res) => res.data)
export const useAddAccountImageMutation = (options?: Partial<MutationFunction<{ id: string, image: ImageUpload }, Promise<Account>>>) => {
	const id = uuid.v4().toString()
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['account.mutation.image.create'],
		onMutate: (payload: { id: string, image: ImageUpload }) => {
			const file = {
				id: id,
				fileName: id + '-' + Date.now() + '.jpg',
				contentType: 'image/jpg',
				added: new Date(),
				isImage: true,
			}
			queryClient.cancelQueries({ queryKey: ['accounts', payload.id] })
			const previousAccount = queryClient.getQueryData<Account>(['accounts', payload.id])
			const currentAccount = queryClient.getQueryData<Account>(['accounts', 'current'])
			if(currentAccount) queryClient.setQueryData<Account>(['accounts', 'current'], {...currentAccount, imageFile: file})
			if(previousAccount) queryClient.setQueryData<Account>(['accounts', payload.id], {...previousAccount, imageFile: file})
			return previousAccount
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['accounts', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData<Account>(['accounts', variables.id], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}


export const useRemoveAccountImageMutation = (options?: Partial<MutationFunction<{ id: string }, Account>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['account.mutation.image.delete'],
		onMutate: (payload: { id: string }) => {
			queryClient.cancelQueries({ queryKey: ['accounts', 'current']})
			const previousAccount = queryClient.getQueryData<Account>(['accounts', 'current'])
			if(previousAccount) queryClient.setQueryData<Account>(['accounts', 'current'], { ...previousAccount, imageFile: null })
			return previousAccount
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['accounts', 'current'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData<Account>(['accounts', 'current'], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}