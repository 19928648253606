import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PersistentDataState {
	pastureRouteOverlays: any
	visiblePastureRouteOverlay: string
	overlayHeight: number
}

const initialState: PersistentDataState = {
	pastureRouteOverlays: [],
	visiblePastureRouteOverlay: '',
	overlayHeight: 0,
}

export const persistentDataSlice = createSlice({
	name: 'persistentDataSlice',
	initialState,
	reducers: {
		reset: (state) => {
			return initialState
		},
		clearPastureRouteOverlays: (state) => {
			state.pastureRouteOverlays = []
		},
		setPastureRouteOverlays: (state, action: PayloadAction<any>) => {
			state.pastureRouteOverlays = action.payload
		},
		setVisiblePastureRouteOverlay: (state, action: PayloadAction<string>) => {
			state.visiblePastureRouteOverlay = action.payload
		},
		setOverlayHeight: (state, action: PayloadAction<number>) => {
			state.overlayHeight = action.payload
		}
	},
})

export const { reset, setPastureRouteOverlays, setVisiblePastureRouteOverlay, setOverlayHeight } = persistentDataSlice.actions

export default persistentDataSlice.reducer
