import { useCallback, useMemo, useState } from 'react'
import { colors } from 'styles'
import RangeSlider from 'components/inputs/RangeSlider'
import { MDIcon } from 'components/icons'
import styled from 'styled-components/macro'
import { lighten } from 'polished'
import { useAnimalTrailLayer } from 'components/map/layers'
import moment from 'moment'
import useAnimationFrame from 'hooks/useAnimationFrame'
import { range } from 'utilities'
import { RootState, store } from 'store'
import { routeSlice } from 'store/route.slice'
import { RoundCloseButton } from 'components/buttons'
import { animalTrailSlice } from 'store/animalTrail.slice'
import { useSelector } from 'react-redux'
export const TrailTimelineControl = ({ className, value, list }: any) => {
	const { COW_TRAIL_TIME, playing, setPlaying, timelineScrubber, setTimelineScrubber, data } = useAnimalTrailLayer()

	const [timelineDate, setTimelineDate] = useState('')
	const [timelineTime, setTimelineTime] = useState('')
	const [currentIndex, setCurrentIndex] = useState<number>(0)
	
	const highlights = useSelector((state: RootState) => state.animalTrailSlice.highlights)

	const getTimestamp = useCallback(() => {
		const waypoints: any = data.waypoints
		const position = timelineScrubber
		
		if (!waypoints || !waypoints.length) return
		let time = waypoints[0].eventTimestamp
		const event = waypoints.find((e: Waypoint) => e.distanceFromStart >= position)
		const index = event ? waypoints.map((e: Waypoint) => e.distanceFromStart).indexOf(event.distanceFromStart) : -1
		const endingDistance = waypoints.reduce((total: number, currentValue: any, i: number) => {
			if (i <= index) return total + currentValue.segmentDistance
			return total
		}, 0)
		if(index >= 0) setCurrentIndex(index)
		if (index > 0) {
			time = range(0, waypoints[index].segmentDistance, new Date(waypoints[index].eventTimestamp).getTime(), new Date(waypoints[index - 1].eventTimestamp).getTime(), endingDistance - position)
		}
		const displayDate = moment(time).format('MMM DD, YYYY')
		const displayTime = moment(time).format('h:mm a')

		setTimelineDate(displayDate)
		setTimelineTime(displayTime)

	}, [data.waypoints, timelineScrubber])

	const ScrubBubble = useMemo(() => {
		if(!data?.waypoints) return null
		const iconUrl = data?.waypoints[currentIndex]?.type === 'BuzzRight' || data?.waypoints[currentIndex]?.type === 'BuzzLeft' ? 'buzz' : data?.waypoints[currentIndex]?.type === 'ShockRight' || data?.waypoints[currentIndex]?.type === 'ShockLeft' ? 'shock' : 'location'
		return ( data?.waypoints[currentIndex]?.type ?
			<ScrubBubbleWrapper>
				<ScrubBubbleImage alt={data?.waypoints[currentIndex]?.type} src={`${process.env.REACT_APP_CORRAL_APPURL}/${iconUrl}.svg`} />
			</ScrubBubbleWrapper> : null
		)
	}, [data?.waypoints, currentIndex])

	useAnimationFrame(() => {
		if (playing) getTimestamp()
	})
	
	return (
		<Container>
			<LabelWrapper>
				<Label>Time Line</Label>
				<RoundCloseButton variant='transparent' onClick={() => {
					store.dispatch(routeSlice.actions.setSnapToIndex(1))
					store.dispatch(animalTrailSlice.actions.setVisible(false))
				}} />
				
			</LabelWrapper>
			<TimeLineWrapper>
				<ButtonWrapper>
					<Button
						onClick={() => {
							setPlaying(!playing)
							// set the current time into state so it starts from it when playing resumes
							setTimelineScrubber(timelineScrubber)
						}}>
						<PlayPauseIcon color={colors.dark} name={playing ? 'pause' : 'play_arrow'} fill={1} />
					</Button>
					<InputWrapper>
						<Output>{timelineDate}</Output>
						<RangeSlider
							scrubberValue={ScrubBubble}
							{...{
								min: 0,
								max: COW_TRAIL_TIME,
								step: 'any',
								label: 'Time Line',
								id: 'cow-trail-scrubber',
								name: 'cow-trail-scrubber',
								value: timelineScrubber,
								displayValue: `${timelineDate} ${timelineTime}`,
								rangeSegments: highlights,
								onKeyDown: (e: any) => {
									//* pause and play on spacebar or enter
									if(e.key === ' ' || e.key === 'Enter') {
										e.preventDefault()
										setPlaying(!playing)
										// set the current time into state so it starts from it when playing resumes
										setTimelineScrubber(timelineScrubber)
									}
								},
								onPointerDown: () => {setPlaying(false)},
								onChange: (e: any) => {
									if (e.type === 'pointermove') return
									setTimelineScrubber(Number(e.target.value))
								},
							}}
							label={null}></RangeSlider>
						<OutputTime>{timelineTime}</OutputTime>
					</InputWrapper>
				</ButtonWrapper>
			</TimeLineWrapper>
		</Container>
	)
}
const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5em;
`
const PlayPauseIcon = styled(MDIcon)`
	font-size: 1.2em;
`
const Button = styled.button`
	all: unset;
	cursor: pointer;
	color: ${colors.dark};
	font-size: 0.7em;
	border-radius: 100%;
	padding: 0.5em;
	// align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border: 1px solid ${lighten(0, colors.dark)};
	// background: ${lighten(0, colors.dark)};
	aspect-ratio: 1 / 1;
	height: 22px;
`
const LabelWrapper = styled.div`
	display: flex;
	gap: 0.5em;
	width: 100%;
	justify-content: space-between;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
`
const Label = styled.span`
	font-size: 0.7em;
	font-weight: 600;
	color: ${colors.dark};
`
const TimeLineWrapper = styled.div`
	display: flex;
	flex-direction: column;
	// gap: 0.5em;
	justify-content: space-between;
	align-items: flex-end;
`

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Output = styled.output`
	font-size: 0.8em;
	font-weight: 600;
	text-align: right;
	white-space: nowrap;
	color: ${colors.dark};
`

const OutputTime = styled.div`
	font-size: 0.8em;
	font-weight: 600;
	color: ${colors.dark};
	text-align: right;
	white-space: nowrap;
`
const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: .5em;
`
const ScrubBubbleWrapper = styled.div`
	object-fit: contain;
	height: 100%;
	padding: 2px;
`
const ScrubBubbleImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: flex;
	align-items: center;
	justify-content: center;
`