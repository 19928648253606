import { useState, useEffect, useMemo, useCallback } from 'react'
import { FeatureCollection, Feature } from '@turf/turf'
import * as turf from '@turf/turf'
import { hexToRGB, kebabize, range } from 'utilities'
import { GeoJsonLayer, PolygonLayer, ScatterplotLayer } from '@deck.gl/layers/typed'
import { PickingInfo } from '@deck.gl/core/typed'
import { RootState, store } from 'store'
import { mapSlice } from 'store/map.slice'
import { useMap } from 'react-map-gl'
import { useNavigate, useParams } from 'react-router-dom'
import { boundarySlice } from 'pages/panels/boundaries/boundary.slice'
import { EventData } from 'mapbox-gl'
import { PathStyleExtension } from '@deck.gl/extensions/typed'
import { useSelector } from 'react-redux'
import { useGetPasturesQuery } from 'library/api/pasture'
import { useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import { useClient } from 'library/api/client'
import { useQuery } from '@tanstack/react-query'
import { useTimeoutState } from 'hooks/useTimeoutState'

export const BoundaryModifyControl = () => {
	const history = useSelector((state: RootState) => state.boundarySlice.history)
	return <button onClick={() => {
		if(history.length) {
			// TODO: currently unused, but needs to be fixed at some point and implemented
			// store.dispatch(boundarySlice.actions.setGeometry(history[history.length - 1].find((f: Feature) => f?.geometry?.type === 'Polygon').geometry))
			// store.dispatch(boundarySlice.actions.setHistory([...history.slice(0, history.length - 1)]))
		}
	}}>Undo</button>
}

export const useBoundary = () => {
	const { MapView } = useMap()
	const params = useParams()
	const navigate = useNavigate()
	const [error, setError] = useTimeoutState<string | null>(null)
	const history = useSelector((state: RootState) => state.boundarySlice.history)
	const mode = useSelector((state: RootState) => state.boundarySlice.mode)
	const edits = useSelector((state: RootState) => state.boundarySlice.feature)
	const cursor = useSelector((state: RootState) => state.mapSlice.cursor)
	const { data: pastureData, dataUpdatedAt: pastureDataUpdatedAt, isRefetching: pasturesIsRefetching } = useGetPasturesQuery()
	
	const { data: exclusionData, dataUpdatedAt: exclusionDataUpdatedAt, isRefetching: exclusionIsRefetching } = useGetPastureFeaturesQuery(undefined, {
		select: (features: Structure[]) => {
			return features.filter((f) => f.type === 'exclusion')
		}
	})

	const [hoveredFeature, setHoveredFeature] = useState<any>(null)
	
	// * new boundary, or boundary we are currently editing
	const [newBoundary, setNewBoundary] = useState<Feature[]>([])
	
	const [newBoundaryCoordinates, setNewBoundaryCoordinates] = useState<any>([])

	const [guide, setGuide] = useState<any>(null)
	
	const [hoveredNode, setHoveredNode] = useState<any>(null)

	const [dragNode, setDragNode] = useState<number | null>(null)

	// * CREATE FEATURECOLLECTION FROM API DATA
	const layerData = useMemo<FeatureCollection>(() => {
		let featureData: any = []

		if (pastureData) {
			featureData = [
				...featureData,
				...pastureData.map((pasture: Pasture) => {
					const { geometry, id, style, ...rest } = pasture
					return turf.feature(geometry, {
						...rest,
						dataUpdatedAt: pastureDataUpdatedAt,
						type: 'pasture',
						style
					}, {
						id
					})
				})
			]
		}

		if (exclusionData) {
			featureData = [
				...featureData,
				...exclusionData.map((exclusionZone) => {
					const { geometry, id, style, ...rest } = exclusionZone
					return turf.feature(geometry, {
						...rest,
						dataUpdatedAt: exclusionDataUpdatedAt,
						type: 'exclusion',
						style
					}, {
						id
					})
				})
			]
		}

		if (newBoundary) {
			featureData = [
				...featureData,
				...newBoundary
			]
		}

		if (guide) {
			featureData = [
				...featureData,
				guide
			]
		}
		// If we are modifying a polygon, don't render it normally, it will be rendered separately as newBoundary
		if (mode === 'ModifyMode') {
			featureData = featureData.filter((f: any) => f && !(String(f.id) === params?.id && kebabize(f.properties.type) === params?.type))
		}

		return turf.featureCollection(featureData) as FeatureCollection
	}, [pastureData, exclusionData, newBoundary, guide, mode, pastureDataUpdatedAt, exclusionDataUpdatedAt, params?.id, params?.type])

	const highLightLine = useMemo(() => {
		if (mode === 'ModifyMode') return []
		return layerData?.features?.filter((feature: Feature) => (feature?.geometry?.type === 'Polygon' && feature?.geometry?.coordinates.length > 0))
			.map(feature => ({...turf.buffer(feature, .005), id: feature.id, properties: feature.properties}))
	}, [mode, layerData])

	// * SET TUTORIAL BASED ON MODE
	useEffect(() => {
		if (mode === 'ModifyMode') store.dispatch(mapSlice.actions.setTutorial('MODIFY_BOUNDARY'))
	}, [mode])

	const handleDrawPolygonModeClick = useCallback((event: EventData, info?: PickingInfo) => {
		if(mode !== 'DrawPolygonMode') return
		/* 
			If there is a polygon being added ontop of another polygon, this runs twice, once for the map click event and once for the layer click event
			We need it on both layers to see when the user clicks on the starting point to close the polygon
			There isn't an id on the boundary that is currently being created, so we can filter the extra calls by checking in an id exists on the layer
		*/ 
		if(info?.object?.properties?.name === 'guide') return

		// Since this function handles both map click events and layer click events, we need to check if the event is a map click event or a layer click event
		// We know it is a layer event if it recieves the pickingInfo object
		const eventCoords = info ? info.coordinate : event.lngLat ? [event.lngLat.lng, event.lngLat.lat] : null
		const coords = [...newBoundaryCoordinates, eventCoords]
		
		// If only one coordinate has been added, create a point
		if(coords.length === 1) {
			setNewBoundary([turf.point(eventCoords as turf.Position, { ...edits.properties, index: 0 })])
			setNewBoundaryCoordinates(coords)
		}
		// If the last coordinate is the same as the first, create a polygon
		else if(coords.length >= 4 && info?.object && info?.object?.properties?.index === 0) {
			const line = turf.lineString(newBoundaryCoordinates)
			// Handle the case where the user tries to kink the boundary
			if(turf.kinks(line).features.length > 0) {
				console.error('Invalid Polygon')
				setError('Boundary lines may not intersect', { timeout: 2000 })
				setGuide(null)
				return
			}
			const polygon = turf.lineStringToPolygon(line, { properties: edits.properties }) as turf.Feature<turf.Polygon, turf.Properties>
			store.dispatch(boundarySlice.actions.setGeometry(turf.getGeom(polygon)))
			setNewBoundary([])
			setNewBoundaryCoordinates([])
			store.dispatch(boundarySlice.actions.setMode('ModifyMode'))
			store.dispatch(mapSlice.actions.setTutorial('MODIFY_BOUNDARY'))
		}
		// If the last coordinate is the same as the first, create a polygon
		else {
			// Handle the case where the user tries to kink the boundary
			const line = turf.cleanCoords(turf.lineString(coords, {...edits.properties}))
			if(turf.kinks(line).features.length > 0) {
				console.error('Invalid Polygon')
				setError('Boundary lines may not intersect', { timeout: 2000 })
				setGuide(null)
				return
			}
			if (coords.length >= 1) {
				store.dispatch(mapSlice.actions.setTutorial('FINISH_DRAWING_BOUNDARY'))
			}
			
			const linePoints = turf.getCoords(line).map((coord: number[], index: number) => turf.point(coord, { ...edits.properties, index: index }))
			setNewBoundary([line, ...linePoints])
			// setNewBoundary([turf.lineString(coords, {...edits.properties}), ...linePoints])
			
			setNewBoundaryCoordinates(coords)
		}
		
	}, [newBoundaryCoordinates, edits.properties, mode, setError])

	const addPointOnLine = useCallback((coordinates: number[], line: turf.Feature<turf.LineString>) => {
		if(coordinates && line) {
			const pointInLine = turf.booleanPointOnLine(turf.point(coordinates), line, { epsilon: 0.0005 })
			if(!pointInLine) {
				store.dispatch(mapSlice.actions.setCursor('auto'))
				return null
			}
			// store.dispatch(mapSlice.actions.setCursor('move'))
			const point = turf.point(coordinates)
			const nearestPoint = turf.nearestPointOnLine(line, point)
			if(!nearestPoint?.properties?.location) return null
			const pointAlong = turf.along(line, nearestPoint?.properties?.location)
			const newLine = turf.clone(line)
			if(nearestPoint?.properties?.index === undefined) return null
			newLine.geometry.coordinates.splice(nearestPoint?.properties?.index + 1, 0, pointAlong.geometry.coordinates)
			newLine.properties = {...line.properties, dashed: true }
			return { line: newLine, pointIndex: nearestPoint?.properties?.index + 1}
		}
	}, [])

	const getPointsFromPolygon = useCallback((polygon: turf.Feature<turf.Polygon>) => {
		if(polygon?.geometry?.type !== 'Polygon') return []
		return turf.explode(turf.cleanCoords(turf.polygonToLineString(polygon))).features.map((feature, index) => ({...feature, properties: {index: index }}))
	}, [])

	// * Overlapping boundaries helper method
	// Because boundaries can overlap, we need to figure out which boundary was selected
	const getLayeredFeature = useCallback((info: PickingInfo) => {
		// We do this by getting all boundaries that contain the clicked coordinate
		if(!info?.coordinate) return null
		
		const boundariesInCoord = layerData.features.filter((feature) => {
			return feature?.geometry?.type === 'Polygon' && feature?.geometry?.coordinates.length > 0 && turf.booleanPointInPolygon(turf.point(info.coordinate as turf.Position), feature as Feature<turf.Polygon>)
		})
		
		// then filtering out only the boundaries that are FULLY contained by another boundary
		const boundariesContainedByAnotherBoundary = boundariesInCoord.reduceRight((total: Feature[], currentValue: Feature, currentIndex: number, arr: Feature[]) => {
			return (currentIndex && (turf.booleanContains(arr[currentIndex - 1], currentValue) || turf.booleanOverlap(arr[currentIndex - 1], currentValue))) ? [...total, currentValue] : total
		}, [])
		// If there are boundaries that are fully contained, assume the user is attempting to select one of those. Otherwise, use the array of boundaries that contain the clicked coordinate
		const boundaries = boundariesContainedByAnotherBoundary.length ? boundariesContainedByAnotherBoundary : boundariesInCoord
		
		// Check the URL params to see if/what boundary is currently selected, so we can pick the next boundary in the array
		const boundaryIndex = boundaries.findIndex((boundary) => boundary?.id === Number(params?.id) && kebabize(boundary?.properties?.type) === params?.type)
		
		// Loops back to the first boundary if the last boundary is selected
		let selected = boundaries[boundaryIndex < boundaries.length - 1 ? boundaryIndex + 1 : 0]
		if (!selected) {
			selected = info.object
		}

		return selected
	}, [layerData, params])
	
	// * Close polygon helper method
	const handleClosePolygon = useCallback((info: PickingInfo) => {
		if (mode !== 'DrawPolygonMode') return
		if(info?.object?.properties?.index === 0 && hoveredNode !== 0) setHoveredNode(0)
		else if(info?.object?.properties?.index !== 0 && hoveredNode !== null) setHoveredNode(null)
	}, [hoveredNode, mode])

	// ** Event Handlers
	// ** CLICK EVENTS
	// Listen for map click events when drawing a polygon
	// Since events are handled for layers only fire when interacting with a layer, we need to listen for events on the map itself when drawing boundaries
	useEffect(() => {
		if(!MapView) return
		function handleMapClickEvent (event: EventData) {
			handleDrawPolygonModeClick(event)
		}
		MapView?.on('click', handleMapClickEvent)
		return () => { MapView?.off('click', handleMapClickEvent) }
	}, [mode, handleDrawPolygonModeClick, MapView])

	const handleViewModeClick = useCallback((info: PickingInfo) => {
		if (mode !== 'ViewMode') return
		if (store.getState().pastureRouteSlice.mode === 'SelectPastureMode') return // If we are selecting a pasture route

		if (!info.object || !info.coordinate) return
		const boundary = getLayeredFeature(info)

		// Check to make sure we aren't navigating to the route they are currently on
		if(kebabize(boundary?.properties?.type) !== params?.type || boundary?.id !== Number(params.id)) {
			navigate(`/${kebabize(boundary?.properties?.type)}/${boundary?.id}`)
		}
	}, [mode, params, navigate, getLayeredFeature])

	const handleSelectPastureModeClick = useCallback((event: EventData, info?: PickingInfo) => {
		if (mode !== 'SelectFeatureMode') return
		store.dispatch(boundarySlice.actions.set(info?.object))
	}, [mode])

	// Line Guide
	// Listen for mouse move events on the map to display a line guide when drawing a polygon
	useEffect(() => {
		function handleMapMouseMove(event: EventData) {
			if(mode === 'DrawPolygonMode') {
				const coords = [event.lngLat.lng, event.lngLat.lat]
				if(coords && newBoundaryCoordinates.length > 0) {
					setGuide(
						turf.lineString(
							[newBoundaryCoordinates[newBoundaryCoordinates.length - 1], coords],
							{
								dashed: true,
								name: 'guide',
								style: edits?.properties?.style,
								type: ''
								// ...edits.properties
							}
						)
					)
				}
			}
			// Hide the guide if we're not drawing a polygon, set to null only if it's not already null
			else if(guide) setGuide(null)
		}
		MapView?.on('mousemove', handleMapMouseMove)
		return () => { MapView?.off('mousemove', handleMapMouseMove) }
	}, [MapView, newBoundaryCoordinates, mode, guide, edits])

	// ** HOVER EVENTS
	const handleViewModeHover = useCallback((info: PickingInfo) => {
		if(mode !== 'ViewMode') return
		const boundary = getLayeredFeature(info)
		store.dispatch(mapSlice.actions.setCursor(info?.object?.id ? 'pointer' : 'default'))
		if(info?.object?.id && info?.object?.id !== hoveredFeature) setHoveredFeature(boundary)
		else if(!info?.object?.id && hoveredFeature !== null) setHoveredFeature(null)

	}, [mode, hoveredFeature, getLayeredFeature])

	const handleDrawPolygonModeHover = useCallback((info: PickingInfo) => {
		if(mode !== 'DrawPolygonMode') return
		handleClosePolygon(info)
		if(cursor !== 'crosshair') store.dispatch(mapSlice.actions.setCursor('crosshair'))	
	}, [mode, cursor, handleClosePolygon])

	const handleModifyModeHover = useCallback((info: PickingInfo) => {
		// Not in Modify Mode, do nothing
		if (mode !== 'ModifyMode') return
		if(!info.coordinate) {
			if(hoveredNode !== null) setHoveredNode(null)
			return
		}
		// If hovering over a point, remove the guide node and highlight the modifying point
		if(info.object?.geometry?.type === 'Point' || !info) {
			// Make sure it isn't already a guide node, otherwise it will flicker from being added and removed indefinitely
			if(!info.object.properties.guideNode) {
				store.getState().mapSlice.cursor !== 'grab' && store.dispatch(mapSlice.actions.setCursor('grab'))
				const polygon = JSON.parse(JSON.stringify(edits))
				polygon.properties.dashed = true
				// We don't want an ID of the original boundary on the new feature, because when in Modify Mode, the original boundary is being filtered out to instead show it in it's modifying state
				delete polygon.id
				setNewBoundary([polygon, ...getPointsFromPolygon(polygon as turf.Feature<turf.Polygon>)])
			}
			if(info?.object?.properties?.index !== undefined) setHoveredNode(info.object.properties.index)
			else setHoveredNode(null)
			return
		}

		// If not a point, we only want to procceed if they are hover over the polygon
		if(info?.object?.geometry?.type !== 'Polygon') return
		store.dispatch(mapSlice.actions.setCursor('grab'))
		setHoveredNode(null)

		// Since the newBoundary also contains points, we need to filter those out and only get the polygon
		const polygon = newBoundary.find((f: any) => f?.geometry.type === 'Polygon')

		// If there is no polygon, do nothing
		if(!polygon) return

		// Add a point to to the line to indicate where the new point will be added
		const updatedFeature = addPointOnLine(info?.coordinate as number[], turf.polygonToLineString(polygon as Feature<turf.Polygon>) as turf.Feature<turf.LineString>)
		if(updatedFeature) {
			const polygon = JSON.parse(JSON.stringify(edits))
			polygon.properties = {...polygon.properties, dashed: true }
			// We don't want an ID of the original boundary on the new feature, because when in Modify Mode, the original boundary is being filtered out to instead show it in it's modifying state
			delete polygon.id
			/* 
				We want to make sure the new point's index isn't being added to the properties of the new point, since that is being used to indicate whether a current point is being dragged/hovered over 
				Otherwise when the DragStart event occurs, the indexes wont match up and it will start dragging the wrong point.
				Instead we add a guideNode property to indicate that it is a guide node
			*/
			setNewBoundary([polygon, ...updatedFeature?.line?.geometry.coordinates.map((coord: number[], index: number) => turf.point(coord, { index: index !== updatedFeature.pointIndex ? index: undefined, guideNode: index === updatedFeature.pointIndex }))])
		}
		
	}, [mode, addPointOnLine, newBoundary, edits, getPointsFromPolygon, hoveredNode])

	// Save the state of the boundary before modifying it so it can return to it if the user tries to kink the boundaries
	const [beforeModifyState, setBeforeModifyState] = useState<any | null>(null)
	// ** DRAG EVENTS
	// * DRAG START EVENT
	const handleModifyModeDragStart = useCallback((info: PickingInfo) => {

		// If not in modify mode, do nothing
		if(mode !== 'ModifyMode' || !info?.coordinate) return

		store.dispatch(mapSlice.actions.setCursor('grabbing'))
		// We want to prevent any unintended modifications to the boundary when outside the event, so we check if the drag start event is within the boundary
		const polygon = newBoundary.find((f: Feature) => f?.geometry.type === 'Polygon')
		setBeforeModifyState(newBoundary)
		if(!polygon) return
		// Add a buffer to the boundary to provide some mercy when selecting near the edges of the polygon
		const bufferedBoundary = turf.buffer(polygon, .05)
		const inBounds = turf.booleanPointInPolygon(turf.point(info?.coordinate as turf.Position), bufferedBoundary)
		if(!inBounds) return

		// Before we start dragging, we want to save the current state of the boundary so the user can undo any changes they make
		store.dispatch(boundarySlice.actions.setHistory([...history, newBoundary] as Feature[]))

		// Disable map panning while dragging
		MapView?.getMap().dragPan.disable()

		// If we're dragging a point, set the drag node to the index of the point
		if(info?.object?.properties?.index !== undefined) {
			setDragNode(info?.object?.properties?.index)
		} else {
			const polygon = newBoundary.find((f: any) => f?.geometry.type === 'Polygon')
			if(!polygon) return
			// If we're dragging a line, add a point to the line and set the drag node to the index of the new point
			const updates = addPointOnLine(info?.coordinate as number[], turf.polygonToLineString(polygon as turf.Feature<turf.Polygon>) as turf.Feature<turf.LineString>)
			if(updates) {
				setDragNode(updates?.pointIndex)
				setNewBoundary([turf.lineStringToPolygon(updates?.line), ...updates?.line?.geometry.coordinates.map((coord: number[], index: number) => turf.point(coord, { index: index }))])
			}
		}
	}, [newBoundary, addPointOnLine, MapView, mode, history])

	// * DRAG EVENT
	const handleModifyModeDrag = useCallback((info: PickingInfo) => {
		// If not in modify mode, isn't dragging a node, or doesn't have a coordinate, then do nothing
		if(mode !== 'ModifyMode' || dragNode === null || !info.coordinate) return
		const point = turf.point(info.coordinate as turf.Position, info?.object?.properties)
		const polygon = newBoundary.find((f: any) => f?.geometry.type === 'Polygon')
		if(!polygon) return
		const line = turf.polygonToLineString(turf.clone(polygon)) as turf.Feature<turf.LineString>
		// Since polygons begin and end on the same point, we need to clean the coordinates to remove the extra node before exploding the line into points
		const points = turf.explode(turf.cleanCoords(line)).features.map((feature, index) => ({...feature, properties: {index: index}}))
		const isStartingPoint = dragNode === 0
		// We need to handle the first point differently because it represents the beginning and the end of the polygon
		if(isStartingPoint) {
			points.splice(0, 1, {...point, properties: {...point.properties, index: 0}})
			// Modify the first and last coordinates of the line, otherwise the polygon will have a gap and will add an extra point on every drag event when the line is converted to a polygon
			line.geometry.coordinates.splice(0, 1, info.coordinate as number[])
			line.geometry.coordinates.splice(line.geometry.coordinates.length - 1, 1, info.coordinate as number[])
		} else {
			points.splice(dragNode, 1, {...point, properties: {...point.properties}})
			line.geometry.coordinates.splice(dragNode, 1, info.coordinate as number[])
		}
		store.dispatch(boundarySlice.actions.setGeometry({type: 'Polygon', coordinates: turf.lineStringToPolygon(line).geometry.coordinates}))
		setNewBoundary([turf.lineStringToPolygon(line), ...points])
	}, [dragNode, newBoundary, mode])
	
	// * DRAG END EVENT
	const handleModifyModeDragEnd = useCallback((info: PickingInfo) => {
		if (mode !== 'ModifyMode') return
		// Handle the case where the user tries to kink the boundary
		const polygon = newBoundary?.find((f: any) => f?.geometry.type === 'Polygon')
		if(polygon && polygon?.geometry?.type === 'Polygon') {
			
			const line = turf.polygonToLineString(polygon as turf.Feature<turf.Polygon>) as turf.Feature<turf.LineString>
			if(turf.kinks(line).features.length > 0) {
				setError('Boundary lines may not intersect', { timeout: 2000 })

				const beforePolygon = beforeModifyState?.find((f: any) => f?.geometry.type === 'Polygon')
				store.dispatch(boundarySlice.actions.setGeometry({type: 'Polygon', coordinates: beforePolygon.geometry.coordinates}))
				setNewBoundary(beforeModifyState)
				return
			}
		}
		// Re-enable map panning when dragging is finished
		MapView?.getMap().dragPan.enable()
		store.dispatch(mapSlice.actions.setCursor('default'))
		setDragNode(null)
	}, [MapView, mode, newBoundary, beforeModifyState, setError])

	// Read target boundary state from redux for creating or editing
	useEffect(() => {
		if(mode === 'ModifyMode' && edits.geometry?.type === 'Polygon') {
			const polygon = turf.polygon(edits.geometry?.coordinates as turf.Position[][], {dashed: true, ...edits.properties})
			const line = turf.polygonToLineString(polygon, {properties: {...edits.properties}})
			const points = turf.explode(turf.cleanCoords(line)).features.map((feature, index) => ({...feature, properties: {index: index}}))
			setNewBoundary([polygon, ...points])
		} else {
			setNewBoundary([])
			setNewBoundaryCoordinates([])
			setGuide(null)
		}
	}, [mode, edits])

	// ** MAIN POLYGON LAYER
	const layer = useMemo(() => new GeoJsonLayer({
		id: 'geojson-ranch-map-layer',
		data: layerData,
		dataComparator: (newData: any, oldData: any) => {
			if (oldData.features.length === 0 && newData.features.length > 0) {
				const [minLng, minLat, maxLng, maxLat] = turf.bbox(newData)
				// I hate how this zoom feels but we should consider adding zoom in some other way maybe??
				// MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
			}
			return oldData === newData
		},
		visible: true,
		pickable: true,
		stroked: true,
		getFillColor: (d: any): [number, number, number, number] => {
			if (d.properties?.style?.opacity === 0) return [255, 255, 255, 0]
			const fillOpacity = Math.round(255 * ((d.properties?.style?.opacity ? d.properties?.style?.opacity : 0.25)))
			
			// Make the fill color more transparent as the zoom level increases
			const opacity = range(17, 17.5, fillOpacity, 0, MapView?.getZoom() || 0)
			return d.properties?.style?.backgroundColor ? hexToRGB(d.properties?.style?.backgroundColor, opacity) : [255, 255, 255, opacity]
			
		},
		getLineWidth: (d: any) => {
			// if dashed, it's a guide line, so make it thicker
			const outlineWidth = d?.properties.dashed ? 4 : d?.properties?.style?.outlineWidth ? d?.properties?.style?.outlineWidth : 1
			// increase the line width as the zoom level increases
			return range(17, 17.5, outlineWidth, 5, MapView?.getZoom() || 0)
		},
		getLineColor: (d: any) => {
			// const isSelected = false
			// const f = isSelected ? edits : d
			return d.properties?.style?.outlineColor ? hexToRGB(d.properties?.style?.outlineColor, 255) : [255, 255, 255, 255]
		},
		
		getDashArray: (d: any)  => d?.properties?.dashed ? [3, 2] : null,
		dashJustified: true,
		dashGapPickable: true,
		lineWidthUnits: 'pixels',
		updateTriggers: {
			getDashArray: [newBoundary, params, mode, edits, MapView?.getZoom(), hoveredFeature, guide ],
			getFillColor: [newBoundary, params, mode, edits, MapView?.getZoom(), hoveredFeature, guide ],
			getLineColor: [newBoundary, params, mode, edits, hoveredFeature, guide],
			getLineWidth: [newBoundary, params, mode, edits, MapView?.getZoom(), hoveredFeature, guide ],
		},
		extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
		_subLayerProps: {
			'points-circle': {
				// Styling for editHandles goes here
				type: ScatterplotLayer,
				stroked: true,
				getLineWidth: 1,
				radiusMinPixels: 4,
				radiusUnits: 'pixels',
				getFillColor: (d: any) => {
					const opacity = d?.geometry?.type === 'Point' && hoveredNode === d?.properties?.index ? 255 : 128
					return hexToRGB(edits?.properties?.style?.backgroundColor, opacity)
				},
				getRadius: (d: any) => {
					if(d?.__source?.object?.geometry?.type === 'Point' && hoveredNode === d?.__source?.object?.properties?.index) {
						return 8
					}
					return 5
				},
				getLineColor: (d: any) => hexToRGB(edits?.properties?.style?.outlineColor),
				updateTriggers: {
					getRadius:    [params, mode, edits, guide, hoveredNode, newBoundary],
					getFillColor: [params, mode, edits, guide, hoveredNode, newBoundary],
					getLineColor: [params, mode, edits, guide, hoveredNode, newBoundary],
					getLineWidth: [params, mode, edits, guide, hoveredNode, newBoundary],
					getOpacity:   [params, mode, edits, guide, hoveredNode, newBoundary],
				},
				transitions: {
					getFillColor: {
						type: 'spring',
						stiffness: 0.1,
						damping: 0.3,
					},
					getRadius: {
						type: 'spring',
						stiffness: 0.1,
						damping: 0.3,
						// duration: 500,
		
					}
				},
			},
		},
		pickingDepth: 5,
		onHover: (info: PickingInfo, event: any) => {
			handleDrawPolygonModeHover(info)
			handleModifyModeHover(info)
			handleViewModeHover(info)
		},
		onDragStart: (info: PickingInfo, event: any) => {
			handleModifyModeDragStart(info)
		},
		onDrag: (info: PickingInfo, event: any) => {
			handleModifyModeDrag(info)
		},
		onDragEnd: (info: PickingInfo, event: any) => {
			handleModifyModeDragEnd(info)
		},
		onClick: (info: PickingInfo, event: any) => {
			if (store.getState().pastureRouteSlice.mode === 'SelectPastureMode') return // If we are selecting a pasture route
			handleDrawPolygonModeClick(event, info)
			handleViewModeClick(info)
			handleSelectPastureModeClick(event, info)
		},
	}), [MapView, edits, guide, handleDrawPolygonModeClick, handleDrawPolygonModeHover, handleModifyModeDrag, handleModifyModeDragEnd, handleModifyModeDragStart, handleModifyModeHover, handleSelectPastureModeClick, handleViewModeClick, handleViewModeHover, hoveredFeature, hoveredNode, layerData, mode, newBoundary, params])

	

	// ** HIGHLIGHT LINE LAYER
	const highLightlineLayer = new PolygonLayer({
		id: 'boundary-outline-layer',
		data: highLightLine,
		lineWidthUnits: 'pixels',
		pickable: false,
		visible: true,
		getFillColor: [0, 0, 0, 0],
		getPolygon: (d: any) => d.geometry.coordinates,
		// getLineColor: (d: any) => params?.id === String(d?.id) ? [0, 0, 255, 255] : [0, 0, 255, 0],
		getLineColor: (d: any) => {
			if(params?.id === String(d?.id) && params?.type === kebabize(d?.properties?.type)) return [255, 255, 255, 200]
			if(hoveredFeature?.id === d?.id && hoveredFeature?.properties?.type === d.properties.type ) return [255, 255, 255, 150]
			return [255, 255, 255, 0]
		},
		getLineWidth: (d: any) => {
			if(params?.id === String(d?.id) && params?.type === kebabize(d?.properties?.type)) return 4
			if(hoveredFeature?.id === d?.id && hoveredFeature?.properties?.type === d.properties.type) return 2
			return 0
		},
		// stroked: true,
		// transitions: {
		// 	getLineColor: {
		// 		type: 'spring',
		// 		stiffness: 0.1,
		// 		damping: 0.3,
		// 	},
		// 	getLineWidth: {
		// 		type: 'spring',
		// 		stiffness: 0.1,
		// 		damping: 0.3,
		// 	}
		// },
		updateTriggers: {
			getLineColor: [params, mode, hoveredFeature],
			getLineWidth: [params, mode, hoveredFeature],
		},
	})

	return {
		error,
		layer: [layer, highLightlineLayer],
		data: layerData,
	}
}