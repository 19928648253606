import { useGetPastureRoutesQuery } from 'library/api/pastureRoute'
import { useEffect, useMemo, useState } from 'react'
import { transparentize, readableColor, animation } from 'polished'
import styled from 'styled-components/macro'
import LoadingSpinner from 'components/LoadingSpinner'
import { useGetPasturesQuery } from 'library/api/pasture'
import { useGetHerdsQuery } from 'library/api/herd'
import { useGetAnimalsQuery } from 'library/api/animal'
import { RootState, store } from 'store'
import { useSelector } from 'react-redux'
import { persistentDataSlice } from 'store/persistentData.slice'

export const PastureRoutesToolbar = () => {
	const { data: routes, isSuccess: getRoutesIsSuccess, isLoading: getRoutesIsLoading } = useGetPastureRoutesQuery()
	const { data: pastures, isSuccess: getPasturesIsSuccess, isLoading: getPasturesIsLoading } = useGetPasturesQuery()
	const { data: herds, isSuccess: getHerdsIsSuccess, isLoading: getHerdsIsLoading } = useGetHerdsQuery()
	const { data: animals, isSuccess: getAnimalsIsSuccess } = useGetAnimalsQuery()

	const pastureRouteOverlays = useSelector((state: RootState) => state.persistentDataSlice.pastureRouteOverlays)
	const visiblePastureRouteOverlay = useSelector((state: RootState) => state.persistentDataSlice.visiblePastureRouteOverlay)
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)

	// fetch our worker
	const worker = useMemo(
		() => new Worker(new URL('../../webWorkers/routeProgressWorker.ts', import.meta.url)),
		[]
	)

	// When worker is done, send data to store
	useEffect(() => {
		if (window.Worker && worker) {
			worker.onmessage = ({ data }) => {
				store.dispatch(persistentDataSlice.actions.setPastureRouteOverlays(data))
			}
		}
		return () => {
			worker.terminate()
		}
	}, [worker, animals, herds])

	const filteredOverlayData = useMemo(() => {
		if (pastureRouteOverlays && herds) {
			return pastureRouteOverlays.filter((data: any) => {
				const herd = herds.find((h) => h.id === data.id)
				return !!herd?.pastureRouteId
			})
		}
		return []
	}, [herds, pastureRouteOverlays])

	// Once all the data is loaded, send it to the worker
	useEffect(() => {
		if (routes && pastures && herds && animals) {			
			worker.postMessage({routes, pastures, herds, animals})
		}
	}, [getRoutesIsSuccess, getPasturesIsSuccess, getHerdsIsSuccess, getAnimalsIsSuccess, routes, pastures, herds, animals, worker])

	return (isMobile ? <></> : <>
		<RoutesListWrapper>
			<>
				{filteredOverlayData ?
					(filteredOverlayData.map((route: any) => {	
						const colorJson = route?.style?.color ? route.style.color : '#222222'
						
						return (<div
							key={route?.herdId}
							onClick={() => {
								const newValue = visiblePastureRouteOverlay === route?.herdId ? '' : route?.herdId
								store.dispatch(persistentDataSlice.actions.setVisiblePastureRouteOverlay(newValue))
							}}
						>
							<RouteIcon
								$backgroundColor={colorJson}
								$enabled={!!(visiblePastureRouteOverlay === route?.herdId)}
							>
								<span>{route?.progress ? `${route.progress}%` : 'N/A'}</span>
							</RouteIcon>
						</div>)
					}))
					:
					<LoadingSpinner />
				}
			</>
		</RoutesListWrapper>
	</>)
}

const RoutesListWrapper = styled.div`
	display: flex;
	justify-self: flex-start;
`

export const RouteIcon = styled.div<{ $backgroundColor?: string; $enabled?: boolean }>`
    padding: 0;
	padding-top: 6px;
	aspect-ratio: 1 / 1;
	font-weight: bold;
	font-size: 0.9rem;
	height: 100%;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
	margin-right: 6px;
    text-align: center;
	// box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	cursor: pointer;
	&:hover {
		// box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
	border: ${props => props.$enabled ? '1px dashed green' : ''};
	// color: ${props => props.$enabled ? 'black' : transparentize(.5, 'black')};
	color: ${props => props.$backgroundColor ? (props.$enabled ? readableColor(props.$backgroundColor) : transparentize(.7, readableColor(props.$backgroundColor))) : ''};
    background-color: ${props => props.$backgroundColor ? (props.$enabled ? transparentize(.5, props.$backgroundColor) : transparentize(.6, props.$backgroundColor)) : '#ffffff'};
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`
