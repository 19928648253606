import { CardItem } from 'components/cards'
import { lighten } from 'polished'
import styled from 'styled-components/macro'
import { colors } from 'styles'

export const DetailsItem = ({ label, tooltip, value, link, children }: any) => (
	<Item label={label} tooltip={tooltip} link={link}>
		<DetailsItemBody>{children}</DetailsItemBody>
	</Item>
)

const Item = styled(CardItem)`
	border-bottom: 1px solid ${lighten(0.15, colors.light)};
	padding-bottom: 0.5em;
	margin-bottom: 0;
	width: 100%;
	&:not(:first-child) {
		padding-top: 0.5em;
	}
	&:last-child {
		border-bottom: 0px solid ${lighten(0.15, colors.light)};
		padding-bottom: 0;
	}
`

export const DetailsHeader = styled.div`
	font-size: 1em;
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
`

export const DetailsItemBody = styled.div`
	font-size: 0.75em;
	width: 100%;
`

export const DetailsBody = styled.div`
	padding: 0.5em;
	background: ${colors.background};
	border-radius: var(--border-radius);
	width: 100%;
	${Item} {
		border-bottom: 1px solid ${lighten(0.15, colors.light)};
		padding-bottom: 0.5em;
		margin-bottom: 0;
		&:not(:first-child) {
			padding-top: 0.5em;
		}
		&:last-child {
			border-bottom: 0px solid ${lighten(0.15, colors.light)};
			padding-bottom: 0;
		}
	}
`
