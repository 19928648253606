import { useEffect, useState } from 'react'
import { Input } from 'components/inputs'
import { CardAction, CardActions } from 'components/cards'
import Loading from 'components/Loading'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateHerdMutation, useUpdateHerdMutation } from 'library/api/herd'
import { RootState, store } from 'store'
import LoadingSpinner from 'components/LoadingSpinner'
import { herdSlice } from './herd.slice'
import { useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { Herd } from 'library/models'
import { useGetHerdQuery } from 'library/api/herd'


export const HerdForm = () => {
	const { id } = useParams<{id: string}>()
	const navigate = useNavigate()
	// const edits = useSelector((state: RootState) => state.herdSlice.herd)

	const { data: herd } = useGetHerdQuery({ id }, { enabled: !!id })

	const { mutate: updateHerd } = useUpdateHerdMutation()
	const { mutate: createHerd } = useCreateHerdMutation()
	
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [formData, setFormData] = useState(new Herd({ new: true }))
	
	useEffect(() => {
		if (herd && formData.new) setFormData(herd)
	}, [formData.new, herd])

	useEffect(() => {
		store.dispatch(herdSlice.actions.setHerd(formData))
	}, [formData])

	return (
		<Form
			onSubmit={async e => {
				e.preventDefault()

				if (formData.new) {
					createHerd(formData)
				} else {
					updateHerd(formData)
				}
				store.dispatch(herdSlice.actions.clear())
				navigate(`/herd/${formData.id}`)
			}}>
			
			<>
				<Input
					label={'Name'}
					type={'text'}
					value={formData.name}
					maxLength={28}
					onChange={(e: any) => {
						setFormData({ ...formData, name: e.target.value })
					}}
				/>
				<CardActions>
					<CardAction type={'submit'} disabled={isSubmitting}>
						{isSubmitting ? <LoadingSpinner/> : 'Save'}
					</CardAction>
					<CardAction
						type={'button'}
						variant={isSubmitting ? 'dark' : 'danger'}
						onClick={() => {
							navigate(-1)
						}}
						disabled={isSubmitting}
					>
						Cancel
					</CardAction>
				</CardActions>
			</>
			
		</Form>
	)
}

const Form = styled.form`
	padding: 1em;
`
const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Label = styled.label<{ $hideLabel?: boolean, disabled?: boolean }>`
	font-size: .8em;
	color: ${transparentize(0.3, colors.dark)};
	font-weight: 600;
	transition: .3s ease all;
	// position: absolute;
	top: 0;
	left: 0;
	${({ disabled }: { disabled?: boolean }) => disabled ? 'padding-left: .7em;' : null}
	${props => (props.$hideLabel ? 'opacity: 0; pointer-events: none;' : 'transform: translateY(0%);')}
`