import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateUser } from 'library/models/user.model'
import { Address } from 'library/models/address.model'
import { CreateRanch } from 'library/models/ranch.model'
import { formatPhoneNumber } from 'utilities'
import * as uuid from 'uuid'
import { CreateAccount } from 'library/models/account.model'
interface CreateAccountState extends CreateAccount {
	image: ImageFile
}
const initialState: CreateAccountState = {
	id: uuid.v4().toString(),
	name: '',
	phone: '',
	user: new CreateUser(),
	address: new Address(),
	image: {
		fileName: '',
		contentType: '',
		data: '',
		width: 0,
		height: 0,
		fileSizeKb: 0,
		added: new Date(),
		isImage: true,
		id: '',
	},
	ranch: new CreateRanch({address: new Address(), addressSameAsAccount: true})
}

export const createAccountSlice = createSlice({
	name: 'createAccountSlice',
	initialState,
	reducers: {
		reset: (state) => {
			state.id = uuid.v4().toString()
			state.address = initialState.address
			state.user = initialState.user
			state.name = initialState.name
			state.phone = initialState.phone
			state.ranch = initialState.ranch
		},
		setAccount: (state, action: PayloadAction<CreateAccount>) => {
			state.id = action.payload.id || uuid.v4().toString()
			state.address = action.payload.address
			state.user = action.payload.user
			state.name = action.payload.name
			state.phone = action.payload.phone
			state.user.phone = action.payload.phone
			state.ranch = action.payload.ranch
		},
		setImage: (state, action: PayloadAction<ImageFile>) => {
			state.image = action.payload
		},
		setName: (state, action: PayloadAction<string>) => {
			state.name = action.payload
		},
		setPhone: (state, action: PayloadAction<string>) => {
			state.phone = formatPhoneNumber(action.payload)
			state.user.phone = formatPhoneNumber(action.payload)
		},
		setUser: (state, action: PayloadAction<CreateUser>) => {
			state.user = action.payload
		},
		setAddress: (state, action: PayloadAction<Address>) => {
			state.address = action.payload
		},
		setRanch: (state, action: PayloadAction<CreateRanch>) => {
			state.ranch = action.payload
		},
	},
})

export const { reset, setAccount, setPhone, setName, setUser, setAddress, setRanch, setImage } = createAccountSlice.actions

export default createAccountSlice.reducer
