import styled from 'styled-components/macro'
import { MDIcon } from 'components/icons'
import React, { useState } from 'react'


interface TooltipInfo {
	className?: any
	text?: string
}

const TooltipWrapper = ({ className, text }: TooltipInfo) => {
	let timeout: NodeJS.Timeout | undefined

	const [active, setActive] = useState<boolean>(false)

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true)
		}, 400)
	}

	const hideTip = () => {
		clearInterval(timeout)
		setActive(false)
	}

	return (
		<span
			className={className}
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
			onClick={active ? hideTip : showTip}
		>
			<MDIcon
				size={'sm'}
				style={{ position: 'absolute', transform: 'scale(.65)', bottom: '-6px'}}
				name={active ? 'radio_button_checked' : 'info'}
			/>
			{active && (
				<TooltipInner>
					{text}
				</TooltipInner>
			)}
		</span>
	)
}

export const Tooltip = styled(TooltipWrapper)`
	font-size: 0.6em;
	color: #666;
	position: relative;
`

const TooltipInner = styled.div`
	font-size: 3em;
	width: 160px;
	position: absolute;
	border-radius: 4px 4px 4px 0px;
	left: 50%;
	transform: translate(12%, -100%);

	padding: 6px;
	color: white;
	background: #666;
	font-size: 10px;
	font-family: sans-serif;
	line-height: 1;
	z-index: 100;
`
