import styled from 'styled-components/macro'
import { colors, device } from 'styles'

export const Container = styled.div`
    padding: 2em;
    @media (${device.max.tablet}) {
        padding: .5em 1em 1em 1em;
    }
    h1 {
        font-size: 1.5em;
        font-weight: 400;
        padding:.5em 0;
        color: ${colors.dark};
    }
    h2 {
        font-size: 1.25em;
        font-weight: 600;
        color: ${colors.dark};
        margin-bottom: 1em;
    }
    h3 {
        font-size: 1.05em;
        font-weight: 600;
        color: ${colors.dark};
        margin-bottom: 1em;
    }
`