import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetAccountQuery } from 'library/api/account'
import { UnauthenticatedTemplate, AuthenticatedTemplate, useIsAuthenticated, useMsalAuthentication, useMsal, MsalAuthenticationTemplate } from '@azure/msal-react'
import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro'
import Loading from 'components/Loading'
import { Notification, Notifications } from 'components/Notifications'
import { Offline } from 'react-detect-offline'
import { ReactComponent as NotificationIcon } from 'images/notification.svg'
import Sidebar from 'components/sidebar/sidebar'
import Toolbar from 'components/toolbar/toolbar'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import Welcome from 'pages/Welcome';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from 'auth/msal';
import LoadingSpinner from 'components/LoadingSpinner';
export const ProtectedRoutes = () => {
	const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest)
	const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })
	let location = useLocation()
	const isAuthenticated = useIsAuthenticated()
	const { data: account, error: accountError, isError, isFetching  } = useGetAccountQuery(undefined, { enabled: isAuthenticated, retry: false, staleTime: 1000 * 60 * 60 * 24 })
	const urlToken = new URLSearchParams(location.search).get('token')
	const [token, setToken] = useState<string | null>(urlToken || localStorage.getItem('inviteToken') || null)
	
	useLayoutEffect(() => {
		const updateSize = () => {
			setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
		}
		window.addEventListener('resize', updateSize)
		updateSize()
		return () => window.removeEventListener('resize', updateSize)
	}, [])
	useEffect(() => {
		if (urlToken) {
			localStorage.setItem('inviteToken', urlToken)
			setToken(urlToken)
		} else if(localStorage.getItem('inviteToken')) {
			setToken(localStorage.getItem('inviteToken'))
		}
	}, [location.search, urlToken])

	// To keep the loading indicator from popping up whenever the account call is made (to determine if they have 
	// an account or should be redirected to the account creation flow), we just store the fact that they
	// have an account in local storage. This way, we can avoid the loading indicator popping up.
	useEffect(() => {
		if(account && !isError) localStorage.setItem('has_account', 'true')
		else localStorage.removeItem('has_account')
	}, [account, isError, isFetching])

	
	const Loader = () => <LoadingOverlay><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>
	const hasAccount = localStorage.getItem('has_account')
	return (
		<>
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect} 
				authenticationRequest={{
					...loginRequest,
					redirectStartPage: location.pathname
				}} 
				loadingComponent={Loader}
			> 
				{isFetching && !hasAccount ? <Loader /> : <>
					{!!account && !isError ? 
						<Container $windowDimensions={windowDimensions}>
							<Notifications>
								<Offline
									//@ts-ignore - wants an enabled prop, but it should default to pulling if needed
									polling={{
										url: window.location.origin,
										interval: 5000,
										timeout: 5000,
									}}>
									<Notification variant={'error'} icon={<NotificationIcon />}>
									You are offline. Some features may not work.
									</Notification>
								</Offline>
							</Notifications>
							<Sidebar />
							<Toolbar />
							<Main>
								<Outlet />
							</Main>
						</Container>
						: token ? <Navigate to={`/invite?token=${token}`} /> : <Navigate to={'/sign-up/account'} />
					}</>}
			</MsalAuthenticationTemplate>
		</>
	)
}

const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.primary};
`
const Main = styled.main`
	grid-area: main;
	overscroll-behavior: none;
	height: calc(100vh - (55px + var(--sat)));
	overflow: auto;
	/* margin: 1em; */
`
const Container = styled.div<{ $windowDimensions: { width: number; height: number } }>`
	display: grid;
	height: 100svh;
	grid-template-columns: 1fr;
	grid-template-rows: calc(55px + var(--sat)) auto calc(55px + var(--sab));
	grid-template-areas:
		'toolbar'
		'main'
		'sidebar';

	@media ${device.min.mobile} {
		margin-bottom: 0px;
		grid-template-columns: max-content auto;
		grid-template-rows: min-content auto min-content;
		grid-template-areas:
			'sidebar toolbar'
			'sidebar main';
	}
`
const NotificationContainer = styled.div`
	display: flex;
	gap: .5em;
	flex-direction: column;
	padding-right: 0.5em;
`
const UpdateButton = styled.button`
	all: unset;
	cursor: pointer;
	color: ${colors.dark};
	font-weight: bold;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 0;
	font-size: 0.8em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${transparentize(.9, colors.dark)};
	align-items: center;
`

