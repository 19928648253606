import 'boxicons'

export const CowIcon = ({ fill = 'currentColor', ...props }) => (
	<svg
		height={24}
		width={24}
		strokeMiterlimit='10'
		style={{
			fillRule: 'nonzero',
			clipRule: 'evenodd',
			strokeLinecap: 'round',
			strokeLinejoin: 'round',
		}}
		version='1.1'
		viewBox='0 0 24 24'
		{...props}
		fill='none'
		id='svg864'>
		<g id='g2437'>
			<path
				id='path2432'
				style={{
					clipRule: 'evenodd',
					fill: fill,
					fillRule: 'nonzero',
					strokeWidth: props.strokeWidth || 0,
					stroke: props.stroke || 'none',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeDasharray: 'none',
					paintOrder: 'stroke fill markers',
				}}
				d='M 11.917695,4.3810274 A 6.6494517,6.6494517 0 0 0 8.4463812,5.3686855 L 7.2069738,4.9571607 5.8562091,4.5069065 l 0.2856436,1.394336 0.2856435,1.394339 c -1.5025458,-0.0082 -5.0399434,-0.457768 -5.0399434,1.00702 0,1.1932527 2.3497224,3.2924195 4.0329236,4.0571325 a 6.6494517,6.6494517 0 0 0 3.3067082,4.483178 v 1.738078 c 0,0.763597 0.7454129,1.379816 1.6751394,1.379816 h 3.011379 c 0.929727,0 1.679982,-0.616219 1.679982,-1.379816 v -1.723552 a 6.6494517,6.6494517 0 0 0 3.306704,-4.42024 c 1.690086,-0.689126 4.212058,-2.8980668 4.212058,-4.1345965 0,-1.478196 -3.605325,-1.00702 -5.083517,-1.00702 -0.03632,0 -0.07053,0.0034 -0.106517,0.005 a 6.6494517,6.6494517 0 0 0 -0.0096,-0.0096 L 17.693664,5.9013585 17.979309,4.5070218 16.633387,4.957274 15.403663,5.3687965 A 6.6494517,6.6494517 0 0 0 11.917695,4.3810274 Z'
			/>
		</g>
	</svg>
)

export const TagIcon2 = ({ fill = 'currentColor', ...props }) => (
	<svg height={24} width={24} viewBox='0 0 6.3500001 6.35' version='1.1' {...props} fill='none' id='svg354'>
		<g id='layer1'>
			<path
				id='rect522'
				style={{ fill: props?.fill, strokeWidth: 0 }}
				d='M 3.1765503 0.41547852 A 0.82604063 0.82604063 0 0 0 2.3517944 1.2128459 L 2.3497274 1.2128459 C 2.3563859 1.6809777 2.277919 2.4587931 1.3099976 3.0034342 L 1.3141317 3.0034342 C 1.1083745 3.061242 0.95859782 3.2494817 0.95859782 3.4742065 L 0.95859782 5.4451457 C 0.95859782 5.7161667 1.1769526 5.9345215 1.4479736 5.9345215 L 4.9020264 5.9345215 C 5.1730474 5.9345215 5.3914022 5.7161667 5.3914022 5.4451457 L 5.3914022 3.4742065 C 5.3914022 3.2494817 5.2416255 3.061242 5.0358683 3.0034342 L 5.0400024 3.0034342 C 4.1325762 2.4928332 4.0069482 1.7764568 4.0002726 1.3032796 A 0.82604063 0.82604063 0 0 0 4.0028564 1.2412679 A 0.82604063 0.82604063 0 0 0 3.1765503 0.41547852 z M 3.1765503 0.92242432 A 0.31881851 0.31881851 0 0 1 3.4953939 1.2412679 A 0.31881851 0.31881851 0 0 1 3.1765503 1.5601115 A 0.31881851 0.31881851 0 0 1 2.8577067 1.2412679 A 0.31881851 0.31881851 0 0 1 3.1765503 0.92242432 z '
			/>
		</g>
	</svg>
)

export const GroupIcon = (props: React.SVGProps<SVGElement> | any) => {
	const { width = '24px', height = '24px', fill = 'currentColor' } = props
	return (
		<svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' viewBox='0 0 24 24' width={width} height={height} fill={fill} {...props}>
			<g>
				<rect fill='none' height='24' width='24' />
			</g>
			<g>
				<g>
					<path d='M6,13c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S8.2,13,6,13z M12,3C9.8,3,8,4.8,8,7s1.8,4,4,4s4-1.8,4-4S14.2,3,12,3z M18,13 c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S20.2,13,18,13z' />
				</g>
			</g>
		</svg>
	)
}

export const ClockIcon = (props: React.SVGProps<SVGElement> | any) => {
	const { width = '24px', height = '24px', fill = 'currentColor' } = props
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height} fill={fill} {...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
			<path d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z' />
		</svg>
	)
}

export const TagIcon = (props: React.SVGProps<SVGElement> | any) => {
	const { width = '24px', height = '24px', fill = 'currentColor' } = props
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height} fill={fill} {...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z' />
		</svg>
	)
}

export const CowTrailsIcon = (props: any) => {
	const { fill = 'currentColor' } = props
	return (
		<svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill={fill} {...props}>
			<g>
				<rect fill='none' height='24' width='24' />
			</g>
			<g>
				<g>
					<path d='M19,15.18V7c0-2.21-1.79-4-4-4s-4,1.79-4,4v10c0,1.1-0.9,2-2,2s-2-0.9-2-2V8.82C8.16,8.4,9,7.3,9,6c0-1.66-1.34-3-3-3 S3,4.34,3,6c0,1.3,0.84,2.4,2,2.82V17c0,2.21,1.79,4,4,4s4-1.79,4-4V7c0-1.1,0.9-2,2-2s2,0.9,2,2v8.18c-1.16,0.41-2,1.51-2,2.82 c0,1.66,1.34,3,3,3s3-1.34,3-3C21,16.7,20.16,15.6,19,15.18z' />
				</g>
			</g>
		</svg>
	)
}

export const AreaIcon = (props: React.SVGProps<SVGElement> | any) => {
	const { width = '24px', height = '24px', fill = 'currentColor' } = props
	return (
		<svg width={width} height={height} viewBox='0 0 12.7 12.7' version='1.1' id='svg1997' xmlns='http://www.w3.org/2000/svg' {...props}>
			<defs id='defs1994' />
			<g id='layer1'>
				<g id='g15738' transform='matrix(0.861156,0,0,0.861156,0.88411314,0.88165962)'>
					<path
						id='rect3148'
						style={{
							fill: 'none',
							stroke: fill,
							strokeWidth: 0.75,
							// strokeWidth: 1.32257,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeDasharray: 'none',
							paintOrder: 'stroke markers fill',
						}}
						d='M 11.066259,11.049847 H 1.6337414 v -9.3996943 0'
					/>
					<path
						id='path3210'
						style={{
							fill: 'none',
							stroke: fill,
							strokeWidth: 0.75,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeDasharray: '2.31694, 2.64793',
							strokeDashoffset: 1.58876,
						}}
						d='M 1.6287423,1.6508525 H 11.065557 V 11.026412'
					/>
				</g>
			</g>
		</svg>
	)
}

//@ts-ignore
export const Icon = (props: any) => <box-icon color={'currentColor'} size={props.size} {...props}></box-icon>
export const MDIcon = (props: any) => (
	<span
		style={{
			fontVariationSettings: `'FILL' ${props.fill || 0}, 'wght' ${props.weight || 100}, 'GRAD' ${props.grad || 200}, 'opsz' ${props.opticalsize || 48}`,
			color: props.color || 'currentColor',
			...props.style,
			
		}}
		{...props}
		className={'material-symbols-outlined' + (props.className ? ' ' + props.className : '')}>
		{props.name}
	</span>
)
