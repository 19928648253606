import { useParams } from 'react-router-dom'
import AnimalDetails from 'pages/panels/animals/Animal.details'
import BoundaryDetails from 'pages/panels/boundaries/Boundary.details'
import StructureDetails from 'pages/panels/structures/Structure.details'
import HerdDetails from 'pages/panels/herds/Herd.details'

export const Details: React.FC = () => {
	const { type } = useParams<{ type: string }>()
	const Page = () => {
		switch (type) {
		case 'animal':
		case 'animals': {
			return <AnimalDetails />
		}
		case 'structure':
		case 'salt-and-mineral-deposit':
		case 'water-tank':
		case 'gate': {
			return <StructureDetails />
		}
		case 'herds':
		case 'herd': {
			return <HerdDetails />
		}
		case 'exclusion':
		case 'pasture':
		case 'safe-zone':
		case 'pastures': {
			return <BoundaryDetails />
		}
		default: {
			return null
		}
		}
	}
	return (
		<>
			<Page />
		</>
	)
}

export default Details