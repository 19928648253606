import { useGetUserQuery } from 'library/api/user'
import { useGetUsersByRanchIdQuery } from 'library/api/user'
import { useGetInvitationsQuery } from 'library/api/invitation'
import styled from 'styled-components/macro'
import { ProfileCard } from 'components/cards/ProfileCard'
import { InviteCard } from 'components/cards/InviteCard'
import { Container } from 'components/base'
import { transparentize } from 'polished'
import { colors } from 'styles'
import LoadingSpinner from 'components/LoadingSpinner'
import { Link } from 'react-router-dom'

const Users = () => {
	const { data: currentUser } = useGetUserQuery()
	const { data: ranchUsers, isPending: ranchUsersIsLoading } = useGetUsersByRanchIdQuery({ id: currentUser?.currentRanch?.id }, {
		enabled:  !!currentUser?.currentRanch?.id,
		select: (users) => users.filter(user => user.id !== currentUser?.id)
	})
	
	const { data: invites, isPending: invitesIsLoading } = useGetInvitationsQuery(undefined, {
		retry: 1,
		enabled: (currentUser?.currentRole && currentUser?.currentRole !== 'Hand')
	})

	return (
		<Container>
			<Header>
				<h2>Team</h2>
			</Header>
			<Column>
				{currentUser?.currentRole !== 'Hand' ?
					<Link to='/account/team/add' style={{textDecoration: 'none'}}>
						<AddButton>
							<EmptyRowText>+ Add Team Member</EmptyRowText>
						</AddButton>
					</Link>
					: null}
				{ranchUsersIsLoading ?
					<EmptyRow>
						<div style={{ margin: 'auto auto' }}>
							<LoadingSpinner/>
						</div>
					</EmptyRow>
					:
					ranchUsers?.map((user) => (
						<ProfileCard key={user.id} user={user} />
					))
				}
				{currentUser?.currentRole && currentUser?.currentRole !== 'Hand' ?
					<>
						<h2 style={{ margin: '10px 0px'}}>Pending Invites</h2>
						{invitesIsLoading ?
							<EmptyRow>
								<div style={{ margin: 'auto auto' }}>
									<LoadingSpinner/>
								</div>
							</EmptyRow>
							:
							invites?.map((invite: UserInvite) => (
								<InviteCard key={invite.id} invite={invite} />
							))
						}
					</>
					: null }
			</Column>
		</Container>
	)
}

const EmptyRow = styled.div`
    height: 60px;
    width: 100%;
    border: 1px dashed ${transparentize(.4, colors.dark)};
    display: flex;
    justify-content: center;
    text-align: center;
	border-radius: var(--border-radius);
	color: ${transparentize(.4, colors.dark)};
`
const AddButton = styled(EmptyRow)`
	&:hover {
		cursor: pointer;
		border-color: ${colors.primary};
		color: ${colors.primary};
		--textColor: ${colors.primary};
	}
`
const EmptyRowText = styled.span`
    margin: auto 0;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5em;
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
`

export default Users