import styled from 'styled-components/macro'
import { formatPhoneNumber } from 'utilities'
import { colors, device } from 'styles';
import { useEffect, useState } from 'react'
import { RootState } from 'store';
import { useCreateUserFromInvitationMutation, useGetInvitationQuery } from 'library/api/invitation'
import { useAddUserImageMutation } from 'library/api/user'
import { useSelector } from 'react-redux'
import { CreateInviteUser, CreateUser } from 'library/models/user.model'
import { AvatarInput } from 'components/inputs/AvatarInput';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { invitationSlice } from 'store/invitation.slice';
import { store } from 'app/store';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest, signUpInvitationRequest } from 'auth/msal';
import LoadingSpinner from 'components/LoadingSpinner';
import * as uuid from 'uuid'
import { useGetAccountQuery } from 'library/api/account';

// import { AvatarInput } from 'components/inputs/AvatarInput'
export const Invite = () => {
	
	const navigate = useNavigate()
	const isAuthenticated = useIsAuthenticated()
	const { mutateAsync: createUserFromInvitation, isPending: createUserIsPending } = useCreateUserFromInvitationMutation()
	const { mutateAsync: addUserImage, isPending: addUserImageIsPending } = useAddUserImageMutation()
	const { data: account, isFetchedAfterMount: accountIsFetchedAfterMount, isError } = useGetAccountQuery(undefined, {
		enabled: isAuthenticated,
		retry: false,
	})
	const location = useLocation()
	
	const urlToken = new URLSearchParams(location.search).get('token')
	const [token, setToken] = useState<string | null>(urlToken || localStorage.getItem('inviteToken') || null)
	const { data: invitation, isFetchedAfterMount, isError: inviteLoopupError } = useGetInvitationQuery({id: token || undefined}, { enabled: !!token, retry: false })

	const [image, setImage] = useState<ImageFile | null>(null)
	const [user, setUser] = useState<CreateUser>(new CreateUser())

	const ExpiredTokenError = () => {
		return <div>
			<h2>Invitation Expired</h2>
			<p>This invitation has expired. Please contact the person who invited you to request a new invitation.</p>
		</div>
	}
	const MissingToken = () => {
		return <div>
			<h2>Something went wrong</h2>
			<p>Please contact the person who invited you to request a new invitation.</p>
		</div>
	}

	useEffect(() => {
		if (urlToken) {
			localStorage.setItem('inviteToken', urlToken)
			setToken(urlToken)
		} else if(localStorage.getItem('inviteToken')) {
			setToken(localStorage.getItem('inviteToken'))
		}
	}, [location.search, urlToken])

	const Loading = () => <LoadingOverlay><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>
	useEffect(() => {
		if(account) {
			localStorage.removeItem('inviteToken')
			navigate('/')
		}
	}, [account, navigate])
	console.log({invitation, inviteLoopupError})
	if(createUserIsPending || addUserImageIsPending) return <LoadingOverlay>Creating Your Profile<br/><LoadingSpinner color={'#ffffff'} /></LoadingOverlay>
	if(!invitation && !isFetchedAfterMount && !inviteLoopupError) return <Loading />
	if(!invitation && inviteLoopupError) return <Container><MissingToken /></Container>
	if(invitation && invitation?.expires && new Date(invitation?.expires).getTime() < new Date().getTime()) return <Container><ExpiredTokenError /></Container>
	if(!urlToken) return <Navigate to={'/'} />
	return (
		<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{
			...signUpInvitationRequest, 
			loginHint: invitation?.email,
			redirectStartPage: location.pathname + location.search
		}} loadingComponent={Loading}>
			<Container>
				{!accountIsFetchedAfterMount ? <Loading /> : <>
					{!!account && accountIsFetchedAfterMount && !isError ? <Navigate to={'/'} /> : null}
					<Form onSubmit={async (e: React.SyntheticEvent) => {
						e.preventDefault();

						const target = e.target as typeof e.target & {
						firstName: { value: string }
						lastName: { value: string }
						phone: { value: string }
						systemOfMeasureMetric: { value: boolean }
						systemOfMeasureMetricImperial: { value: boolean }
					}
					
						const createUserInvitationObject = {
							id: uuid.v4(),
							token: token || '',
							name: target.firstName.value + ' ' + target.lastName.value,
							email: invitation?.email || '',
							firstName: target.firstName.value,
							lastName: target.lastName.value,
							phone: target.phone.value,
							systemOfMeasure: target.systemOfMeasureMetric.value ? 'metric' : 'imperial',
							theme: 'system'
						} as CreateInviteUser
						const userInfo = await createUserFromInvitation(createUserInvitationObject)
						if (userInfo?.id && image) {
							await addUserImage({
								id: userInfo?.id,
								image: {
									fileName: image.fileName,
									contentType: image.contentType,
									data: image.data
								} as ImageUpload
							})
						}
						localStorage.removeItem('inviteToken')
						navigate('/')
					
					}}>
						{<>
							<HeaderText>Profile</HeaderText>
							<HeaderSubText>Let's get your profile set up</HeaderSubText>
							<InputWrapper>
								<AvatarInput
									image={null}
									imageSize={120}
									editLabelAlwaysVisible={true}
									onChange={(image) => setImage(image)}
								/>
							</InputWrapper>
							<InputWrapper>
								<Input
									autoComplete={'given-name'}
									value={user.firstName}
									placeholder={'First Name'}
									id={'firstName'}
									onChange={(event) => {    
										setUser({ ...user, firstName: event.target.value })
									}}
								/>
								<Label htmlFor={'firstName'}>First Name</Label>
							</InputWrapper>
							<InputWrapper>
								<Input
									autoComplete={'family-name'}
									value={user.lastName}
									placeholder={'Last Name'}
									id={'lastName'}
									onChange={(event) => {
										setUser({ ...user, lastName: event.target.value })
									}} />
								<Label htmlFor={'lastName'}>Last Name</Label>
							</InputWrapper>
							<InputWrapper>
								<Input
									type={'phone'}
									autoComplete={'phone'}
									value={user.phone}
									placeholder={'Phone'}
									id={'phone'}
									onChange={(event) => {
										const formattedPhone = formatPhoneNumber(event.target.value)
										setUser({ ...user, phone: formattedPhone! })
									}} />
								<Label htmlFor={'phone'}>Phone</Label>
							</InputWrapper>

							<RadioGroup>
								<RadioGroupTitle>System of Measurement</RadioGroupTitle>
								<RadioWrapper>
									<Radio>
										<RadioInput
											onChange={e => setUser({ ...user, systemOfMeasure: 'imperial' })}
											checked={user.systemOfMeasure === 'imperial'}
											name={'systemOfMeasure'}
											id={'systemOfMeasureMetricImperial'}
											type={'radio'}
										/>
										<RadioLabel htmlFor={'systemOfMeasureMetricImperial'}>Imperial</RadioLabel>
									</Radio>
									<Radio>
										<RadioInput
											onChange={e => setUser({ ...user, systemOfMeasure: 'metric' })}
											checked={user.systemOfMeasure === 'metric'}
											name={'systemOfMeasure'}
											id={'systemOfMeasureMetric'}
											type={'radio'}
										/>
										<RadioLabel htmlFor={'systemOfMeasureMetric'}>Metric</RadioLabel>
									</Radio>
								</RadioWrapper>
							</RadioGroup>
						</>}
						<ContinueButton>Submit</ContinueButton>
					</Form>
				</>}
			</Container>
		</MsalAuthenticationTemplate>
	)
}

export default Invite;

const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	flex-direction: column;
	background: ${colors.primary};
`
const Container = styled.div`
    width: 100%;
    min-height: 100svh;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
	background: ${colors.primary};
    h2, p {
        color: white;
        text-align: center;
    }

`
const Form = styled.form`
    padding: 1em;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1em;
`
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Label = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1em;
    color: rgba(255,255,255,.75);
    padding: .75em 1em;
    pointer-events: none;
    transition: all .25s ease-in-out;
    ${InputWrapper}:focus-within & {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }

`
const Input = styled.input`
    border: none;
    width: 100%;
    border-radius: 5px;
    background: rgba(255,255,255,.2);
    color: #ffffff;
    padding: .75em 1em;
    &::placeholder {
        color: transparent;
    }
    &:not(:placeholder-shown) ~ ${Label} {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }
`
const RadioLabel = styled.label`
    border-radius: 5px;
    padding: .25em 1em;
    color: white;
    border: 1px solid rgba(255,255,255,.85);
    cursor: pointer;
    position: relative;   
`
const Radio = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: .5em;
    position: relative;
    &:focus-within::before {
        content: '';
        position: absolute;
        inset: 5px;
        border-radius: 8px;
        border: 2px solid blue;
    }
`
const RadioInput = styled.input`
    width: 0;
    height: 0;
    appearance: none;
    position: absolute;
    &:checked + ${RadioLabel} {
        background: rgba(255,255,255,.85);
        color: black;
        mix-blend-mode: screen;
        
    }
`
const RadioGroup = styled.fieldset`
display: flex;
flex-direction: row;
background: rgba(255,255,255,.2);
border-radius: 5px;
align-items: center;
justify-content: space-between;
margin-top: .5em;
margin-bottom: .5em;
`
const RadioGroupTitle = styled.div`
    padding-left: 1em;
    color: rgba(255,255,255,.85);
    font-size: 1em;
    margin-bottom: 0;
    line-height: 1.2;
`

const RadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
const ContinueButton = styled.button`
    border: none;
    text-decoration: none;
    background: white;
    opacity: .85;
    text-transform: uppercase;
    color: black;
    mix-blend-mode: screen;
    font-size: 1em;
    font-weight: 400;
    padding: 1em 2em;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: .75;
    }
`
const HeaderText = styled.h2`
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    margin-bottom: 0;
    @media ${device.max.mobile} {
        font-size: 2em;
    }
`
const HeaderSubText = styled.p`
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1.25em;

`