import { useCallback, useEffect, useState } from 'react'
import { TripsLayer } from '@deck.gl/geo-layers/typed'
import { IconLayer } from '@deck.gl/layers/typed'
import * as turf from '@turf/turf'
import useAnimationFrame from 'hooks/useAnimationFrame'
import moment from 'moment'
import { useLazyGetAnimalHistoryQuery } from 'library/api/animal'
import { RootState, store } from 'store'
import { animalTrailSlice } from 'store/animalTrail.slice'
import { useSelector } from 'react-redux'

export const useAnimalTrailLayer = () => {
	const TRAIL_TIME = 10

	const { mutateAsync: getAnimalHistoryQuery } = useLazyGetAnimalHistoryQuery()
	
	const visible = useSelector((state: RootState) => state.animalTrailSlice.visible)
	const data = useSelector((state: RootState) => state.animalTrailSlice.waypoints)
	const playing = useSelector((state: RootState) => state.animalTrailSlice.playing)
	const dateRange = useSelector((state: RootState) => [state.animalTrailSlice.startTime ? new Date(state.animalTrailSlice.startTime): null, state.animalTrailSlice.endTime ? new Date(state.animalTrailSlice.endTime) : null])
	const totalDistance = useSelector((state: RootState) => state.animalTrailSlice.totalDistance)
	const [lastWaypoint, setLastWaypoint] = useState<Waypoint | null>(null)
	const [timelineScrubber, _setTimelineScrubber] = useState(0)
	const [pauseTimelineForUserInput, setPauseTimelineForUserInput] = useState<boolean>(false)
	const [marker, setMarker] = useState<any>(null)

	const currentTime = useSelector((state: RootState) => state.animalTrailSlice.currentTime)

	useEffect(() => {
		_setTimelineScrubber(currentTime)
	}, [currentTime])


	const getWaypointsWithTimestamps = (events: any) => {
		if(!events.length) return []
		const totalTime = TRAIL_TIME
		const totalLength = turf.length(turf.lineString(events.map((e: any) => e.coordinate)))
		store.dispatch(animalTrailSlice.actions.setTotalDistance(totalLength))
		const waypoints = events.reduce((accumulator: Waypoint[], currentEvent: Waypoint, index: number, events: Waypoint[]) => {
			// get the previous coordinate so we have a full segment
			const previousCoordinate = events[index - 1]?.coordinate
			// set timestamp to 0 in the case it's the first coordinate
			let timestamp = 0
			let segmentTime = 0
			let segmentDistance = 0
			let segmentPercentage = 0
			let distanceFromStart = 0
			let timelineTimestamp = 0
			if (previousCoordinate) {
				// calculate the segment length
				const segmentLength = turf.distance(turf.point(currentEvent.coordinate), turf.point(previousCoordinate))

				// calculate the segment percentage of total length
				segmentPercentage = segmentLength / totalLength

				// calculate the percentage of total time the segment represents
				segmentTime = totalTime * segmentPercentage

				// grab the previous timestamp so we can accumulate
				const previousTimestamp = accumulator.length ? accumulator[accumulator.length - 1]?.timestamp : 0
				const previousTimelineTimestamp = accumulator.length ? new Date(accumulator[accumulator.length - 1].timelineTimestamp).getDate() : 0

				// accumulate the timestamp
				timestamp = previousTimestamp + segmentTime
				timelineTimestamp = previousTimelineTimestamp + segmentTime

				segmentDistance = segmentLength
				distanceFromStart = accumulator.length ? accumulator[accumulator.length - 1].distanceFromStart + segmentLength : segmentLength
			}
			// push the waypoint onto the accumulator
			accumulator.push({ ...currentEvent, timelineTimestamp, coordinate: currentEvent.coordinate, segmentDistance, distanceFromStart, segmentTimeStamp: timestamp, segmentTime, segmentPercentage })
			return accumulator
		}, [])
		return waypoints
	}

	const getData = async ({ id, startTime, endTime }: { id: string; startTime: Date; endTime: Date, }) => {
		const response: any = await getAnimalHistoryQuery({ id, startDate: startTime, endDate: endTime, hasCoordinates: true })
		if (response?.events.length) {
			const tripLayer = getWaypointsWithTimestamps(response.events.map((event: any) => ({
				...event,
				displayTimestamp: moment(event.timestamp).format('M/D/YY h:mma'),
				coordinates: [event.coordinateX, event.coordinateY],
				segmentTime: event.segmentTime,
			})),
			)
			setLastWaypoint(tripLayer[0])
			store.dispatch(animalTrailSlice.actions.setVisible(true))
			store.dispatch(animalTrailSlice.actions.setWaypoints({waypoints: tripLayer}))
			return { waypoints: tripLayer }
		}
		return { waypoints: [] }
	}

	
	const getCurrentDistance = (waypoints: any[], currentPosition: number) => {
		return waypoints.reduce((total: number, currentValue: any) => {
			if (total + currentValue.segmentDistance <= currentPosition) {
				return total + currentValue.segmentDistance
			}
			return total
		}, 0)
	}

	const getMarker = ({ waypoints, currentPoint, currentPosition }: any) => {
		const findNextPoint = waypoints.find((e: any) => e.distanceFromStart >= currentPosition)

		return { ...currentPoint, properties: findNextPoint }
	}
	// const getLine = ({ waypoints, currentPoint, currentPosition }: any) => {
	// 	const filteredPoints = waypoints.filter((e: any) => e.distanceFromStart <= currentPosition)
	// 	const cutDistance = currentPosition - 2 < 0 ? 0 : currentPosition - 2
	// 	if (filteredPoints.length > 2) {
	// 		return turf.lineSliceAlong(turf.lineString([...filteredPoints.map((point: any) => point.coordinates), currentPoint.geometry.coordinates]), cutDistance, currentPosition)
	// 	} else {
	// 		return turf.lineString([waypoints[0].coordinates, currentPoint.geometry.coordinates])
	// 	}
	// }
	
	const updateFeature = useCallback((waypoints: any[], currentPosition: number) => {
		if(waypoints && waypoints.length && currentPosition) {
			const currentDistance = getCurrentDistance(waypoints, currentPosition)
			
			const mappedCoords = waypoints.map((e: any) => e.coordinate)
			const linestring: any = turf.lineString(mappedCoords)
			const currentPoint = turf.along(linestring, currentDistance)
			const marker = getMarker({ waypoints, currentPoint, currentPosition })
			return { marker }
		}  
	}, [])

	const onAnimationFrame = useCallback((currTimelineScrubber: number) => {
		if (playing) {
			if (currTimelineScrubber + 0.005 >= totalDistance) return 0
			else return currTimelineScrubber + 0.005
		}
		return currTimelineScrubber
	}, [playing, totalDistance])

	useEffect(() => {
		if (pauseTimelineForUserInput || !playing) return
		if (data?.waypoints?.length) {
			const features = updateFeature(data.waypoints, timelineScrubber)
			if(features) {
				setLastWaypoint(features?.marker.properties)
				setMarker(features?.marker)
			}
		}
	}, [data.waypoints, pauseTimelineForUserInput, playing, timelineScrubber, updateFeature])
	

	// useAnimationFrame((deltaTime: number) => {
	// 	// onAnimationFrame()
	// 	const newPosition = onAnimationFrame(timelineScrubber)
	// 	if (newPosition && newPosition >= 0) {
	// 		_setTimelineScrubber(newPosition)
	// 	}
	// })

	useEffect(() => {
		const cancelTimeout = setTimeout(function() {
			requestAnimationFrame(() => {
				const newPosition = onAnimationFrame(timelineScrubber)
				if (newPosition && newPosition >= 0) {
					_setTimelineScrubber(newPosition)
				}
			});	 
		}, 1000 / 20);
		return () => clearTimeout(cancelTimeout)
	}, [onAnimationFrame, timelineScrubber])
	

	const layer = new TripsLayer({
		id: 'cow-trails',
		data: [data],
		visible: visible,
		getPath: (d: any) => d.waypoints.map((p: any) => p.coordinate),
		getColor: (d: any) => [255, 255, 255, 255],
		getWidth: (p: any) => 4,
		getTimestamps: (d: any) => d.waypoints.map((p: any) => p.distanceFromStart),
		fadeTrail: true,
		trailLength: 2,
		capRounded: true,
		jointRounded: true,
		currentTime: timelineScrubber,
	})

	const animalTrailIconLayer = new IconLayer({
		id: 'animal-trail-icon-layer',
		data: marker ? [marker] : [],
		visible: visible,
		pickable: false,
		pickingRadius: 25,
		autoHighlight: true,
		stroked: true,
		pointType: 'icon+text',
		getIcon: (d: any) => {
			const iconUrl = d.properties?.type === 'BuzzRight' || d.properties?.type === 'BuzzLeft' ? 'cow-bubble-audio.png' : d.properties?.type === 'ShockRight' || d.properties?.type === 'ShockLeft' ? 'cow-bubble-zap.png' : 'cow-bubble.png'
			return {
				url: `${process.env.REACT_APP_CORRAL_APPURL}/${iconUrl}`,
				width: 128,
				height: 128,
				anchorY: 128,
			}
		},
		sizeScale: 10,
		opacity: 1,
		getPosition: (d: any) => d.geometry.coordinates,
		getSize: (d: any) => 5,
		getColor: (d: any) => [57, 68, 188, 255],
		updateTriggers: {
			getPosition: [timelineScrubber, marker, lastWaypoint],
			getIcon: [marker, timelineScrubber, lastWaypoint],
		},
		highlightColor: [255, 255, 255, 100],
		getLineColor: [255, 255, 255, 255],
		background: true,
		backgroundPadding: [5, 5],
	})

	return {
		visible,
		setVisible: (visible: boolean) => store.dispatch(animalTrailSlice.actions.setVisible(visible)),
		playing,
		setPlaying: (playing: boolean) => store.dispatch(animalTrailSlice.actions.setPlaying(playing)),
		timelineScrubber,
		setTimelineScrubber: (currentTime: number) => {
			setPauseTimelineForUserInput(true)
			store.dispatch(animalTrailSlice.actions.setCurrentTime(currentTime))
			setTimeout(() => { setPauseTimelineForUserInput(false) }, 200)
		},
		data,
		setData: (data: any) => store.dispatch(animalTrailSlice.actions.setWaypoints(data)),
		dateRange,
		setDateRange: (dateRange: [Date, Date]) => store.dispatch(animalTrailSlice.actions.setDateRange({startTime: String(dateRange[0]), endTime: String(dateRange[1])})),
		reset: () => store.dispatch(animalTrailSlice.actions.reset()),
		lastWaypoint,
		setLastWaypoint,
		layer: [layer, animalTrailIconLayer],
		getData,
		// TimelineControl,
		COW_TRAIL_TIME: totalDistance,
	}
}
