import { AxiosError } from 'axios'
import { useEndpoints } from './endpoints'
import { useQuery, QueryMeta, useQueryClient, MutationMeta, useMutation, MutateFunction, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query'
import { CreateInviteUser, User } from '../models/user.model'

export const useGetInvitationsQuery = (payload: undefined, options: Partial<UseQueryOptions<UserInvite[], AxiosError>>) => {
	const { invitation } = useEndpoints()
	return useQuery({
		queryKey: ['invitations'],
		queryFn: () => invitation.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}
export const useGetInvitationQuery = (payload: { id: string | undefined }, options: Partial<UseQueryOptions<UserInvite, AxiosError>>) => {
	const { invitation } = useEndpoints()
	return useQuery({
		queryKey: ['invitations', payload.id],
		queryFn: () => invitation.query.byId(payload),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useResendInvitationMutation = (options?: Partial<UseMutationOptions<UserInvite, AxiosError, {id: string}, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['invitation.mutation.resend'],
		onMutate: (payload: { id: string }) => {
		},
		onSuccess: (data, variables, context) => {
		},
		onError: (data, variables, context) => {
		},
		networkMode: 'offlineFirst',
		...options,
	})
}

export const useCreateInvitationMutation = (options?: Partial<UseMutationOptions<UserInvite, AxiosError, { id: string, email: string, role: RanchRoleType, hostname: string }>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['invitation.mutation.create'],
		onMutate: (payload: { id: string, email: string, role: RanchRoleType, hostname: string }) => {
			queryClient.cancelQueries({ queryKey: ['invitations'] })
			const invitations = queryClient.getQueryData(['invitations'])
			queryClient.setQueryData(['invitations'], (old: any[]) => old ? [payload, ...old] : [payload])
			queryClient.setQueryData(['invitations', payload.id], payload)
			return invitations
		},
		onSuccess: (data, variables, context) => {
			queryClient.cancelQueries({ queryKey: ['invitations'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['invitations'], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const deleteGrazingPlan = (payload: { id: string }) => client.delete(`/GrazingPlans/${payload.id}`).then((res) => res.data)
export const useCreateUserFromInvitationMutation = (options?: Partial<UseMutationOptions<User, AxiosError, CreateInviteUser, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['invitation.mutation.accept'],
		// mutationFn: deleteGrazingPlan,
		onMutate: (payload: CreateInviteUser) => {
			queryClient.cancelQueries({ queryKey: ['invitations'] })
			queryClient.cancelQueries({ queryKey: ['users'] })
			queryClient.cancelQueries({ queryKey: ['invitations', payload.token] })
			const invitations = queryClient.getQueryData<any[]>(['invitations'])
			const invitation = queryClient.getQueryData<any>(['invitations', payload.token])
			const users = queryClient.getQueryData<any[]>(['users'])
			queryClient.setQueryData(['invitations'], invitations ? invitations.filter(inv => inv.id !== payload.token) : [])
			queryClient.removeQueries({queryKey: ['invitations', payload.token]})
			return { invitations, invitation }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['invitations'] })
			queryClient.invalidateQueries({ queryKey: ['invitations', variables.token] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['invitations'], context?.invitations)
			queryClient.setQueryData(['invitations', variables.token], context?.invitation)
		},
		networkMode: 'offlineFirst',
		...options
	})
}
export const useDeleteInvitationMutation = (options?: Partial<UseMutationOptions<Boolean, AxiosError, {id: string}, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['invitation.mutation.delete'],
		// mutationFn: deleteGrazingPlan,
		onMutate: (payload: {id: string}) => {
			queryClient.cancelQueries({ queryKey: ['invitations'] })
			queryClient.cancelQueries({ queryKey: ['invitations', payload.id] })
			const invitations = queryClient.getQueryData<any[]>(['invitations'])
			const invitation = queryClient.getQueryData<any[]>(['invitations', payload.id])
			queryClient.setQueryData(['invitations'], invitations ? invitations.filter(inv => inv.id !== payload.id) : [])
			queryClient.removeQueries({queryKey: ['invitations', payload.id]})
			return { invitations, invitation }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['invitations'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['invitations'], context?.invitations)
			queryClient.setQueryData(['invitations', variables.id], context?.invitation)
		},
		networkMode: 'offlineFirst',
		...options
	})
}