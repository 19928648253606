import {Alert } from 'react-bootstrap'
import Loading from 'components/Loading'
import styled from 'styled-components/macro'
import { Card, CardItem, CardAction, AddCard } from 'components/cards'
import { colors, device } from 'styles'
import { lighten, transparentize } from 'polished'
import { useGetHerdsQuery } from 'library/api/herd'
import { Link, useNavigate } from 'react-router-dom'
import { Label } from 'components/inputs'
import { MDIcon } from 'components/icons'
import { useGetAnimalsByHerdIdQuery } from 'library/api/animal'
import { useSelector } from 'react-redux'
import { RootState, store } from 'store'
import { RouteIcon } from 'components/toolbar/PastureRoutesToolbar'
import { useMemo } from 'react'
import { persistentDataSlice } from 'store/persistentData.slice'


export const Herds = () => {
	const navigate = useNavigate()
	const { data: herds, isLoading: herdsIsLoading, isError: herdsIsError } = useGetHerdsQuery()

	return (
		<>
			<Container>
				{herdsIsError && <Alert variant='danger'>{'An Error has occured'}</Alert>}
				<CardGrid>
					<AddCard text={'Add Herd'} onClick={() => navigate('/herd/add')} />
					{!herdsIsLoading ? (
						herds?.map((herd: any) => (
							<HerdCard herd={herd} key={herd?.id} />
						))
					) : (
						<Loading />
					)}
				</CardGrid>
			</Container>
		</>
	)
}

const HerdCard = ({ herd }: { herd: Herd }) => {

	const navigate = useNavigate()
	const pastureRouteOverlays = useSelector((state: RootState) => state.persistentDataSlice.pastureRouteOverlays)

	const route = useMemo(() => pastureRouteOverlays.find((p: any) => p.herdId === herd?.id), [herd?.id, pastureRouteOverlays])
	return (<>
		<StyledHerdCard key={herd.id}>
			<HerdName label={'Name'} value={herd.name} />
			{/* <HerdCount label={'Count'} value={`${herd.animalCount} Animal${herd.animalCount === 1 ? '' : 's'}`} /> */}
			<HerdCount label={'Count'} value={`${herd.animalCount || 0} Animal${(herd.animalCount || 0) === 1 ? '' : 's'}`} />

			<PastureSelect>
				<Label>Pasture</Label>
				{herd.pasture?.name || 'Unassigned'}
			</PastureSelect> 
			
			{herd?.pastureRouteId && route && route?.progress < 100 && <EnrouteIndicator>
				<Label>Enroute</Label>
				<div>
					<RouteIcon
						style={{height: '32px', width: '32px', fontSize: '0.8em', padding: '5px 0px 0px', cursor: 'default'}}
						$backgroundColor={route?.style?.color ? route.style.color : '#222222'}
						$enabled={true}
					>
						<span>{route?.progress}%</span>
					</RouteIcon>
				</div>
			</EnrouteIndicator>}

			<HerdToggle>
				<Label>Collars</Label>
				<CollarState $state={herd.enabled}>
					<MDIcon style={{ color: '#ffffff', marginTop: herd.enabled ? '3px' : 0 }} fill={1} grad={-25} weight={100} opticalsize={24} name={herd.enabled ? 'flash_on' : 'flash_off'} />
				</CollarState>
			</HerdToggle>
			<ButtonWrapper>
				<CardAction
					onClick={() => {
						navigate(`/herd/${herd.id}`)
					}}>
					Details
				</CardAction>
			</ButtonWrapper>
		</StyledHerdCard>
	</>)
}

const Container = styled.div`
	color: var(--dark);
	margin: 0.5em;
	@media ${device.max.mobile} {
		padding-bottom: calc(55px + var(--sab));
	}
`
const CardGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	@media ${device.max.mobile} {
		grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
	}
	grid-template-rows: auto;
	flex-direction: column;
	gap: 0.5em;
`
const StyledHerdCard = styled(Card)`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr min-content;
	grid-template-rows: auto auto;
	grid-gap: 0.5em;
	grid-template-areas:
		'herdName herdCount herdToggle'
		// 'pastureSelect pastureSelect pastureSelect'
		'pastureSelect pastureSelect enrouteIndicator'
		'actionButton actionButton actionButton';
`

const HerdName = styled(CardItem)`
	grid-area: herdName;
`

const HerdCount = styled(CardItem)`
	grid-area: herdCount;
`

const HerdToggle = styled.div`
	grid-area: herdToggle;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const CollarState = styled.div<{ $state: boolean }>`
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: ${props => (props.$state ? colors.success : transparentize(.25, colors.primary))};
	display: flex;
	justify-content: center;
	align-items: center;
`

const PastureSelect = styled.div`
	grid-area: pastureSelect;
	display: flex;
	flex-direction: column;
`

const EnrouteIndicator = styled.div`
	grid-area: enrouteIndicator;
	display: flex;
	flex-direction: column;
	// height: 30px;
	// width: 30px;
`

const ButtonWrapper = styled.div`
	border-top: 1px solid ${lighten(0.175, colors.light)};
	padding-top: 0.5em;
	display: flex;
	justify-content: flex-end;
	grid-area: actionButton;
`

export default Herds
