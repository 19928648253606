import { SVGProps } from 'react'

function coordinateToPoint(coords: [number, number]) {
	const [lng, lat] = coords
	return {
		x: (lng + 180) * (256 / 360),
		y: (256 / 2) - (256 * Math.log(Math.tan((Math.PI / 4) + ((lat * Math.PI / 180) / 2))) / (2 * Math.PI))
	};
}

function polyToSvg(coords: [number, number][]) {
	let minX = 256,
		minY = 256,
		maxX = 0,
		maxY = 0;
	const points = coords.map(coord => {
		const point = coordinateToPoint(coord)
		minX = Math.min(minX, point.x);
		minY = Math.min(minY, point.y);
		maxX = Math.max(maxX, point.x);
		maxY = Math.max(maxY, point.y);
		return [point.x, point.y].join(',')
	}
	)
	const path = points.join(' ')
	return {
		path: 'M' + path,
		x: minX,
		y: minY,
		width: maxX - minX,
		height: maxY - minY
	};

}

interface SvgFromGeometryProps extends SVGProps<SVGPathElement> {
	geometry: GeoJSON.Geometry | null
}
const SvgFromGeometry = (props: SvgFromGeometryProps) => {
	const { geometry, width, height, ...rest } = props
	if(!geometry) return null
	const coords = geometry.type === 'LineString' ? geometry.coordinates : geometry.type === 'Polygon' ? geometry.coordinates[0] : null
	if(!coords) return null
	const svgProps = polyToSvg(coords as [number, number][])
	const strokeWidth = props.strokeWidth
	let buffer = Number(props.strokeWidth)
	if(String(strokeWidth).search('%') !== -1) {
		const diagnal = Math.sqrt((svgProps.width * svgProps.width) + (svgProps.height * svgProps.height))
		const percent = Number(String(strokeWidth).replace('%', '')) / 100
		buffer = diagnal * percent
	}
	const viewBox = [svgProps.x - buffer, svgProps.y - buffer, svgProps.width + (buffer * 2), svgProps.height + (buffer * 2)].join(' ')
	return (
		<svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink="http://www.w3.org/1999/xlink"  width={width} height={height} fill={'none'} viewBox={viewBox}>
			<g>
				<path d={svgProps.path} {...rest} strokeWidth={strokeWidth} id={props.id}  />
			</g>
		</svg>
	)
}

export default SvgFromGeometry