import styled from 'styled-components/macro'
import CorralLogo from 'images/Primary Logo/Full Color/Corral_Primary Logo_full color.svg'
import { colors, device } from 'styles'
import { CloseButton } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {  } from 'react-router-dom'
export function PrivacyPolicy () {
	return (
		<>
			<Wrapper>
				<Heading>Privacy Policy</Heading>
				<p>This privacy policy (the “Privacy Policy”) applies to the Corral Technologies mobile application and web application (the “App”), owned and operated by Corral Holdings, Inc. (“Corral,” “we,” “us,” or “our”). We have created this Privacy Policy to tell you what information the App collects, how we use that information, and who we will share that information with, if at all. This Privacy Policy does not address the privacy practices of any third parties that we do not own, control, or are affiliated with. By visiting and/or using our App, you are agreeing to the terms of this Privacy Policy. We encourage you to read the Privacy Policy, and to use the information it contains to help you make informed decisions.</p>
				<ol type="1">
					<li><strong>Information We Collect or Receive.</strong> In the course of operating the App, we will collect and/or receive the following types of information. You authorize us to collect and/or receive such information.</li>
					
					<ol type="a">
						<li><u>Personal Information</u>. We only receive or collect information that identifies you personally if you choose to provide such personally identifiable information to us via email or other means. When you sign up to become a user or contact us, you will be required to provide us with personal information about yourself (collectively, the “<u>Personal Information</u>”). Such Personal Information may include your name, e-mail address, physical address, and phone number. We do not collect any Personal Information from you when you use the App unless you provide us with the Personal Information voluntarily.</li>
						<li><u>Payment Information</u>. If you choose to make a purchase or subscribe to a feature or service ours that requires a fee, you will be required to provide us with your payment information, including, without limitation, bank account numbers, credit card or debit card numbers, account details, ACH information, and similar data (collectively, “<u>Payment Information</u>”).</li>
						<li><u>Geolocational Information</u>. Certain features and functionalities of the App are based on your location. In order to provide these features and functionalities while you are using your mobile device, we may, with your consent, automatically collect geolocational information from your mobile device or wireless carrier and/or certain third-party service providers (collectively, “<u>Geolocational Information</u>”). You may decline to allow us to collect such Geolocational Information, in which case we will not be able to provide certain features or functionalities to you.</li>
						<li><u>Other Information</u>. In addition to the Personal Information, Payment Information, and Geolocational Information, we may automatically collect or receive additional information regarding you and your use of the App; your interactions with us and our advertising; and information regarding your computer and mobile devices used to access the App (collectively, the “<u>Other Information</u>”). Such <u>Other Information</u> may include:
							<ol type="i">
								<li><u>From You</u>. Additional information about yourself that you voluntarily provide to us, such as your gender and your product and service preferences.</li>
								<li><u>From Your Activity</u>. We may collect or receive information regarding:
									<ol type="A">
										<li>IP address, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or mobile device;</li>
										<li>browser type and language;</li>
										<li>referring and exit pages and URLs;</li>
										<li>date and time; and</li>
										<li>details regarding your activity on the App, such as search queries and other performance and usage data.</li>
									</ol>
								</li>
								<li><u>About Your Mobile Device</u>. We may collect or receive information regarding:
									<ol type="A">
										<li>type of mobile device;</li>
										<li>advertising Identifier (“IDFA” or “AdID”);</li>
										<li>operating system and version (e.g., iOS, Android or Windows);</li>
										<li>carrier; and</li>
										<li>network type (WiFi, 3G, 4G, LTE, 5G)</li>
									</ol>
								</li>
								<li><u>From Cookies</u>. We may use both session cookies, which expire once you close the App, and persistent cookies, which stay on your mobile device until you delete them and other technologies to help us collect data and to enhance your experience with the App. Cookies are small text files an app can use to recognize a repeat visitor to the app. We may use cookies for various purposes, including to:
									<ol type="A">
										<li>type of mobile device;</li>
										<li>personalize your experience;</li>
										<li>analyze which portions of the App are visited and used most frequently; and</li>
										<li>measure and optimize advertising and promotional effectiveness.</li>
									</ol>
                                    If you do not want us to deploy cookies in the App, you can opt out by setting your mobile device to reject cookies. You can still use the App if you choose to disable cookies, although your ability to use some of the features may be affected.
								</li>
							</ol>
						</li>
					</ol>
					<li><strong>How Information is Used and Shared.</strong>
						<ol type="a">
							<li>You authorize us to use the Personal Information, Payment Information, Geolocational Information, and the Other Information (collectively, the “<u>Information</u>”) to:
								<ol type="i">
									<li>provide and improve our App;</li>
									<li>provide our services;</li>
									<li>administer our promotional programs;</li>
									<li>solicit your feedback; and</li>
									<li>inform you about our products and services</li>
								</ol>
								<li>In order to provide our services and administer our promotional programs, we may share the Information with our third-party promotional and marketing partners, including, without limitation, businesses participating in our various programs.</li>
								<li>We engage third-party companies and individuals to perform functions on our behalf. Examples may include providing technical assistance, customer service, marketing assistance, and administration of promotional programs. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.</li>
								<li>In an ongoing effort to better understand our users, the App, and our products and services, we may analyze certain Information in anonymized and aggregate form to operate, maintain, manage, and improve the App and/or such products and services. This aggregate information does not identify you personally. We may share and/or license this aggregate data to our affiliates, agents, business and promotional partners, and other third parties. We may also disclose aggregated user statistics to describe the App and these products and services to current and prospective business partners and investors and to other third parties for other lawful purposes.</li>
								<li>We may share some or all of your Information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us.</li>
								<li>As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the Information may be part of the transferred assets.</li>
								<li>To the extent permitted by law, we may also disclose the Information:
									<ol type="A">
										<li>when required by law, court order, or other government or law enforcement authority or regulatory agency; or</li>
										<li>whenever we believe that disclosing such Information is necessary or advisable, for example, to protect the rights, property, or safety of us or others, including you.</li>
									</ol>
								</li>
							</li>
						</ol>
                        
					</li>
					<li>
						<p><strong>Accessing and Modifying Information and Communication Preferences.</strong> If you have provided us any Personal Information, you may access, remove, review, and/or make changes to the same by contacting us as set forth below. In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “unsubscribe” link located on the bottom of any of our marketing e-mails. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases. You cannot opt out of receiving transactional e-mails related to the App (e.g., requests for support).
						</p>
						<p>
                        We may also deliver notifications to your mobile device (e.g., push notifications). You can disable these notifications by deleting the relevant service or by changing the settings on your mobile device.
						</p>
					</li>
					<li>
						<strong>How We Protect Your Information.</strong> We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such Information, nor can we guarantee that the Information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.
					</li>
					<li>
						<strong>Important Notices to Non-U.S. Residents.</strong> The App and its servers are operated in the United States. If you are located outside of the United States, please be aware that your Information, including your Personal Information, may be transferred to, processed, maintained, and used on computers, servers, and systems located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to use the App, you hereby irrevocably and unconditionally consent to such transfer, processing, and use in the United States and elsewhere.
					</li>
					<li>
						<p><strong>App Stores; External Websites.</strong> Your app store (e.g., iTunes or Google Play) may collect certain information in connection with your use of the App, such as Personal Information, Payment Information, Geolocational Information, and other usage-based data. We have no control over the collection of such information by a third-party app store, and any such collection or use will be subject to that third party’s applicable privacy policies.
						</p>
						<p>
                        The App may contain links to third-party websites. We have no control over the privacy practices or the content of these websites. As such, we are not responsible for the content or the privacy policies of those third-party websites. You should check the applicable third-party privacy policy and terms of use when visiting any other websites.
						</p>
					</li>
					<li>
						<strong>Children.</strong> The App is not directed to children under the age of 13. We adhere to the Children’s Online Privacy Protection Act (COPPA) and will not knowingly collect Personal Information from any child under the age of 13. We ask that minors (under the age of 13) not use the App. If a child under the age of 13 has provided us with Personal Information, a parent or guardian of that child may contact us and request that such information be deleted from our records.
					</li>
					<li>
						<strong>Changes to This Privacy Policy.</strong> We may change this Privacy Policy from time to time. Any such changes will be posted on the App. By accessing the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.
					</li>
					<li>
						<strong>How to Contact Us.</strong> If you have questions about this Privacy Policy, please contact us at <a referrerPolicy='no-referrer' target='_blank' href="https://www.corraltech.com/contact">www.corraltech.com/contact</a>
					</li>
				</ol>
			</Wrapper>
		</>
	)
}

export function PrivacyPolicyPage () {
	const navigate = useNavigate()
	const location = useLocation()
	const canGoBack = location.key !== 'default';
	return (
		<Container>
			<HomeLink onClick={() => {canGoBack ? navigate(-1) : navigate('/')}}>&times;</HomeLink>
			<LogoWrapper>
				<Logo src={CorralLogo} alt={'Corral Technologies Logo'} />
			</LogoWrapper>
			<PrivacyPolicy />
		</Container>
	)
}

const Container = styled.div`
    position: relative;
    padding: 1em;
    margin: 0 auto;
    max-width: ${device.size.tablet}px;
`

const Wrapper = styled.div`
    padding: 1em;
    max-width: ${device.size.tablet}px;
    position: relative;
    color: ${colors.navy};
    ol {
        margin-bottom: 1em;
        li {
            margin-bottom: .5em;
            color: ${colors.navy};
        }
    }
`
const Heading = styled.h1`
    font-size: 1.4em;
    color: ${colors.navy};
`
const HomeLink = styled.button`
    position: fixed;
    top: 0em;
    right: .25em;
    border: none;
    background: transparent;
    font-size: 2em;
    justify-content: flex-end;
    color: ${colors.primary};
`
const Logo = styled.img`
    max-width: 300px;
    margin: auto;
    `
const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
`