import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CowTrailsIcon, Icon, MDIcon } from 'components/icons'
import { colors, device } from 'styles'
import moment from 'moment'
import { formatCoordinates } from 'utilities'
import { useGrazingDistributionLayer, useAnimalTrailLayer, useAnimalsLayer } from 'components/map/layers'
import { useArchiveAnimalMutation, useGetAnimalQuery } from 'library/api/animal'
import * as turf from '@turf/turf'
import { DetailsBody, DetailsHeader, DetailsItem } from 'components/details'
import { CardAction, CardActions } from 'components/cards'
import { CardDeleteAction } from 'components/cards/CardActions'
import styled from 'styled-components/macro'
import { Modal } from 'react-bootstrap'
import { Input } from 'components/inputs'
import Loading from 'components/Loading'
import { RootState, store } from 'store'
import { routeSlice } from 'store/route.slice'
import { useSelector } from 'react-redux'

export default function AnimalDetails() {
	const navigate = useNavigate()
	const { id } = useParams<{id: string}>()
	const grazingDistribution = useGrazingDistributionLayer()
	const animalTrail = useAnimalTrailLayer()
	const animalsLayer = useAnimalsLayer()
	const { data: animal, isLoading: animalIsLoading } = useGetAnimalQuery({ id }, { enabled: !!id })
	const { mutate: archiveAnimal } = useArchiveAnimalMutation()

	const [toArchive, setToArchive] = useState<string>('')
	const [archiveReason, setArchiveReason] = useState<string>('')

	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)

	const handleEdit = () => {
		animalsLayer.setVisible(true)
		grazingDistribution.setVisible(false)
		animalTrail.reset()
		navigate(`/animals/${id}/edit`)
	}

	const handleDelete = async () => {
		if(id) {
			archiveAnimal({
				id,
				archived: true,
				archiveDate: new Date(),
				archiveReason: archiveReason
			})
		}
		navigate('/animals')
	}

	const handleCenterAction = async () => {
		if (!animal || !animal?.lastCoordinate) return null
		animalsLayer.zoomIntoView(animal.lastCoordinate as turf.helpers.Position)
		if (isMobile) store.dispatch(routeSlice.actions.setSnapToIndex(0))
	}

	const handleGrazingPatternAction = async () => {
		grazingDistribution.setVisible(true)
		animalsLayer.setVisible(false)
		navigate(`/animal/${id}/grazing-map`)
	}
	const handleTrailAction = async () => {
		grazingDistribution.setVisible(false)
		animalTrail.reset()
		navigate(`/animal/${id}/location-history`)
	}

	const details = useMemo(
		() => [
			{ label: 'Tag', value: animal?.tag || '', tooltip: '' },
			{ label: 'Herd', value: animal?.herdName || 'Not Available', tooltip: 'The animal\'s herd assignment', link: `/herd/${animal?.herdId}` },
			{ label: 'Last Check In', value: animal?.lastEventTimestamp ? moment(animal?.lastEventTimestamp).format('h:mma M/D/YYYY') : 'Not Available', tooltip: 'The last time the collar reported an event' },
			{ label: 'Last Coordinates', value: animal?.lastCoordinate && animal.lastCoordinate.length ? formatCoordinates(animal?.lastCoordinate) : 'Not Available', tooltip: '' },
			{ label: 'Collar ID', value: animal?.deviceId || 'Unassigned', tooltip: 'The unique identifier for the collar' },
			{ label: 'Sex', value: animal?.sex || 'Unknown', tooltip: '' },
			{ label: 'Breed', value: animal?.breed || 'Unknown', tooltip: '' },
		],
		[animal]
	)

	// if (!animal) return null
	if (animalIsLoading || !animal) {
		return <Loading color={colors.dark} />
	}
	return (
		<>
			<Container>
				<CardActions>
					<CardAction onClick={async () => handleCenterAction()}>
						<MDIcon size={'sm'} style={{ transform: 'scale(.75)' }} name={'my_location'} />
						Locate Position
					</CardAction>
					<CardAction onClick={async () => handleGrazingPatternAction()}>
						<Icon size={'sm'} style={{ transform: 'scale(.75)' }} name={'scatter-chart'} />
						Grazing Pattern
					</CardAction>
					<CardAction onClick={async () => handleTrailAction()}>
						<CowTrailsIcon style={{ transform: 'scale(.75)' }} fill={colors.dark} />
						Location History
					</CardAction>
				</CardActions>

				<DetailsHeader>Details</DetailsHeader>
				<DetailsBody>
					{details.map(detail => (
						<DetailsItem key={detail.label} label={detail.label} tooltip={detail.tooltip} link={detail?.link}>
							{detail.value}
						</DetailsItem>
					))}
				</DetailsBody>
				<CardActions>
					<CardAction style={{ flexDirection: 'row', justifyContent: 'center' }} onClick={e => handleEdit()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.dark} name={'pencil'} />
						Edit
					</CardAction>
					{/* <CardDeleteAction onClick={e => handleDelete()}> */}
					<CardDeleteAction onClick={e => setToArchive(animal.id)}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.error} name={'trash'} />
						Archive
					</CardDeleteAction>
				</CardActions>
			</Container>
			<Modal
				show={toArchive.length > 0}
				onHide={() => setToArchive('')}
				backdrop={'static'}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Archive animal?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ModalBodyWrapper>
						<span>Are you sure you would like to archive animal "{animal.tag}"</span>
						<Input
							label={'Reason'}
							value={archiveReason}
							maxLength={20}
							onChange={(e: any) => {
								setArchiveReason(e.target.value)
							}}
						/>
					</ModalBodyWrapper>
				</Modal.Body>
				<Modal.Footer style={{justifyContent: 'center'}}>
					<CardDeleteAction onClick={e => handleDelete()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.error} name={'trash'} />
						Archive
					</CardDeleteAction>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
	--rbs
`
const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    // @media (${device.max.tablet}) {
    //     flex-direction: column;
    // }
`
