import { ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { Icon } from 'components/icons'
import { transparentize } from 'polished'
import { colors } from 'styles'
const Select = ({ label, required, value, defaultValue, onChange, children, name, disabled }: { label: string; required?: boolean; value?: any; name?: string, defaultValue?: any; disabled?: boolean, onChange: (e: ChangeEvent<HTMLSelectElement>) => void; children: any }) => {
	return (
		<SelectWrapper>
			<Label disabled={disabled}>{label}{required && <RequiredStar> * </RequiredStar>}</Label>
			<InputWrapper>
				<SelectInput disabled={disabled} name={name} value={value === null ? '' : value} onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e)}>
					{children}
				</SelectInput>
				<IconWrapper>
					{!disabled ? <Icon color={'var(--dark'} name={'chevron-down'} /> : null}
				</IconWrapper>
			</InputWrapper>
		</SelectWrapper>
	)
}

const SelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5em;
	position: relative;
`
const Label = styled.label`
	font-size: .8em;
	font-weight: 600;
	transition: .3s ease all;
	position: absolute;
	top: 0;
	left: 0;
	color: ${transparentize(0.3, colors.dark)};
	${({ disabled }: { disabled?: boolean }) => disabled ? 'padding-left: .7em;' : null}
`
const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	margin-top: 1.1em;
	margin-right: 1em;
`
const SelectInput = styled.select`
	all: unset;
	padding: 0.5em;
	border: 1px solid var(--light);
	border-radius: var(--border-radius);
	position: relative;
	transition: .3s ease all;
	&:disabled {
		border: 1px solid transparent;
		background-color: transparent;
		transform: translateY(-.25em);
		border-radius: 0;
	}
`
const IconWrapper = styled.div`
	position: absolute;
	right: 0.25em;
	z-index: 1;
	top: calc(50% + 3px);
	transform: translateY(-50%);
	pointer-events: none;
`

const RequiredStar = styled.span`
	color: red
`

export default Select
