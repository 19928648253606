import styled from 'styled-components/macro'
import { colors } from 'styles'

export const Checkbox = (props: any) => {
	// const [checked, setChecked] = useState(props.checked)

	return (
		<InputWrapper>
			<Input
				id={props.id}
				checked={props.checked}
				type={'checkbox'}
				onChange={e => {
					props.onChange(e.target.checked)
				}}
			/>
			<CheckboxLabel htmlFor={props.id}>{props.label}</CheckboxLabel>
		</InputWrapper>
	)
}

export default Checkbox

const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0.5em 0px;
	gap: 0.25em;
	position: relative;
`
const CheckboxLabel = styled.label`
	position: relative;
	padding-left: 2em;
	font-size: 0.6em;
	color: #666;
	color: ${colors.dark};
	transition: 0.3s ease all;
	&:before {
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 14px;
		height: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		border: 1px solid ${colors.light};
		transition: 0.3s ease all;
	}
	&:after {
		transition: 0.3s ease all;
		content: '';
		position: absolute;
		max-height: 0;
		height: 10px;
		width: 10px;
		top: 2px;
		left: 2px;
		transform: translateY(3px);
		border-radius: 1px;
		background: transparent;
	}
`
const Input = styled.input`
	appearance: none;
	width: 0;
	height: 0;
	opacity: 0;
	margin: 0;
	position: absolute;
	&:after {
		transition: 0.3s ease all;
		content: '';
		position: absolute;
		max-height: 0;
		height: 1em;
		top: 0;
		border-radius: 1px;
		background: transparent;
	}
	&:checked {
		transition: 0.3s ease all;
		& ~ ${CheckboxLabel} {
			&:before {
				transition: 0.3s ease all;
				border: 1px solid ${colors.primary};
			}
			&:after {
				transition: 0.3s ease all;
				content: '';
				position: absolute;
				top: 2px;
				left: 2px;
				width: 10px;
				height: 10px;
				max-height: 14px;
				border-radius: 1px;
				transform: translateY(0px);
				background-color: ${colors.primary};
			}
		}
	}
`
