import { useEndpoints } from './endpoints'
import { useQuery,  useMutation, UseQueryOptions, MutationFunction } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import * as turf from '@turf/turf'
import { AxiosError } from 'axios'

export const useGetPastureFeaturesQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Structure[], AxiosError>>) => {
	const { structure } = useEndpoints()
	return useQuery<Structure[], AxiosError>({
		queryKey: ['structures'],
		queryFn: () => structure.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useGetPastureFeatureQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Structure, AxiosError>>) => {
	const { structure } = useEndpoints()
	return useQuery<Structure, AxiosError>({
		queryKey: ['structures', payload.id],
		queryFn: () => structure.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

export const useCreatePastureFeatureMutation = (options?: Partial<MutationFunction<Structure, AxiosError>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['structure.mutation.create'],
		onMutate:  (payload: Structure) => {
        
			queryClient.cancelQueries({ queryKey: ['map-features'] })
			queryClient.cancelQueries({ queryKey: ['structures'] })
			const previousMapFeatures = queryClient.getQueryData<MapFeature[]>(['map-features'])
			const previousStructures = queryClient.getQueryData<Structure[]>(['structures'])
			queryClient.setQueryData(['map-features'], (old: turf.FeatureCollection) => {
				const features = old?.features || []
				if(payload.geometry?.coordinates.length) {
					const { id, geometry, ...rest } = payload
					const feature = turf.feature(geometry, rest, { id })
					const updatedFeatures = [...features, feature] as turf.Feature[]
					return turf.featureCollection(updatedFeatures)
				} 
				return old
			})
			queryClient.setQueryData(['structures'], (old: Structure[]) => old ? [...old, payload] : [payload])
			queryClient.setQueryData(['structures', payload.id], {...payload, id: payload.id})
			return {previousMapFeatures, previousStructures}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['structures'] })
			queryClient.invalidateQueries({ queryKey: ['structures', variables.id] })
			queryClient.invalidateQueries({ queryKey: ['map-features'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['map-features'], context?.previousMapFeatures)
			queryClient.setQueryData(['structures'], context?.previousStructures)
			queryClient.removeQueries({ queryKey: ['structures', variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const updatePastureFeature =  (payload: Structure) => client.put(`/PastureFeature/${payload.id}`, payload).then((res) => res.data)
export const useUpdatePastureFeatureMutation = (options?: Partial<MutationFunction<Structure, AxiosError>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['structure.mutation.update'],
		// mutationFn: updatePastureFeature,
		onMutate:  (payload: Structure) => {
			queryClient.cancelQueries({ queryKey: ['structures'] })
			queryClient.cancelQueries({ queryKey: ['structures', payload.id] })
			queryClient.cancelQueries({ queryKey: ['map-features'] })
			const structures = queryClient.getQueryData<Structure[]>(['structures'])
			const mapFeatures = queryClient.getQueryData<Structure[]>(['map-features'])
			const structure = queryClient.getQueryData<Structure[]>(['structures', payload.id])
			queryClient.setQueryData(['map-features'], (old: turf.FeatureCollection) => {
				const features = old?.features.filter(feature => feature.id !== payload.id) || []
				if(payload.geometry?.coordinates.length) {
					const { id, geometry, ...rest } = payload
					const feature = turf.feature({ type: 'Feature', id: id, geometry: geometry, properties: rest })
					const updatedFeatures = [...features, feature] as turf.Feature[]
					return turf.featureCollection(updatedFeatures)
				} 
				return turf.featureCollection(features)
			})
			queryClient.setQueryData(['structures'], (old: Structure[]) => old ? old.map(structure => structure.id === payload.id ? {...structure, ...payload} : structure) : [])
			queryClient.setQueryData(['structures', payload.id], payload)
			return {structures, structure, mapFeatures}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['map-features'] })
			queryClient.invalidateQueries({ queryKey: ['structures'] })
			queryClient.invalidateQueries({ queryKey: ['structures', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['structures'], context?.structures)
			queryClient.setQueryData(['map-features'], context?.mapFeatures)
			queryClient.setQueryData(['structures', variables.id], context?.structure)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const deletePastureFeature =  (payload: { id: string }) => client.delete(`/PastureFeature/${payload.id}`, { data: payload }).then((res) => res.data)
export const useDeletePastureFeatureMutation = (options?: Partial<MutationFunction<Structure, AxiosError>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['structure.mutation.delete'],
		// mutationFn: deletePastureFeature,
		onMutate:  (payload: { id: string }) => {
			queryClient.cancelQueries({ queryKey: ['structures'] })
			queryClient.cancelQueries({ queryKey: ['structures', payload.id] })
			queryClient.cancelQueries({ queryKey: ['map-features'] })
			const previousStructures = queryClient.getQueryData<Structure[]>(['structures'])
			const previousStructure = queryClient.getQueryData<Structure[]>(['structures', payload.id])
			const previousMapFeatures = queryClient.getQueryData<MapFeature[]>(['map-features'])
			queryClient.setQueryData(['structures'], previousStructures ? previousStructures.filter(pastureFeature => pastureFeature.id !== payload.id) : [])
			queryClient.setQueryData(['map-features'], (old: turf.FeatureCollection) => {
				if (!old || !old?.features) return
				const updated = { ...old, features: old.features.filter(feature => feature.id !== payload.id) }
				return updated
			})
			queryClient.removeQueries({ queryKey: ['structures', payload.id] })
			return { pastureFeatures: previousStructures, pastureFeature: previousStructure, mapFeatures: previousMapFeatures }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['structures'] })
			queryClient.invalidateQueries({ queryKey: ['structures', variables.id] })
			queryClient.invalidateQueries({ queryKey: ['structures'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['structures'], context?.pastureFeatures)
			queryClient.setQueryData(['structures', variables.id], context?.pastureFeature)
			queryClient.setQueryData(['map-features'], context?.mapFeatures)
		},
		networkMode: 'offlineFirst',
		...options
	})
}