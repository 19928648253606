import { useEffect, useState, useRef, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { RootState, store } from 'store'
import { animalTrailSlice } from 'store/animalTrail.slice'
import { grazingDistributionSlice } from 'store/grazingDistribution.slice'
import { boundarySlice } from 'pages/panels/boundaries/boundary.slice'
import { animalSlice } from 'pages/panels/animals/animal.slice'
import { structureSlice } from 'pages/panels/structures/structure.slice'
import { mapSlice } from 'store/map.slice'
import { routeSlice } from 'store/route.slice'
import 'react-spring-bottom-sheet/dist/style.css'
import { pastureRouteSlice } from 'pages/panels/pastureRoutes/pastureRoute.slice'
import { useSelector } from 'react-redux'

export const BottomSheetOutlet = () => {
	const bottomSheet = useRef<any>(null)
	const location = useSelector((state: RootState) => state.routeSlice.location)
	const scrollLocking = useSelector((state: RootState) => state.routeSlice.scrollLocking)
	const snapToIndex = useSelector((state: RootState) => state.routeSlice.snapToIndex)
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)
	const Header = useSelector((state: RootState) => state.routeSlice.header)
	const Footer = useSelector((state: RootState) => state.routeSlice.footer)
	const sidebarHeight = useSelector((state: RootState) => state.routeSlice.sidebarHeight)

	const [isOpen, setIsOpen] = useState<boolean>(false)

	//* If you need to reset the state of something when navigating away from a panel, do it here
	const resetState = useCallback(() => {
		store.dispatch(animalTrailSlice.actions.reset())
		store.dispatch(mapSlice.actions.reset())
		store.dispatch(grazingDistributionSlice.actions.reset())
		store.dispatch(boundarySlice.actions.reset())
		store.dispatch(structureSlice.actions.reset())
		store.dispatch(animalSlice.actions.reset())
		store.dispatch(routeSlice.actions.resetBottomSheet())

		if (store.getState().pastureRouteSlice.mode === 'CreateRouteMode') {
			store.dispatch(pastureRouteSlice.actions.resetMaintainRoute())
		} else {
			store.dispatch(pastureRouteSlice.actions.reset())
		}

	}, [])

	useEffect(() => {
		resetState()
		setIsOpen(false)
		//* Timeout is so the page doesn't transition before the bottom sheet closes
		setTimeout(() => {
			//* Prevent the bottom sheet from opening when navigating to the home page
			if (location.pathname !== '/') {
				store.dispatch(routeSlice.actions.setSnapToIndex(1))
			} else {
				store.dispatch(routeSlice.actions.setSnapToIndex(-1))
			}
		}, 500)
	}, [location.pathname, resetState, isMobile])

	useEffect(() => resetState(), [resetState])

	useEffect(() => {
		if (snapToIndex > 0) {
			setIsOpen(true)
			bottomSheet?.current?.snapTo(({ snapPoints }: { snapPoints: number[] }) => Math.max(...snapPoints))
		}
		if (snapToIndex === 0) {
			setIsOpen(true)
			bottomSheet?.current?.snapTo(({ snapPoints }: { snapPoints: number[] }) => Math.min(...snapPoints))
		}
		if (snapToIndex < 0) {
			setIsOpen(false)
		}
	}, [snapToIndex, bottomSheet])

	useEffect(() => {
		const handleResize = (e?: Event) => {
			bottomSheet?.current?.snapTo(({ snapPoints }: { snapPoints: number[] }) => Math.max(...snapPoints))
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<>
			<BottomSheet
				scrollLocking={scrollLocking}
				expandOnContentDrag={true}
				ref={bottomSheet}
				header={Header}
				footer={Footer}
				defaultSnap={({ lastSnap, snapPoints }) =>
					lastSnap ?? Math.max(...snapPoints)
				}
				snapPoints={({ maxHeight }) => {
					return [(maxHeight * 0.1) + sidebarHeight, maxHeight * 0.9]
				}}
				open={isOpen}
				blocking={false}
			>
				<Outlet context={bottomSheet?.current} />
			</BottomSheet>
		</>
	)
}