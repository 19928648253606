import Address from './address.model'
import { CreateRanch } from './ranch.model'
import { CreateUser } from './user.model'
import * as uuid from 'uuid'
export class Account {
	id: string
	name: string
	phone: string
	trialExpires: Date | null
	createdDate: Date
	limited: boolean
	pricingModelId: string
	deviceCount: number
	nextRenewalDate: Date | null
	addressId: string | null
	billingAddressId?: string | null
	billingSameAddress: boolean
	imageFile?: ImageFile | null
	address: Address | null
	billingAddress: Address | null
	accountOwner: Account | null
	ranches: Ranch[]

	constructor(props?: Partial<Account>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.phone = props?.phone || ''
		this.trialExpires = props?.trialExpires || null
		this.createdDate = props?.createdDate || new Date()
		this.limited = props?.limited || false
		this.pricingModelId = props?.pricingModelId || ''
		this.deviceCount = props?.deviceCount || 0
		this.nextRenewalDate = props?.nextRenewalDate || null
		this.addressId = props?.addressId || null
		this.billingAddressId = props?.billingAddressId || null
		this.billingSameAddress = props?.billingSameAddress || false
		this.address = props?.address || new Address()
		this.billingAddress = props?.billingAddress || new Address()
		this.accountOwner = props?.accountOwner || null
		this.ranches = props?.ranches || []
	}
}
export class CreateAccount {
	id: string
	name: string
	address: Address
	user: CreateUser
	phone: string
	ranch: CreateRanch
    
	constructor(props?: Partial<CreateAccount>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.address = props?.address || new Address()
		this.user = props?.user || new CreateUser()
		this.phone = props?.phone || ''
		this.ranch = props?.ranch || new CreateRanch({name: '', address: new Address(), addressSameAsAccount: true})
	}
}

export default Account
