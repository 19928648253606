import styled from 'styled-components/macro'
// const card = ({ className, before, after, children }: any) => (
// 	<div className={className}>
// 		{before ? before : null}
// 		{children}
// 		{after ? after : null}
// 	</div>
// )

export const Card = styled.div`
	position: relative;
	padding: 0.5em;
	background: #ffffff;
	border-radius: var(--border-radius);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	color: var(--dark);
	transition: 0.3s cubic-bezier(0, 0, 0, 1.8) all;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
`
