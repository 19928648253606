import { lighten, transparentize } from 'polished'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { colors } from 'styles'

export const GenderList = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [selected, setSelected] = useState<{ name: string; value: string | null }>({ name: 'Unknown', value: null })
	return (
		<Container>
			<List>
				{[
					{ name: 'Unknown', value: null },
					{ name: 'Sire', value: 'Sire' },
					{ name: 'Dam', value: 'Dam' },
				]?.map(gender => (
					<ListItem key={gender.name}>
						<Item htmlFor={gender.name}>
							{gender.name}
							<input
								id={gender.name}
								type={'radio'}
								value={gender.name}
								checked={gender.value === selected.value}
								onChange={e => {
									setSelected(gender)
									navigate(location.state.from.pathname, { replace: true, state: { gender: gender.value } })
								}}
							/>
						</Item>
					</ListItem>
				))}
			</List>
		</Container>
	)
}

const Container = styled.div`
	padding: 0.5em;
`
const List = styled.ul`
	padding: 0;
`
const ListItem = styled.li`
	&:not(:last-child) {
		border-bottom: 1px solid ${lighten(0.15, colors.light)};
	}
`

const Item = styled.label`
	display: flex;
	padding: 0.5em;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		background: ${transparentize(0.9, colors.light)};
	}
`
