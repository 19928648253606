import styled from 'styled-components'
import { RadioList } from 'components/inputs'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { CardAction, CardActions } from 'components/cards'
import { pastureRouteSlice } from '../pastureRoutes/pastureRoute.slice'
import { routeSlice } from 'store/route.slice'
import { useSelector } from 'react-redux'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import useCardBuffer from 'hooks/useCardBuffer'
import LoadingSpinner from 'components/LoadingSpinner'
import { animalSlice } from '../animals/animal.slice'
import { RootState, store } from 'store'
import { useGetHerdQuery } from 'library/api/herd'
import { useGetPasturesQuery } from 'library/api/pasture'
import { useGetPastureRoutesByPastureIdsQuery } from 'library/api/pastureRoute'
import { Pasture } from 'library/models/pasture.model'

export const MoveHerdForm = () => {
	const { id } = useParams<QueryParams<{id: string}>>()
	const navigate = useNavigate()
	const { MapView } = useMap()
	const card = useCardBuffer()

	const { data: herd, isLoading: herdIsLoading } = useGetHerdQuery({id: id})
	const { data: pastures } = useGetPasturesQuery()
	
	const selectedDestinationPasture = useSelector((state: RootState) => state.pastureRouteSlice.selectedDestinationPasture)
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)
	
	const [selectedFromPasture, setSelectedFromPasture] = useState<{title: string, value: string} | undefined>(herd?.pasture ? {title: herd?.pasture?.name, value: herd?.pasture?.id} : undefined)
	const [selectedToPasture, setSelectedToPasture] = useState<{title: string, value: string}>()
	
	const { data: routes, isLoading: getRoutesIsLoading, refetch } = useGetPastureRoutesByPastureIdsQuery({ fromId: selectedFromPasture?.value, toId: selectedToPasture?.value }, { enabled: !!selectedFromPasture && !!selectedToPasture, retry: false })

	// Set up state: mode, and clear selection
	useEffect(() => {
		store.dispatch(pastureRouteSlice.actions.reset())
		store.dispatch(pastureRouteSlice.actions.setMode('SelectPastureMode'))
	}, [])

	// Load from pasture into dropdown
	useEffect(() => {
		if (!herd) return
		if (herd?.pasture) {
			setSelectedFromPasture({ title: herd?.pasture?.name, value: herd?.pasture?.id })
			store.dispatch(pastureRouteSlice.actions.setSelectedFromPasture(herd?.pasture))
		}
	}, [herd, herdIsLoading])

	// Zoom into route
	useEffect(() => {
		if (!herd?.pasture?.geometry) return
		const [n,e,s,w] = turf.bbox(herd?.pasture?.geometry)
		if (isMobile) {
			MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth, top: 0, bottom: 0, right: 0 } })
		} else {
			MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		}
	}, [MapView, card.bufferWidth, herd?.pasture?.geometry, isMobile])

	// Sync store state with component state. Dropdown data formatting
	useEffect(() => {
		if (!selectedDestinationPasture) return
		setSelectedToPasture({ title: selectedDestinationPasture.name, value: selectedDestinationPasture.id })
		store.dispatch(routeSlice.actions.setSnapToIndex(1))
	}, [selectedDestinationPasture])

	return (
		<>
			<Container>
				<h3>Move {herd?.name}</h3> 
				{selectedFromPasture && <RadioList
					disabled
					header={'From Pasture'}
					items={pastures?.map((pasture: Pasture) => ({title: pasture.name, value: pasture.id}))}
					onChange={() => {}}
					defaultValue={selectedFromPasture ? selectedFromPasture : herd?.pasture ? {title: herd?.pasture?.name, value: herd?.pasture?.id} : undefined}
				/>}
				<RadioList
					header={'To Pasture'}
					items={
						pastures?.filter((pasture: Pasture) => pasture.id !== selectedFromPasture?.value)
							.map((pasture: Pasture) => ({title: pasture.name, value: pasture.id}))
					}
					onChange={async (e: {title: string, value: string}) => {
						const val = pastures?.find((p) => p.id === e.value)
						if (val) store.dispatch(pastureRouteSlice.actions.setSelectedDestinationPasture(val))
						refetch()
					}}
					defaultValue={(selectedToPasture ? selectedToPasture : undefined)}
					onClick={isMobile ? () => {
						store.dispatch(routeSlice.actions.setSnapToIndex(0))
					} : undefined}
				/>
				<CardActions>
					<CardAction
						type={'submit'}
						disabled={ !(selectedToPasture?.value) || getRoutesIsLoading }
						onClick={() => {
							if (!getRoutesIsLoading && routes && routes.length > 0) {
								herd && store.dispatch(animalSlice.actions.setHerd(herd))
								navigate(`/routes/${herd?.pasture?.id}/${selectedToPasture?.value}`)
							} else {
								herd && store.dispatch(animalSlice.actions.setHerd(herd))
								navigate(`/routes/${selectedFromPasture?.value}/${selectedToPasture?.value}/add`)
							}
						}}
					>
						{(selectedToPasture?.value) && getRoutesIsLoading ? <LoadingSpinner/> : 'Next'}
					</CardAction>
					<CardAction
						type={'button'}
						variant={'dark'}
						onClick={() => navigate(-1)}
					>
						Cancel
					</CardAction>
				</CardActions>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
`
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Label = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1em;
    color: rgba(255,255,255,.75);
    padding: .75em 1em;
    pointer-events: none;
    transition: all .25s ease-in-out;
    ${InputWrapper}:focus-within & {
        font-size: .75em;
        top: -2.3em;
        left: -1em;
        color: rgba(255,255,255,.85);
    }

`
