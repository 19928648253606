// Highlight text from argument 1 based on matching text from argument 2
export const highlight = (str: string, match?: string | null) => {
	if (!match) return str
	const index = str.toLowerCase().indexOf(match.toLowerCase())
	if (index === -1) return str
	return (
		<span>
			{str.substring(0, index)}
			<mark style={{ padding: 0 }}>{str.substring(index, index + match.length)}</mark>
			{str.substring(index + match.length)}
		</span>
	)
}

// Takes in a hex color value and returns the color in an array of rgba values [r, g, b, a]
export const hexToRGB = (hex: string, opacity = 255): [number, number, number, number] => {
	if (!hex) return [255, 255, 255, opacity] // default to white if hex is not provided
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), opacity] : [0, 0, 0, opacity]
}

export const sortByDate = (items: any, prop: string) => {
	return items.sort((a: any, b: any) => {
		return new Date(a[prop]).getTime() - new Date(b[prop]).getTime()
	})
}

export const formatCoordinates = (coords: number[] | null | undefined) => {
	if (coords === null || coords === undefined || coords.length !== 2) return 'Not Available'
	const [lng, lat] = coords
	if (lat !== undefined && lng !== undefined) {
		return `${Math.abs(lat).toFixed(5)}° ${lat < 0 ? 'S' : 'N'}, ${Math.abs(lng).toFixed(5)}° ${lng < 0 ? 'W' : 'E'}`
	}
}

export const cambelCaseToTitle = (str: string) => str.replace(/([A-Z0-9])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase())

export const kebabize = (str: string | undefined) => (str ? str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()) : str)
export const camelize = (str: string | undefined) => (str ? str.replace(/-./g, x => x[1].toUpperCase()) : str)

export const formatPhoneNumber = (phone?: string) => {
	const cleaned = String(phone).replace(/\D/g, '')
	const m = cleaned.match(/^(\d{0,3})?[- ]??[\s]?(\d{0,3})?[\s]?(\d{0,4})?(.*)?$/);

	if(!m) return '';
	if(m[1] && !m[2]) return `(${m[1]}`
	if(m[1] && m[2] && !m[3]) return `(${m[1]}) ${m[2]}`
	if(m[1] && m[2] && m[3] && !m[4]) return `(${m[1]}) ${m[2]}-${m[3]}`
	if(m[1] && m[2] && m[3] && m[4]) return `(${m[1]}) ${m[2]}-${m[3]} x${m[4]}`
	return ''
}

export const getFeatureTypeByGeometryType = (geometryType: string) => {
	switch (geometryType) {
	case 'Point':
		return 'Structure'
	case 'LineString':
		return 'Road'
	case 'Polygon':
		return 'Boundary'
	default:
		return 'Structure'
	}
}

export const convertBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);

		fileReader.onload = () => {
			resolve(fileReader.result as string);
		};

		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};

export const convertImageToBase64 = async (image: File) => {
	try {
		const base64: string = await convertBase64(image) as string
		const img = base64.split(',')[1]
		return img
	} catch (error) {
		console.log(error)
	}
}

/**
 * @name lerp
 * @description Get the value between two numbers at a specified decimal midpoint
 * @param x start input range
 * @param y end input range
 * @param a float between 0 and 1
 * @returns interpolated value
 */
export const lerp = (x: number, y: number, a: number): number => x * (1 - a) + y * a;

/**
 * @name clamp
 * @description clamp a value within a range
 * @param a current input value
 * @param min start input range
 * @param max end input range
 * @returns value within range
 */
export const clamp = (a: number, min = 0, max = 1): number => Math.min(max, Math.max(min, a));

/**
 * @name invlerp
 * @description returns based on the input range and value
 * @param x start input range
 * @param y end input range
 * @param a current input value
 * @returns float between 0 and 1
 */
export const invlerp = (x: number, y: number, a: number): number => clamp((a - x) / (y - x));



/**
 * @name range
 * @description interpolate a value from one range to another
 * @param x1 start input range
 * @param y1 end input range
 * @param x2 start output range
 * @param y2 end output range
 * @param a current input value
 * @returns interpolated value
 */
export const range = (x1: number, y1: number, x2: number, y2: number, a: number): number => lerp(x2, y2, invlerp(x1, y1, a));

export const formatImageForUpload = async (id: string, image: File): Promise<ImageUpload> => {
	const data = await convertBase64(image)
	return {
		id: id,
		fileName: id + '.jpg',
		contentType: 'image/jpg',
		data: data
	}
}

export const getHeightAndWidthFromDataUrl = (dataURL: string) => new Promise((resolve: (value: {width: number, height: number}) => void) => {
	const img = new Image()
	img.onload = () => {
		resolve({
			height: img.height,
			width: img.width
		})
	}
	img.src = dataURL
})