export const colors = {
	primary: '#EB5824',
	navy: '#001C33',
	blue: '#155684',
	wheat: '#B28539',
	offwhite: '#FFF6ED',
	error: '#CA4249',
	warning: '#F2C94C',
	success: '#1bb89a',
	water: '#1DA9D7',
	mineral: '#D8B2B2',
	dark: '#001C33',
	light: '#FFF6ED',
	background: '#f4f4f6',
}
export const colorsOG = {
	primary: '#CA4249',
	warning: '#F2C94C',
	success: '#1bb89a',
	water: '#1DA9D7',
	mineral: '#D8B2B2',
	dark: '#2c3547',
	light: '#bcc0c9',
	background: '#f4f4f6',
}

export const size = {
	mobileS: 320,
	mobileM: 375,
	mobileL: 425,
	mobile: 480,
	tablet: 768,
	laptop: 1024,
	laptopL: 1440,
	desktop: 2560,
}

export const device = {
	size: {
		mobileS: 320,
		mobileM: 375,
		mobileL: 425,
		mobile: 480,
		tablet: 768,
		laptop: 1024,
		laptopL: 1440,
		desktop: 2560,
	},

	min: {
		mobileS: `(min-width: ${size.mobileS + 1}px)`,
		mobileM: `(min-width: ${size.mobileM + 1}px)`,
		mobileL: `(min-width: ${size.mobileL + 1}px)`,
		mobile: `(min-width: ${size.mobile + 1}px)`,
		tablet: `(min-width: ${size.tablet + 1}px)`,
		laptop: `(min-width: ${size.laptop + 1}px)`,
		laptopL: `(min-width: ${size.laptopL + 1}px)`,
		desktop: `(min-width: ${size.desktop + 1}px)`,
		desktopL: `(min-width: ${size.desktop + 1}px)`,
	},
	max: {
		mobileS: `(max-width: ${size.mobileS}px)`,
		mobileM: `(max-width: ${size.mobileM}px)`,
		mobileL: `(max-width: ${size.mobileL}px)`,
		mobile: `(max-width: ${size.mobile}px)`,
		tablet: `(max-width: ${size.tablet}px)`,
		laptop: `(max-width: ${size.laptop}px)`,
		laptopL: `(max-width: ${size.laptopL}px)`,
		desktop: `(max-width: ${size.desktop}px)`,
		desktopL: `(max-width: ${size.desktop}px)`,
	},
	touchScreen: '(hover: none) and (pointer: coarse)',
	stylus: '(hover: none) and (pointer: fine)',
	mouse: '(hover: hover) and (pointer: fine)',
}
export const getSafeArea = () => ({
	top: Number(getComputedStyle(document.documentElement).getPropertyValue('--sat').replace(/\D/g, '')),
	bottom: Number(getComputedStyle(document.documentElement).getPropertyValue('--sab').replace(/\D/g, '')),
	left: Number(getComputedStyle(document.documentElement).getPropertyValue('--sal').replace(/\D/g, '')),
	right: Number(getComputedStyle(document.documentElement).getPropertyValue('--sar').replace(/\D/g, '')),
})
const LightenDarkenColor = (color: string, amount: number, pound?: boolean) => {
	let usePound = false

	if (color[0] === '#') {
		color = color.slice(1)
		usePound = true
	}
	if (pound !== undefined) usePound = pound
	const num = parseInt(color, 16)

	let r = (num >> 16) + amount

	if (r > 255) r = 255
	else if (r < 0) r = 0

	let b = ((num >> 8) & 0x00ff) + amount

	if (b > 255) b = 255
	else if (b < 0) b = 0

	let g = (num & 0x0000ff) + amount

	if (g > 255) g = 255
	else if (g < 0) g = 0

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

const lighten = (color: string, amount: number, pound?: boolean) => {
	return LightenDarkenColor(color, amount, pound)
}

const darken = (color: string, amount: number, pound?: boolean) => {
	return LightenDarkenColor(color, -amount, pound)
}

const alpha = (hex: string, amount: number) => {
	let c: string[] | number[] | number
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('')
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = Number('0x' + c.join(''))
		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${1 - amount})`
	}
	throw new Error('Bad Hex')
}

export { lighten, darken, alpha }
