import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useCardBuffer from 'hooks/useCardBuffer'
import { RootState, store } from 'store'
import styled from 'styled-components/macro'
import LoadingSpinner from './LoadingSpinner'
import { lighten, transparentize } from 'polished'
import { CowIcon, Icon, MDIcon } from 'components/icons'
import { Link, useNavigate } from 'react-router-dom'
import { readableColor } from 'polished'
import { CardAction } from './cards'
import { alpha, colors } from 'styles'
import { persistentDataSlice } from 'store/persistentData.slice'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import { routeSlice } from 'store/route.slice'

export const PastureRoutesOverlay = () => {
	const { bufferWidth } = useCardBuffer()
	const navigate = useNavigate()

	const overlayRef = useRef<HTMLDivElement>(null)

	const overlays = useSelector((state: RootState) => state.persistentDataSlice.pastureRouteOverlays)
	const visiblePastureRouteOverlay = useSelector((state: RootState) => state.persistentDataSlice.visiblePastureRouteOverlay)
	const bottomSheetIsMinimized = useSelector((state: RootState) => state.routeSlice.snapToIndex)


	useEffect(() => {
		store.dispatch(persistentDataSlice.actions.setOverlayHeight(overlayRef?.current?.getBoundingClientRect().height || 0))
		// store.dispatch(routeSlice.actions.setHeightOffset(overlayRef?.current?.getBoundingClientRect().height || 0))
	}, [visiblePastureRouteOverlay, overlayRef])

	const inputBuffer = useMemo(() => (bottomSheetIsMinimized < 1 ? 0 : bufferWidth), [bottomSheetIsMinimized, bufferWidth])


	const handleViewAction = async (route: any) => {
		// const destinationPasturePoint = turf.centerOfMass(route?.toPastureData?.geometry)

		// const combinedAnimalFeatures = turf.featureCollection(route?.animals.map((animal: Animal) => {
		// 	return animal?.lastCoordinate?.length ? turf.point(animal?.lastCoordinate) :  null
		// }).filter((feature: any) => {
		// 	if (!feature) return false
		// 	return turf.distance(feature.geometry, destinationPasturePoint) > 1
		// }))

		// const routeCenter = turf.centerOfMass(route?.geometry)
		// const features = [...combinedAnimalFeatures.features, routeCenter]
		// const combinedFeatures = turf.featureCollection(features)
		// const [minLng, minLat, maxLng, maxLat] = turf.bbox(combinedFeatures)
		// MapView?.fitBounds([Math.max(minLng, -90), Math.max(minLat, -90), Math.min(maxLng, 90), Math.min(maxLat, 90)], { padding: { left: bufferWidth + 0, top: 0, bottom: 0, right: 0 } })
		
		navigate(`/routes/${route?.routeId}`)
	}
    
	return <Container $inputMargin={inputBuffer}>
		{overlays ?
			(overlays.filter((r: any) => r.herdId === visiblePastureRouteOverlay).map((route: any) => {
				const parsedColor = route?.style?.color ? route?.style?.color : '#222222'
				
				return (
					<PastureRouteOverlayWrapper
						ref={overlayRef}
						key={route.routeId}
						$backgroundColor={parsedColor}
					>
						<PastureRouteOverlayProgressBarWrapper
							$backgroundColor={readableColor(parsedColor)}
						>
							<CowIcon
								style={{
									position: 'absolute',
									left: `calc(${route.progress}% - 12px)`,
									top: 'calc(50% - 12px)',
									zIndex: 1,
								}}
								color={readableColor(parsedColor)}
							/>
							<PastureRouteOverlayProgressBar
								$color={parsedColor}
								style={{
									width: `${route.progress}%`,
								}}
							/>
						</PastureRouteOverlayProgressBarWrapper>
						<PastureRouteInfoWrapper>
							<Destinations>
								<PastureLink to={`/pastures/${route?.fromPastureId}`}>
									{route.fromPasture}
								</PastureLink>
								<MDIcon color={readableColor(parsedColor)} size={16} name={'arrow_forward'} />
								<PastureLink to={`/pastures/${route?.toPastureId}`}>
									{route.toPasture}
								</PastureLink>
							</Destinations>
							<Details>
								<ViewRouteLink onClick={() => {handleViewAction(route)}}>
									{route.progress}% Complete
								</ViewRouteLink>
							</Details>
						</PastureRouteInfoWrapper>
					</PastureRouteOverlayWrapper>
				)
			}))
			:
			<LoadingSpinner />
		}
	</Container>
};

const Container = styled.div<{ $inputMargin?: number; }>`
	text-align: center;
	display: flex;
	flex-direction: column;
	z-index: 100;
	transition: 0.2s ease-in-out 0.2s;
	margin-top: .5em;
`

const Destinations = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: .5em;
	margin-top: .5em;
`
const Details = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: .5em;
	margin-top: .5em;
`

const PastureRouteInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	color: black;
	justify-content: space-between;
	flex-wrap: wrap;
`
const PastureLink = styled(Link)`
	text-decoration: none;
	display: inline-block;
	font-size: .7em;
	vertical-align: middle;
	line-height: normal;
	font-weight: 600;
	color: ${colors.dark};
	background: ${alpha(colors.dark, 0.95)};
	padding: .5em 1em;
	border-radius: var(--border-radius);
	transition: .3s ease all;
	&:hover {
		background: ${alpha(colors.dark, 0.9)};
	}
`
const ViewRouteLink = styled(CardAction)`
	display: flex;
	flex-direction: row;
	gap: .25em;
	padding: .5em 1em;
`
const PastureRouteOverlayProgressBarWrapper = styled.div<{ $backgroundColor?: string; }>`
	width: 100%;
	height: 8px;
	border-radius: 4px;
	margin: auto;
	position: relative;
	background-color: ${colors.background};
	flex-grow: 2;
`
const PastureRouteOverlayProgressBar = styled.div<{ $color?: string; }>`
	position: relative;
	height: 8px;
	border-radius: 4px;
	// top: calc(50% - 2px);
	margin: auto 0;
	background-color: ${props => props.$color};
`
const PastureRouteOverlayWrapper = styled.div<{ $backgroundColor?: string; }>`
	display: flex;
	flex-direction: column;
	padding: 1em;
	border-radius: var(--border-radius);
	gap: .5em;
	width: 100%;
	box-shadow: var(--box-shadow);
	position: relative;
	background-color: ${props => props.$backgroundColor ? lighten(.3, props.$backgroundColor) : 'white'};
`

export default PastureRoutesOverlay