import { useState, useRef, useEffect } from 'react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { Icon, MDIcon } from 'components/icons'
import { darken, readableColor, transparentize } from 'polished'
import { useTransition, animated, config } from '@react-spring/web'
import { useGetUserQuery, useUpdateUserActiveRanchMutation } from 'library/api/user'
import { RoundCloseButton } from 'components/buttons'
import { useNavigate } from 'react-router-dom'
import { RootState, persistor, store } from 'store'
import { ProfileImage } from 'components/ProfileImage'
import { PastureRoutesToolbar } from './PastureRoutesToolbar'
import { useSelector } from 'react-redux'
import { persistentDataSlice } from 'store/persistentData.slice'
import LoadingSpinner from 'components/LoadingSpinner'
import { useQueryClient } from '@tanstack/react-query'
// import { Link } from "react-router-dom";
const Toolbar = () => {
	const panelRef = useRef<HTMLDivElement>(null)
	const profileWrapperRef = useRef<HTMLDivElement>(null)
	const dropdownRef = useRef<HTMLDivElement>(null)
	const { data: user } = useGetUserQuery()
	const { mutate: setActiveRanch } = useUpdateUserActiveRanchMutation()
	const [showDropdown, setShowDropdown] = useState(false)
	const [boola, setBoola] = useState(false)
	const [activePanel, setActivePanel] = useState('default')
	const { instance } = useMsal()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const pastureRouteOverlays = useSelector((state: RootState) => state.persistentDataSlice.pastureRouteOverlays)
	const visiblePastureRouteOverlay = useSelector((state: RootState) => state.persistentDataSlice.visiblePastureRouteOverlay)
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)

	function signOutClickHandler(instance: IPublicClientApplication) {
		localStorage.removeItem('has_account')
		persistor.purge()
		queryClient.clear()
		const logoutRequest = {
			account: instance.getActiveAccount(),
			mainWindowRedirectUri: window.location.origin,
			postLogoutRedirectUri: window.location.origin,
		};
		instance.logoutRedirect(logoutRequest);
	}

	const DefaultPanel = () => (
		<>
			{isMobile && <>
				<RoutesListWrapper>
					<Label>In Progress Routes:</Label>

					<RouteIconList>
						{pastureRouteOverlays ?
							(pastureRouteOverlays.map((route: any) => {	
								const colorJson = route?.style?.color ? route.style.color : '#222222'
								
								return (
									<RouteIcon
										$backgroundColor={colorJson}
										$enabled={!!(visiblePastureRouteOverlay === route?.herdId)}
										key={route?.herdId}
										onClick={() => {
											const newValue = visiblePastureRouteOverlay === route?.herdId ? '' : route?.herdId
											store.dispatch(persistentDataSlice.actions.setVisiblePastureRouteOverlay(newValue))
										}}
									>
										<RouteTextContainer>
											<RouteText>{route?.progress}%</RouteText>
										</RouteTextContainer>
									</RouteIcon>
								)
							}))
							:
							<LoadingSpinner />
						}
					</RouteIconList>
				</RoutesListWrapper>
				<Hr />
			</>}
			
			{user && user?.ranchRoles?.length > 1 ? (
				<>
					{/* <NavItem
							to={'/notifications'}
							onClick={() => {
								setShowDropdown(false)
							}}>
							Notifications
							<Icon name={'chevron-right'} />
						</NavItem> */}
					<Item
						onClick={() => {
							setActivePanel('ranches')
						}}>
						Change Ranch <Icon name={'chevron-right'} />
					</Item>
					<Hr />
				</>
			) : null}
			{user ?
				<Item onClick={() => {
					setShowDropdown(false)
					navigate('/account')
				}
				}>
					Account
				</Item> 
				:
				null
			}
			<Item onClick={() => window.location.reload()}>Reload</Item>
			<Item onClick={() => {
				setShowDropdown(false)
				navigate('/account/support')}}>Support</Item>
			<Item onClick={() => signOutClickHandler(instance)}>Log out</Item>
			
		</>
	)

	const RanchesPanel = () => (
		<>
			{user?.ranchRoles.map((role: RanchRole) => (
				<Item
					key={role.ranchId}
					onClick={() => {
						setShowDropdown(false)
						setActiveRanch({ id: role.ranchId })
					}}>
					{role.ranchName}
				</Item>
			))}
			<Hr />
			<BackButton onClick={() => setActivePanel('default')}>
				<Icon name={'left-arrow-alt'} /> Back
			</BackButton>
		</>
	)
	const panels = {
		default: DefaultPanel,
		ranches: RanchesPanel,
	}

	const dropdownTransition = useTransition(showDropdown, {
		from: (item: boolean) => {
			return {
				opacity: 0,
				transform: `translate3d(${100 * Number(item)}%, 0, 0)`,
			}
		},
		enter: (item: boolean, blah) => {
			return {
				opacity: 1,
				transform: 'translate3d(0, 0, 0)',
			}
		},
		leave: (item: boolean, blah) => {
			return {
				opacity: 0,
				transform: `translate3d(-${1 * 100}%, 0, 0)`,
			}
		},
		reverse: showDropdown,
		config: config.stiff,
	})
	const panelTransitions = useTransition(activePanel, {
		from: {
			position: 'absolute',
			opacity: 0,
			transform: 'translate3d(100%, 0, 0)',
			width: '100%',
		},
		enter: {
			position: 'absolute',
			opacity: 1,
			transform: 'translate3d(0, 0, 0)',
			width: '100%',
		},
		leave: {
			position: 'absolute',
			opacity: 0,
			transform: 'translate3d(0%, 100%, 0)',
			width: '100%',
		},
		config: config.stiff,
	})

	useEffect(() => {
		const handleWindowClick = (e: any) => {
			const path = e.composedPath()
			if (showDropdown && !path.includes(profileWrapperRef?.current)) {
				setShowDropdown(false)
				setActivePanel('default')
				window.removeEventListener('click', handleWindowClick)
			}
		}
		if (showDropdown) {
			window.addEventListener('click', handleWindowClick)
		} else {
			setTimeout(() => setActivePanel('default'), 300)
			window.removeEventListener('click', handleWindowClick)
		}
		return () => window.removeEventListener('click', handleWindowClick)
	}, [showDropdown])
	return (
		<Container $showDropdown={showDropdown}>


			<PastureRoutesToolbar/>
			
			<ProfileWrapper ref={profileWrapperRef} id={'toolbar-profile-wrapper'} $showDropdown={showDropdown}>
				<Profile
					$showDropdown={showDropdown}
					onClick={_ => {
						setShowDropdown(!showDropdown)
					}}>
					{/* <Avatar fill={1} name={'account_circle'} /> */}
					{user ? 
						<Avatar user={user} imageSize={40}/> 
						: null}
					<TextWrapper $showDropdown={showDropdown}>
						<UserWrapper>
							<UserName>{user?.name ? user?.name : ''}</UserName>
							{(user?.ranchRoles?.length ?? 0) > 1 ? <Ranch>{user?.currentRanch?.name}</Ranch> : null}
						</UserWrapper>
						<ChevronIcon>
							<Icon rotate={showDropdown ? '180' : null} size={'sm'} name={'chevron-down'} />
						</ChevronIcon>
					</TextWrapper>
				</Profile>
				{dropdownTransition(
					(dropdownStyles, dropdown, t) =>
						dropdown && (
							// @ts-ignore
							<Dropdown ref={dropdownRef} id={'toolbar-profile-dropdown'} as={animated.div} style={dropdownStyles}>
								{panelTransitions((styles, item) => {
									// @ts-ignore
									const Component = panels[item]
									return (
										// @ts-ignore
										<animated.div style={styles}>
											<Panel id={'toolbar-profile-panel'} ref={panelRef}>
												<Component />
											</Panel>
										</animated.div>
									)
								})}
							</Dropdown>
						)
				)}
			</ProfileWrapper>
		</Container>
	)
}



const Container = styled.div<{ $showDropdown: boolean }>`
	grid-area: toolbar;
	display: flex;
	justify-content: space-between;
	position: sticky;
	top: 0;
	height: 55px;
	z-index: ${props => (props.$showDropdown ? 101 : 100)};
	width: auto;
	transition: 0.3s ease all;
	box-sizing: border-box;
	padding: calc(0.5em + env(safe-area-inset-top, 0)) 1em 0.5em 1em;
	${RoundCloseButton} {
		display: none;
	}
	@media ${device.max.mobile} {
		box-sizing: border-box;
		margin: 0.5em;
		transition: 0.5s ease all;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: ${props => (props.$showDropdown ? `1px solid ${darken(0.1, colors.background)}` : '1px solid transparent')};
		box-shadow: ${props => (props.$showDropdown ? 'rgba(0, 0, 0, 0.1) 0px -2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;' : 'none')};
		/* width: ${props => (props.$showDropdown ? 'calc(100% - 1em)' : '56px')}; */
		margin: ${props => (props.$showDropdown ? '0.5em' : '0.5em 0')};
		position: fixed;
		right: 0;
		border-radius: var(--border-radius);
		background-color: ${props => (props.$showDropdown ? '#ffffff' : 'transparent')};
		${RoundCloseButton} {
			display: ${props => (props.$showDropdown ? 'block' : 'none')};
		}
		width: 100%;
		background-color: ${colors.dark};
		margin: 0;
		border: 0;
		border-radius: 0;
		height: auto;
	}
	@media ${device.min.mobile} {
		width: auto;
		border-bottom: 1px solid var(--light);
		background: var(--background);
	}
`

const ProfileWrapper = styled.div<{ $showDropdown: boolean }>`
	@media ${device.min.mobile} {
		position: relative;
	}
	display: flex;
	align-items: center;
	justify-self: flex-end;
	transition: 1s ease all;
	@media ${device.max.mobile} {
		width: 100%;
	}
`
const ChevronIcon = styled.div`
	@media ${device.max.mobile} {
		color: ${colors.light};
	}
`
const TextWrapper = styled.div<{ $showDropdown: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease all;
	gap: 0.5em;
	@media ${device.max.mobile} {
		white-space: nowrap;
		width: 100%;
		color: ${colors.light};
		overflow: hidden;
	}
`
const Dropdown = styled.div`
	position: absolute;
	right: 0;
	top: calc(100% + 1em);
	@media ${device.min.mobile} {
		width: 100%;
		box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
	@media ${device.max.mobile} {
		left: 0.5em;
		right: 0.5em;
		top: calc(env(safe-area-inset-top, 0) + 0.5em);
		padding-top: 55px;
	}
`

const Profile = styled.button<{ $showDropdown: boolean }>`
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 11;
	gap: 0.5em;
	@media ${device.min.mobile} {
		&:hover > *:not(${Dropdown}) {
			opacity: 0.9;
		}
	}
	@media ${device.max.mobile} {
		gap: 0.5em;
		width: 100%;
	}
`
const Panel = styled.div`
	padding: 0.5em;
	background: #ffffff;
	border-radius: var(--border-radius);
	box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	border: 1px solid ${darken(0.1, colors.background)};
	/* width: 100%; */
`
const Avatar = styled(ProfileImage)`
	height: 39px;
	width: 39px;
	aspect-ratio: 1 / 1;
`
const UserWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
const UserName = styled.div`
	color: ${colors.dark};
	@media ${device.max.mobile} {
		color: ${colors.light};
	}
`
const Ranch = styled.div`
	color: ${colors.dark};
	font-size: 0.8em;
	@media ${device.max.mobile} {
		color: ${colors.light};
	}
`

const Item = styled.button`
	all: unset;
	white-space: nowrap;
	cursor: pointer;
	width: 100%;
	padding: 0.5em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5em;
	box-sizing: border-box;
	&:hover {
		background: ${colors.background};
		border-radius: var(--border-radius);
	}
`
const Label = styled.span`
	font-size: 0.6em;
	color: #666;
	padding-bottom: 8px;
`
const RouteIcon = styled.div<{ $backgroundColor?: string; $enabled?: boolean }>`
    padding: 0;
	padding-top: 6px;
	aspect-ratio: 1 / 1;
	// height: 100%;
	height: 3rem;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
	margin-right: 6px;
    text-align: center;
	padding: 8px;
	// box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	cursor: pointer;
	&:hover {
		// box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
	border: ${props => props.$enabled ? '1px dashed green' : ''};
	// color: ${props => props.$enabled ? 'black' : transparentize(.5, 'black')};
	color: ${props => props.$backgroundColor ? (props.$enabled ? readableColor(props.$backgroundColor) : transparentize(.7, readableColor(props.$backgroundColor))) : ''};
    background-color: ${props => props.$backgroundColor ? (props.$enabled ? transparentize(.5, props.$backgroundColor) : transparentize(.6, props.$backgroundColor)) : '#ffffff'};
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`
const RoutesListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-self: flex-start;
`
const RouteTextContainer = styled.div`
	white-space: nowrap;
	overflow: hidden;
	line-height: 1.8rem;
`
const RouteText = styled.span`
	display: inline-block;
	font-size: 1em;
	vertical-align: middle;
	line-height: normal;
	overflow: hidden;
	text-overflow: ellipsis;
`
const RouteIconList = styled.div`
	display: flex;
	flex-direction: row;
`
// const NavItem = styled(Link)`
// 	all: unset;
// 	cursor: pointer;
// 	width: 100%;
// 	padding: 0.5em;
// 	display: flex;
// 	color: ${colors.dark};
// 	align-items: center;
// 	justify-content: space-between;
// 	gap: 0.5em;
// 	box-sizing: border-box;
//     &:hover {
//         background: ${colors.background};
//         border-radius: var(--border-radius);
//     }
// `
const BackButton = styled(Item)`
	justify-content: flex-start;
`

const Hr = styled.hr`
	margin-top: 0.5em;
	margin-bottom: 0.5em;
`
export default Toolbar
