import { PickingInfo } from '@deck.gl/core/typed'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Feature, Geometry, Properties } from '@turf/turf'

export interface SelectedFeatureProps {
	value?: any | null
	coordinates?: number[] | null
	object: Feature<Geometry, Properties> | null
	x?: number | null
	y?: number | null
	picked?: boolean
	type?: string | null
	index?: number | null
	indexes: number[]
	history?: SelectedFeatureProps[]
}

const initialState: SelectedFeatureProps = {
	value: null,
	coordinates: null,
	object: null,
	x: null,
	y: null,
	picked: false,
	type: null,
	index: null,
	indexes: [],
	history: [],
}

export const selectedFeature = createSlice({
	name: 'selectedFeature',
	initialState,
	reducers: {
		set: (state, action: PayloadAction<PickingInfo>) => {
			// state.history.push(state)
			state.index = action.payload.index
			state.type = action.payload.object?.properties.type
			state.coordinates = action.payload.coordinate
			state.object = action.payload.object
			state.x = action.payload.x
			state.y = action.payload.y
			state.picked = action.payload.picked
		},
		setSelectedFeatureIndexes: (state, action: PayloadAction<number[]>) => {
			state.indexes = action.payload
		},
		// setHistory: (state, action: PayloadAction<RanchMapState[]>) => {
		// 	state = action.payload
		// },
		clear: state => {
			state.coordinates = initialState.coordinates
			state.object = initialState.object
			state.type = initialState.type
			state.index = initialState.index
			state.x = initialState.x
			state.y = initialState.y
			state.picked = initialState.picked
		},
		get: state => state,
	},
})

export const { set, get, clear, setSelectedFeatureIndexes } = selectedFeature.actions

export default selectedFeature.reducer
