import { useGetAnimalBreedsQuery } from 'library/api/animal'
import { lighten, transparentize } from 'polished'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { colors } from 'styles'

export const BreedList = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { data: breeds } = useGetAnimalBreedsQuery()
	const [selectedBreed, setSelectedBreed] = useState<Breed>({ breed: 'Aberdeen Angus', description: null })
	return (
		<Container>
			<List>
				{breeds?.map((breed: Breed) => (
					<ListItem key={breed.breed}>
						<Item htmlFor={breed.breed}>
							{breed.breed}
							<input
								id={breed.breed}
								type={'radio'}
								value={breed.breed}
								checked={breed.breed === selectedBreed.breed}
								onChange={e => {
									setSelectedBreed(breed)
									navigate(location.state.from.pathname, { state: { breed }, replace: true })
								}}
							/>
						</Item>
					</ListItem>
				))}
			</List>
		</Container>
	)
}

const Container = styled.div`
	padding: 0.5em;
`
const List = styled.ul`
	padding: 0;
`
const ListItem = styled.li`
	&:not(:last-child) {
		border-bottom: 1px solid ${lighten(0.15, colors.light)};
	}
`

const Item = styled.label`
	display: flex;
	padding: 0.5em;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		background: ${transparentize(0.9, colors.light)};
	}
`
