import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import { Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Container as BaseContainer } from 'components/base'
import { useGetUserQuery } from 'library/api/user'
export const Account = () => {
	const { data: user } = useGetUserQuery()
	return (
		<Container>
			<h1>Account Settings</h1>
			<Wrapper>
				<Nav>

					<ul>
						<li>
							<StyledNavLink to={'/account'} end>Account Info</StyledNavLink>
						</li>
						<li>
							<StyledNavLink to={'/account/user'} end>My User</StyledNavLink>
						</li>
						<li>
							<StyledNavLink to={'/account/ranches'} end>Ranches</StyledNavLink>
						</li>
						<li>
							<StyledNavLink to={'/account/team'} end>Team</StyledNavLink>
						</li>
						{user?.accountOwner &&
							<li>
								<StyledNavLink to={'/account/billing'} end>Billing</StyledNavLink>
							</li>
						}
						<li>
							<StyledNavLink to={'/account/support'} end>Support</StyledNavLink>
						</li>
						<li>
							<StyledNavLink to={'/account/terms-and-conditions'} end>Terms of Use</StyledNavLink>
						</li>
						<li>
							<StyledNavLink to={'/account/privacy-policy'} end>Privacy Policy</StyledNavLink>
						</li>
					</ul>

				</Nav>
				<RouteWrapper>
					<Outlet />
				</RouteWrapper>
			</Wrapper>
		</Container>
	)
}

const Container = styled(BaseContainer)`
	display: flex;
	flex-direction: column;
	padding: .5em 2em 2em 2em;
	position: relative;
	@media (${device.max.tablet}) {
		padding: .5em 1em 1em 1em;
	}
	`
const RouteWrapper = styled.div`
		overflow: auto;
		width: 100%;
	`

const Wrapper = styled.div`
	
	height: 100%;
	width: 100%;
	background-color: #fff;
	position: relative;
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: row;
	@media (${device.max.tablet}) {
		flex-direction: column;
	}
`

const Nav = styled.nav`
	border-right: 1px solid ${transparentize(.9, colors.dark)};
	margin: 1em 0;
	position: sticky;
	top: 0px;
	padding: 1em;
	// max-width: 150px;
	width: auto;
	padding-top: 1em;
	@media (${device.min.tablet}) {
		min-height: calc(100vh - 12em);
	}
	ul {
		all: unset;
		display: flex;
		flex-direction: column;
		gap: .5em;
		position: sticky;
		top: 1em;
		@media (${device.max.tablet}) {
			top: 0;
			border-radius: var(--border-radius);
			padding: .5em;
			overflow: auto;
			background: white;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 1em;
				right: 1em;
				height: 1px;
				background: ${transparentize(.9, colors.dark)};
			}
		}
		@media (${device.max.tablet}) {
			flex-direction: row;
			justify-content: space-evenly;
		}
		@media (${device.max.mobileM}) {
			flex-direction: row;
			justify-content: flex-start;
		}
		li {
			all: unset;
			
		}
	}
	@media (${device.max.laptop}) {
		width: auto;
		max-width: auto;
	}
	@media (${device.max.tablet}) {
		margin: 0;
		padding: 0;
		max-width: 100%;
		overflow: hidden;
		
	}
	
`

const StyledNavLink = styled(NavLink)`
	text-decoration: none;
	color: ${colors.dark};
	display: inline-block;
	padding: 0.5em 1em;
	transition: .3s ease all;
	white-space: nowrap;
	@media (${device.max.tablet}) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	&:hover {
		color: ${colors.primary};
	}
	&.active {
		color: ${colors.primary};
		font-weight: 600;
		background: ${transparentize(.9, colors.primary)};
		border-radius: 9999999px;
	}
	
`