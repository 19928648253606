import { useEffect, useMemo, useState } from 'react'
import { CardAction, CardActions } from 'components/cards'
import * as turf from '@turf/turf'
import styled from 'styled-components/macro'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState, store } from 'store'
import { mapSlice } from 'store/map.slice'
import { pastureRouteSlice } from './pastureRoute.slice'
import { useMap } from 'react-map-gl'
import { useCardBuffer } from 'hooks'
import { useGetPastureRouteQuery, useCreatePastureRouteMutation, useUpdatePastureRouteMutation } from 'library/api/pastureRoute'
import { useSelector } from 'react-redux'
import { colors, device } from 'styles'
import SvgFromGeometry from 'components/SvgFromGeometry'
import { Input, RadioList } from 'components/inputs'
import ColorPicker from 'components/inputs/ColorPicker'
import { useGetPastureQuery } from 'library/api/pasture'
import { useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import LoadingSpinner from 'components/LoadingSpinner'
import { useMoveHerdToPastureMutation } from 'library/api/herd'
import Loading from 'components/Loading'
import { v4 as uuidv4 } from 'uuid'


export const PastureRouteForm = () => {
	const { id, id1: fromPastureId, id2: toPastureId } = useParams()
	const navigate = useNavigate()
	const { MapView } = useMap()
	const card = useCardBuffer()

	// if we are editing an existing route, take a single route id
	const { data: route, isSuccess: getPastureRouteSuccess, isLoading: getPastureRouteIsLoading } = useGetPastureRouteQuery({id: id}, { enabled: !!id })

	// if we are creating a new route, take two pasture ids
	const { data: fromPasture} = useGetPastureQuery({ id: fromPastureId || '' }, { enabled: !!fromPastureId })
	const { data: toPasture } = useGetPastureQuery({ id: toPastureId || '' }, { enabled: !!toPastureId })
	const { data: pastureFeatures} = useGetPastureFeaturesQuery()
	const { mutate: moveHerdToPasture } = useMoveHerdToPastureMutation()


	const herd = useSelector((state: RootState) => state.herdSlice.herd)
	const edits = useSelector((state: RootState) => state.pastureRouteSlice.route)
	const pastureRouteEdits = useSelector((state: RootState) => state.pastureRouteSlice.route)
	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)

	const { mutateAsync: createPastureRoute } = useCreatePastureRouteMutation()
	const { mutate: updatePastureRoute } = useUpdatePastureRouteMutation()

	const [zoomed, setZoomed] = useState<boolean>(false)
	const [waitForWorker, setWaitForWorker] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [mapBackgroundImg, setMapBackgroundImg] = useState<any>()
	const [timeoutId, setTimeoutId] = useState<any>()

	// fetch our worker
	const worker: Worker = useMemo(
		() => new Worker(new URL('../../../webWorkers/routeGeneratorWorker.ts', import.meta.url)),
		[]
	)

	useEffect(() => {
		store.dispatch(mapSlice.actions.setCursor('crosshair'))
	}, [])

	// Set up our routeLine worker
	useEffect(() => {
		if (id || pastureRouteEdits?.geometry?.coordinates.length) return // dont generate route if we have one
		if (window.Worker && worker) {
			worker.onmessage = ({ data: routeLine }) => {
				if (fromPastureId && toPastureId) {

					// set temporary route
					store.dispatch(pastureRouteSlice.actions.setMode('CreateRouteMode'))
					store.dispatch(pastureRouteSlice.actions.setRoute({
						name: '',
						style: {
							color: '#ffffff'
						},
						pastureIds: [fromPastureId, toPastureId],
						id: uuidv4().toString(),
						geometry: routeLine,
					}))
				}
			}
		}
	}, [fromPasture, fromPastureId, id, pastureRouteEdits?.geometry?.coordinates.length, toPasture, toPastureId, worker])

	// Zoom into route
	useEffect(() => {
		if (!pastureRouteEdits?.geometry?.coordinates.length || zoomed) return
		const [n,e,s,w] = turf.bbox(pastureRouteEdits?.geometry)
		
		if (isMobile) {
			MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth, top: 0, bottom: 0, right: 0 } })
		} else {
			MapView?.fitBounds([n,e,s,w], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		}
		
		setZoomed(true)
	}, [MapView, card.bufferWidth, isMobile, pastureRouteEdits, zoomed])

	// when route is fetched, load it into the store
	useEffect(() => {
		if (route) {
			store.dispatch(pastureRouteSlice.actions.setRoute(route))
			store.dispatch(pastureRouteSlice.actions.setMode('EditRouteMode'))
		}
	}, [route, getPastureRouteSuccess, getPastureRouteIsLoading])

	// Temp route should have been generated in the overview panel,
	// If it wasn't, or this page was reloaded, generate it here.
	// Possibly move this common functionality somewhere else?
	useEffect(() => {
		if(!pastureRouteEdits) return
		if (id || pastureRouteEdits?.geometry?.coordinates?.length) return
		const drawRoute = async () => {
			if (!herd?.pasture?.geometry || !toPasture?.geometry) return

			const fromPasturePoint = turf.centerOfMass(herd?.pasture?.geometry)
			const toPasturePoint = turf.centerOfMass(toPasture?.geometry)

			let exclusionZones: any[] = [];
			if (pastureFeatures) {
				exclusionZones = pastureFeatures?.filter((pf) => pf.type === 'exclusion')
			}
			if (exclusionZones?.length === 0) exclusionZones = []
			if(!waitForWorker) {			
				setWaitForWorker(true)
				worker.postMessage({ exclusionZones, fromPasturePoint, toPasturePoint })
			}
		}
		drawRoute()
	}, [pastureRouteEdits, herd, toPasture, id, pastureFeatures, waitForWorker, worker])

	useEffect(() => {
		if(!pastureRouteEdits.geometry) return
		if (!pastureRouteEdits?.geometry.coordinates.length) return

		if (timeoutId) {
			clearTimeout(timeoutId)
		}
		const newTimeoutId = setTimeout(() => {
			const centerPoint = turf.center(pastureRouteEdits?.geometry as turf.LineString);
			
			const geojsonData = {
				type: 'Feature',
				geometry: pastureRouteEdits?.geometry,
				properties: {
					stroke: pastureRouteEdits?.style?.color,
					'stroke-width': '16',
				}
			}
			const link = `https://api.mapbox.com/styles/v1/jackkeating/ckyklu3l405r214o8duoe7fe8/static/geojson(${encodeURIComponent(JSON.stringify(geojsonData))})/${centerPoint.geometry.coordinates[0]},${centerPoint.geometry.coordinates[1]},15/500x500?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
			setMapBackgroundImg(link)
			clearTimeout(timeoutId)
			setTimeoutId(undefined)
		}, 2000)
		
		setTimeoutId(newTimeoutId)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pastureRouteEdits])

	if (!edits) return null

	return (<>
		<Container>
			{getPastureRouteIsLoading ? <Loading /> :<>
				<MoveSection>
					{id ? <h3>Edit Route</h3> : <h3>Create Route</h3>}

					{herd.name && <OverviewHeader>Current herd: {herd.name}</OverviewHeader>}

					{fromPasture && <RadioList
						disabled
						header={'From Pasture'}
						onChange={() => {}}
						defaultValue={{title: fromPasture.name, value: fromPasture.name }}
					/>}
					{toPasture && <RadioList
						disabled
						header={'To Pasture'}
						onChange={() => {}}
						defaultValue={{title: toPasture.name, value: toPasture.name }}
					/>}
					<img
						src={mapBackgroundImg}
						style={{
							width: '100%',
							
						}}
					/>
					<Input
						label={'Name'}
						defaultValue={pastureRouteEdits?.name || ''}
						maxLength={100}
						onChange={(e: any) => {
							store.dispatch(pastureRouteSlice.actions.setName(e.target.value))
						}}
					/>

					<ColorPicker
						color={pastureRouteEdits?.style?.color}
						pickRandom={true}
						label='Route Color'
						onChange={(color) => store.dispatch(pastureRouteSlice.actions.setColor(color))}
					/>

					<CardActions>
						<CardAction onClick={async () => {
							store.dispatch(mapSlice.actions.setCursor('default'))
							if (route) { // edit
								const updatedRouteData = { ...pastureRouteEdits }
								setIsSubmitting(true)
								updatePastureRoute({ ...updatedRouteData })
								setIsSubmitting(false)
								store.dispatch(pastureRouteSlice.actions.reset())
								navigate(`/routes/${route.pastureIds[0]}/${route.pastureIds[1]}`)
							} else { // create
								if (fromPastureId && toPastureId) {
									setIsSubmitting(true)
									const newId = uuidv4().toString()
									await createPastureRoute({...pastureRouteEdits, id: newId, pastureIds: [fromPastureId, toPastureId]})
									
									if (herd && !herd?.pastureRouteId && newId) {
										moveHerdToPasture({herdId: herd?.id, pastureId: toPastureId, routeId: newId})
									}

									setIsSubmitting(false)
									store.dispatch(pastureRouteSlice.actions.reset())
									navigate(`/routes/${fromPasture?.id}/${toPasture?.id}`)
								}
							}
						}}>
							{isSubmitting ? <LoadingSpinner /> : <>{id || herd?.pastureRouteId? 'Save' : 'Save and Move'}</>}
							
						</CardAction>
						<CardAction
							variant='danger'
							onClick={() => {
								store.dispatch(mapSlice.actions.setCursor('default'))
								navigate(-1)
							}}
						>
							Cancel
						</CardAction>
						
					</CardActions>
				</MoveSection>
			</>}
		</Container>
	</>)
}

const OverviewHeader = styled.span`
	font-weight: bold;
	margin-bottom: 20px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-width: 300px;
	color: var(--dark);
	overflow: visible;
	position: relative;
	margin: 0.5em;
	@media ${device.max.mobile} {
		padding-bottom: calc(55px + var(--sab));
	}
	/* min-width: 300px; */
`

const MoveSection = styled.div`
	padding: 0.5em;
	display: flex;
	flex-direction: column;
`
