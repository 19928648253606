import { useQuery,  QueryMeta, useMutation, MutationMeta, MutationFunction, UseQueryOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { useEndpoints } from './endpoints'
import { AxiosError } from 'axios'

export const useGetHerdsQuery = (payload?: undefined, options?: Partial<UseQueryOptions<Herd[], AxiosError>>) => {
	const { herd } = useEndpoints()
	return useQuery<Herd[], AxiosError>({
		queryKey: ['herds'],
		queryFn: () => herd.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options
	})}

export const useGetHerdQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Herd, AxiosError>>) => {
	const { herd } = useEndpoints()
	return useQuery<Herd, AxiosError>({
		queryKey: ['herds', payload.id],
		queryFn: () => herd.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

export const useCreateHerdMutation = (options?: Partial<MutationFunction>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.create'],
		onMutate: (payload: Herd) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			const previousHerds = queryClient.getQueryData<Herd[]>(['herds'])
			queryClient.setQueryData(['herds'], (old: Herd[]) => old ? [...old, payload] : [payload])
			queryClient.setQueryData(['herds', payload.id], payload)
			return previousHerds
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['herds'] })
			queryClient.invalidateQueries({ queryKey: ['herds', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.removeQueries({queryKey: ['herds', variables.id ]})
			queryClient.setQueryData(['herds'], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const updateHerd = (payload: Herd) => client.put(`/Herd/${payload.id}`, payload).then((res) => res.data)
export const useUpdateHerdMutation = (options?: Partial<MutationFunction<Herd, AxiosError>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.update'],
		onMutate: (payload: Herd) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			queryClient.cancelQueries({ queryKey: [ 'herds', payload.id ] })
			const herd = queryClient.getQueryData<Herd>(['herds', payload.id])
			queryClient.setQueryData(['herds', payload.id], (old: Herd) => old ? {...old, ...payload} : old)
			return herd
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: [ 'herds', variables.id ] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['herds', variables.id], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useDeleteHerdMutation = (options?: Partial<MutationFunction>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.delete'],
		onMutate: (payload: { herdId: string, deleted: boolean }) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			queryClient.cancelQueries({ queryKey: ['herds', payload.herdId] })
			const herds = queryClient.getQueryData<Herd[]>(['herds'])
			const herd = queryClient.getQueryData<Herd>(['herds', payload.herdId])
			queryClient.setQueryData(['herds'], (old: Herd[]) => old ? old.filter(herd => herd.id !== payload.herdId) : [])
			queryClient.removeQueries({queryKey: ['herds', payload.herdId ]})
			return { herd, herds }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: [ 'herds', variables.herdId ] })
			queryClient.invalidateQueries({ queryKey: ['herds'] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['herds', variables.herdId], context?.herd)
			queryClient.setQueryData(['herds'], context?.herds)
			// queryClient.invalidateQueries({ queryKey: [ 'herds', variables.herdId ] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const assignHerdToPasture = (payload: {herdId: string, pastureId: string}) => client.put(`/Herd/${payload.herdId}/Pasture/${payload.pastureId}`).then((res) => res.data)
export const useAssignHerdToPastureMutation = (options?: Partial<MutationFunction>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.pasture.assign'],
		onMutate: (payload: {herdId: string, pastureId: string}) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			queryClient.cancelQueries({ queryKey: ['herds', payload.herdId] })
			const herd = queryClient.getQueryData<Herd>(['herds', payload.herdId])
			const pasture = queryClient.getQueryData<Pasture>(['pastures', payload.pastureId])
			queryClient.setQueryData(['herds', payload.herdId], (old: Herd) => old ? {...old, pastureId: payload.pastureId, pasture: pasture} : old)
			return herd
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['herds', variables.herdId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['herds', variables.herdId], context)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useMoveHerdToPastureMutation = (options?: Partial<MutationFunction>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.pasture.move'],
		onMutate: (payload: {herdId: string, pastureId: string, routeId: string}) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			queryClient.cancelQueries({ queryKey: ['herds', payload.herdId] })
			const herd = queryClient.getQueryData<Herd>(['herds', payload.herdId])
			const herds = queryClient.getQueryData<Herd>(['herds'])
			const pasture = queryClient.getQueryData<Pasture>(['pastures', payload.pastureId])
			queryClient.setQueryData(['herds'], (old: Herd[]) => old ? old.map((herd: Herd) => herd.id === payload.herdId ? {...herd, pastureId: payload.pastureId, pasture: pasture, pastureRouteId: payload.routeId } : herd) : [{...herd, pastureId: payload.pastureId, pasture: pasture, pastureRouteId: payload.routeId }])
			queryClient.setQueryData(['herds', payload.herdId], (old: Herd) => old ? {...old, pastureId: payload.pastureId, pasture: pasture, pastureRouteId: payload.routeId } : old)
			return { herd, herds }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['herds'] })
			queryClient.invalidateQueries({ queryKey: ['herds', variables.herdId] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['herds'], context?.herds)
			queryClient.setQueryData(['herds', variables.herdId], context?.herd)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

// export const toggleHerd = (payload: ToggleHerdParams) => client.put(`/Herd/${payload.id}/Enabled/${Boolean(payload.enabled)}`, payload).then((res) => res.data)
export const useToggleHerdMutation = (options?: Partial<MutationFunction>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['herd.mutation.device.toggle'],
		onMutate: (payload: ToggleHerdParams) => {
			queryClient.cancelQueries({ queryKey: ['herds'] })
			queryClient.cancelQueries({ queryKey: ['herds', payload.id] })
			const herds = queryClient.getQueryData<Herd[]>(['herds'])
			const herd = queryClient.getQueryData<Herd>(['herds', payload.id])
			queryClient.setQueryData(['Herds'], (old: Herd[]) => old ? old.map(herd => herd.id === payload.id ? {...herd, enabled: payload.enabled} : herd) : [])
			queryClient.setQueryData(['herds', payload.id], (old: Herd) => old ? {...old, enabled: payload.enabled} : old)
			return { herd, herds }
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['herds'] })
			queryClient.invalidateQueries({ queryKey: ['herds', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['herds', variables.id], context?.herd)
			queryClient.setQueryData(['herds'], context?.herds)
		},
		networkMode: 'offlineFirst',
		...options
	})
}