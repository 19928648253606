import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { Link, useLocation } from 'react-router-dom'
import { CowIcon, Icon, MDIcon } from 'components/icons'
import { colors, device, size } from 'styles'
import { darken } from 'polished'
import { useMap } from 'react-map-gl'
import CorralLogo from 'images/Primary Logo/Light/Corral_Primary Logo_light.svg'
import CorralLogoStamp from 'images/Logo Stamp/Light/Corral_Logo Stamp_light.svg'
import { routeSlice } from 'store/route.slice'
import { RootState, store } from 'store'

const Sidebar = () => {
	const location = useLocation()
	const { MapView } = useMap()
	const navWrapperRef = useRef<HTMLDivElement>(null)
	const [navCollapseIsPristine, setNavCollapseIsPristine] = useState<boolean>(true)
	const [compactNavigation, setCompactNavigation] = useState<boolean>(false)
	const [navMaxWidth, setNavMaxWidth] = useState(170)

	useEffect(() => {
		const handleResize = (e?: Event) => {
			
			if (navCollapseIsPristine || window.innerWidth < size.tablet) {
				setCompactNavigation(window.innerWidth < size.tablet && window.innerWidth > size.mobile)
			}
			
			store.dispatch(routeSlice.actions.setIsMobile(window.innerWidth < size.tablet))

			setTimeout(() => {
				if (navWrapperRef?.current) {
					const rootStyle = document.querySelector(':root') as HTMLElement
					if (rootStyle) rootStyle?.style?.setProperty('--navbar-width', `${compactNavigation ? 62 : navMaxWidth}px`)
				}

				if (window.innerWidth < size.tablet) {
					store.dispatch(routeSlice.actions.setIsMobile(true))
					store.dispatch(routeSlice.actions.setSidebarHeight(navWrapperRef?.current?.getBoundingClientRect().height || 0))
				} else {
					store.dispatch(routeSlice.actions.setIsMobile(false))
				}


			}, 0)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [navCollapseIsPristine, compactNavigation, navMaxWidth])

	return (
		<Nav $compact={compactNavigation}>
			<NavWrapper
				onLoad={e => {
					setNavMaxWidth(navWrapperRef?.current?.getBoundingClientRect().width || 170)
				}}
				onTransitionEnd={e => {
					if (navWrapperRef?.current) {
						setNavMaxWidth(navWrapperRef?.current?.getBoundingClientRect().width || 170)
						if (MapView) {
							setTimeout(() => MapView?.resize(), 0)
						}
					}
				}}
				ref={navWrapperRef}>
				<LogoWrapper>
					<Logo src={compactNavigation ? CorralLogoStamp : CorralLogo} width={'100%'} alt='Corral Tech Logo' />
				</LogoWrapper>
				<NavLinkWrapper $compact={compactNavigation}>
					<NavLink $compact={compactNavigation} $active={location.pathname === '/'} to='/'>
						<IconWrapper>
							<MDIcon type={'solid'} color={'var(--light)'} name={'map'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Map</LinkText>
					</NavLink>
					<NavLink $compact={compactNavigation} $active={location.pathname === '/features'} to='/features'>
						<IconWrapper>
							<MDIcon type={'solid'} color={'var(--light)'} name={'fence'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Features</LinkText>
					</NavLink>
					{/* <NavLink $compact={compactNavigation} $active={location.pathname === '/routes'} to='/routes'>
						<IconWrapper>
							<MDIcon type={'solid'} color={'var(--light)'} name={'route'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Routes</LinkText>
					</NavLink> */}
					<NavLink $compact={compactNavigation} $active={location.pathname === '/herds'} to='/herds'>
						<IconWrapper>
							<MDIcon type={'solid'} color={'var(--light)'} name={'workspaces'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Herds</LinkText>
					</NavLink>
					<NavLink $compact={compactNavigation} $active={location.pathname === '/animals'} to='/animals'>
						<IconWrapper>
							<CowIcon fill={'transparent'} stroke={colors.light} strokeWidth={1} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Animals</LinkText>
					</NavLink>
					<SupportNavLink $compact={compactNavigation} $active={location.pathname === '/account/support'} to='/account/support'>
						<IconWrapper>
							<MDIcon type={'solid'} color={'var(--light)'} name={'help'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Support</LinkText>
					</SupportNavLink>


					{/* <NavLink $compact={compactNavigation} $active={location.pathname === '/analytics'} to='/analytics'>
						<IconWrapper>
						<MDIcon type={'solid'} name={'analytics'} />
						</IconWrapper>
						<LinkText $compact={compactNavigation}>Analytics</LinkText>
					</NavLink> */}
					
				</NavLinkWrapper>
				<CompactButton
					$compact={compactNavigation}
					onClick={_ => {
						setNavCollapseIsPristine(false)
						setCompactNavigation(!compactNavigation)
					}}>
					<Icon color={'var(--light)'} name='left-arrow-alt' />
				</CompactButton>
			</NavWrapper>
		</Nav>
	)
}


const Nav = styled.nav<{ $compact?: boolean }>`
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	top: auto;
	background: transparent;
	flex-direction: row;
	justify-content: space-around;
	position: fixed;
	z-index: 151;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-x: clip;
	width: auto;
	background: ${colors.dark};
	@media ${device.max.mobile} {
		top: auto;
		transition: 0.3s ease background;
	}
	@media ${device.min.mobile} {
		position: sticky;
		grid-area: sidebar;
	}
`

const NavWrapper = styled.div`

	@media ${device.max.mobile} {
		width: 100%;
	}
`
const LogoWrapper = styled.div`
	background: ${colors.primary};
	align-items: center;
	justify-content: center;
	display: flex;
	@media ${device.max.mobile} {
		position: relative;
		display: none;
	}
`
const Logo = styled.img`
	height: 54px;
	width: 100%;
	max-width: 200px;
	padding: 0.5em;
`

const CompactButton = styled.button<{ $compact: boolean }>`
	display: none;
	background: transparent;
	border: none;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: ${props => (props.$compact ? '0.5em calc(0.5em + 3px)' : '0.5em .5em')};
	color: ${colors.light};
	transition: 0.3s linear all;
	align-items: center;
	justify-content: center;
	transform: ${props => (props.$compact ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
	@media ${device.min.mobile} {
		display: flex;
	}
`

const NavLinkWrapper = styled.div<{ $compact: boolean }>`
	display: flex;
	overflow-x: clip;
	flex-direction: row;
	justify-content: flex-start;
	background: ${colors.dark};
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
	width: 100%;
	transition: 0.3s ease-in max-width;
	max-width: ${props => (props.$compact ? '62px' : '200px')};
	border-radius: 0;
	margin: 0;
	box-shadow: none;
	justify-content: flex-start;
	flex-direction: column;
	color: ${colors.light};
	@media ${device.max.mobile} {
		max-width: 100%;
		padding: 0.25em;
		flex-direction: row;
		padding-bottom: env(safe-area-inset-bottom, 0.25em);
	}
`
const LinkText = styled.div<{ $compact: boolean }>`
	/* display: none; */
	transition: 0.3s linear all;
	transition-delay: ${props => (props.$compact ? '.3s transform' : '.3s')};
	color: ${colors.light};
	display: flex;
	@media ${device.min.mobile} {
		flex-shrink: 999;
		width: 100%;
		transform: ${props => (props.$compact ? 'translateX(30px)' : 'translateX(0px)')};
		max-width: ${props => (props.$compact ? '0' : '300px')};
		opacity: ${props => (props.$compact ? '0' : '1')};
	}
	@media ${device.max.mobile} {
		font-size: 0.8em;
	}
`
const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	@media ${device.max.mobile} {
		transform: scale(0.8);
	}
`

const NavLink = styled(Link)<{ $active: boolean; $compact: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	/* width: 100%; */
	text-decoration: none;
	transition: 0.3s linear all;
	justify-content: flex-start;
	background: ${props => (props.$active ? darken(0.05, colors.dark) : 'transparent')};
	color: ${colors.light};
	@media ${device.max.mobile} {
		padding: 0.25em;
		border-radius: var(--border-radius);
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 3px;
	}
	&:active,
	&:hover {
		color: ${colors.light};
		background: ${darken(0.025, colors.dark)};
	}
	@media ${device.min.mobile} {
		gap: ${props => (props.$compact ? '0' : '1em')};
		justify-content: flex-start;
		padding: ${props => (props.$compact ? '0.5em 1em' : '0.5em 2em')};
		border-radius: 0;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			transition: 0.3s ease-in all;
			transition-delay: 0.3s background;
			width: ${props => (props.$active ? '5px' : 0)};
			background: ${props => (props.$active ? colors.primary : 'transparent')};
		}
		&:first-child:after {
			transition: 0.3s ease-in all;
			top: 0px;
			background-color: ${darken(0.1, colors.primary)};
			left: ${props => (props.$active ? '5px' : 0)};
			right: 0;
			height: 1px;
			content: '';
			position: absolute;
		}
	}
`
const SupportNavLink = styled(NavLink)`
	@media ${device.max.mobile} {
		display: none;
	}
`
export default Sidebar
