// import { useClient } from 'api/client'
import { useQuery,  QueryMeta, useMutation, MutationMeta, QueryOptions, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import * as uuid from 'uuid'
import { FeatureCollection } from '@turf/turf'
import { useEndpoints } from './endpoints'
import { AxiosError } from 'axios'

export const useGetPasturesQuery = (payload?: undefined, options?: QueryMeta) => {
	const { pasture } = useEndpoints()
	return useQuery({
		queryKey: ['pastures'],
		queryFn: () => pasture.query.all(),
		networkMode: 'offlineFirst',
		refetchOnWindowFocus: true,
		...options,
	})}

export const useGetPastureQuery = (payload: { id: string | undefined }, options?: Partial<UseQueryOptions<Pasture, Error>>) => {
	const { pasture } = useEndpoints()
	return useQuery<Pasture, Error>({
		queryKey: ['pastures', payload.id],
		queryFn: () => pasture.query.byId(payload),
		networkMode: 'offlineFirst',
		...options
	})}

export const useCreatePastureMutation = (options?: Partial<UseMutationOptions<Pasture, AxiosError, Pasture>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pasture.mutation.create'],
		onMutate: (payload: Pasture) => {
        
			queryClient.cancelQueries({ queryKey: ['pastures'] })
			const previousPastures = queryClient.getQueryData<Pasture[]>(['pastures'])
			queryClient.setQueryData(['pastures'], (old: Pasture[]) => old ? [...old, payload] : [payload])
			queryClient.setQueryData(['pastures', payload.id], payload)
			return previousPastures
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['pastures'] })
			queryClient.invalidateQueries({ queryKey: ['pastures', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['pastures'], context)
			queryClient.removeQueries({ queryKey: ['pastures', variables.id] })
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useUpdatePastureMutation = (options?: Partial<UseMutationOptions<Pasture, AxiosError, Pasture, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pasture.mutation.update'],
		onMutate: (payload: Pasture) => {
			queryClient.cancelQueries({ queryKey: ['pastures'] })
			queryClient.cancelQueries({ queryKey: ['pastures', payload.id] })
			queryClient.cancelQueries({ queryKey: ['map-features'] })
			const pastures = queryClient.getQueryData<Pasture[]>(['pastures'])
			const pasture = queryClient.getQueryData<Pasture>(['pastures', payload.id])
			const mapFeatures = queryClient.getQueryData<FeatureCollection>(['map-features'])
			const features = mapFeatures ? mapFeatures.features.map(feature => feature.id === payload.id ? {...feature, properties: {...feature.properties, ...payload}} : feature) : [{id: payload.id, type: 'Feature', properties: payload, geometry: {type: 'Polygon', coordinates: [payload.geometry.coordinates]}}]
			queryClient.setQueryData(['map-features'], { ...mapFeatures, features })
			queryClient.setQueryData(['pastures'], (old: Pasture[]) => old ? old.map(pasture => pasture.id === payload.id ? payload : pasture) : [])
			queryClient.setQueryData(['pastures', payload.id], payload)

			return {pastures, pasture, mapFeatures}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['pastures'] })
			queryClient.invalidateQueries({ queryKey: ['pastures', variables.id] })
			queryClient.invalidateQueries({queryKey: ['map-features']})
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['pastures'], context?.pastures)
			queryClient.setQueryData(['pastures', variables.id], context?.pasture)
			queryClient.setQueryData(['map-features'], context?.mapFeatures)
		},
		networkMode: 'offlineFirst',
		...options
	})
}

export const useDeletePastureMutation = (options?: Partial<UseMutationOptions<Boolean, AxiosError, { id: string }, any>>) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['pasture.mutation.delete'],
		onMutate: (payload: { id: string }) => {
			queryClient.cancelQueries({ queryKey: ['pastures'] })
			queryClient.cancelQueries({ queryKey: ['pastures', payload.id] })
			const pastures = queryClient.getQueryData<Pasture[]>(['pastures'])
			const pasture = queryClient.getQueryData<Pasture[]>(['pastures', payload.id])
			queryClient.setQueryData(['pastures'], (old: Pasture[]) => old ? old.filter(pasture => pasture.id !== payload.id) : [])
			queryClient.removeQueries({queryKey: ['pastures', payload.id]})
			return {pastures, pasture}
		},
		onSuccess: (data, variables, context) => {
			queryClient.invalidateQueries({ queryKey: ['pastures'] })
			queryClient.invalidateQueries({ queryKey: ['pastures', variables.id] })
		},
		onError: (data, variables, context) => {
			queryClient.setQueryData(['pastures'], context?.pastures)
			queryClient.setQueryData(['pastures', variables.id], context?.pasture)
		},
		networkMode: 'offlineFirst',
		...options
	})
}