import {RedirectRequest, BrowserCacheLocation, PublicClientApplication, AuthenticationResult, EventMessage, EventType} from '@azure/msal-browser'
const tenant = process.env.REACT_APP_CORRAL_B2C_TENANT || '';
const tenantId = process.env.REACT_APP_CORRAL_B2C_TENANT_ID || '';
const authorityDomain = tenant;

const signInPolicy = process.env.REACT_APP_CORRAL_B2C_SIGNIN_POLICY || '';
const signInAuthority = `https://${tenant}/tfp/${tenantId}/${signInPolicy}`;

const signUpPolicy = process.env.REACT_APP_CORRAL_B2C_SIGNUP_POLICY || '';
const signUpAuthority = `https://${tenant}/tfp/${tenantId}/${signUpPolicy}`;
const signUpInvitationAuthority = `https://${tenant}/tfp/${tenantId}/b2c_1_signup_invitation`;

const signUpSignInAuthority = `https://${tenant}/tfp/${tenantId}/b2c_1_signUpSignIn`;

export const msalInstance = new PublicClientApplication({
	auth: {
		clientId: process.env.REACT_APP_CORRAL_B2C_CLIENT_ID || '',
		redirectUri: '/',
		postLogoutRedirectUri: process.env.REACT_APP_CORRAL_B2C_REDIRECT_URI
		,
		knownAuthorities: [authorityDomain],
		// navigateToLoginRequestUrl: false,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: BrowserCacheLocation.SessionStorage,
	},
})

msalInstance.addEventCallback((event: EventMessage) => {
	if(event.eventType === EventType.LOGIN_FAILURE) {
		// If user hits cancel on the signup screen, send them back to the welcome page
		if(event?.error && String(event?.error).includes('AADB2C90091')) {
			window.location.href = '/'
		}
	}
	if ((event.eventType === EventType.LOGIN_SUCCESS || EventType.SSO_SILENT_SUCCESS) && event.payload) {
		const payload = event.payload as AuthenticationResult
		const account = payload.account
		msalInstance.setActiveAccount(account)
	}
})

// Scopes for id token to be used at MS Identity Platform endpoints
export const loginRequest: RedirectRequest = {
	authority: signInAuthority,
	scopes: [],
	redirectUri: '/',
}

export const signUpRequest: RedirectRequest = {
	authority: signUpAuthority,
	scopes: [],
	redirectUri: '/',
	redirectStartPage: '/sign-up/account',
}
export const signUpInvitationRequest: RedirectRequest = {
	authority: signUpInvitationAuthority,
	scopes: [],
	redirectUri: '/invite',
}

export const signUpSignInRequest: RedirectRequest = {
	authority: signUpSignInAuthority,
	scopes: [],
	redirectUri: '/',
}


export const getAccessToken = async (): Promise<String> => {
	const account = msalInstance.getActiveAccount();
	if (!account) {
		// Don't throw an error if the user is on the invite page, just return an empty string so the api can hit the invite endpoint
		if(!window.location.pathname.includes('/invite')) {
			throw Error('No active account')
		} else {
			return ''
		}
	}

	let request = loginRequest
	if (String(account?.idTokenClaims?.tfp).toLowerCase() === signUpPolicy.toLowerCase()) {
		request = signUpRequest
	}
	if (String(account?.idTokenClaims?.tfp).toLowerCase() === 'b2c_1_signup_invitation') {
		request = signUpInvitationRequest
	}

	const response = await msalInstance.acquireTokenSilent({
		...request,
		account,
	});

	return response.idToken;
};
