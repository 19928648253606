import { Alert } from 'react-bootstrap'
import { AddCard, Card, CardItem } from 'components/cards'
import styled, { keyframes } from 'styled-components/macro'
import { colors, device } from 'styles'
import { lighten, darken } from 'polished'
import { area, convertArea, Feature, Properties } from '@turf/turf'
import { Geometry } from 'geojson'
import { useNavigate } from 'react-router-dom'
import { invlerp, kebabize } from 'utilities'
import * as turf from '@turf/turf'
import { useEffect, useId, useState } from 'react';
import SvgFromGeometry from 'components/SvgFromGeometry'
import { useGetPasturesQuery } from 'library/api/pasture'
import { useGetPastureFeaturesQuery } from 'library/api/pastureFeature'
import { store } from 'store'
import { boundarySlice } from './boundary.slice'
export const Boundaries = () => {
	const navigate = useNavigate()
	const { data: pastures, isRefetching: getPasturesIsRefetching, dataUpdatedAt: pasturesUpdatedAt } = useGetPasturesQuery()
	const { data: pastureFeatures, isRefetching: getPastureFeaturesIsRefetching, dataUpdatedAt: pastureFeaturesUpdatedAt } = useGetPastureFeaturesQuery()
	

	const formatFeatureType = (type: string) => {
		type = type.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
		return type.charAt(0).toUpperCase() + type.slice(1)
	}
	
	const uuid = useId()

	const [features, setFeatures] = useState<any[]>([])
	const [error, setError] = useState({ message: '' })
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState()

	useEffect(() => {
		if (!pastures || !pastureFeatures) return
		const formattedPastures = pastures?.map((pasture) => {
			return { ...pasture, type: 'pasture' }
		})
		const formattedPastureFeatures = pastureFeatures?.map((pastureFeature) => {
			return { ...pastureFeature }
		})
		setFeatures([ ...formattedPastures, ...formattedPastureFeatures ])
	}, [pastureFeatures, pastures, getPasturesIsRefetching, getPastureFeaturesIsRefetching, pasturesUpdatedAt, pastureFeaturesUpdatedAt ])

	// TODO: Refine this
	return (
		<>
			<Container>
				{error && 'message' in error && isError && <Alert variant='danger'>{error.message}</Alert>}
				{isLoading ? (
					<SkeletonWrapper>
						<CardGrid>
							{Array.from(Array(8)).map((_, i) => (
								<SkeletonLoader key={`${uuid}_${i}`} />
							))}
						</CardGrid>
					</SkeletonWrapper>
				) : (
					<CardGrid>
						<AddCard text={'Add Feature'} onClick={() => navigate('/feature/add')} />
						{features?.map((item, index: number) => (
							<Card key={item?.type + item.id}>
								<CardWrapper>
									<CardItemWrapper>
										<div>
											<CardItem
												label={'Name'}
												// icon={<MDIcon name={'title'} />}
												value={item?.name != null ? item.name : 'N/A'}
											/>
											<CardItem
												label={'Type'}
												// icon={<MDIcon name={'category'} />}
											>
												<CardItemSecondary>{formatFeatureType(item?.type)}</CardItemSecondary>
											</CardItem>
											{item?.type === 'pasture' || item?.type.toLowerCase() === 'exclusion' ? (
												<CardItem
													label={'Area'}
													// icon={<MDIcon name={'straighten'} />}
												>
													<CardItemSecondary>{convertArea(area(item.geometry), 'meters', 'acres').toFixed(1)} Acres</CardItemSecondary>
												</CardItem>
											) : null}
										</div>
										{item.geometry ? <SvgFromGeometry 
											width={50} 
											height={50} 
											strokeLinecap='round' 
											strokeLinejoin='round' 
											geometry={item.geometry as turf.Polygon | turf.LineString} 
											strokeWidth={Math.round(invlerp(-5, 200, item?.style?.outlineWidth || 1) * 100) + '%' || '2%'} 
											// fill={item?.style?.backgroundColor && item?.style?.backgroundColor !== '#ffffff' ? item?.style?.backgroundColor : '#000'}
											fill={item?.style?.backgroundColor && item?.style?.backgroundColor !== '#ffffff' ? item?.style?.backgroundColor : '#000'}
											fillOpacity={item?.style?.opacity || 0.5}
											stroke={item?.style?.outlineColor && item?.style?.outlineColor !== '#ffffff' ? item?.style?.outlineColor : '#000'}
										/> : null }
									</CardItemWrapper>
									<ButtonWrapper>
										<ActionButton
											onClick={() => {
												navigate(`/${kebabize(item?.type)}/${item.id}`)
											}}>
											Details
										</ActionButton>
									</ButtonWrapper>
								</CardWrapper>
							</Card>
						))}
					</CardGrid>
				)}
			</Container>
		</>
	)
}

const SkeletonLoader = () => {
	return (
		<SkeletonCard>
			<SkeletonItem>
				<SkeletonLabel $large={true} />
				<SkeletonText $large={true} />
			</SkeletonItem>
			<SkeletonItem>
				<SkeletonLabel />
				<SkeletonText />
			</SkeletonItem>
		</SkeletonCard>
	)
}

// const Sheet = styled(BottomSheet)`
// 	--rsbs-bg: ${colors.background};
// `

const loadingAnimation = keyframes`
	0%, 100% {
		opacity: 1
	}
	50% {
		opacity: 0.75
	}
`

const CardItemWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`
// const Header = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	font-weight: 600;
// `

const SkeletonWrapper = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const SkeletonCard = styled.div`
	background: ${darken(0.035, colors.background)};
	height: 100px;
	width: 100%;
	border-radius: var(--border-radius);
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0.5em;
	animation: ${loadingAnimation} 1.5s ease-in-out infinite;
	&:before {
		content: '';
		position: absolute;
		right: 0.5em;
		left: 0.5em;
		bottom: 0.5em;
		height: 32px;
		background-color: ${darken(0.1, colors.background)};
		border-radius: var(--border-radius);
	}
`
const SkeletonItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25em;
`
const SkeletonLabel = styled.div<{ $large?: boolean }>`
	height: 10px;
	width: ${props => (props.$large ? '30px' : '20px')};
	background-color: ${darken(0.1, colors.background)};
	border-radius: var(--border-radius);
`
const SkeletonText = styled.div<{ $large?: boolean }>`
	height: 15px;
	width: ${props => (props.$large ? '120px' : '50px')};
	background-color: ${darken(0.1, colors.background)};
	border-radius: var(--border-radius);
`

const Container = styled.div`
	height: 100%;
	min-width: 300px;
	color: var(--dark);
	overflow: visible;
	position: relative;
	margin: 0.5em;
	@media ${device.max.mobile} {
		padding-bottom: calc(55px + var(--sab));
	}
	/* min-width: 300px; */
`

const CardGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	@media ${device.max.mobile} {
		grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
	}
	grid-template-rows: auto;
	gap: 0.5em;
`

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
`
// const CardInfo = styled.div`
// 	display: grid;
// 	grid-template-columns: auto min-content;
// 	column-gap: 0.5em;
// 	row-gap: 0;
// `

const ButtonWrapper = styled.div`
	border-top: 1px solid ${lighten(0.175, colors.light)};
	padding-top: 0.5em;
	display: flex;
	gap: 0.5em;
	justify-content: center;
`

const ActionButton = styled.button`
	all: unset;
	cursor: pointer;
	color: var(--dark);
	font-weight: bold;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 0;
	font-size: 0.8em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${darken(0, colors.background)};
	align-items: center;
	justify-self: end;
`

const CardItemSecondary = styled.div`
	font-size: 0.8em;
	line-height: 1;
	white-space: nowrap;
`
export default Boundaries
