import { useState, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import Loading from 'components/Loading'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import Input from 'components/inputs/Input'
import { MDIcon } from 'components/icons'
import { Card, AddCard, CardItem } from 'components/cards'
import { device } from 'styles'
import { useGetAnimalsQuery } from 'library/api/animal'
import { darken, lighten } from 'polished'
import DateTime from 'components/DateTime'
import { useNavigate } from 'react-router-dom'
import { Animal } from 'library/models/animal.model'

const Animals: React.FC = ({ showDetails }: { showDetails?: (animal: any) => void }) => {
	const cardContainer = useRef<any>([])
	const { data: animals, isLoading, isError, error } = useGetAnimalsQuery()
	const [searchQuery, setSearchQuery] = useState<string | null | undefined>(null)
	const navigate = useNavigate()
	function filterSearch(items: any) {
		if (!items) return []
		if (!searchQuery) return items
		return items.filter((item: any) => {
			const properties = Object.entries(item)
				.filter(([key, value]) => key !== 'deviceId' && key !== 'lastCoordinate')
				.map(([key, value]) => {
					return String(value).toLowerCase().search(searchQuery.toLowerCase()) > -1
				})
			return properties.includes(true)
		})
	}
	
	return (
		<>
			<Container>
				<Tools>
					<Input label={'Search'} hideLabel={true} type={'search'} icon={'search'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)} />
				</Tools>
				{isError && 'message' in error && <Alert variant='danger'>{error.message}</Alert>}
				{isLoading ? (
					<Loading />
				) : (
					<CardContainer ref={cardContainer}>
						<AddCard text={'Add Animal'} onClick={() => {
							navigate('/animals/add')}} />

						{filterSearch(animals).filter((animal: Animal) => !animal.deleted).map((animal: Animal) => (
							<Card key={animal.id}>
								<CardWrapper>
									<CardInfo>
										<CardItem label={'Tag'} icon={<MDIcon name={'local_offer'} />} value={animal.tag != null ? animal.tag : 'N/A'} />
										{/* <CardItem label={'Herd'} icon={<GroupIcon width={20} height={20} fill={lighten(0.25, colors.dark)} />} value={animal.herdName} /> */}
										<CardItem label={'Last Check In'} icon={<MDIcon name={'schedule'} color={lighten(0.25, colors.dark)} />}>
											<CardItemSecondary>{animal.lastEventTimestamp ? <DateTime date={animal.lastEventTimestamp} /> : <NoWrap>Not Available</NoWrap>}</CardItemSecondary>
										</CardItem>
									</CardInfo>
									<ButtonWrapper>
										<ActionButton
											onClick={() => {
												navigate(`/animals/${animal.id}`)
											}}>
											Details
										</ActionButton>
									</ButtonWrapper>
								</CardWrapper>
							</Card>
						))}
					</CardContainer>
				)}
			</Container>
		</>
	)
}
// const Panel = styled(BottomSheet)`
// 	--rsbs-bg: ${colors.background};
// `
const Container = styled.div`
	width: 100%;
	padding: 0.5em;
	@media ${device.max.mobile} {
		padding-bottom: calc(110px + var(--sab));
	}
`
// const Header = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	font-weight: 600;
// `
const Tools = styled.div`
	display: flex;
	// margin-bottom: 0.5em;
	justify-content: space-between;
	align-items: center;
	gap: 0.5em;
	// background: ${colors.background};
`
const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
`
const CardInfo = styled.div`
	display: grid;
	grid-template-columns: auto min-content;
	column-gap: 0.5em;
	row-gap: 0;
`

const NoWrap = styled.span`
	white-space: nowrap;
`

const ButtonWrapper = styled.div`
	border-top: 1px solid ${lighten(0.175, colors.light)};
	padding-top: 0.5em;
	display: flex;
	gap: 0.5em;
	justify-content: center;
`
const CardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	@media ${device.max.mobile} {
		grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
	}
	gap: 0.5em;
	box-sizing: border-box;
`
const ActionButton = styled.button`
	all: unset;
	cursor: pointer;
	color: var(--dark);
	font-weight: bold;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 0;
	font-size: 0.8em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${darken(0, colors.background)};
	align-items: center;
	justify-self: end;
`
const CardItemSecondary = styled.div`
	font-size: 0.8em;
`
// const PopupPanel = styled.div`
// 	position: relative;
// 	box-sizing: border-box;
// 	overflow: visible;
// 	height: 0;
// 	bottom: 0;
// 	grid-area: panels;
// 	/* & > div:first-child:empty {
// 		display: none;
// 		pointer-events: none;
// 	} */
// `
export default Animals
