import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import { EditButton } from 'components/buttons'
import { Image } from 'components/Image'
import { Icon, MDIcon } from 'components/icons'
import { Link } from 'react-router-dom'
import { AvatarInput } from 'components/inputs/AvatarInput'
import { useGetRanchQuery } from 'library/api/ranch'
import { useGetUserQuery, useUpdateUserActiveRanchMutation } from 'library/api/user'
export const RanchCard = ({ ranchId }: { ranchId: string }) => {

	const { data: ranch } = useGetRanchQuery({ id: ranchId })
	const { data: currentUser } = useGetUserQuery()
	const { mutate: setActiveRanch } = useUpdateUserActiveRanchMutation()

	const roleName = useMemo(() => {
		const role = currentUser?.ranchRoles.filter((r) => r.ranchId === ranchId).pop()
		if (role) return role.role
		else return ''
	}, [currentUser?.ranchRoles, ranchId])

	return (
		<Card>
			<CardWrapper>
				<ProfileCardImage>
					{ranch?.imageFile ? <Image alt={'profile'} src={`file/${ranch?.imageFile?.id}/0`} /> : null}
				</ProfileCardImage>
				<ProfileCardInfo>
					<ProfileCardName>{ranch?.name}<i style={{ color: 'gray' }}>{roleName && (' - ' + roleName)}</i></ProfileCardName>
				</ProfileCardInfo>
				<ButtonContainer>
					<ViewRanchButton to={`/account/ranch/${ranch?.id}`}>View
						<MDIcon name={'chevron_right'} style={{}} />
					</ViewRanchButton>
				</ButtonContainer>
				{(currentUser?.currentRanch?.id !== ranchId) && <ButtonContainer>
					<EditButton type={'button'}
						onClick={e => {
							e.preventDefault()
							setActiveRanch({ id: ranchId })
						}}>
						Switch
						<Icon name={'edit-alt'} />
					</EditButton>
				</ButtonContainer>}
			</CardWrapper>
		</Card>
	)
}

const CardWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	gap: 1em;
	@media (${device.max.mobileM}) {
		flex-direction: column;
	}
`
const Card = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1em;
	border-radius: var(--border-radius);
	border: 1px solid ${transparentize(.9, colors.dark)};
	padding: 1em;
	width: 100%;
	@media (${device.max.tablet}) {
		flex-direction: column;
		flex-wrap: wrap;
	}
`
const ProfileCardImage = styled.div`
	padding: 0;
	width: 100px;
	height: 100px;
	border-radius: 100%;
	overflow: hidden;
	border: none;
	flex-shrink: 0;
	margin: auto;
	background: ${transparentize(.9, colors.dark)};
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
	@media (${device.max.mobile}) {
		width: 75px;
		height: 75px;
	}
`
const ProfileCardInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
`
const ProfileCardName = styled.div`
	font-weight: 600;
	font-size: 1.15em;
	color: ${colors.dark};
`
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;


	@media (${device.max.tablet}) {
		display: flex;
		flex-direction: row;
		
		}
`
const ViewRanchButton = styled(Link)`
	text-decoration: none;
	color: ${colors.dark};
	padding: .5em .5em .5em 1em;
	border-radius: 999999px;
	font-weight: 500;
	border: 1px solid ${transparentize(.9, colors.dark)};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .25em;
	&:hover {
		background: ${transparentize(.95, colors.dark)};
		color: ${colors.dark};
	}
`
