import { useState, Fragment, ReactNode, useEffect, useCallback } from 'react'
import twcolors from 'tailwindcss/colors'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { darken, lighten, readableColor, transparentize } from 'polished'
import { Icon } from 'components/icons'

export interface ColorPickerProps {
	color?: string | null
	label: string
	pickRandom?: boolean
	onChange: (color: string) => void
	children?: ReactNode
}
const defaultColors = {
	dark: [twcolors.red[500], twcolors.orange[500], twcolors.yellow[500], twcolors.green[500], twcolors.teal[500], twcolors.blue[500], twcolors.indigo[500], twcolors.purple[500]],
	light: [twcolors.red[300], twcolors.orange[300], twcolors.yellow[300], twcolors.green[300], twcolors.teal[300], twcolors.blue[300], twcolors.indigo[300], twcolors.purple[300]],
	neutral: [twcolors.white, twcolors.gray[200], twcolors.gray[400], twcolors.gray[600], twcolors.gray[800], twcolors.black, twcolors.amber[900], twcolors.amber[700]],
}
export default function ColorPicker(props: ColorPickerProps) {
	const { color, onChange, label, pickRandom, children } = props
	const [toggleColors, setToggleColors] = useState(false)
	const [selectedColor, setSelectedColor] = useState(color ? color : '#ffffff')
	
	const convert3DigitHexTo6Digit = useCallback((hex: string) => {
		if (!hex) hex = '#ffffff'
		if (hex[0] !== '#') hex = '#' + hex
		if (hex.length === 4) hex = hex.replace(/#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/g, '#$1$1$2$2$3$3')
		return hex
	}, [])
	
	const selectRandomColor = useCallback(() => {
		const allColors = Object.values(defaultColors).flat().map(color => convert3DigitHexTo6Digit(color))
		const randomColor = allColors[Math.floor(Math.random() * allColors.length)]
		return randomColor
	}, [convert3DigitHexTo6Digit])

	const colorIsASelectableOption = useCallback((c: string, defColors: object): boolean => {
		const allColors = Object.values(defColors)
			.flat()
			.map(color => convert3DigitHexTo6Digit(color))
		return allColors.includes(c)
	}, [convert3DigitHexTo6Digit])
	
	// TODO: Maybe useMemo here?
	useEffect(() => {
		if(!color && pickRandom) {
			const randomColor = selectRandomColor()
			setSelectedColor(randomColor)
			onChange(randomColor)
		} else {
			setSelectedColor(color ? convert3DigitHexTo6Digit(color) : '#ffffff')
			onChange(color ? convert3DigitHexTo6Digit(color) : '#ffffff')
		}
		// DO NOT FIX THIS, IT WILL BREAK THE COLOR PICKER
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [color, selectRandomColor, pickRandom, convert3DigitHexTo6Digit])

	const [customColors, setCustomColors] = useState<string[]>(!color || colorIsASelectableOption(color, defaultColors) ? [] : [color])

	return (
		<Container>
			<Label>{label}</Label>
			<Wrapper>
				<PreviewWrapper>
					<Row>{children}</Row>
					<SwatchPreview $collapse={toggleColors}>
						<Label>Color</Label>
						<SwatchWrapper type={'button'} onClick={e => setToggleColors(!toggleColors)}>
							<Swatch style={{ backgroundColor: selectedColor }}></Swatch>
							<Icon name={toggleColors ? 'chevron-down' : 'chevron-up'} size={24} style={{ color: colors.dark }} />
						</SwatchWrapper>
					</SwatchPreview>
				</PreviewWrapper>
				<ColorContainer $collapse={toggleColors}>
					<ColorsWrapper>
						{Object.entries({ ...defaultColors, customColors }).map(([key, row]) => (
							<Fragment key={key}>
								{row.map((c: string, index: number) => (
									<Color key={`${c}_${index}`} style={{ backgroundColor: c }}>
										{c && selectedColor === convert3DigitHexTo6Digit(c) ? <Checkmark style={{ color: readableColor(selectedColor, darken(0.5, c), lighten(0.65, c), false) }}>✓</Checkmark> : null}
										<ColorInput
											onChange={event => {
												onChange(convert3DigitHexTo6Digit(event.target.value))
												setSelectedColor(event.target.value)
											}}
											id={`outline_color_${c}`}
											name={'outline_color'}
											value={convert3DigitHexTo6Digit(c)}
											type={'radio'}
										/>
										<ColorLabel htmlFor={`outline_color_${c}`} />
									</Color>
								))}
							</Fragment>
						))}
					</ColorsWrapper>

					<Row>
						<CustomColorButton htmlFor='customColor'>
							<input
								id={'customColor'}
								style={{ appearance: 'none', position: 'absolute', opacity: 0 }}
								type={'color'}
								defaultValue={selectedColor}
								onChange={event => {
									setSelectedColor(convert3DigitHexTo6Digit(event.target.value))
									onChange(convert3DigitHexTo6Digit(event.target.value))
								}}
								onBlur={event => {
									onChange(convert3DigitHexTo6Digit(event.target.value))
									setSelectedColor(convert3DigitHexTo6Digit(event.target.value))
									if (!colorIsASelectableOption(event.target.value, { ...defaultColors, customColors })) setCustomColors([...customColors, event.target.value])
								}}
							/>
							Custom Color
						</CustomColorButton>
					</Row>
				</ColorContainer>
			</Wrapper>
		</Container>
	)
}

const Container = styled.div``
const Label = styled.label`
	font-size: 0.7em;
	color: var(--dark);
	font-weight: 600;
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border: 1px solid ${colors.light};
	border-radius: 4px;
	width: 100%;
	padding-top: 0.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
`
const SwatchPreview = styled.div<{ $collapse: boolean }>`
	display: flex;
	flex-direction: column;

	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 0.5em;
	transition: 0.3s ease all;
	// opacity: ${props => (props.$collapse ? 1 : 0)};
`
const SwatchWrapper = styled.button`
	height: 42px;
	width: 70px;
	background: transparent;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0.5rem;
	border-radius: 4px;
	border: 1px solid ${colors.light};
`
const Swatch = styled.div`
	width: 24px;
	height: 24px;
	aspect-ratio: 1/1;
	transition: 0.3s ease all;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
`
const PreviewWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

const ColorContainer = styled.div<{ $collapse: boolean }>`
	transition: 0.5s ease max-height, 0.3s ease opacity, 0.2s ease padding-bottom;
	max-height: ${props => (props.$collapse ? '0px' : '1000px')};
	opacity: ${props => (props.$collapse ? 0 : 1)};
	pointer-events: ${props => (props.$collapse ? 'none' : 'auto')};
	// overflow: hidden;
	padding-bottom: ${props => (props.$collapse ? 0 : '0.5em')};
`
const ColorsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	gap: 0.5rem;
`
const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 0.5rem;
`
const Color = styled.div`
	aspect-ratio: 1/1;
	border-radius: 4px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: 0.1s ease-out transform;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
	cursor: pointer;
	&:hover {
		transform: scale(1.1);
	}
`

const ColorLabel = styled.label`
	width: 100%;
	height: 100%;
	cursor: pointer;
`
const ColorInput = styled.input`
	appearance: none;
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	&:checked + ${ColorLabel} {
	}
`
const Checkmark = styled.span`
	position: absolute;
	font-size: 1.5rem;
`
const CustomColorButton = styled.label`
	font-weight: bold;
	font-size: 0.75rem;
	padding: 0;
	margin: 0;
	margin-top: 0.5em;
	transition: 0.1s ease-out background;
	padding: 0.5rem;
	cursor: pointer;
	color: ${lighten(0.1, colors.dark)};
	&:hover {
		background: ${transparentize(0.9, colors.dark)};
	}
`
