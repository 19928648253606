import { useGetHerdQuery } from 'library/api/herd'
import DateRangeCalendar from 'components/DateRangeCalendar'
import { useAnimalsLayer, useGrazingDistributionLayer } from 'components/map/layers'
import { useCardBuffer } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as turf from '@turf/turf'
import LoadingSpinner from 'components/LoadingSpinner'
import { useGetAnimalQuery } from 'library/api/animal'
import { clamp } from 'lodash'
import { transparentize } from 'polished'
import { colors } from 'styles'
export const GrazingMap = () => {
	const { id, type } = useParams()
	const navigate = useNavigate()
	const { MapView } = useMap()
	const card = useCardBuffer()
	const grazingDistribution = useGrazingDistributionLayer()


	const { data: animal } = useGetAnimalQuery({ id })
	const { data: herd }: any = useGetHerdQuery({ id: animal?.herdId }, {
		enabled: !!animal?.herdId,
	})

	const [dateRange, setDateRange] = useState<Date[]>([herd?.pastureAssignmentDate ? new Date(herd?.pastureAssignmentDate) : new Date(), new Date()])
	// const animalsMap = useAnimalsLayer()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>()
	const [helpText, setHelpText] = useState<string | null>()

	const displayDateRange = useMemo(() => {
		if (!dateRange || dateRange.length < 1) return
		if (dateRange[1].getFullYear() - dateRange[0].getFullYear() > 1) {
			const newStartDate = new Date()
			newStartDate.setMonth(1)
			newStartDate.setFullYear(dateRange[1].getFullYear())
			return [newStartDate, dateRange[1]]
		}
		return dateRange
	}, [dateRange])


	const getGrazingPattern = useCallback(async ([startDate, endDate]: Date[]) => {
		if(!type || !id) return
		setError(null)
		setHelpText(null)
		setIsLoading(true)
		const grazingData = await grazingDistribution.getData({ id: id, type: type as DeviceLocationParams['type'], startDate, endDate })
		setIsLoading(false)
		if (!grazingData || grazingData?.data?.features.length === 0) {
			setError('No location data found')
			setHelpText('Please select a different date range')
			return
		}
		let [minLng, minLat, maxLng, maxLat] = turf.bbox(grazingData)
		MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		minLng = clamp(minLng, -180, 180)
		maxLng = clamp(maxLng, -180, 180)
		minLat = clamp(minLat, -90, 90)
		maxLat = clamp(maxLat, -90, 90)
		MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: 100 })
		grazingDistribution.setVisible(!grazingDistribution.visible)
	}, [MapView, card.bufferWidth, grazingDistribution, id, type])

	return (
		<Container>
			<DateRangeCalendar
				standAlone={true}
				id={'grazing-distribution-date-range'}
				dateRange={displayDateRange || dateRange}
				onCancel={() => navigate(-1)}
				onChange={async (e: Date[]) => {
					setDateRange(e)
					getGrazingPattern(e)
				}}
				isLoading={isLoading}
			/>
			{isLoading && <LoadingOverlay><LoadingSpinner/></LoadingOverlay>}
			{error || helpText ? <ErrorContainer>
				<Error>{error}</Error> 
				<Help>{helpText}</Help>
			</ErrorContainer>
				: null}
		</Container>
	)
}

const Container = styled.div`
	padding: 0.5em;
	position: relative;
`
const LoadingOverlay = styled.div`
	position: absolute;
	inset: 0;
	background: ${transparentize(0.2, '#ffffff')};
	display: flex;
	justify-content: center;
	align-items: center;
`
const EventsFoundContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: .5em;
	width: 100%;
	justify-content: center;
	gap: .5em;
	align-items: center;
	text-align: center;
	`
const ErrorContainer = styled(EventsFoundContainer)`
	background: ${colors.error};
	border-radius: var(--border-radius);
	padding: .5em;
	text-align: left;

`
const Error = styled.div`
	color: #ffffff;
	width: 100%;
	font-size: .9em;
	font-weight: 700;
`
const Help = styled.div`
	font-size: 0.8em;
	color: #ffffff;
	width: 100%;
`