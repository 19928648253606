import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Herd } from 'library/models'


interface HerdState {
    layer: {
        visible: boolean
	}
    herd: Herd
}

const initialHerd = new Herd({ new: true })

const initialState: HerdState = {
	layer: {
		visible: true,
	},
	herd: initialHerd
}

export const herdSlice = createSlice({
	name: 'herdSlice',
	initialState,
	reducers: {
		setVisible: (state, action: PayloadAction<boolean>) => {
			state.layer.visible = action.payload
		},
		clear: state => {
			state.herd = new Herd({ new: true })
		},
		reset: () => {
			return { 
				...initialState,
				herd: new Herd({ new: true })
			}
		},
		setHerd: (state, action: PayloadAction<Herd>) => {
			state.herd = action.payload
		},
		setHerdName: (state, action: PayloadAction<string>) => {
			state.herd.name = action.payload
		},
		setPastureId: (state, action: PayloadAction<string>) => {
			state.herd.pastureId = action.payload
		},
	},
})

export const { clear, reset, setHerd } = herdSlice.actions

export default herdSlice.reducer