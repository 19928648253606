import styled from 'styled-components/macro';
import moment from 'moment'
import { colors } from 'styles';
const dateTime = ({className, date, hideTime, row}: {className?: any, date: string | Date | null | undefined, hideTime?: boolean, row?: boolean}) => (
	<DateTimeWrapper className={className} $row={row}>
		{date ? (
			<>
				{!hideTime && <Time>
					{date ? moment(date).format('h:mm') : '--:--'}
					<TimePeriod>{date ? moment(date).format('A') : '--'}</TimePeriod>
				</Time>}
				<Date>{date ? moment(date).format('M/DD/YY') : '--/--/--'}</Date>
			</>
		) : (
			<Date>No Timestamp</Date>
		)}
	</DateTimeWrapper>
)

export const DateTime = styled(dateTime)`
	display: flex;
	justify-self: flex-start;
	/* text-align: right; */
	// color: ${colors.dark};
`

const DateTimeWrapper = styled.div<{ $row?: boolean; }>`
	flex-direction: ${props => props.$row ? 'row' : 'column'}
`

const Date = styled.div`
	font-size: 1em;
	/* font-weight: bold; */
	/* text-align: right; */
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	// line-height: 0.9;
`
const TimePeriod = styled.span`
	font-size: 0.6em;
	/* font-weight: bold; */
	/* text-align: right; */
`
const Time = styled.div`
	font-size: 1em;
	/* text-align: right; */
`

export default DateTime;
