import 'react-calendar/dist/Calendar.css'
import { useState, useRef } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components/macro'
import moment from 'moment'
// import { Modal } from './Modal'
// import ReactPortal from './ReactPortal'
import { CardAction, CardActions } from './cards'
import { BottomSheet } from 'react-spring-bottom-sheet'

export interface DateRangeCalendarProps {
	className?: any
	id?: string
	label?: string
	dateRange: Date[]
	standAlone?: boolean
	onCancel?: () => void
	onChange: (d: Date[]) => void
	isLoading?: boolean
}
export type DateRange = Date | [Date | null, Date | null] | null | undefined

const DateRangeInput = ({ className, dateRange, onChange, onCancel, isLoading, standAlone = false, label = 'Date Range' }: DateRangeCalendarProps) => {
	const calendarRef = useRef<any>(null)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [_dateRange, _setDateRange] = useState<Date[]>(dateRange)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const [showCalendar, setShowCalendar] = useState<boolean>(false)
	// const [position, setPosition] = useState<{ top: string; transform: string }>({ top: 'calc(100% + 10px)', transform: 'translate(-50%, 0)' })
	// const dateModalRef = useRef<any>(null)
	const handleChange = (d: Date[]) => {
		onChange(d)
		_setDateRange(d)
		setShowCalendar(false)
		setIsOpen(false)
		// dateModalRef.current.close()
	}

	// Hide the calendar when the user clicks outside of it or the button
	// const handleClickOutside = (event: Event) => {
	// 	if (calendarRef.current && buttonRef.current) {
	// 		if (!calendarRef.current.contains(event.target as Node) && !buttonRef.current.contains(event.target as Node)) {
	// 			setShowCalendar(false)
	// 			dateModalRef.current?.close()
	// 		}
	// 	}
	// }
	// useEffect(() => {
	// 	if (showCalendar) {
	// 		document.addEventListener('mousedown', handleClickOutside)
	// 		if (calendarRef.current) {
	// 			const rect = calendarRef.current.getBoundingClientRect()
	// 			const inView =
	// 				rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	// 			if (!inView) {
	// 				if (rect.top - (rect.height + 72) < 0) {
	// 					setPosition({ top: 'calc(100% + 10px)', transform: 'translate(-50%, 0)' })
	// 				} else if (rect.bottom + (rect.height + 72) > (window.innerHeight || document.documentElement.clientHeight)) {
	// 					setPosition({ top: '0', transform: 'translate(-50%, calc(-100% - 10px))' })
	// 				}
	// 			}
	// 		}
	// 	} else {
	// 		document.removeEventListener('mousedown', handleClickOutside)
	// 	}
	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutside)
	// 	}
	// }, [showCalendar])
	return (
		<div className={className}>
			<BottomSheet open={isOpen} snapPoints={({ maxHeight }) => maxHeight - 65}>
				<DateWrapper>
					<DateLabel>{label}</DateLabel>

					<StyledCalendar
						inputRef={calendarRef}
						selectRange={true}
						calendarType={'US'}
						onChange={(d: Date[]) => _setDateRange(d)}
						//@ts-ignore
						value={_dateRange}
						maxDate={new Date()}
					/>
					<CardActions>
						<CardAction disabled={isLoading} onClick={() => handleChange(_dateRange)}>Apply</CardAction>
						<CardAction variant={'danger'} onClick={() => handleChange(dateRange)}>
							Cancel
						</CardAction>
					</CardActions>
				</DateWrapper>
			</BottomSheet>
			<DateLabel>{label}</DateLabel>
			<DateRangeButton
				ref={buttonRef}
				onClick={() => {
					if (!standAlone) {
						setIsOpen(!showCalendar)
						setShowCalendar(!showCalendar)
					}
				}}>
				<DateContainer>
					{/* <DateHeader>Start Date</DateHeader> */}
					{Array.isArray(_dateRange) ? moment(_dateRange[0]).format('MM/DD/YYYY') : '--/--/-----'}
				</DateContainer>
				<ArrowContainer>
					<svg width='48.000999' height='24' version='1.1' id='svg108'>
						<defs id='defs112' />
						<g id='g342' transform='translate(0.24313218,0.0446411)'>
							<path d='m 33.510475,17.248359 1.414,1.414 6.707,-6.707 -6.707,-6.7070002 -1.414,1.414 4.293,4.2930002 h -9.586 v 2 h 9.586 z' id='path106' />
							<path d='m 14.00426,6.6623588 -1.414,-1.414 -6.7069999,6.7070002 6.7069999,6.707 1.414,-1.414 -4.2929999,-4.293 H 19.29726 v -2 H 9.7112601 Z' id='path272' />
							<rect
								style={{
									fill: '#000000',
									strokeWidth: '0.659723',
								}}
								id='rect326'
								width='11.162712'
								height='1.9912386'
								x='19.224628'
								y='10.959155'
							/>
						</g>
					</svg>
				</ArrowContainer>
				<DateContainer>
					{/* <DateHeader>End Date</DateHeader> */}
					{Array.isArray(_dateRange) ? moment(_dateRange[1]).format('MM/DD/YYYY') : '--/--/-----'}
				</DateContainer>
			</DateRangeButton>
			{standAlone ? (
				<DateWrapper>
					<StyledCalendar
						inputRef={calendarRef}
						selectRange={true}
						calendarType={'US'}
						onChange={(d: Date[]) => _setDateRange(d)}
						//@ts-ignore
						value={_dateRange}
						maxDate={new Date()}
					/>
					<CardActions>
						<CardAction onClick={() => handleChange(_dateRange)}>Apply</CardAction>
						<CardAction
							variant={'danger'}
							onClick={() => {
								standAlone && onCancel ? onCancel() : handleChange(dateRange)
							}}>
							Cancel
						</CardAction>
					</CardActions>
				</DateWrapper>
			) : null}
		</div>
	)
}

// Styles
const DateRangeCalendar = styled(DateRangeInput)`
	width: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-end;
`

const DateWrapper = styled.div`
	padding-top: 1em;
`
const DateLabel = styled.label`
	font-size: 0.7em;
	color: var(--dark);
	font-weight: 600;
`
const DateRangeButton = styled.button`
	padding: 0.5em 1em;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background: transparent;
	border: 1px solid #ccc;
	border-radius: var(--border-radius);
	position: relative;
	width: 100%;
	background-color: white;
`
const ArrowContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0.5);
`
const DateContainer = styled.div`
	position: relative;
	// padding-top: 10px;
`
const StyledCalendar = styled(Calendar)`
	width: 100%;
`
export default DateRangeCalendar
