import 'react-spring-bottom-sheet/dist/style.css'
// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { MapProvider } from 'react-map-gl'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { getAccessToken, msalInstance } from './auth/msal'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import { persistor, store } from 'store'
// import { createGlobalStyle } from 'styled-components';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { PersistGate } from 'redux-persist/integration/react'
import { InteractionType } from '@azure/msal-browser'
import { onlineManager, QueryClient, focusManager, useIsRestoring, QueryClientProvider } from '@tanstack/react-query';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistQueryClientProvider, persistQueryClient, } from '@tanstack/react-query-persist-client';
import { ApiClientProvider } from 'library/api/client'
import { ReactQueryClientProvider } from 'library/api'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
	<MsalProvider instance={msalInstance}>
		<MapProvider>

			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
						<QueryWrapper>
							<App />
						</QueryWrapper>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</MapProvider>
	</MsalProvider>
)

function QueryWrapper({ children }: { children: React.ReactNode }) {
	// const { getToken, refreshAuthToken } = useAuth()
	const persister = createAsyncStoragePersister({
		storage: window.localStorage,
		throttleTime: 1000,
	})
	return <ApiClientProvider baseUrl={process.env.REACT_APP_CORRAL_BASEURL || ''} getToken={getAccessToken} refreshToken={getAccessToken}>
		<ReactQueryClientProvider persister={persister}>
			{children}
		</ReactQueryClientProvider>
	</ApiClientProvider>

}

// const GlobalStyle = createGlobalStyle``

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
