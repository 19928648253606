import React from 'react'
import { MutationCache, MutationFunction, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider, Persister } from '@tanstack/react-query-persist-client'
import { useEndpoints } from './endpoints'

export function ReactQueryClientProvider({ persister, children }: { persister: Persister, children: React.ReactNode }) {

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				gcTime: 1000 * 60 * 60 * 24, // 24 hours
				staleTime: 2000,
				retry: 2,
			},
			mutations: {
				gcTime: 1000 * 60 * 60 * 24, // 24 hours
				networkMode: 'offlineFirst',
				retry: 1,
			}
		},
		mutationCache: new MutationCache({
			onSuccess: (data) => {
			},
			onError: (error) => {
				console.error(error.message)
			},
		}),
	})


	// Generate mutation default functions for all mutation endpoints
	type Endpoint = {
		key: string // Key is a string representing the full path to the function in dot notation - eg: ranch.mutation.create
		value: MutationFunction // Value is the endpoint function
	}

	// Recursive function to iterate over all nested object and returns an array of Endpoints. 
	const unpackEndpoints = (key: string, obj: object): Endpoint[] => {
		let result: Endpoint[] = []
		Object.entries(obj).map(([endpointKey, endpointValue]) => {
			if (typeof endpointValue === 'object') {
				// If the endpoint is an object, dig deeper
				result = [...result, ...unpackEndpoints(`${key}.${endpointKey}`, endpointValue)]
			} else {
				result = [...result, { key: `${key}.${endpointKey}`, value: endpointValue }]
			}
		})
		return result
	}
	// Generate Mutation Keys and functions from endpoints list so they cn be frontloaded for offline support
	const endpoints = useEndpoints()
	const mutations = Object.entries(endpoints).flatMap(([key, value]) => unpackEndpoints(`${key}.mutation`, value.mutation))

	// Set mutation defaults
	mutations.forEach((endpoint) => {
		queryClient.setMutationDefaults([endpoint.key], { mutationFn: endpoint.value })
	})
	return (
		<PersistQueryClientProvider
			onSuccess={() => {
				queryClient.resumePausedMutations().then(() => queryClient.invalidateQueries())
			}}
			persistOptions={{ persister }}
			client={queryClient}>
			{children}
		</PersistQueryClientProvider>
	)
}

