import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Geometry } from '@turf/turf'
import { Animal } from 'library/models/animal.model'
import { v4 as uuidv4 } from 'uuid'

export type AnimalFeatureProperties = {
	tag: string
	deviceId?: string | null
	breed?: Breed['breed'] | null
	sireId?: string | null
	damId?: string | null
	herdId?: string
	herdName?: string
	sex?: 'Sire' | 'Dam' | null
	dob?: Date | null
	weight?: number
	type?: 'animal'
	animalEvents?: AnimalEvent[]
}

interface AnimalState {
	animal: Animal
	layer: {
		visible: boolean
	}
	feature: {
		id: string
		geometry: Geometry | null
		properties: AnimalFeatureProperties
	}
}

const initialAnimal = new Animal({ new: true })

const initialState: AnimalState = {
	layer: {
		visible: true,
	},
	animal: initialAnimal,
	feature: {
		id: initialAnimal.id,
		geometry: null,
		properties: {
			tag: '',
			type: 'animal',
		},
	},
}

export const animalSlice = createSlice({
	name: 'animalSlice',
	initialState,
	reducers: {
		setFeature: (state, action: PayloadAction<AnimalState['feature']>) => {
			state.feature = action.payload
		},
		setVisible: (state, action: PayloadAction<boolean>) => {
			state.layer.visible = action.payload
		},
		setAnimal: (state, action: PayloadAction<Animal>) => {
			state.animal = action.payload
		},
		reset: (state) => {
			const newAnimal = new Animal({ new: true })
			state.animal = newAnimal
			state.feature = initialState.feature
			state.layer = initialState.layer
		},
		setId: (state, action: PayloadAction<string>) => {
			state.feature.id = action.payload
			state.animal.id = action.payload
		},
		setDeviceId: (state, action: PayloadAction<string>) => {
			state.feature.properties.deviceId = action.payload
			state.animal.deviceId = action.payload
		},
		setBreed: (state, action: PayloadAction<Breed['breed']>) => {
			state.feature.properties.breed = action.payload
			state.animal.breed = action.payload
		},
		setTag: (state, action: PayloadAction<string>) => {
			console.log('setTag: ', action.payload)
			console.log(state.animal)
			state.feature.properties.tag = action.payload
		},
		setGender: (state, action: PayloadAction<'Sire' | 'Dam'>) => {
			state.feature.properties.sex = action.payload
			state.animal.sex = action.payload
		},
		setDOB: (state, action: PayloadAction<Date>) => {
			state.feature.properties.dob = action.payload
			state.animal.dob = action.payload
		},
		setHerd: (state, action: PayloadAction<{ name: string; id: string }>) => {
			state.animal.herdName = action.payload.name
			state.animal.herdId = action.payload.id
			state.animal.herd = action.payload
			state.feature.properties.herdName = action.payload.name
			state.feature.properties.herdId = action.payload.id
		},
		setSireId: (state, action: PayloadAction<string>) => {
			state.animal.sireId = action.payload
			state.feature.properties.sireId = action.payload
		},
		setDamId: (state, action: PayloadAction<string>) => {
			state.animal.damId = action.payload
			state.feature.properties.damId = action.payload
		},
	},
})

export const { setFeature, setAnimal, reset } = animalSlice.actions

export default animalSlice.reducer
