import { useNavigate, useParams } from 'react-router-dom'
import { Icon, MDIcon } from 'components/icons'
import { useGrazingDistributionLayer, useAnimalTrailLayer, useAnimalsLayer } from 'components/map/layers'
import { RootState, store } from 'store'
import * as turf from '@turf/turf'
import { useMap } from 'react-map-gl'
import { mapSlice } from 'store/map.slice'
import { useCardBuffer } from 'hooks'
import { cambelCaseToTitle, kebabize } from 'utilities'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { DetailsBody, DetailsHeader, DetailsItem } from 'components/details'
import { CardAction, CardActions } from 'components/cards'
import { CardDeleteAction } from 'components/cards/CardActions'
import { structureSlice } from './structure.slice'
import { useDeletePastureFeatureMutation, useGetPastureFeatureQuery } from 'library/api/pastureFeature'
import { routeSlice } from 'store/route.slice'
import { useSelector } from 'react-redux'
export default function StructureDetails() {
	const navigate = useNavigate()
	const { id } = useParams<QueryParams<{ id: string }>>()
	const grazingDistribution = useGrazingDistributionLayer()
	const animalTrail = useAnimalTrailLayer()
	const { MapView } = useMap()
	// const ranchMap = useRanchMapLayer()
	const animalsMap = useAnimalsLayer()
	const {mutate: deletePastureFeature} = useDeletePastureFeatureMutation()
	const card = useCardBuffer()
	const { data: structure } = useGetPastureFeatureQuery({ id: id || '' }, { enabled: !!id })

	const isMobile = useSelector((state: RootState) => state.routeSlice.isMobile)


	const handleCenterAction = async () => {
		if(!structure || !structure.geometry) return
		const coords: any = structure.geometry.coordinates // TODO: dont use any
		const feature = turf.point(coords)
		const buffer = turf.buffer(feature, (structure?.safeZoneDistance || 10) + 5, { units: 'meters' })
		const [minLng, minLat, maxLng, maxLat] = turf.bbox(buffer)
		await MapView?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: { left: card.bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
		if (isMobile) store.dispatch(routeSlice.actions.setSnapToIndex(0))
	}

	const handleEdit = async () => {
		animalsMap.setVisible(true)
		grazingDistribution.setVisible(false)
		animalTrail.reset()
		store.dispatch(mapSlice.actions.setMode('ModifyMode'))
		if (structure) store.dispatch(structureSlice.actions.set(structure))
		await handleCenterAction()
		navigate(`/structure/${id}/edit`)
	}

	const handleDelete = () => {
		if(id) deletePastureFeature({ id: id })
		navigate('/features')
	}

	return (
		<>
			<Container>
				<CardActions>
					<CardAction onClick={async () => handleCenterAction()}>
						<MDIcon size={'sm'} style={{ transform: 'scale(.75)' }} name={'my_location'} />
						Locate Position
					</CardAction>
				</CardActions>
				<DetailsHeader>Details</DetailsHeader>
				<DetailsBody>
					<DetailsItem label={'Name'}>{structure?.name}</DetailsItem>
					<DetailsItem label={'Type'}>{structure?.type ? cambelCaseToTitle(structure?.type) : ''}</DetailsItem>
					<DetailsItem label={'Safe Zone'} tooltip={'Enabling a safe zone on a structure will ensure it is always reachable for the animals'}>{structure?.safeZone ? 'Enabled' : 'Disabled'}</DetailsItem>
					{structure?.safeZone ? <DetailsItem label={'Safe Zone Distance'} tooltip={'The diameter of the safe zone around the structure'}>{structure?.safeZoneDistance}</DetailsItem> : null}
				</DetailsBody>
				<CardActions>
					<CardAction style={{ flexDirection: 'row', justifyContent: 'center' }} onClick={e => handleEdit()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.dark} name={'pencil'} />
						Edit
					</CardAction>
					<CardDeleteAction onClick={e => handleDelete()}>
						<Icon style={{ transform: 'scale(.75)' }} color={colors.error} name={'trash'} />
						Delete
					</CardDeleteAction>
				</CardActions>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 1em;
	padding-top: 0;
`