import { useParams } from 'react-router-dom'
import { AnimalForm, BoundaryForm, StructureForm, HerdForm, MoveHerdForm } from 'pages/panels/forms'
import { AssignHerdForm } from '../herds/AssignHerd.form'

export const Edit: React.FC = () => {
	const { type, mode } = useParams<{ type: string, id: string, id1: string, id2: string, mode: string }>()
	const Page = () => {
		switch (type) {
		case 'animal':
		case 'animals': {
			return <AnimalForm />
		}
		case 'structure':
		case 'salt-and-mineral-deposit':
		case 'water-tank':
		case 'gate': {
			return <StructureForm />
		}
		case 'herds':
		case 'herd': {
			if (mode === 'move') return <MoveHerdForm />
			if (mode === 'assign') return <AssignHerdForm />
			return <HerdForm />
		}
		case 'exclusion':
		case 'pasture':
		case 'pastures': {
			return <BoundaryForm />
		}
		default: {
			return null
		}
		}
	}
	return (
		<>
			<Page />
		</>
	)
}

export default Edit
