import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { 
	// FLUSH, PAUSE, PERSIST, PURGE, REGISTER, 
	REHYDRATE, persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAccessToken } from 'auth/msal'

// Slices
import selectedFeature from 'store/selectedFeature'
import mapSlice from 'store/map.slice'
import createAccountSlice from 'store/createAccount.slice'
import routeSlice from 'store/route.slice'
import invitationSlice from 'store/invitation.slice'
import grazingDistributionSlice from 'store/grazingDistribution.slice'
import animalTrailSlice from 'store/animalTrail.slice'
import structureSlice from 'pages/panels/structures/structure.slice'
import boundarySlice from 'pages/panels/boundaries/boundary.slice'
import animalSlice from 'pages/panels/animals/animal.slice'
import pastureRouteSlice from 'pages/panels/pastureRoutes/pastureRoute.slice'
import herdSlice from 'pages/panels/herds/herd.slice'
import persistentDataSlice from 'store/persistentData.slice'

// const api = createApi({
// 	reducerPath: 'corralApi',
// 	baseQuery: fetchBaseQuery({
// 		baseUrl: process.env.REACT_APP_CORRAL_BASEURL,
// 		prepareHeaders: async headers => {
// 			const token = await getAccessToken()
// 			// If we have a token set in state, let's assume that we should be passing it.
// 			if (token) {
// 				headers.set('Authorization', `Bearer ${token}`)
// 			}

// 			return headers
// 		},
// 	}),
// 	extractRehydrationInfo(action, { reducerPath }) {
// 		if (action.type === REHYDRATE) {
// 			return action.payload?.[reducerPath]
// 		}
// 	},
// 	refetchOnMountOrArgChange: 180,
// 	refetchOnReconnect: true,
// 	refetchOnFocus: true,
// 	tagTypes: [
// 		'Animals', 
// 		'Account', 
// 		'AnimalBreeds', 
// 		'AnimalHistory', 
// 		'Pastures', 
// 		'PastureRoutes', 
// 		'PastureFeatures', 
// 		'Herds', 
// 		'UsersRanches', 
// 		'MapFeatures', 
// 		'AnimalFeatures', 
// 		'Analytics', 
// 		'Notifications', 
// 		'DeviceLocations', 
// 		'User', 
// 		'AnimalAnalytics', 
// 		'AnimalEventTypes', 
// 		'ArchivedAnimals', 
// 		'GrazingPlans',
// 		'Invitations',
// 	],
// 	endpoints: () => ({}),
// })

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['selectedFeature', 'selectedSlice', 'mapSlice', 'animalSlice', 'animalTrailSlice', 'boundarySlice', 'structureSlice', 'routeSlice', 'grazingDistributionSlice', 'pastureRouteSlice'],
}

const reducers = combineReducers({ 
	// [api.reducerPath]: api.reducer, 
	selectedFeature,
	mapSlice,
	structureSlice,
	boundarySlice,
	animalSlice,
	routeSlice,
	animalTrailSlice,
	grazingDistributionSlice,
	pastureRouteSlice,
	herdSlice,
	createAccountSlice,
	invitationSlice,
	persistentDataSlice,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			// serializableCheck: {
			// 	ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			// },
			serializableCheck: false,
			
		})
})
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof reducers>
const persistor = persistStore(store)
export { store, persistor }
