import React, { createContext, useContext, useMemo } from 'react'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
export const AxiosClient = createContext<AxiosInstance>(axios.create({
	headers: {
		'Content-type': 'application/json',
	},
}))

export function useClient() {
	return useContext(AxiosClient)
}

export function ApiClientProvider({ baseUrl, children, refreshToken, getToken }: { refreshToken: any, getToken: any, baseUrl: string, children: React.ReactNode }) {
	const client = useMemo(() => {
		const axiosClient: AxiosInstance = axios.create({
			baseURL: baseUrl,
			headers: {
				'Content-type': 'application/json',
			},
		})

		axiosClient.interceptors.request.use(
			async (config: AxiosRequestConfig) => {
				return new Promise(async (resolve, reject) => {
					if (!config || !config.headers) {
						return reject('Config headers undefined')
					}
					const auth = await getToken()

					if (auth) {
						// web
						if (typeof auth === 'string') {
							config.headers['Authorization'] = `Bearer ${auth}`
						} else {
							// native
							const token = JSON.parse(auth)
							config.headers['Authorization'] = `Bearer ${token.accessToken}`
						}
					}
					return resolve(config)
				})
			}, async (error) => {
				console.log({ error })
			})

		axiosClient.interceptors.response.use((response) => {
			return response
		}, async (error) => {
			const config = error.config
			if (error.response && error.response.status === 401 && !config?._retry) {
				let auth = await refreshToken()
				if(typeof auth === 'string') {
					config.headers['Authorization'] = `Bearer ${auth}`
				} else if (auth?.refreshToken) {
					config.headers['Authorization'] = `Bearer ${auth.accessToken}`

				}
				return axiosClient({...config, _retry: true})
			}
			return Promise.reject(error)
		})
		return axiosClient
	}, [baseUrl, refreshToken, getToken])




	return (
		<AxiosClient.Provider value={client}>
			{children}
		</AxiosClient.Provider>
	)
}