import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as turf from '@turf/turf'
import * as uuid from 'uuid'
export type StructureType = 'gate' | 'waterTank' | 'saltAndMineralDeposit'

interface StructureProperties {
	name: string
	safeZone: boolean
	safeZoneDistance: number
	type: StructureType
	pastureIds: string[]
	style?: { [key: string]: string | object | null }[]
}

interface StructureState {
	mode: 'DrawPolygonMode' | 'ViewMode' | 'DrawLineStringMode' | 'DrawPointMode' | 'ModifyMode' | 'SplitPolygonMode'
	feature: Structure
}

const initialState: StructureState = {
	mode: 'ViewMode',
	feature: {
		id: uuid.v4().toString(),
		geometry: undefined,
		name: '',
		safeZone: false,
		style: undefined,
		safeZoneDistance: 0,
		type: 'waterTank',
		pastureIds: []
	},
}

export const structureSlice = createSlice({
	name: 'structureSlice',
	initialState,
	reducers: {
		set: (state, action: PayloadAction<Structure>) => {
			state.feature = action.payload
		},
		reset: () => initialState,
		clear: () => initialState,
		setDefault: (state, action: PayloadAction<StructureType>) => {
			state.feature.id = uuid.v4().toString()
			state.feature.geometry = undefined
			state.feature.name = ''
			state.feature.type = action.payload
			state.feature.safeZoneDistance = 0
			switch (action.payload) {
			case 'waterTank': {
				state.feature.safeZone = true
				break
			}
			case 'gate': {
				state.feature.safeZone = false
				break
			}
			case 'saltAndMineralDeposit': {
				state.feature.safeZone = true
				break
			}
			}
		},
		setMode: (state, action: PayloadAction<'DrawPolygonMode' | 'ViewMode' | 'DrawLineStringMode' | 'DrawPointMode' | 'ModifyMode' | 'SplitPolygonMode'>) => {
			state.mode = action.payload
		},
		setPastureIds: (state, action: PayloadAction<string[]>) => {
			state.feature.pastureIds = action.payload
		},
		setId: (state, action: PayloadAction<string>) => {
			state.feature.id = action.payload
		},
		setProperties: (state, action: PayloadAction<StructureState['feature']>) => {
			state.feature = action.payload
		},
		setGeometry: (state, action: PayloadAction<turf.Point | turf.Polygon>) => {
			state.feature.geometry = action.payload
		},
		setType: (state, action: PayloadAction<StructureType>) => {
			state.feature.type = action.payload
		},
		setSafeZone: (state, action: PayloadAction<boolean>) => {
			state.feature.safeZone = action.payload
		},
		setSafeZoneDistance: (state, action: PayloadAction<number>) => {
			state.feature.safeZoneDistance = action.payload
		},
		setName: (state, action: PayloadAction<string>) => {
			state.feature.name = action.payload
		},
		setStyles: (state, action: PayloadAction<string>) => {
			state.feature.name = action.payload
		},
		setCoordinates: (state, action: PayloadAction<number[]>) => {
			state.feature.geometry = {
				type: 'Point',
				coordinates: action.payload,
			}
		},
	},
})

export const { set, reset, clear, setDefault, setMode, setPastureIds, setId, setProperties, setType, setGeometry, setSafeZone, setSafeZoneDistance, setCoordinates } = structureSlice.actions

export default structureSlice.reducer
