import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { lighten } from 'polished'

export interface RangeSliderProps {
	step?: number | 'any'
	min?: number
	max?: number
	value?: string | number
	displayValue?: string | number | null | undefined
	label?: string | null
	onChange?: any
	onPointerDown?: any
	onPointerUp?: any
	onPointerMove?: any
	onKeyDown?: any
	listId?: string
	list?: string[]
	name: string
	id: string
	scrubberValue?: any
	rangeSegments?: DeviceEventHighlight[]
}
const RangeSlider = ({ step = 1, min = 0, max = 100, value = 1, label, displayValue, listId, list, name, id, onChange, onKeyDown, onPointerDown, onPointerUp, onPointerMove, scrubberValue, rangeSegments }: RangeSliderProps) => {
	const rangeInput = useRef<HTMLInputElement>(null)
	const [showScrubber, setShowScrubber] = useState(false)
	
	const [currentValue, setCurrentValue] = useState(value)

	useEffect(() => {
		setCurrentValue(value)
	}, [value])

	const getLowerTrackFillWidth = useMemo(() => {
		if (rangeInput.current && max > 0) {
			const rangeThumbSize = 9
			const halfThumb = rangeThumbSize / 2
			return ((Number(value) - min) / (max - min)) * (rangeInput.current.getBoundingClientRect().width - halfThumb - halfThumb) + halfThumb
		} else {
			return 0
		}
	}, [min, max, value])
	return (
		<Slider data-body-scroll-lock-ignore={true}>
			{label ? (
				<LabelWrapper>
					<label htmlFor={name}>{label}</label>
					<output htmlFor={name}>{displayValue ? <div>{displayValue}</div> : null}</output>
				</LabelWrapper>
			) : null}
			<InputWrapper>
				{scrubberValue ? 
					<ScrubberBubble $show={showScrubber} style={{ left: getLowerTrackFillWidth }}>
						{scrubberValue}
					</ScrubberBubble>
					: null}
				<LowerTrackFill>
					{rangeSegments?.map((segment, index) => (
						<svg key={segment.id} height={50} width={`${segment.segmentPercent * 100}%`}>
							<rect y={0} x={0} width='100%' height={'100%'} fill={segment.type === 'ShockLeft' || segment.type === 'ShockRight' ? '#FFCC00' : segment.type === 'BuzzLeft' || segment.type === 'BuzzRight' ? '#2CA089' : 'transparent'} />
						</svg>
					))}
				</LowerTrackFill>
				<Input
					ref={rangeInput}
					id={id}
					step={step}
					min={min}
					max={max}
					name={name}
					list={listId}
					onKeyDown={(e: any) => onKeyDown ? onKeyDown(e) : null}
					onPointerDown={(e: any) => {
						// console.log('start', e.touches[0].movementX)
						// store.dispatch(routeSlice.actions.setScrollLocking(true))
						if (onPointerDown) onPointerDown(e)
						setShowScrubber(true)}
					}
					onPointerMove={(e: any) => {
						if (onPointerMove) onPointerMove(e)
						onChange(e)
					}}
					onPointerUp={(e: any) => {
						// store.dispatch(routeSlice.actions.setScrollLocking(false))
						if (onPointerUp) onPointerUp(e)
						setShowScrubber(false)
					}}
					// value={value}
					value={currentValue}
					type={'range'}
					onChange={(e: any) => {
						if (onChange) {
							onChange(e)
						} else {
							setCurrentValue(value)
						}

					}}
				/>
				
			</InputWrapper>
			{list ? (
				<datalist id={listId}>
					{list.map((item, index) => (
						<option key={`${listId}_${item}_${index}`} value={item} />
					))}
				</datalist>
			) : null}
		</Slider>
	)
}

const Slider = styled.div`
	width: 100%;
`
const thumbSize = 24
const trackHeight = 20
const sliderThumb = `
	appearance: none;
	height: ${thumbSize}px;
	width: 9px;
	border: 0;
	background: transparent;
	border: 3px solid white;
	border-radius: 999999px;
	transform: translateY(${-(thumbSize - trackHeight + 2) / 2}px);
	filter: drop-shadow(0 0 1px ${colors.dark});
`
const sliderTrack = `
	width: 100%;
	border: 1px solid ${lighten(0.3, colors.dark)};
	height: 100%;
	border-radius: var(--border-radius);
`

const InputWrapper = styled.div`
	position: relative;
	// overflow: hidden;
	height: ${trackHeight + 2}px;
`

const ScrubberBubble = styled.div.attrs((props: any) => ({
	// transform: props.transform,
	// transform: props.transform,
	left: props.left,
}))<{$show: boolean}>`
	position: absolute;
	top: -5px;
	transform: translateX(-50%) translateY(-100%) scale(${props => props.$show ? 1 : 0});
	opacity: ${props => props.$show ? 1 : 0};
	height: 30px;
	background: #ffffff;
	border-radius: 4px;
	width: 30px;
	pointer-events: none;
	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
	transform-origin: bottom center;
	border: 1px solid ${lighten(0.5, colors.dark)};
	
`

const LowerTrackFill = styled.div`
	position: absolute;
	overflow: hidden;
	top: 2px;
	right: 2px;
	left: 2px;
	bottom: 2px;
	height: ${trackHeight - 4}px;
	/* border: 1px solid ${lighten(0, colors.dark)}; */
	pointer-events: none;
	cursor: pointer;
	border-radius: 6px;
	// border: 1px solid ${lighten(0.7, colors.dark)};
	background: ${colors.dark};
`
const Input = styled.input`
	appearance: none;
	width: 100%;
	height: ${trackHeight}px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: transparent;
	&::-moz-range-thumb {
		${sliderThumb}
	}
	&::-webkit-slider-thumb {
		${sliderThumb}
	}
	&::-moz-range-track {
		${sliderTrack}
	}
	&::-webkit-slider-runnable-track {
		${sliderTrack}
	}
`
const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`
export default RangeSlider
