import styled from 'styled-components/macro'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { EditButton } from 'components/buttons'
import { useAddRanchImageMutation, useCreateRanchMutation, useGetRanchesQuery } from 'library/api/ranch'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import { CreateRanch } from 'library/models/ranch.model'
import { Address } from 'library/models'
import { countries, stateAbbreviations } from 'library/models/address.model'
import { useGetAccountQuery } from 'library/api/account'
import { Container } from 'react-bootstrap'
import { Input } from 'components/inputs'
import { Icon } from 'components/icons'
import { AvatarInput } from 'components/inputs/AvatarInput'
import { useNavigate } from 'react-router-dom'
import { useColors } from 'hooks'

const RanchForm = () => {
	const { data: account } = useGetAccountQuery()
	const { mutate: createRanch } = useCreateRanchMutation()
	const { mutate: addRanchImage } = useAddRanchImageMutation()
	const navigate = useNavigate()
	const colors = useColors()

	const [ranch, setRanch] = useState(new CreateRanch({ address: new Address() }))
	const [image, setImage] = useState<ImageUpload>()

	return (
		<Container>
			<Column>
				<HeaderText>New Ranch</HeaderText>
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
					<div style={{ margin: 'auto' }}>
						<AvatarInput
							placeholder={'rr'}
							primaryColor={colors.getColorForObject(account).primary}
							backgroundColor={colors.getColorForObject(account).background}
							image={account?.imageFile ? account.imageFile : null}
							onChange={async ({ fileName, contentType, data}) => {
								setImage({
									id: uuidv4().toString(),
									fileName,
									contentType,
									data
								})
							}}
						/>
					</div>
				</div>
				<Form onSubmit={async (e) => {
					e.preventDefault();
					const ranchData = ranch				

					if (!ranchData.name) return

					const newId = uuidv4().toString()

					const payload: CreateRanchParams = {
						id: newId,
						name: ranchData.name,
						address: ranch.address,
						addressSameAsAccount: false,
					}
					createRanch(payload)

					if (image) {
						addRanchImage({
							id: newId,
							image
						})
					}

					navigate('/account/ranches')
				}}>
					<Input
						label={'Ranch Name'}
						value={ranch.name}
						id={'ranchName'}
						maxLength={100}
						onChange={(event) => {
							setRanch({ ...ranch, name: event.target.value })
							event.preventDefault()
						}}
					/>
					<InputWrapper>
						<Input
							label={'Line 1'}
							value={ranch.address.address1}
							maxLength={50}
							placeholder={'Line 1'}
							id={'ranch_address1'}
							onChange={(event) => {
								setRanch({ ...ranch, address: { ...ranch.address, address1: event.target.value } })
								event.preventDefault()

							}} />
					</InputWrapper>
					<InputWrapper>
						<Input
							label={'Line 2'}
							value={ranch.address.address2}
							maxLength={50}
							placeholder={'Line 2'}
							id={'ranch_address2'}
							onChange={(event) => {
								setRanch({ ...ranch, address: { ...ranch.address, address2: event.target.value } })
								event.preventDefault()
							}} />
					</InputWrapper>
					<InputGroup>
						<InputWrapper>
							<Input
								label={'City'}
								value={ranch.address.city}
								maxLength={50}
								placeholder={'City'}
								id={'ranch_city'}
								onChange={(event) => {
									setRanch({ ...ranch, address: { ...ranch.address, city: event.target.value } })
									event.preventDefault()
								}} />
						</InputWrapper>
						<InputWrapper>
							<SelectContainer>
								<Label >State</Label>
								<SelectInputWrapper>
									<Select
										required
										value={ranch.address.state}
										id={'ranch_state'}
										onChange={(event) => {
											setRanch({ ...ranch, address: { ...ranch.address, state: event.target.value } })
											event.preventDefault()
										}}>
										<option value={''} style={{ display: 'none' }}></option>
										{stateAbbreviations.map((state) => <option style={{ color: 'black' }} key={state} value={state}>{state}</option>)}
									</Select>
								</SelectInputWrapper>
							</SelectContainer>
						</InputWrapper>
						<InputWrapper>
							<Input
								label={'Zip code'}
								type={'text'}
								maxLength={5}
								minLength={5}
								pattern={'[0-9]{5}'}
								value={ranch.address.postalCode}
								placeholder={'Zip Code'}
								id={'ranch_postalCode'}
								onChange={(event) => {
									setRanch({ ...ranch, address: { ...ranch.address, postalCode: event.target.value.replace(/[^0-9]/g, '').slice(0, 5) } })
									event.preventDefault()
								}} />
						</InputWrapper>
					</InputGroup>
					<InputWrapper>
						<SelectContainer>
							<Label>Country</Label>
							<Select
								required
								value={ranch.address.country}
								id={'ranch_country'}
								onChange={(event) => {
									setRanch({ ...ranch, address: { ...ranch.address, country: event.target.value } })
								}}>
								<option value={''} style={{ display: 'none', color: 'black' }}></option>
								{countries.map((country) => <option style={{ color: 'black' }} key={country.code} value={country.code}>{country.name}</option>)}
							</Select>
						</SelectContainer>
					</InputWrapper>
					<EditButton type={'submit'} >
						Create
						<Icon name={'check'} />
					</EditButton>
				</Form>
			</Column>
		</Container>
	)
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5em;
`
const SelectContainer = styled.div<{ $hasIcon?: boolean; $hideLabel?: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 0.5em;
	padding: 0;
	width: 100%;
`
const SelectInputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
`
const Form = styled.form`
    padding: 1em;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
`
const InputWrapper = styled.div`
`
const Label = styled.label<{ $hideLabel?: boolean, disabled?: boolean }>`
	font-size: .8em;
	color: ${transparentize(0.3, colors.dark)};
	font-weight: 600;
	transition: .3s ease all;
	top: 0;
	left: 0;
	${({ disabled }: { disabled?: boolean }) => disabled ? 'padding-left: .7em;' : null}
	${props => (props.$hideLabel ? 'opacity: 0; pointer-events: none;' : 'transform: translateY(0%);')}
`
const Select = styled.select`
	padding: 0.5em;
	border: 1px solid var(--light);
	border-radius: var(--border-radius);
	background-color: #ffffff;
	transition: all 0.3s ease-in-out;
`
const InputGroup = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    gap: 1em;
    width: 100%;
    & > ${InputWrapper} {
        flex: 1;
    }
`
const HeaderText = styled.h2`
    font-size: 2.5em;
    font-weight: 600;
    color: white;
    margin-bottom: 0;
    @media ${device.max.mobile} {
        font-size: 2em;
    }
`

export default RanchForm