import styled from 'styled-components/macro'
import { Container } from 'components/base'
import { RanchCard } from 'components/cards/RanchCard'
import { useGetUserQuery } from 'library/api/user'
import { Link } from 'react-router-dom'
import { MDIcon } from 'components/icons'
import { colors } from 'styles'
import { transparentize } from 'polished'

const Ranches = () => {
	const { data: currentUser } = useGetUserQuery()

	return (
		<Container>
			<Header>
				<h2>Ranches</h2>
				<CreateRanchButton to={'add'}>Create Ranch
					<MDIcon name={'chevron_right'} style={{}} />
				</CreateRanchButton>
			</Header>
			<Column>
				{currentUser?.ranchRoles?.map((role: RanchRole) => {
					return <RanchCard key={role.ranchId} ranchId={role.ranchId} />
				})}
			</Column>
		</Container>
	)
}

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
`
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: .5em;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5em;
`
const CreateRanchButton = styled(Link)`
	text-decoration: none;
	color: ${colors.dark};
	padding: .5em .5em .5em 1em;
	border-radius: 999999px;
	font-weight: 500;
	border: 1px solid ${transparentize(.9, colors.dark)};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .25em;
	&:hover {
		background: ${transparentize(.95, colors.dark)};
		color: ${colors.dark};
	}
`

export default Ranches