import * as uuid from 'uuid'

enum DeviceType {
	Unknown = 0,
	Boss = 1,
	Secondary = 2
}
export class Device {
	id: string
	type: DeviceType
	accountId: string
	ranchId: string
	deviceIdStr: string
	lastReportedFirmwareVersion: number
	requestedFirmwareVersion: number | null
	inService: Date
	outOfService: Date | null
	modelNumber: | null
	lastCheckInTimestamp: Date | null
	enabled: boolean
	logReporting: boolean
	lastReportedVoltage: number | null
	locateUntil: Date | null
	locateNoiseActive: boolean
	animalId: string
	constructor(props?: Partial<Device>) {
		this.id = props?.id || uuid.v4().toString()
		this.type = props?.type || DeviceType.Secondary
		this.accountId = props?.accountId || ''
		this.ranchId = props?.ranchId || ''
		this.deviceIdStr = props?.deviceIdStr || ''
		this.lastReportedFirmwareVersion = props?.lastReportedFirmwareVersion || 0
		this.requestedFirmwareVersion = props?.requestedFirmwareVersion || null
		this.inService = props?.inService || new Date()
		this.outOfService = props?.outOfService || null
		this.modelNumber = props?.modelNumber || null
		this.lastCheckInTimestamp = props?.lastCheckInTimestamp || null
		this.enabled = props?.enabled || false
		this.logReporting = props?.logReporting || false
		this.lastReportedVoltage = props?.lastReportedVoltage || null
		this.locateUntil = props?.locateUntil || null
		this.locateNoiseActive = props?.locateNoiseActive || false
		this.animalId = props?.animalId || ''
	}
}

export default Device
