import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { useState, useCallback, useEffect, useRef, useMemo, LegacyRef } from 'react'
import { FlyToInterpolator } from '@deck.gl/core/typed'
import styled from 'styled-components/macro'
import { useAnimalTrailLayer, useGrazingDistributionLayer, useBoundary, useAnimalsLayer } from 'components/map/layers'
import Attribution from 'components/map/Attribution'
import { RootState } from 'store'
import { useStructureLayer, useHerds } from 'components/map/layers'
import { useControl, Map, NavigationControl, ScaleControl, GeolocateControl } from 'react-map-gl'
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed'
import { useCardBuffer } from 'hooks'
import { usePastureRoute } from 'pages/panels/pastureRoutes/usePastureRoute'
import { TrailTimelineControl } from 'components/inputs/TrailTimelineControl'
import { colors, device } from 'styles'
import { useGetUserQuery } from 'library/api/user'
import { BoundaryModifyControl } from 'pages/panels/boundaries/useBoundary'
import Tutorial from 'components/Tutorial'
import { useSelector } from 'react-redux'
import PastureRoutesOverlay from 'components/PastureRoutesOverlay'
import { lighten } from 'polished'
import { MDIcon } from 'components/icons'

// import {_MapContext as MapContext, NavigationControl} from 'react-map-gl';
function DeckGLOverlay(
	props: MapboxOverlayProps & {
		interleaved?: boolean
	}
) {
	const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props))
	overlay.setProps(props)
	return null
}
const MapPage = () => {
	const { bufferWidth } = useCardBuffer()
	const { data: user } = useGetUserQuery() 
	const infoHubRef = useRef<any>()
	const INITIAL_VIEW_STATE = {
		latitude: 39.8283,
		longitude: -98.5795,
		zoom: 3,
		bearing: 0,
		pitch: 0,
		transitionDuration: 'auto',
		transitionInterpolator: new FlyToInterpolator(),
	}
	// const { data: user } = useGetUserQuery()
	const grazingDistribution = useGrazingDistributionLayer()
	const animalTrail = useAnimalTrailLayer()
	const boundary = useBoundary()
	const animalsMap = useAnimalsLayer()
	const structure = useStructureLayer()
	const herds = useHerds()
	const pastureRoute = usePastureRoute()

	const mapState = useSelector((state: RootState) => state.mapSlice)

	const handleMapCursor = useCallback(
		(state: { isHovering: boolean; isDragging: boolean }): string => {
			return mapState.cursor
			// if (mapState.mode === 'DrawPointMode' || mapState.mode === 'DrawPolygonMode' || mapState.mode === 'DrawLineMode') return 'crosshair'
			// if (mapState.mode === 'ModifyMode' && state.isHovering) return 'grab'
			// if (hoverState?.sourceLayer?.id === 'structure-icon') {
			// 	if (mapState.mode !== 'ModifyMode') return 'pointer'
			// 	if (state.isDragging && id === hoverState?.object?.id) return 'grabbing'
			// 	if (state.isHovering && id === hoverState?.object?.id) return 'grab'
			// }
			// if (hoverState?.sourceLayer?.id === 'structure-safe-zone-resize-ring') {
			// 	if (mapState.mode !== 'ModifyMode') return 'pointer'
			// 	if (state.isHovering) return 'nesw-resize'
			// 	if (state.isDragging) return 'grabbing'
			// 	return 'pointer'
			// }
			// if (state.isDragging) return 'all-scroll'
			// if (state.isHovering) return 'pointer'
			// return mapState.cursor
		},
		[mapState.cursor]
	)
	const tutorial = useSelector((state: RootState) => state.mapSlice.tutorial)
	const overlayHeight = useSelector((state: RootState) => state.persistentDataSlice.overlayHeight)

	const [infoHubBuffer, setInfoHubBuffer] = useState<number>(0)

	useEffect(() => {		
		function handleInfoHubBuffer() {
			setTimeout(() => {
				const clientRect = infoHubRef?.current?.getBoundingClientRect()
				clientRect?.height ? setInfoHubBuffer(clientRect?.height + 20) : setInfoHubBuffer(10)
			}, 100)
		}
		window.addEventListener('resize', handleInfoHubBuffer)
		handleInfoHubBuffer()
		return () => {window.removeEventListener('resize', handleInfoHubBuffer)}
	}, [tutorial, infoHubRef, boundary?.error])
	
	const heightBuffer = useMemo(() => {
		return infoHubBuffer + overlayHeight
	}, [infoHubBuffer, overlayHeight])
	return (
		<MapContainer>
			<Map
				id={'MapView'}
				initialViewState={INITIAL_VIEW_STATE}
				doubleClickZoom={false}
				attributionControl={false}
				pitch={0}
				antialias={true}
				mapStyle={'mapbox://styles/jackkeating/ckyklu3l405r214o8duoe7fe8'}
				// cursor={handleMapCursor({isDragging: false, isHovering: false})}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN ?? ''}>

				<DeckGLOverlay
					interleaved={true}
					id={'ranch-map-overlay'}
					onHover={(info, event) => {
						// setHoverState(info)
						structure.handleMapHover(info)
					}}
					onClick={(info, event) => {
						structure.handleMapClick(info)
						pastureRoute.handleMapClick(info)
					}}
					onError={err => console.log(err)}
					getCursor={state => handleMapCursor(state)}
					// onLoad={() => {
					// 	if (ranchMap?.data?.features?.length) {
					// 		// fit map to the bounds of the ranch map
					// 		let box = turf.bbox(ranchMap.data)
					// 		// if the id and type are provided, fit the map to that feature
					// 		if(id && type && ranchMap.data.features) {
					// 			//TODO: if the type is herd, do a pasture lookup for that herd and zoom in on that pasture
					// 			if(type !== 'herd') box = turf.bbox(ranchMap.data.features.find(l => l?.id === id && kebabize(l.properties?.type) === type))
					// 		}
					// 		if(box) MapView?.fitBounds([box[0], box[1], box[2], box[3]], { padding: { left: bufferWidth + 100, top: 100, bottom: 100, right: 100 } })
					// 	}
					// }}
					layers={[
						boundary?.layer,
						grazingDistribution?.layer, 
						structure?.layer, 
						animalTrail?.layer, 
						pastureRoute?.layer,
						animalsMap?.layer, 
						herds?.layer,
					]}
				/>
				<GeolocateControl 
					showUserLocation 
					showUserHeading 
					showAccuracyCircle 
					trackUserLocation
					style={{transition: '.3s ease all', marginTop: `${heightBuffer}px`}} 
					positionOptions = {{
						enableHighAccuracy: true,
						timeout: 10000,
					}}
				/>
				<ScaleControl style={{transition: '.3s ease all', marginLeft: `${bufferWidth + 5}px`, marginTop: `${heightBuffer}px`}} unit={user?.systemOfMeasure} position='top-left' />
				<NavigationControl style={{transition: '.3s ease all'}}  position='top-right' showCompass showZoom />
			</Map>
			
			<InfoHub key={bufferWidth}  ref={infoHubRef} $bufferWidth={bufferWidth}>
				<PastureRoutesOverlay />
				<Tutorial  />
				{boundary.error ? <ErrorMessage><MDIcon name={'warning'} />{boundary?.error}</ErrorMessage> : null}
			</InfoHub>
			<ToolOverlay style={{marginLeft: bufferWidth + 'px'}}>
				<Attribution />
				{mapState.mode === 'ModifyMode' ?
					<BoundaryModifyControl /> 
					: null}
				{ animalTrail?.visible ?
					<TimelineControlWrapper>
						<TrailTimelineControl />
					</TimelineControlWrapper> 
					: null }
			</ToolOverlay>
		</MapContainer>
	)
}
const InfoHub = styled.div<{$bufferWidth: number}>`
	position: absolute;
	top: 0;
	left: 0;
	margin-left: calc(.5em + ${props => props.$bufferWidth + 'px'});
	right: .5em;
	display: flex;
	flex-direction: column;
	gap: .5em;
`
// TODO: Should use some sort of animation library so the animation time isn't hard coded
const ErrorMessage = styled.div`
	padding: .5em 1em;
	background: ${lighten(.35, colors.error)};
	border-radius: var(--border-radius);
	color: ${colors.error};
	font-weight: 600;
	text-align: center;
	justify-content: center;
	display: flex;
	gap: .5em;
	transform: translateY(-100%);
	opacity: 0;
	@keyframes slideInFromTop {
		0% {
			transform: translateY(-100%);
			opacity: 0;
		}
		15% {
			transform: translateY(0);
			opacity: 1;
		}
		85% {
			transform: translateY(0);
			opacity: 1;
		}
		100% {
			transform: translateY(-100%);
			opacity: 0;
		}
	
	}
	animation: slideInFromTop 2s ease-in-out;
`
const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: var(--border-radius);
`
const ToolOverlay = styled.div`
	z-index: 100;
`
const TimelineControlWrapper = styled.div`
	position: absolute;
	bottom: calc(1em + 10px + var(--sab));
	@media (${device.max.mobile}) {
		bottom: calc(1em + 60px + var(--sab));
	}
	padding: .5em 1em;
	margin: .5em;
	border-radius: var(--border-radius);
	width: calc(100% - 1em);
	display: block;
	box-sizing: border-box;
	max-width: 400px;
	background: rgba(255,255,255,.6);
	backdrop-filter: blur(10px);
`

export default MapPage
