import './App.scss'
import React, { useLayoutEffect, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import HomePage from './pages/Home'
import Sidebar from './components/sidebar/sidebar'
import Toolbar from './components/toolbar/toolbar'
import Analytics from './pages/Analytics'
import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { Notification, Notifications } from 'components/Notifications'
import { Offline } from 'react-detect-offline'
import { ReactComponent as NotificationIcon } from 'images/notification.svg'
import Overview from 'pages/panels/pageRouters/Overview'
import Details from 'pages/panels/pageRouters/Details'
import Edit from 'pages/panels/pageRouters/Edit'
import { GrazingMap } from 'pages/GrazingMap'
import { BreedList } from 'pages/panels/lists/Breeds'
import { GenderList } from 'pages/panels/lists/Gender'
import { HerdList } from 'pages/panels/lists/Herds'
import { ParentList } from 'pages/panels/lists/Parent'
import { LocationHistory } from 'pages/LocationHistory'
import { AddFeature } from 'pages/panels/AddFeature'
// import { CSSTransition, TransitionGroup } from 'react-transition-group'
// import { BottomSheetOutlet } from 'components/BottomSheetOutlet'
import { RootState, store } from 'store'
import { routeSlice } from 'store/route.slice'
import { Account } from 'pages/account/Account'
import Welcome from 'pages/Welcome'
import { Ranch } from 'pages/account/Ranch'
import Users from 'pages/account/Users'
import { User } from 'pages/account/User'
import Ranches from 'pages/account/Ranches'
import { Billing } from 'pages/account/Billing'
import { useServiceWorker } from 'hooks/useServiceWorker'
import { transparentize } from 'polished'
import { MDIcon } from 'components/icons'
import { useIsAuthenticated, useAccount, useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux'
import { SignUp } from 'pages/SignUp'
import AddTeamMember from 'pages/account/AddTeamMember'
import Loading from 'components/Loading'
import Invite from 'pages/Invite'
import { PastureRouteForm } from 'pages/panels/pastureRoutes/PastureRoute.form'
import PastureRoutesOverview from 'pages/panels/pastureRoutes/PastureRoute.overview'
import { ProtectedRoutes } from 'pages/panels/pageRouters/Protected'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { ApiClientProvider } from 'library/api/client'
import { ReactQueryClientProvider } from 'library/api'
import { getAccessToken } from 'auth/msal'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TermsAndConditions, TermsAndConditionsPage } from 'pages/TermsAndConditions'
import { TeamUser } from 'pages/account/TeamUser'
import { Support } from 'pages/Support'
import { PrivacyPolicy, PrivacyPolicyPage } from 'pages/PrivacyPolicy'
import { AccountProfile } from 'pages/account/AccountProfile'
import RanchForm from 'pages/account/RanchForm'

const App: React.FC = () => {
	const location = useLocation()

	const { waitingWorker, showReload, reloadPage } = useServiceWorker();
	const [showUpdateToast, setShowUpdateToast] = useState<boolean>(false);
	const displayLocation = useSelector((state: RootState) => state.routeSlice.displayLocation)
	const isAuthenticated = useIsAuthenticated()
	// decides when to show the update toast
	useEffect(() => {
		if (showReload && waitingWorker) {
			setShowUpdateToast(true);
		} else setShowUpdateToast(false);
	}, [waitingWorker, showReload, reloadPage]);

	useEffect(() => {
		if (location !== displayLocation) {
			store.dispatch(routeSlice.actions.setLocation(location))
			setTimeout(() => {
				store.dispatch(routeSlice.actions.setDisplayLocation(location))
			}, 500)
		}
	}, [location, displayLocation])

	return (
		<>
			<ReactQueryDevtools initialIsOpen={false} />
			{ showUpdateToast ? 
				<NotificationContainer>
					There is a new version of the app available. Please update to the latest version.
					<UpdateButton onClick={() => reloadPage()}>Update</UpdateButton>
				</NotificationContainer> 
				: null}
			<div id={'notification-portal'}></div>
			<Routes location={displayLocation}>
				{!isAuthenticated ? <Route path='/' element={<Welcome />} /> : null}
				<Route path={'/sign-up'} element={<Navigate to={'/sign-up/account'} replace={true} />} />
				<Route path={'/sign-up/:section'} element={<SignUp />} />
				<Route path={'/invite'} element={<Invite />} />
				<Route path={'/terms-and-conditions'} element={<TermsAndConditionsPage />} />
				<Route path={'/privacy-policy'} element={<PrivacyPolicyPage />} />
				
				<Route element={<ProtectedRoutes />}>
					<Route path='/' element={<HomePage />} >
						{/* </Route> */}
						<Route path={'/routes'} element={<PastureRoutesOverview />} />
						<Route path={'/route/:id'} element={<PastureRouteForm />} />
						<Route path={'/routes/:id'} element={<PastureRouteForm />} />
						<Route path={'/routes/:id1/:id2'} element={<PastureRoutesOverview />} />
						<Route path={'/routes/:id1/:id2/add'} element={<PastureRouteForm />} />

						<Route path={'feature/add'} element={<AddFeature />} />
						<Route path={':type'} element={<Overview />} />
						<Route path={':type/add'} element={<Edit />} />
						<Route path={':type/:id'} element={<Details />} />
						<Route path={':type/:id/:mode'} element={<Edit />} />
						{/* <Route path={':id'} element={<Details />}> */}
						<Route path={':type/:id/grazing-map'} element={<GrazingMap />} />
						<Route path={':type/:id/location-history'} element={<LocationHistory />} />
						<Route path={'list/breeds'} element={<BreedList />} />
						<Route path={'list/gender'} element={<GenderList />} />
						<Route path={'list/herds'} element={<HerdList />} />
						<Route path={'list/parent'} element={<ParentList />} />
					</Route>
					<Route path={'/account'} element={<Account />} >
						<Route path={''} element={<AccountProfile />} />
						<Route path={'user'} element={<User />} />
						<Route path={'team'} element={<Users />} />
						<Route path={'team/:id'} element={<TeamUser />} />
						<Route path={'team/add'} element={<AddTeamMember />} />
						<Route path={'ranches'} element={<Ranches />} />
						<Route path={'ranches/add'} element={<RanchForm />} />
						<Route path={'ranch/:id'} element={<Ranch />} />
						<Route path={'billing'} element={<Billing />} />
						<Route path={'terms-and-conditions'} element={<TermsAndConditions />} />
						<Route path={'privacy-policy'} element={<PrivacyPolicy />} />
						<Route path={'support'} element={<Support />} />
					</Route>
					
					{/* <Route path='/analytics' element={<Analytics />} /> */}
				</Route>
			
				{/* <Route path='/notifications' element={<Notifications />} /> */}
			</Routes>
		</>
	)
}

const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.primary};
`
const Main = styled.main`
	grid-area: main;
	overscroll-behavior: none;
	height: calc(100vh - (55px + var(--sat)));
	overflow: auto;
	/* margin: 1em; */
`
const Container = styled.div<{ $windowDimensions: { width: number; height: number } }>`
	display: grid;
	height: 100svh;
	grid-template-columns: 1fr;
	grid-template-rows: calc(55px + var(--sat)) auto calc(55px + var(--sab));
	grid-template-areas:
		'toolbar'
		'main'
		'sidebar';

	@media ${device.min.mobile} {
		margin-bottom: 0px;
		grid-template-columns: max-content auto;
		grid-template-rows: min-content auto min-content;
		grid-template-areas:
			'sidebar toolbar'
			'sidebar main';
	}
`
const NotificationContainer = styled.div`
	display: flex;
	gap: .5em;
	flex-direction: column;
	padding-right: 0.5em;
	position: fixed;
	top: 0;
	margin: .5em;
	right: 0;
	background: #ffffff;
	border-radius: var(--border-radius);
	padding: 1em;
	box-shadow: 0 0 50px rgba(0,0,0,.45);
	@media ${device.min.mobile} {
		max-width: 400px;
	}
`
const UpdateButton = styled.button`
	all: unset;
	cursor: pointer;
	color: ${colors.dark};
	font-weight: bold;
	display: flex;
	flex-direction: column;
	gap: 0;
	font-size: 0.8em;
	border-radius: var(--border-radius);
	padding: 0.5em;
	background: ${transparentize(.9, colors.dark)};
	align-items: center;
`




export default App

