import { Address } from './address.model'
import * as uuid from 'uuid'
export class Ranch {
	id: string
	name: string
	phone?: string | null
	createdDate: Date
	addressId: string | null
	addressSameAsAccount: boolean
	imageFile?: ImageFile | null
	address: Address
	constructor(props?: Partial<Ranch>) {
		this.id = props?.id || uuid.v4().toString()
		this.name = props?.name || ''
		this.phone = props?.phone || null
		this.createdDate = props?.createdDate || new Date()
		this.addressId = props?.addressId || null
		this.addressSameAsAccount = props?.addressSameAsAccount || false
		this.imageFile = props?.imageFile || null
		this.address = props?.address || new Address()
	}
}

export class CreateRanch {
	name?: string
	address: Address
	addressSameAsAccount?: boolean
	constructor(params?: CreateRanch) {
		this.name = params?.name || ''
		this.address = params?.address || new Address()
		this.addressSameAsAccount = params?.addressSameAsAccount || false
	}
}