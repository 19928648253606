import { useCreateInvitationMutation } from 'library/api/invitation'
import { Container } from 'components/base'
import { Select, Input } from 'components/inputs'
import { useState } from 'react'
import { SubmitButton } from 'components/buttons'
import { Icon } from 'components/icons'
import LoadingSpinner from 'components/LoadingSpinner'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import * as uuid from 'uuid'

const Users = () => {
	const navigate = useNavigate()

	const { mutateAsync: inviteUser, isPending: inviteLoading } = useCreateInvitationMutation()
	const [sent, setSent] = useState<Date | undefined>()

	return (
		<Container>
			<Header>
				<h2>New Team Member</h2>
			</Header>
			<form onSubmit={async (e: React.SyntheticEvent) => {
				e.preventDefault()
				const target = e.target as typeof e.target & {
                    email: { value: string };
                    role: { value: RanchRoleType };
                };

				const inviteSentResponse = await inviteUser({
					id: uuid.v4(),
					email: target.email.value,
					role: target.role.value,
					hostname: window.location.hostname
				}) as UserInvite

				setSent(new Date(inviteSentResponse?.expires))
				navigate('/account/team')
			}}>
				<Row>
					<Input
						label={'Email'}
						name={'email'}
						maxLength={128}
						required
					/>
				</Row>
				<Row>
					<Select 
						label={'Role'}
						name={'role'}
						onChange={e => {}}
					>
						<option value={'Hand'}>Ranch Hand</option>
						<option value={'Manager'}>Manager</option>
					</Select>
				</Row>
				<Row style={{justifyContent: 'right'}}>
					<SubmitButton type={'submit'} disabled={!!sent}>
						{sent ? <><Icon name={'check'} />Sent</> : <>{inviteLoading ? <LoadingSpinner/> : 'Send Invite'}</>}
					</SubmitButton>
				</Row>
			</form>
		</Container>
	)
}

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // justify-content: right;
    gap: .5em;
`

export default Users