import { convertImageToBase64, getHeightAndWidthFromDataUrl } from 'utilities'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import { Image as ImageFile } from 'components/Image'
import { v4 as uuidv4 } from 'uuid'
import { useColors } from 'hooks'
import LoadingSpinner from 'components/LoadingSpinner'

export const AvatarInput = ({ user, imageSize = 0, image, onChange, editLabelAlwaysVisible = false, placeholder = '', backgroundColor = '#dddddd', primaryColor = '#aaaaaa' }: { user?: User, imageSize?: number, image: ImageFile | null, editLabelAlwaysVisible?: boolean, placeholder?: string, backgroundColor?: string, primaryColor?: string, onChange: (image: ImageFile) => void }) => {
	const [localSrc, setLocalSrc] = useState<string | null>(null)

	return (
		<AvatarImage $hasSrc={Boolean(image || localSrc)}>
			
			{(!(image || localSrc) && placeholder) && <>
				<ProfileCardImage $backgroundColor={backgroundColor}>
					<ProfileInitialContainer>
						<ProfileInitials $color={primaryColor} viewBox="0 0 32 18">
							<text x={'50%'} y={15}>{placeholder}</text>
						</ProfileInitials>
					</ProfileInitialContainer>
				</ProfileCardImage>
			</>}
			<input
				id={'profileImage'}
				type={'file'}
				onChange={async (e) => {
					const file = e.target.files?.length ? e.target.files[0] : null
					if(!file) return
					const localImage = URL.createObjectURL(file)
					const {width, height} = await getHeightAndWidthFromDataUrl(localImage)
					setLocalSrc(localImage)
					const img = await convertImageToBase64(file)
					onChange({
						id: uuidv4(),
						added: new Date(),
						isImage: true,
						fileName: file?.name,
						fileSizeKb: file.size,
						contentType: file?.type,
						data: img,
						width,
						height
					})
				}}
			/>
			{
				(!image || localSrc) && !placeholder ? <Avatar alt={'profile'} $hasSrc={Boolean(image || localSrc)} src={localSrc || '/default_avatar.svg'} /> :
					<ImageFile alt={'profile'} src={`file/${image?.id}/${imageSize}`} />
			}
			<Label $color={primaryColor} $hasSrc={Boolean(image || localSrc || placeholder)} $alwaysVisible={editLabelAlwaysVisible} htmlFor={'profileImage'}>Edit</Label>
			
		</AvatarImage>
	)
}

const AvatarImage = styled.div<{$hasSrc: boolean}>`
	padding: 0;
	max-width: 120px;
	width: 100%;
	max-height: 120px;
	height: 100%;
	border-radius: 100%;
	overflow: hidden;
	// background: transparent;
	border: none;
	position: relative;
	aspect-ratio: 1 / 1;
	background: ${transparentize(.6, '#ffffff')};
	// ${props => props.$hasSrc ? '' : 'mix-blend-mode: screen;'}
	label {

	}
	&:hover > label {
		opacity: 1;
		transform: translateY(-10%);
	}
	input {
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;

	}
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;

	}
`

const Avatar = styled.img<{$hasSrc: boolean}>`
	object-fit: cover;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	mix-blend-mode: ${props => props.$hasSrc ? 'none' : 'soft-light'};

`
const Label = styled.label<{ $alwaysVisible: boolean, $hasSrc: boolean, $color: string }>`
		opacity: 0;
		position: absolute;
		bottom: -.25em;
		right: 0;
		left: 0;
		text-align: center;
		background: ${props => props.$hasSrc ? transparentize(.3, '#ffffff') : transparentize(0, '#ffffff')};
		mix-blend-mode: ${props => props.$color ? 'none' : 'screen'};
		padding: .25em;
		padding-bottom: .5em;
		cursor: pointer;
		font-weight: 600;
		font-size: .8em;
		transition: .2s ease-out all;
		transform: translateY(0%);
		color: ${props => props.$color || 'black'};
		opacity: ${props => props.$alwaysVisible ? 1 : 0};
		transform: ${props => props.$alwaysVisible ? 'translateY(-10%)' : 'translateY(0%)'};
		&:hover {
			opacity: 1;
			transform: translateY(-10%);
		}
`

const ProfileInitials = styled.svg<{ $color?: string; }>`
	fill: ${props => props.$color};
	margin: auto;
	position: absolute;
	inset: 0;
	text {
		text-align: center;
		text-anchor: middle;
		font-weight: 600;
	}

`
const ProfileInitialContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	align-items: center;
	psoition: relative;
	aspect-ratio: 1 / 1;
`
const ProfileCardImage = styled.div<{ $backgroundColor?: string; }>`
    padding: 0;
		aspect-ratio: 1 / 1;
		height: 100%;
		width: 100%;
		height: 100%;
    border-radius: 100%;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    margin: auto;
    text-align: center;
		
    background-color: ${props => props.$backgroundColor ? transparentize(.9, props.$backgroundColor) : '#333333'};
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`
