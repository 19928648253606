import styled from 'styled-components/macro'
import { Tooltip } from 'components/cards'

interface LabelWithTooltipProps {
	className?: any
	text?: string
	tooltipText?: string
}
const label = ({ className, text, tooltipText }: LabelWithTooltipProps) => (
	<span className={className}>
		{text}
		{tooltipText && <Tooltip text={tooltipText} />}
	</span>
)

export const LabelWithTooltip = styled(label)`
	font-size: 0.6em;
	color: #666;
`
