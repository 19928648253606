import { useGetHerdsQuery } from 'library/api/herd'
import { lighten, transparentize } from 'polished'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { colors } from 'styles'

export const HerdList = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { data: herds } = useGetHerdsQuery()
	const [selected, setSelected] = useState<Herd | null>(null)
	return (
		<Container>
			<List>
				{herds?.map((herd: Herd) => (
					<ListItem key={herd.id}>
						<Item htmlFor={`herd_${herd.id}`}>
							{herd.name}
							<input
								id={`herd_${herd.id}`}
								type={'radio'}
								value={herd.id}
								checked={herd.id === selected?.id}
								onChange={e => {
									setSelected(herd)
									navigate(location.state.from.pathname, { replace: true, state: { herd } })
								}}
							/>
						</Item>
					</ListItem>
				))}
			</List>
		</Container>
	)
}

const Container = styled.div`
	padding: 0.5em;
`
const List = styled.ul`
	padding: 0;
`
const ListItem = styled.li`
	&:not(:last-child) {
		border-bottom: 1px solid ${lighten(0.15, colors.light)};
	}
`

const Item = styled.label`
	display: flex;
	padding: 0.5em;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		background: ${transparentize(0.9, colors.light)};
	}
`
