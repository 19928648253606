import { ChangeEvent, FormEvent, useState } from 'react'
import styled from 'styled-components/macro'
import CorralLogo from 'images/Primary Logo/Full Color/Corral_Primary Logo_full color.svg'
import { colors } from 'styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useGetUserQuery } from 'library/api/user'
import { transparentize } from 'polished'
const SUPPORT_FORM_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/20300561/3ef7e86d-838c-4a6c-830f-b3156f0caaf3'
import { states } from 'library/models/address.model'
import { Icon } from 'components/icons'
import { CardAction, CardActions } from 'components/cards'
import { Select } from 'components/inputs'
export function Support() {
	const { data: user } = useGetUserQuery()
	const [sent, setSent] = useState(false)
	const [content, setContent] = useState('')
	const [error, setError] = useState<{message: string, correlationId: string, status: 'error'} | null>(null)
	const [supportType, setSupportType] = useState('')
	const [loading, setLoading] = useState(false)
	return (
		<>
			<Wrapper>
				{sent ? <>
					<Heading>Thank you!</Heading>
					<p>A support request has been made. A Corral Tech team member with reach out to you shortly.</p>
				</> 
					: 
					<>
						<Heading>Need help?</Heading>
						<p>Send us a message and a member of our team will reach out to assist you.</p>
						
						<Form onSubmit={(e: FormEvent<HTMLFormElement>) => {
							setError(null)
							e.preventDefault()

							const formData = {
								fields: [
									{
										objectTypeId: '0-1',
										name: 'firstname',
										value: user?.firstName
									},
									{
										objectTypeId: '0-1',
										name: 'lastname',
										value: user?.lastName
									},
									{
										objectTypeId: '0-1',
										name: 'state',
										value: states.find((s) => user?.account?.address?.state === s.abbreviation)?.name
									},
									{
										objectTypeId: '0-1',
										name: 'phone',
										value: user?.phone
									},
									{
										objectTypeId: '0-1',
										name: 'email',
										value: user?.email
									},
									{
										objectTypeId: '0-1',
										name: 'jobtitle',
										value: user?.currentRole
									},
									{
										objectTypeId: '0-1',
										name: 'ranch_id',
										value: user?.currentRanch?.id
									},
									{
										objectTypeId: '0-1',
										name: 'ranch_name',
										value: user?.currentRanch?.name
									},
									{
										objectTypeId: '0-1',
										name: 'account_id',
										value: user?.account?.id
									},
									{
										objectTypeId: '0-1',
										name: 'account_name',
										value: user?.account?.name
									},
									{
										objectTypeId: '0-5',
										name: 'subject',
										value: `Web Support Request: ${supportType}`
									},
									{
										objectTypeId: '0-5',
										name: 'content',
										value: content
									}
								]
							}
							setLoading(true)
							fetch(SUPPORT_FORM_URL, {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json'
								},
								body: JSON.stringify(formData)
							}).then(res => {
								return res.json()
							}).then((response) => {
								if(response.status === 'error') {
									setError(response)
									throw Error(response.message)
								}
								setSent(true)
							}).catch((error) => {
								console.error({error})
							}).finally(() => {
								setLoading(false)
							})
						}}>
							<InputContainer>
								<Select onChange={(e) => setSupportType(e.target.value)} label={'Type'} required>
									<option value="" selected disabled hidden></option>
									<option value={'Question'}>General Question</option>
									<option value={'Bug'}>Report a Bug</option>
								</Select>
								<Label htmlFor={'support_content'}>Message <RequiredStar>*</RequiredStar></Label>
								<TextArea required rows={10} id={'support_content'} placeholder={'Please describe your issue'} onChange={(e) => {
									setError(null)
									setContent(e.target.value)}} />
							</InputContainer>
							{error ? <ErrorMessage><Icon size={'md'} name={'error'} /> <div><ErrorTitle>Error</ErrorTitle> 
							
								<ErrorContent>
									{error?.message}
								</ErrorContent>
							</div></ErrorMessage> : null}
							<CardActions><FormSubmitButton type={'submit'}><Icon name={'paper-plane'} /> Send</FormSubmitButton></CardActions>
						</Form>
					</>}
			</Wrapper>
		</>
	)
}

export function SupportPage() {
	const navigate = useNavigate()
	const location = useLocation()
	const canGoBack = location.key !== 'default';
	return (
		<Container>
			<HomeLink onClick={() => {canGoBack ? navigate(-1) : navigate('/')}}>&times;</HomeLink>
			<LogoWrapper>
				<Logo src={CorralLogo} alt={'Corral Technologies Logo'} />
			</LogoWrapper>
			<Support />
		</Container>
	)
}

const RequiredStar = styled.span`
	color: red
`

const ErrorMessage = styled.div`
	color: ${colors.error};
	background-color: ${transparentize(.9, colors.error)};
	font-weight: 600;
	width: 100%;
	padding: .5em;
	border-radius: var(--border-radius);
	display: flex;
	align-items: center;
	gap: .5em;
`
const ErrorTitle = styled.div`
	font-size: 1em;
	font-weight: 700;
`
const ErrorContent = styled.div`
	font-size: .8em;
`
const Container = styled.div`
    position: relative;
    padding: 1em;
    box-sizing: border-box;
`

const Wrapper = styled.div`
    padding: 1em;
    max-width: 800px;
    position: relative;
    box-sizing: border-box;
    color: ${colors.navy};
    li {
        margin-bottom: 1em;
        color: ${colors.navy};
    }
`
const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
`
const InputContainer = styled.div`
	width: 100%;
`
const Label = styled.label`
	font-size: .8em;
	color: ${transparentize(0.3, colors.dark)};
	font-weight: 600;
	transition: .3s ease all;
	transform: translateY(0%);
`
const FormSubmitButton = styled(CardAction)`
	color: ${colors.primary};
	background-color: ${transparentize(.9, colors.primary)};
	font-weight: 600;
	font-size: 1em;
	padding: .5em 1em;
	transition: .3s ease all;
	display: flex;
	flex-direction: row;
	gap: .25em;
	margin-top: 0;
	&:hover {
		background-color: ${transparentize(.8, colors.primary)};
	}
`
const TextArea = styled.textarea`
	padding: 0.5em;
	border: 1px solid var(--light);
    width: 100%;
    box-sizing: border-box;
	border-radius: var(--border-radius);
	position: relative;
	background-color: #ffffff;
	// transition: all 0.3s ease-in-out;
	&:disabled {
		background-color: transparent;
		transform: translateY(-.25em);
		border: 1px solid transparent;
		border-radius: 0;
	}
`
const Heading = styled.h1`
    font-size: 1.4em;
    color: ${colors.navy};
`
const HomeLink = styled.button`
    position: absolute;
    top: 0em;
    right: .25em;
    border: none;
    background: transparent;
    font-size: 2em;
    justify-content: flex-end;
    color: ${colors.primary};
`
const Logo = styled.img`
    max-width: 300px;
    margin: auto;
    `
const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
`