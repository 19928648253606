import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Icon } from 'components/icons'
import { colors } from 'styles'
import { darken } from 'polished'

interface IAutoComplete {
	id: string
	listId: string
	icon?: string
	hideLabel?: boolean
	label: string
	value?: {id: string | number; value: string | number}
	list: {id: string | number; value: string | number}[] | []
	onSelect: (value: {id: string | number; value: string | number}) => void
	'aria-live'?: 'off' | 'polite' | 'assertive'
}

const AutoComplete = (props: IAutoComplete) => {
	const {id, icon, label, hideLabel = false, list = [], listId, onSelect, value} = props
	// const [selection, setSelection] = useState(props.value)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [hasExpanded, setHasExpanded] = useState(false)
	const hasIcon = icon ? true : false
	const [items, setItems] = useState(value ? [value, ...list.filter(l => l.id !== value.id)] : list)
	const [inputValue, setInputValue] = useState(value?.value || '')
	const filterItems = (input: string) => { 
		const filteredItems = list.filter(item => String(item?.value).toLowerCase().includes(input.trim().toLowerCase()))
		setSelectedIndex(0)
		setItems(filteredItems)
	}
	const [maxHeight, setMaxHeight] = useState(200)

	const inputRef = useRef<HTMLInputElement>(null)
	const dropdownWrapperRef = useRef<HTMLDivElement>(null)

	const handleSelectedIndex = (event: React.KeyboardEvent<HTMLInputElement>) => { 
		if (event.key === 'ArrowUp') {
			if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1)
			event.preventDefault()
		} else if (event.key === 'ArrowDown') {
			if (selectedIndex < items.length - 1) setSelectedIndex(selectedIndex + 1)
			event.preventDefault()
		} else if (event.key === 'Enter') {
			handleSelect(items[selectedIndex])
			event.preventDefault()
		}
	}
	const handleSelect = ({id, value}: { id: string | number; value: string | number }) => { 
		// setSelection({ id, value })
		
		// Put selected value at top of the list
		const listItems = [{id, value}, ...list.filter(l => l.id !== id)]
		
		setItems(listItems)
		setInputValue(String(value))
		onSelect({id, value})
		inputRef?.current?.blur()

	}
	useEffect(() => {
		const dropdown = dropdownWrapperRef.current
		if (dropdown) {
			setMaxHeight(window.innerHeight - dropdown.getBoundingClientRect().bottom - 20)
		}
	}, [dropdownWrapperRef])
	return (
		<InputWrapper $hideLabel={hideLabel} $hasIcon={hasIcon}>
			<Label htmlFor={id} $hideLabel={hideLabel}>
				{label}
			</Label>
			{icon ? (
				<IconWrapper>
					<Icon color={'var(--dark)'} name={icon}></Icon>
				</IconWrapper>
			) : null}
			<InputField
				id={id}
				ref={inputRef}
				list={listId}
				$hasIcon={hasIcon}
				value={inputValue}
				onInput={(e: React.KeyboardEvent<HTMLInputElement>) => {
					const target = (e.target as HTMLInputElement)
					setInputValue(target.value)
					filterItems(target.value)
				}}
				onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
					handleSelectedIndex(event)
				}}
				onFocus={e => setHasExpanded(true)}
				onBlur={e => setHasExpanded(false)}
			/>
			<DropdownContainer ref={dropdownWrapperRef}>
				{hasExpanded && (
					<DropdownWrapper $maxHeight={maxHeight}>
						<DataList $maxHeight={maxHeight} id={listId} role='listbox' aria-live={hasExpanded ? props['aria-live'] || 'polite' : 'off'}>
							{items.length === 0 ? (
								<DataOption $selected={true} value={'create'} role={'option'}>
									<Icon name={'plus'} size={'sm'} style={{transform: 'scale(.75)'}} color={colors.dark} /> Create Herd
								</DataOption>
							) : (
								items.map(({id, value}, index) => (
									<DataOption onMouseDown={(e: React.MouseEvent<HTMLLIElement>) => handleSelect({id, value})} $selected={selectedIndex === index} key={id} value={id} role={'option'}>
										{value}
									</DataOption>
								))
							)}
						</DataList>
					</DropdownWrapper>
				)}
			</DropdownContainer>
		</InputWrapper>
	)
}
const Label = styled.label<{$hideLabel?: boolean}>`
	font-size: 0.7em;
	color: ${colors.dark};
	position: absolute;
	top: 0;
	left: 0;
	${props => (props.$hideLabel ? 'opacity: 0; pointer-events: none;' : 'transform: translateY(0%);')}
`
const InputWrapper = styled.div<{$hasIcon?: boolean, $hideLabel?: boolean}>`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: .5em;
`

const InputField = styled.input<{ $hasIcon?: boolean }>`
	all: unset;
	padding: 0.5em;
	${props => (props.$hasIcon ? 'padding-left: 2em;' : '')}
	border: 1px solid var(--light);
	border-radius: var(--border-radius);
	position: relative;
	background-color: #ffffff;
	margin-top: 1em;
`
const IconWrapper = styled.div`
	position: absolute;
	left: 0.5em;
	z-index: 1;
	top: calc(50% + 3px);
	transform: translateY(-50%);
	pointer-events: none;
`
const DropdownContainer = styled.div<{$maxHeight?: number}>`
	position: relative;
	width: 100%;
`
const DropdownWrapper = styled.div<{ $maxHeight?: number}>`
	position: absolute;
	width: 100%;
	height: ${props => props.$maxHeight}px;
	overflow: scroll;
	position: absolute;
	top: 0.25em;
	left: 0;
	right: 0;
`
const DataList = styled.ul<{$maxHeight?: number}>`
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
	background-color: #ffffff;
	border: 1px solid var(--light);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
`

const DataOption = styled.li<{$selected: boolean}>`
	all: unset;
	cursor: pointer;
	padding: 0.5em;
	color: ${colors.dark};
	display: flex;
	align-items: center;
	background-color: ${props => props.$selected ? darken(0, colors.background) : '#ffffff'};
	&:hover {
		background-color: var(--background);
	}
`

export default AutoComplete
