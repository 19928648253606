import { useEffect, useState } from 'react'
import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed'
import { GeoJsonLayer } from '@deck.gl/layers/typed'
import { useLazyGetDeviceLocationsQuery } from 'library/api/analytics'
import { RootState, store } from 'store'
import { grazingDistributionSlice } from 'store/grazingDistribution.slice'
import { useMap } from 'react-map-gl'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useGrazingDistributionLayer = () => {
	const  location = useLocation()
	const { MapView } = useMap()

	const [boundingBox, setBoundingBox] = useState<any>(null)
	
	const { mutateAsync: getDeviceLocations, isPending: isLoading, isError} = useLazyGetDeviceLocationsQuery()
	
	const visible = useSelector((state: RootState) => state.grazingDistributionSlice.visible)
	const setVisible = (visible: boolean) => store.dispatch(grazingDistributionSlice.actions.setVisible(visible))
	
	const data = useSelector((state: RootState) => state.grazingDistributionSlice.coordinates)
	
	const getData = async (deviceLocationRequest: DeviceLocationParams) => {
		if (deviceLocationRequest.startDate && deviceLocationRequest.endDate) {

			const formattedStartDate = deviceLocationRequest.startDate.toISOString().replace(/T.*/,'').split('-').join('-')
			const formattedEndDate = deviceLocationRequest.endDate.toISOString().replace(/T.*/,'').split('-').join('-')

			const payload: any = {
				...deviceLocationRequest,
				startDate: formattedStartDate,
				endDate: formattedEndDate
			}
			const response: any = await getDeviceLocations(payload)
			if(response) {
				const coordinates = response?.features.map((feature: any) => ({ COORDINATES: feature.geometry.coordinates }))
				if(coordinates) store.dispatch(grazingDistributionSlice.actions.setCoordinates(coordinates))
				MapView?.on('zoomend', () => {
					setVisible(true)
				})
			}
			return response
		}
	}

	// turn off grazing distribution layer when navigating away from grazing map
	useEffect(() => {
		if(location.pathname.search(/grazing-map/g) === -1 && visible) {
			setVisible(false)
		}
	}, [location.pathname, visible])
	const layer = new HeatmapLayer({
		id: 'grazing-distribution-layer',
		data,
		visible: visible,
		getPosition: d => d.COORDINATES,
		aggregation: 'SUM',
		colorRange: [
			[62, 151, 20, 150],
			[105, 173, 27, 150],
			[155, 193, 35, 150],
			[209, 213, 44, 150],
			[231, 196, 54, 150],
			[228, 165, 59, 150],
			[225, 137, 64, 200],
			[222, 112, 68, 200],
			[219, 70, 73, 200],
			[217, 30, 86, 200],
		],
	})

	const boundaryLayer = new GeoJsonLayer({
		id: 'grazing-boundary-layer',
		data: boundingBox,
		stroked: true,
		filled: true,
		pointRadiusUnits: 'pixels',
		visible: visible,
		getFillColor: [25, 128, 14, 150],
		getLineColor: [25, 128, 14, 255],
	})

	return {
		layer: [boundaryLayer, layer],
		visible,
		setVisible,
		setBoundingBox,
		boundingBox,
		getData,
		loading: isLoading,
		error: isError,
	}
}
