import { transparentize } from 'polished'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { colors } from 'styles'
import { structureSlice } from './structures/structure.slice'
import { store } from 'store'
import { boundarySlice } from './boundaries/boundary.slice'

export const AddFeature = () => {
	const navigate = useNavigate()

	return (
		<>
			<Container>
				Select a Feature to Add
				<FeatureButton
					onClick={() => store.dispatch(structureSlice.actions.setDefault('waterTank'))}
					to={'/structure/add'}>
					Structure
				</FeatureButton>
				{/* <FeatureButton
					onClick={() => store.dispatch(structureSlice.actions.setDefault('gate'))}
					to={'/gate/add'}>
					Gate
				</FeatureButton>
				<FeatureButton
					onClick={() => store.dispatch(structureSlice.actions.setDefault('waterTank'))}
					to={'/water-tank/add'}>
					Water Tank
				</FeatureButton>
				<FeatureButton
					onClick={() => store.dispatch(structureSlice.actions.setDefault('saltAndMineralDeposit'))}
					to={'/salt-and-mineral-deposit/add'}>
					Mineral Deposit
				</FeatureButton> */}
				{/* <FeatureButton
					onClick={() => {
						// store.dispatch(boundarySlice.actions.setDefault('exclusion'))
					}}
					to={'/route/add'}>
					Route
				</FeatureButton> */}
				<FeatureButton
					onClick={() => store.dispatch(boundarySlice.actions.setDefault('pasture'))}
					to={'/pasture/add'}>
					Pasture
				</FeatureButton>
				<FeatureButton
					onClick={() => store.dispatch(boundarySlice.actions.setDefault('exclusion'))}
					to={'/exclusion/add'}>
					Exclusion Zone
				</FeatureButton>
				<CancelButton
					onClick={() => navigate(-1)}>
					Cancel
				</CancelButton>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 0.5em;
	display: flex;
	flex-direction: column;
	gap: 0.5em;
`
const FeatureButton = styled(Link)`
	padding: 0.5em;
	font-size: 1em;
	text-decoration: none;
	border: none;
	text-align: center;
	color: ${colors.dark};
	font-weight: 600;
	border-radius: 8px;
	transition: 0.3s ease all;
	background: ${transparentize(0.95, colors.dark)};
	&:hover {
		color: ${colors.dark};
		background: ${transparentize(0.9, colors.dark)};
	}
`
const CancelButton = styled.button`
	padding: 0.5em;
	font-size: 1em;
	text-decoration: none;
	text-align: center;
	color: ${colors.primary};
	border: none;
	font-weight: 600;
	transition: 0.3s ease all;
	border-radius: 8px;
	background: ${transparentize(0.9, colors.primary)};
	&:hover {
		background: ${transparentize(0.85, colors.primary)};
	}
`