import styled from 'styled-components/macro'
import CorralLogo from 'images/Primary Logo/Full Color/Corral_Primary Logo_full color.svg'
import { colors, device } from 'styles'
import { CloseButton } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {  } from 'react-router-dom'
export function TermsAndConditions () {
	return (
		<>
			<Wrapper>
				{/* <HomeLink onClick={() => {canGoBack ? navigate(-1) : navigate('/')}}>x</HomeLink> */}
				{/* <LogoWrapper>
					<Logo src={CorralLogo} alt={'Corral Technologies Logo'} />
				</LogoWrapper> */}
				<Heading>Terms and Conditions</Heading>
				<p>BY PLACING AN ORDER FOR EQUIPMENT, SOFTWARE OR SERVICES FROM CORRAL TECHNOLOGIES, LLC, YOU ACCEPT AND ARE BOUND BY THESE TERMS AND CONDITIONS.</p>
				<ol type="1">
					<li><u>Price and Payment Terms.</u> You (“Buyer”) shall purchase the equipment, software, and services from Corral Technologies, LLC (“Seller”), at the price set forth in Seller’s invoice. Prices are exclusive of all taxes, freight and labor, unless expressly stated otherwise. Buyer will be responsible for, and pay all taxes due, including, but not limited to, sales, use, or value-added taxes. Buyer shall pay all invoiced amounts due to Seller on receipt of Seller’s invoice.</li>
					<li><u>Return of Equipment.</u> Upon the conclusion or termination of the subscription period, at Seller’s option and expense, all equipment shall be returned to Seller within 30 days. If Seller does not exercise such option, Buyer shall destroy such equipment. Buyer acknowledges and agrees that resale of equipment to any third-party is strictly prohibited. Failure to return or destroy equipment, as the case may be, will result in a minimum charge of $200 per unit.</li>            
					<li><u>Warranties.</u>
						<ol type="a">
							<li>Subject to the limitation of liability set forth below, Seller warrants to Buyer that for the entirety of the subscription period, that such equipment and software will substantially conform to the specifications set forth in Seller's published specifications in effect as of the date of shipment and will be free from material defects.</li>
							<li>Seller agrees to repair or replace any equipment which may become defective during the subscription period if: (i) Buyer gives written notice of the defective equipment to Seller within 14 days of the time when Buyer discovers or ought to have discovered the defect; (ii) Buyer returns such equipment to Seller's place of business at Seller's cost; (iii) Seller determines that Buyer followed Seller's oral or written instructions as to the storage, installation, commissioning, use or maintenance of the equipment; and that (iv) Buyer has not altered or repaired such equipment without the prior written consent of Seller.</li>
						</ol>
					</li>
					<li><u>Limitation of Liability.</u> TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAW, IN NO EVENT SHALL (A) SELLER BE LIABLE TO BUYER OR ANY THIRD PARTY FOR ANY LOSS OF USE, REVENUE OR PROFIT OR DIMINUTION IN VALUE, OR FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), SYSTEM FAILURE, NON-COMPATIBILITY OF LIVESTOCK WITH THE EQUIPMENT OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT SELLER HAS BEEN ADVISED OF THE POSSIBILTY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE; OR (B) SELLER AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO SELLER FOR THE EQUIPMENT AND SOFTWARE SOLD HEREUNDER OR, AS TO SERVICES, FOR THE AMOUNTS PAID TO SELLER FOR THE SPECIFIC SERVICE GIVING RISE TO BUYER’S CLAIM. This limitation of liability is a material basis for the parties’ bargain and reflects the bargained-for allocation of risks between Seller and Buyer, without which Seller would not have agreed to provide the equipment, software, or services at the price charged.</li>
					<li><u>Use and Perimeter Fencing.</u> Buyer acknowledges that the equipment may not be suitable for all applications, and that among other factors, breed of livestock, hair length, and climate may affect equipment functionality. Buyer agrees that Buyer has and shall continue to maintain a suitable fence around the external perimeter of the livestock’s intended pasture area throughout the duration of the subscription period, and that Seller shall not be liable for any damage caused by livestock as a result of equipment or software failure.</li>
					<li><u>Property Ownership.</u> Seller is and will remain the sole and exclusive owner of all rights in and to the software made available to Buyer and any related specifications, instructions, documentation or other materials, including, but not limited to, all related copyrights, patents, trademarks and other intellectual property rights. Buyer does not and will not have, nor will acquire, any ownership of the software made available by Seller, or of any intellectual property rights relating to the equipment or software. Buyer shall not reverse engineer, decompile or disassemble any software or equipment provided by Seller to Buyer. Seller has the right to access, use, receive, collect, or otherwise process all data acquired during the subscription period, provided that Seller is using such degree of care as is appropriate to avoid unauthorized access, use, or disclosure. Buyer shall own such equipment purchased from Seller, subject to the restrictions set forth herein, and provided that Seller reserves an exclusive right to repurchase such equipment at a nominal price determined solely by Seller, and that the shipping expense to return such equipment is borne by Seller.</li>
					<li><u>Governing Law.</u> The terms and conditions hereunder shall be governed by the laws of the State of Nebraska, without giving effect to its principles of conflicts of law, and the parties hereby irrevocably commit to the jurisdiction and venue of the courts of Nebraska to adjudicate any dispute arising hereunder or relating hereto.</li>
					<li><u>Acceptance.</u> Buyer agrees that it has reviewed the provisions detailed in the invoice and these terms and conditions. By its signature on this invoice, emailing acceptance to Buyer, or issuing payment, Buyer expressly agrees to the foregoing terms and conditions.</li>
				</ol>
			</Wrapper>
		</>
	)
}

export function TermsAndConditionsPage () {
	const navigate = useNavigate()
	const location = useLocation()
	const canGoBack = location.key !== 'default';
	return (
		<Container>
			<HomeLink onClick={() => {canGoBack ? navigate(-1) : navigate('/')}}>&times;</HomeLink>
			<LogoWrapper>
				<Logo src={CorralLogo} alt={'Corral Technologies Logo'} />
			</LogoWrapper>
			<TermsAndConditions />
		</Container>
	)
}

const Container = styled.div`
    position: relative;
    padding: 1em;
    margin: 0 auto;
    max-width: ${device.size.tablet}px;
`

const Wrapper = styled.div`
    padding: 1em;
	max-width: ${device.size.tablet}px;
    position: relative;
    color: ${colors.navy};
    li {
        margin-bottom: 1em;
        color: ${colors.navy};
    }
`
const Heading = styled.h1`
    font-size: 1.4em;
    color: ${colors.navy};
`
const HomeLink = styled.button`
    position: fixed;
    top: 0em;
    right: .25em;
    border: none;
    background: transparent;
    font-size: 2em;
    justify-content: flex-end;
    color: ${colors.primary};
`
const Logo = styled.img`
    max-width: 300px;
    margin: auto;
    `
const LogoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
`