import styled from 'styled-components/macro'
import { Icon } from 'components/icons'
import { transparentize } from 'polished'
import { colors } from 'styles'
import { InputHTMLAttributes } from 'react'
interface Props extends InputHTMLAttributes<HTMLInputElement> {
	icon?: string
	label: string
	hideLabel?: boolean
	error?: string
	defaultValue?: string | number
}
const Input = (props: Props) => {
	const hasIcon = props.icon ? true : false
	return (
		<Container $hideLabel={props.hideLabel} $hasIcon={hasIcon}>
			{<Label disabled={props.disabled} $hideLabel={props.hideLabel}>{props.label}{props.required && <RequiredStar> * </RequiredStar>}</Label>}
			<InputWrapper>
				{props.icon ? (
					<IconWrapper>
						<Icon color={'var(--dark)'} name={props.icon}></Icon>
					</IconWrapper>
				) : null}
				<InputField $hasIcon={hasIcon} {...props} />
			</InputWrapper>
		</Container>
	)
}

const Container = styled.div<{ $hasIcon?: boolean; $hideLabel?: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 0.5em;
	padding: 0;
	width: 100%;
`

const Label = styled.label<{ $hideLabel?: boolean, disabled?: boolean }>`
	font-size: .8em;
	color: ${transparentize(0.3, colors.dark)};
	font-weight: 600;
	transition: .3s ease all;
	position: absolute;
	top: 0;
	left: 0;
	${({ disabled }: { disabled?: boolean }) => disabled ? 'padding-left: .7em;' : null}
	${props => (props.$hideLabel ? 'opacity: 0; pointer-events: none;' : 'transform: translateY(0%);')}
`

const InputWrapper = styled.div`
	position: relative;
	margin-top: 1.1em;
	display: flex;
	flex-direction: column;
`

const InputField = styled.input<{ $hasIcon?: boolean }>`
	all: unset;
	padding: 0.5em;
	${props => (props.$hasIcon ? 'padding-left: 2em;' : '')}
	border: 1px solid var(--light);
	border-radius: var(--border-radius);
	position: relative;
	background-color: #ffffff;
	transition: all 0.3s ease-in-out;
	&:disabled {
		background-color: transparent;
		transform: translateY(-.25em);
		border: 1px solid transparent;
		border-radius: 0;
	}
`
const IconWrapper = styled.div`
	position: absolute;
	left: 0.5em;
	z-index: 1;
	top: calc(50% + 3px);
	transform: translateY(-50%);
	pointer-events: none;
`

const RequiredStar = styled.span`
	color: red
`

export default Input
