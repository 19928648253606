import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import { useState } from 'react'
import { SubmitButton, DeleteButton } from 'components/buttons'
import { Icon } from 'components/icons'
import DateTime from 'components/DateTime'


import LoadingSpinner from 'components/LoadingSpinner'
import Modal from 'react-bootstrap/Modal'
import { useDeleteInvitationMutation, useResendInvitationMutation } from 'library/api/invitation'


export const InviteCard = (props: any) => {
	const { invite: inviteData }: { invite: UserInvite } = props
	const [toDelete, setToDelete] = useState<string | undefined>()
	const [resent, setResent] = useState<Date | undefined>()
	const [invite, setInvite] = useState<UserInvite>(inviteData)
	const { mutateAsync: resendInvite, isPending: resendLoading } = useResendInvitationMutation()
	const { mutate: deleteInvite, isPending: deleteLoading } = useDeleteInvitationMutation()

	return (
		<>
			<Card>
				<CardWrapper>
					<InviteCardImage>
						<Icon
							size={'sm'}
							style={{
								// transform: 'scale(.75)',
								transform: 'scale(1.5)',
								margin: 'auto auto'
							}}
							color={colors.dark}
							name={'envelope'}
						/>
					</InviteCardImage>
					<InviteCardInfo>
						<InviteCardRanch>{invite?.email}</InviteCardRanch>
						<InviteCardRole>{invite?.role}</InviteCardRole>
						<InviteCardDate>Invited:&nbsp;<DateTime date={invite?.created} hideTime row /></InviteCardDate>
						<InviteCardDate>Expires:&nbsp;<DateTime date={invite?.expires} hideTime row /></InviteCardDate>
					</InviteCardInfo>
				</CardWrapper>
				<ButtonContainer>
					{!toDelete && <SubmitButton
						type={'button'}
						disabled={!!resent}
						$success={!!resent}
						onClick={async () => {
							const resentResponse: any = await resendInvite({ id: invite?.id })
							setInvite({ ...invite, expires: resentResponse?.expires })
							setResent(new Date(resentResponse?.expires))
						}}
					>
						{resent ? <><Icon name={'check'} />Sent</> : <>{resendLoading ? <LoadingSpinner/> : 'Resend'}</>}
					</SubmitButton>}
					<DeleteButton
						disabled={!!toDelete}
						type={'button'}
						onClick={async () => {
							if (toDelete) {
								deleteInvite({ id: toDelete })
								setToDelete(undefined)
							} else {
								setToDelete(invite?.id)
							}
						}}
					>
						{deleteLoading ? <LoadingSpinner/> : <>Delete</>}
					</DeleteButton>
				</ButtonContainer>
                
			</Card>
			<Modal
				show={!!toDelete}
				onHide={() => setToDelete(undefined)}
				backdrop={'static'}
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Invite?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CardWrapper>
						<InviteCardImage>
							<Icon
								size={'sm'}
								style={{
									// transform: 'scale(.75)',
									transform: 'scale(1.5)',
									margin: 'auto auto'
								}}
								color={colors.dark}
								name={'envelope'}
							/>
						</InviteCardImage>
						<InviteCardInfo>
							<InviteCardRanch>{invite?.email}</InviteCardRanch>
							<InviteCardRole>{invite?.role}</InviteCardRole>
							<InviteCardDate>Invited:&nbsp;<DateTime date={invite?.created} hideTime row /></InviteCardDate>
							<InviteCardDate>Expires:&nbsp;<DateTime date={invite?.expires} hideTime row /></InviteCardDate>
						</InviteCardInfo>
					</CardWrapper>
				</Modal.Body>
				<Modal.Footer style={{justifyContent: 'center'}}>
					<DeleteButton
						type={'button'}
						onClick={() => {
							if (toDelete) {
								deleteInvite({ id: toDelete })
								setToDelete(undefined)
							} else {
								setToDelete(invite?.id)
							}
						}}
					>
						{deleteLoading ? <LoadingSpinner/> : <>Delete</>}
					</DeleteButton>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
    @media (${device.max.tablet}) {
        flex-direction: column;
    }
    
    
`
const Card = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    border-radius: var(--border-radius);
    border: 1px solid ${transparentize(.9, colors.dark)};
    padding: 1em;
    width: 100%;
    @media (${device.max.laptop}) {
        flex-direction: column;
        flex-wrap: wrap;
    }
    transition: 0.3s ease box-shadow;
    box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0) 0px 1px 2px 0px;
    &:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
`
const InviteCardImage = styled.div`
    padding: 0;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
    border: none;
    flex-shrink: 0;
    margin: auto;
    text-align: center;
    display: flex;

    background: ${transparentize(.9, colors.dark)};
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    @media (${device.max.laptop}) {
        width: 75px;
        height: 75px;
    }
    @media (${device.max.tablet}) {
        width: 100px;
        height: 100px;
    }
`
const InviteCardInfo = styled.div`
    display: flex;
    flex-grow: 1;
    min-width: 80%;
    flex-direction: column;
    // justify-content: center;
    @media (${device.max.tablet}) {
        text-align: center;
    }
`
const InviteCardRole = styled.div`
    color: ${transparentize(.3, colors.dark)};
`
const InviteCardRanch = styled.div`
    font-weight: 600;
    color: ${transparentize(.3, colors.dark)};
    @media (${device.max.mobile}) {
        font-size: 0.8rem;
    }
`
const InviteCardDate = styled.div`
    display: flex;
    flex-direction: row;
    color: ${transparentize(.3, colors.dark)};
    @media (${device.max.tablet}) {
        justify-content: center;
    }
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;
    margin-top: auto;
    gap: 0.5rem;

    @media (${device.max.laptop}) {
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        justify-content: left;
    }
    @media (${device.max.tablet}) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    @media (${device.max.mobile}) {
        display: flex;
        // flex-direction: row;
        // justify-content: center;
    }
`