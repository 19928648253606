import styled from 'styled-components/macro'
import { colors, device } from 'styles'
import { transparentize } from 'polished'
import { ChipContainer, Chip } from 'components/buttons'
import { ProfileImage } from 'components/ProfileImage'
import { Link } from 'react-router-dom'
import { MDIcon } from 'components/icons'
import { User } from 'library/models/user.model'

export const ProfileCard = ({ user }: { user: User }) => {

	return (
		<Card>
			<CardWrapper>
				<ProfileImage user={user} />
				<ProfileCardInfo>
					<ProfileCardName>{user?.name}</ProfileCardName>
					<ProfileCardRanch>{user?.email}</ProfileCardRanch>
					{user.ranchRoles.filter((role: RanchRole) => role.role === 'Owner').length ?
						<ProfileCardRole>
							<ChipContainer>Owner:
								{user.ranchRoles.filter((role: any) => role.role === 'Owner').map((role: any) => {
									return <Chip key={role.ranchId} to={`/account/ranch/${role.ranchId}`}>{role.ranchName}</Chip>
								})}
							</ChipContainer>
						</ProfileCardRole>
						:
						null
					}
					{user.ranchRoles.filter((role: RanchRole) => role.role === 'Manager').length ?
						<ProfileCardRole>
							<ChipContainer>Manager:
								{user.ranchRoles.filter((role: any) => role.role === 'Manager').map((role: any) => {
									return <Chip key={role.ranchId} to={`/account/ranch/${role.ranchId}`}>{role.ranchName}</Chip>
								})}
							</ChipContainer>
						</ProfileCardRole>
						:
						null
					}
					{user.ranchRoles.filter((role: RanchRole) => role.role === 'Hand').length ?
						<ProfileCardRole>
							<ChipContainer>Hand:
								{user.ranchRoles.filter((role: any) => role.role === 'Hand').map((role: any) => {
									return <Chip key={role.ranchId} to={`/account/ranch/${role.ranchId}`}>{role.ranchName}</Chip>
								})}
							</ChipContainer>
						</ProfileCardRole>
						:
						null
					}
				</ProfileCardInfo>
				<ButtonContainer>
					<ViewProfileButton to={`/account/team/${user?.id}`}>View
						<MDIcon name={'chevron_right'} style={{}} />
					</ViewProfileButton>
				</ButtonContainer>
			</CardWrapper>
		</Card>
	)
}

const CardWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1em;
	flex-grow: 1;
	flex-wrap: wrap;
	@media (${device.max.tablet}) {
		flex-direction: column;
	}
`
const Card = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1em;
	border-radius: var(--border-radius);
	border: 1px solid ${transparentize(.9, colors.dark)};
	padding: 1em;
	width: 100%;
	transition: .3s ease box-shadow;
	@media (${device.max.tablet}) {
		flex-direction: column;
		flex-wrap: wrap;
	}
	box-shadow: rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0) 0px 1px 2px 0px;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
`
const ButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: flex-end;
`
const ViewProfileButton = styled(Link)`
	text-decoration: none;
	color: ${colors.dark};
	padding: .5em .5em .5em 1em;
	border-radius: 999999px;
	font-weight: 500;
	border: 1px solid ${transparentize(.9, colors.dark)};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .25em;
	&:hover {
		background: ${transparentize(.95, colors.dark)};
		color: ${colors.dark};
	}
`
const ProfileCardInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
`
const ProfileCardName = styled.div`
	font-weight: 600;
	font-size: 1.15em;
	color: ${colors.dark};
`
const ProfileCardRole = styled.div`
	color: ${transparentize(.3, colors.dark)};
`
const ProfileCardRanch = styled.div`
	font-weight: 600;
	color: ${transparentize(.3, colors.dark)};
	@media (${device.max.mobile}) {
		font-size: 0.8rem;
	}
`
